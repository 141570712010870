<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ title }}</h1>
          <div>
            <button type="button" class="mt-7 btn btn-green btn-big" (click)="openModal(createReservationModal)"  *ngIf="editAllowed"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>New Reservation</button>
          </div>
        </div>
        <form [formGroup]="reservationSearch">
          <div class="row">
            <div class="col-xs-12 col-sm-8 col-md-6">
              <label>Search for</label>
              <input class="form-control custom-input searchQuery" formControlName="searchQuery" (keyup.enter)="search('search')">
            </div>
            <div class="col-xs-12 col-sm-4 col-md-2" *ngIf="hotels.length > 1">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2">
              <label>Start date</label>
              <div class="datepicker-input">
                <input type="text" placeholder="Start date" class="form-control" bsDatepicker [(bsValue)]="startDate"
                  #startDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChange('startDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red reservation-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
            </div>
            <div class="col-xs-6 col-sm-3 col-md-2">
              <label>End date</label>
              <div class="datepicker-input">
                <input type="text" placeholder="End date" class="form-control" bsDatepicker [(bsValue)]="endDate"
                  #endDateInput="bsDatepicker" (blur)="validateDate()" (bsValueChange)="dateChange('endDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red reservation-end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-6">
              <label>Filters</label>
              <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                  <div class="menu-selected">
                    <ng-container *ngFor="let filter of getThreeFilter(); let i = index">
                      <span *ngIf="filter.checked" class="filter-item">
                        <span class="filter-label">{{filter.label}}</span>
                        <span (click)="removeFilter(filter, $event)" class="glyphicon glyphicon-remove"></span>
                      </span>
                    </ng-container>
                    <span *ngIf="getAllFilter().length > 3" class="filter-item">
                      <span class="filter-label"> + {{getAllFilter().length - 3}}</span>
                    </span>
                  </div>
                  <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
                <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                  (click)="utils.preventClose($event)">
                  <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                  <ng-container *ngFor="let filter of filters; let i = index">
                    <div class="normal-checkbox" (click)="changeFilter(i,$event)">
                      <input type="checkbox" [checked]="filter.checked">
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      <div class="checkbox-label">{{filter.label}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-2">
              <label for="select-report">Booking channel</label>
              <dropdown-menu
                [menuList]="channelList"
                [selectedItem]="selectedChannel"
                [fullWidth]="true"
                (selectItem)="selectChannel($event)"
              ></dropdown-menu>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-4">
              <label>Date selection</label>
              <div class="box-selector">
                <div class="custom-radio" *ngFor="let mode of dateMode">
                  <input type="radio" name="date-mode" [checked]="selectedDateMode.value == mode.value"
                    (click)="changeDateMode(mode)">
                  <div class="radio-box">{{mode.label}}</div>
                </div>
              </div>
            </div>
          </div>
        </form>
  
        <div class="bottom-filter" >
          <div class="bottom-filter-left">
            <div>
              <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big" (click)="search('departingToday')"><span
                class="glyphicon glyphicon-asterisk"></span> Departing today</button>
            </div>
            <div>
              <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big left-margin"
              (click)="search('arrivingToday')"><span class="glyphicon glyphicon-align-left"></span> Arriving today</button>
            </div>
            <div>
              <button type="button" [disabled]="isLoading || isMoreLoading" class="btn btn-outline-green btn-big left-margin"
              (click)="search('stayingTonight')"><span class="glyphicon glyphicon-align-right"></span> Staying tonight</button>
            </div>
          </div>
          <div class="bottom-filter-right">
            <div>
              <button type="button" class="btn btn-none-outline-green left-margin" (click)="resetSearchFields()"><span
              class="glyphicon glyphicon-trash"></span> Clear search</button>
            </div>
            <div>
              <button type="button" [disabled]="isLoading || isMoreLoading || hotels.length < 1" class="btn btn-green btn-big left-margin"
              (click)="search('search')"><span class="glyphicon glyphicon-search"></span> Search</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div>
          <!-- <label class="clickable" (click)="toggleStatusFilter()">{{showStatusFilter ? 'Hide' : 'Show'}} status filters</label> -->
          <label class="title-search">Search results</label>
          <ng-container>
            <div class="filter-checkbox-group">
              <label>Filters</label>
              <div class="filter-radio" *ngFor="let status of reservationStatus; let i = index">
                <input type="checkbox" name="reservation" [checked]="status.checked" (click)="changeStatus(i,$event)">
                <div class="radio-box">
                  <span _ngcontent-yfs-c96="" class="glyphicon glyphicon-ok"></span>
                  {{status.label}}
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="custom-table-wrapper">
          <table class="custom-table" *ngIf="!isLoading && searchResult && searchResult.reservations.length > 0">
            <colgroup>
              <col>
              <col>
              <col>
              <col>
              <col>
              <col style="min-width: 50%;">
              <col>
              <col>
            </colgroup>
            <thead>
              <th><div class="padding">Reservation</div></th>
              <th [ngClass]="{sortActive: orderBy == 'CONTACT_NAME'}">
                <div class="flex-container padding">
                  <div class="table-title">Booker Information</div>
                  <div class="table-sort" (click)="toggleSorting('CONTACT_NAME')">
                    <span class="glyphicon"
                      [ngClass]="{'glyphicon-sort-by-attributes-alt': orderBy == 'CONTACT_NAME' && orderDir == 'DESC', 'glyphicon-sort-by-attributes': orderBy != 'CONTACT_NAME' || orderDir == 'ASC'}"></span>
                  </div>
                </div>
              </th>
              <th><div class="padding">Hotel</div></th>
              <th [ngClass]="{sortActive: orderBy == 'CHECK_IN'}">
                <div class="flex-container padding">
                  <div class="table-title">Check in</div>
                  <div class="table-sort" (click)="toggleSorting('CHECK_IN')">
                    <span class="glyphicon"
                      [ngClass]="{'glyphicon-sort-by-attributes-alt': orderBy == 'CHECK_IN' && orderDir == 'DESC', 'glyphicon-sort-by-attributes': orderBy != 'CHECK_IN' || orderDir == 'ASC'}"></span>
                  </div>
                </div>
              </th>
              <th [ngClass]="{sortActive: orderBy == 'CHECK_OUT'}">
                <div class="flex-container padding">
                  <div class="table-title">Check out</div>
                  <div class="table-sort" (click)="toggleSorting('CHECK_OUT')">
                    <span class="glyphicon"
                      [ngClass]="{'glyphicon-sort-by-attributes-alt': orderBy == 'CHECK_OUT' && orderDir == 'DESC', 'glyphicon-sort-by-attributes': orderBy != 'CHECK_OUT' || orderDir == 'ASC'}"></span>
                  </div>
                </div>
              </th>
              <th><div class="padding">Guests</div></th>
              <th><div class="padding">Fully refundable</div></th>
              <th><div class="padding">Status</div></th>
            </thead>
            <tbody>
              <ng-container *ngFor="let result of searchResult.reservations">
                <tr [ngClass]="{graybg: result.state != 'CONFIRMED'}">
                  <td>
                    <a routerLink="/reservations/{{result.uuid}}"></a>
                    <b>{{result.reservationCode}}</b>
                    <br>{{getChannelLabel(result.bookingChannel)}}
                    <ng-container *ngIf="result.bookingChannelReservationId">
                      <br><br>External channel reservation number: <b>{{result.bookingChannelReservationId}}</b>
                    </ng-container>
                  </td>
                  <td>
                    <a routerLink="/reservations/{{result.uuid}}"></a>
                    {{result.customer.firstName+' '+result.customer.lastName}}
                    <br *ngIf="(result.customer.emailReal)">{{result.customer.emailReal}}
                    <br *ngIf="(result.customer.emailVirtual)">{{result.customer.emailVirtual}}
                    <br>{{result.customer.mobile}}
                  </td>
                  <td><a routerLink="/reservations/{{result.uuid}}"></a>{{showHotelBaseOnConfig(result.dropdownHotelName)}}</td>
                  <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.startDate}}</td>
                  <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.endDate}}</td>
                  <td>
                    <a class="no-absolute" routerLink="/reservations/{{result.uuid}}"></a>
                    <ng-container *ngIf="checkEmptyGuests(result.guestsByRoomLabel)">
                      <div *ngFor="let room of result.guestsByRoomLabel | keyvalue;let roomIndex = index">
                        <b>Room {{room.key}}</b>
                        <ng-container *ngIf="roomIndex === 0">
                          <div>{{result.customer.firstName + ' ' + result.customer.lastName }}</div>
                        </ng-container>
                        <ng-container *ngIf="roomIndex !== 0">
                          <ng-container *ngFor="let guest of convertToArray(room.value)">
                            <div>{{guest.firstName + ' ' +
                              guest.lastName }}</div>
                          </ng-container>
                        </ng-container>
                        <br>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!checkEmptyGuests(result.guestsByRoomLabel)">
                      <div *ngFor="let room of result.guestsByRoomLabel | keyvalue">
                        <b>Room {{room.key}}</b>
                        <div *ngFor="let guest of convertToArray(room.value)">
                          <div>{{guest.firstName + ' ' +
                            guest.lastName }}</div>
                        </div><br>
                      </div>
                    </ng-container>
                  </td>
                  <td><a routerLink="/reservations/{{result.uuid}}"></a>{{result.bookingChannelReservationId ? 'n/a' : (result.isFullyRefund ? 'Yes' : 'No')}}</td>
                  <td>
                    <a routerLink="/reservations/{{result.uuid}}"></a>
                    <span [style]="{color: result.state === 'INITIAL' ? 'red' : '#424F68'}">{{utils.showReservationStatus(result.state)}}</span>
                    <br>{{utils.convertStringDate(result.created)}}
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>

      </div>

      <div class="show-more" *ngIf="!isLoadingCountSearch && totalCount && totalCount > limitSearchReservations">
        <label>Showing {{currentCount}}/{{totalCount}}</label>
        <button class="btn btn-green large-btn show-more-btn" (click)="search('showMore')"
          *ngIf="currentCount != totalCount" [disabled]="isMoreLoading"><span class="glyphicon glyphicon-refresh"></span> Show
          {{remainingCount}} more</button>
      </div>
    </div>
  </div>
  <div class="center-item" *ngIf="isLoading">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>

  <div class="center-item" *ngIf="isMoreLoading">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>

</div>

<ng-template #createReservationModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Reservation Type</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body checkbox-margin">
    <div class="normal-checkbox radio-style">
      <input type="radio" name="type-selection" [checked]="createType == 'NORMAL'" (click)="changeCreateType('NORMAL')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Normal</div>
    </div>
    <div class="normal-checkbox radio-style" *ngIf="hotelConfig.feature.groupBooking">
      <input type="radio" name="type-selection" [checked]="createType == 'GROUP'" (click)="changeCreateType('GROUP')">
      <div class="checkbox-box">
        <div class="radio-icon"></div>
      </div>
      <div class="checkbox-label">Group</div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="submit" class="btn btn-green" (click)="openCreateReservation()">Create</button>
  </div>
</ng-template>