<header class="header">
  <div class="container-fluid header-container">
    <div class="top-header">
      <div class="top-left-header">
        <div class="container-logo">
          <img class="brand-logo" src="assets/images/nelson-white.png"> <span class="capitalize">{{ hotelName }}</span>
        </div>
      </div>
      <div class="top-right-header mobile-hide">
        <div class="header-item">
          <button *ngIf="userManagementAccess" class="btn btn-outline-yellow btn-back-admin" (click)="backToUserManagement()">
            <img class="brand-logo" src="assets/images/back-admin.svg">
            Admin
          </button>
        </div>
        <div class="header-item">
          <div class="email-account" style="cursor: pointer" routerLink="/users" [queryParams]="{editCurrentUser: true}">
            <span class="avatar">{{renderAvatar()}}</span>
          </div>
        </div>
        <div class="header-item">
          <button class="btn btn-yellow" (click)="logout()">
            <img class="brand-logo" src="assets/images/icon-signout.svg">
            Sign out
          </button>
        </div>
      </div>
      <div class="top-right-header tablet-hide">
        <div style="text-align: right; margin-bottom: 20px;">
          <div class="header-item">
            <div class="email-account" style="cursor: pointer" routerLink="/users" [queryParams]="{editCurrentUser: true}">
              <span class="avatar">{{renderAvatar()}}</span>
            </div>
          </div>
          <div class="header-item">
            <button class="btn btn-yellow" (click)="logout()">
              <img class="brand-logo" src="assets/images/icon-signout.svg">
              Sign out
            </button>
          </div>
        </div>
        <div style="text-align: right; margin-bottom: 10px;">
          <div class="header-item">
            <button *ngIf="userManagementAccess" class="btn btn-outline-yellow btn-back-admin" (click)="backToUserManagement()">
              <img class="brand-logo" src="assets/images/back-admin.svg">
              Admin
            </button>
          </div>
          <div class="header-item">
            <div class="btn-group header-hamburger-menu">
              <button type="button" class="btn btn-default" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="glyphicon glyphicon-menu-hamburger" style="color: white; font-size: 2.5rem;"></span>
              </button>
              <ul class="dropdown-menu dropdown-menu-right">
                <li *ngFor="let item of menuItems" class="header-menu-li">
                  <a class="capitalize-mobile" routerLinkActive="active" routerLink="/{{ item.pageName }}">{{ item.friendlyName }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-header">
      <div class="inner" *ngIf="menuItems.length > 0">
        <ul class="nav">
          <li *ngFor="let item of menuItems">
            <a class="capitalize" routerLinkActive="active" routerLink="/{{ item.pageName }}">{{ item.friendlyName }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>