<app-header-user-management></app-header-user-management>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">Internal tools (Nelson system)</h1>
        </div>
        <div class="notice-container">
          <div class="notice-icon">
            <span class="glyphicon glyphicon-info-sign"></span>
          </div>
          <div class="notice-content">WARNING: Internal tools is only available to Nelson Manager users and is used for various modification to Nelson system. Please read the descriptions of each tools before using them.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="body-container">
    <h3>Features are under development</h3>
  </div>
</div>