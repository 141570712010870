import { Component, OnInit, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'
import { cloneDeep } from "lodash";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { CONSTANT } from 'src/app/helpers/constants';
import { HotelService } from 'src/app/services/hotel.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { AnimationOptions } from 'ngx-lottie';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'app-hotels',
  templateUrl: './hotels.component.html',
  styleUrls: ['./hotels.component.sass','../../../styles.sass']
})
export class HotelsComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }  
  hotels: any[] = [];
  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;

  isAllStartDateEndDateNull: boolean = false;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  startDate: any = new Date();
  endDate: any = new Date();
  datepickerConfig: any = CONSTANT.datepickerConfig;
  loading: boolean = true;

  isFormValid:boolean = false;

  datepickerCustom: DatepickerDateCustomClasses[];

  constructor(
    private hotelService: HotelService,
    private storeService: StoreService,
    private toastr: ToastrService, 
    public utils: UTILS
  ) {
    this.datepickerCustom = [];
  }
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  ngOnInit(): void {
    this.refreshData();
  }
  refreshData(): void {
    this.loading = true;
    this.hotelService.getForcingSSN().subscribe(
      data => {
        this.hotels = data;
        let tmpHotelList:MenuType[] = [];
        data.forEach((hotel:any) => {
          tmpHotelList.push({
            label: hotel.hotelLabel,
            value: hotel.hotelLabel,
            disabled: false
          })
        })
        this.hotelList = tmpHotelList;
        this.selectHotel(tmpHotelList[0])
        
        this.startDate = data[0].forceSsnStartDate ? new Date(data[0].forceSsnStartDate) : new Date();
        this.endDate = data[0].forceSsnEndDate ? new Date(data[0].forceSsnEndDate) : new Date(new Date().getTime() + 24*3600*1000);
        this.loading = false;
      });
  }
  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    const findHotel = this.hotels.find((obj: any) => {
      return obj.hotelLabel == item.value;
    });
    if(findHotel) {
      this.startDate = findHotel.forceSsnStartDate ? new Date(findHotel.forceSsnStartDate) : new Date();
      this.endDate = findHotel.forceSsnEndDate ? new Date(findHotel.forceSsnEndDate) : new Date(new Date().getTime() + 24*3600*1000);
      this.validateDate();
    }
  }
  saveHotelSettings(): void {
    if(this.selectedHotel) {
      const request = {
        hotelLabel: this.selectedHotel.value,
        forceSsnStartDate: formatDate(this.startDate, "YYYY-MM-dd", CONSTANT.EN_US),
        forceSsnEndDate: formatDate(this.endDate, "YYYY-MM-dd", CONSTANT.EN_US)
      }
      this.hotelService.updateForcingHotel(request).subscribe(
        data => {
          this.toastr.success("Updated successfully", 'Success!');
          this.refreshData();
        },
        err => {
          console.log("err:", err.error);
          this.toastr.error(err.error.description, 'Error!');
        });
    }
  }
  disableForm() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.startDate && this.endDate && this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }
  updateDatepicker() {
    if(this.startDate && this.endDate) {
      let custom = [
        {date: this.startDate, classes: ['selectedDate', 'start']},
      ]
      let tmpTime = this.startDate.getTime() + 24*3600*1000;
      while(tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24*3600*1000;
      }
      custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
      this.datepickerCustom = custom
    }
  }
  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.endDate = new Date(this.startDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  isEnabledForcingSSN(hotel: any) {
    if (!hotel.forceSsnStartDate && !hotel.forceSsnEndDate) {
      return '-';
    } else {
      if (hotel.isEnable) {
        return 'Yes';
      }
      return 'No';
    }
  }
}
