<app-header></app-header>
<div class="main-container">
  <div class="container-fluid">
    <div class="row row-bottom">
      <div class="col-md-12">
        <h1 class="view-title">{{ title }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-7">
        <form [formGroup]="calendarForm">
          <!-- Select hotel-->
          <div class="col-xs-6 nls-less-col-padding" *ngIf="hotels.length > 1">
            <label for="select-report">Hotel</label>
            <dropdown-menu
              [menuList]="hotelList"
              [selectedItem]="selectedHotel"
              [fullWidth]="true"
              (selectItem)="selectHotel($event)"
            ></dropdown-menu>
          </div>
          <!-- Select month -->
          <div class="col-xs-6 nls-less-col-padding">
            <label for="month-picker">Month</label>
            <div class="input-group">
              <input type="text" placeholder="Select date" class="form-control text-input nls-input" formControlName="monthYear"
                (ngModelChange)="dateInputChange($event)">
              <span class="input-group-btn">            
                <button class="btn btn-green datepicker-btn format-calendar-report month-picker-btn" bsDatepicker [(bsValue)]="inputMonth" (bsValueChange)="updateCal($event)"
                  [bsConfig]="{ isAnimated: true, minMode:'month', containerClass:'theme-red end-cal-month' }">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </span>
            </div>
          </div>
          <div class="col-xs-12 nls-less-col-padding wrapper-calendar">
            <div class="divider"></div>
            <ul class="custom-calendar">
              <li class="days" *ngFor="let day of days">{{day}}</li>
              <li class="empty-days" *ngFor="let day of days"></li>
              <li *ngFor="let date of dates"
                [ngClass]="{outsideMonth: !isSameMonth(date.day), unavailableDay: !date.available, today: isToday(date.day)}"
                (click)="onSelectDate(date,updateBreakfastModal)">
                {{date.day | date:'d'}}
                <div class="day-price">{{utils.getCurrency(currency)}} {{date.price}}</div>
              </li>
            </ul>
          </div>
        </form>
      </div>
      <div class="col-xs-12 col-sm-5">
        <div class="row no-gutters">
          <form [formGroup]="actionForm">
            <!-- Action -->
            <div class="col-md-6 col-xs-6 nls-less-col-padding">
              <label for="select-report">Action</label>
              <dropdown-menu
                [menuList]="actionList"
                [selectedItem]="selectedAction"
                [fullWidth]="true"
                (selectItem)="selectAction($event)"
              ></dropdown-menu>
            </div>
  
            <!-- Set Price -->
            <div class="breakfast-price-field col-md-6 col-xs-6 nls-less-col-padding" *ngIf="selectedAction !== undefined && selectedAction.value === 'setPrice'">
              <label>Price</label>
              <input type="text" class="form-control searchQuery custom-input ng-pristine ng-invalid ng-touched" formControlName="actionPrice" (ngModelChange)="checkPriceValidWhenChoosingPriceAction()">
              <div class="currency-icon">{{utils.getCurrency(currency)}}</div>
            </div>
  
            <!-- Start date -->
            <div class="col-md-6 col-xs-6 nls-less-col-padding">
              <label for="start-date">Start date</label>
              <div class="datepicker-input">
                <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                  [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                  (bsValueChange)="dateChange('startDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
            </div>
  
            <!-- End date -->
            <div class="col-md-6 col-xs-6 nls-less-col-padding">
              <div [ngClass]="{customHide: infiniteEnd}">
                <label for="end-date">End date</label>
                <div class="datepicker-input">
                  <input type="text" placeholder="End date" class="form-control" bsDatepicker appEditDate
                    [(bsValue)]="endDate" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                    (bsValueChange)="dateChange('endDate')"
                    [dateCustomClasses]="datepickerCustom"
                    [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                  <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
              </div>
            </div>
  
            <!-- Show Only on specific weekdays checkbox when choosing mark unavailable action -->
            <div class="col-xs-12 nls-less-col-padding">
              <div class="normal-checkbox" style="display: block; margin-bottom: 15px;" *ngIf="selectedAction !== undefined && (selectedAction.value === 'markUnavailable' || selectedAction.value === 'setPrice')">
                <input type="checkbox" [checked]="onlyWeekdays" (click)="updateActionCheckbox('onlyWeekdays')">
                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                <div class="checkbox-label">Only on specific weekdays</div>
              </div>
              <div class="normal-checkbox" style="display: block" *ngIf="selectedAction !== undefined && selectedAction.value === 'setPrice'">
                <input type="checkbox" [checked]="infiniteEnd" (click)="updateActionCheckbox('infiniteEnd')">
                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                <div class="checkbox-label">Infinite end date</div>
              </div>
              <div class="weekday-selector" [ngClass]="{hide: !onlyWeekdays}">
                <div class="custom-radio" *ngFor="let weekday of selectedWeekdays">
                  <input type="checkbox" formControlName="selectedWeekday"
                    (ngModelChange)="weekdayChange($event,weekday.label)">
                  <div class="radio-box">{{weekday.label}}</div>
                </div>
              </div>
            </div>
  
            <div class="col-xs-12 nls-less-col-padding text-right">
              <button type="button" class="btn btn-outline-green btn-big mr-10" (click)="onOpenEmailModal(updateEmailModal)">Restaurant
                emails</button>
              <button type="submit" class="btn btn-green btn-big " (click)="onUpdateAction()" 
              [disabled]="(!isValidPriceWhenChoosingPriceAction && selectedAction !== undefined && selectedAction.value === 'setPrice') || !isFormValid || disabledBtnWhileCallingAPI">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

<!-- Modal -->
<ng-template #updateBreakfastModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Change breakfast availability</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="updateBreakfastForm" (ngSubmit)="onUpdateSingleDay()">
    <div class="modal-body" *ngIf="selectedDate">
      <div class="selected-date">
        <ng-container *ngIf="hotels.length > 1">Hotel: {{utils.hotelDisplay(hotelConfig, hotels, selectedHotel ? selectedHotel.value : "")}}</ng-container>
        <br *ngIf="hotels.length > 1">{{selectedDate.day | date:'yyyy-MM-dd'}}
      </div>
      <div class="row no-gutters">
        <div class="col-xs-6">
          <div class="custom-radio">
            <input type="radio" value="available" formControlName="selectedAvailable" (click)="updateRadioForAvaibleBreakfast('available')">
            <div class="radio-box">Available</div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="custom-radio">
            <input type="radio" value="notAvailable" formControlName="selectedAvailable" (click)="updateRadioForAvaibleBreakfast('notAvailable')">
            <div class="radio-box">NOT Available</div>
          </div>
        </div>
      </div>
      <div class="row no-gutters">
        <div class="col-xs-6">
          <label>
            <div>
              Breakfast price
              <span class="price-invalid" [ngClass]="{showError: !isValidPrice}"><br>(Price format is invalid!)</span>
            </div>
          </label>
        </div>
        <div class="col-xs-6 breakfast-price-field {{ isDisablePriceField ? 'disabled' : ''}} ">
          <input type="text" class="height-40 form-control text-input nls-input right-align" formControlName="selectedPrice" (ngModelChange)="checkPriceValid()">
          <div class="dialog-euro-icon">{{utils.getCurrency(currency)}}</div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-green" [disabled]="!(isValidPrice)">Save</button>
    </div>
  </form>
</ng-template>

<!-- Email -->
<ng-template #updateEmailModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Change breakfast report emails</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="emailModal.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <form [formGroup]="emailForm">
    <div class="modal-body">
      <div class="row no-gutters">
        <div class="col-xs-12 col-sm-3" *ngIf="hotels.length > 1">
          <label for="select-report">Hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotel"
            [fullWidth]="true"
            (selectItem)="selectHotel($event)"
          ></dropdown-menu>
        </div>
        <div class="col-xs-12 col-sm-9">
          <label for="hotel">Email</label>
          <input type="text" class="form-control custom-input" formControlName="selectedEmail">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-green" [disabled]="emailForm.invalid" (click)="onUpdateEmail()">Save</button>
    </div>
  </form>
</ng-template>