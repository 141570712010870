import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { InternalToolsService } from 'src/app/services/internal-tools.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-manual-external-retrieve',
  templateUrl: './manual-external-retrieve.component.html',
  styleUrls: ['../internal-tools-mui.component.sass']
})
export class ManualExternalRetrieveComponent implements OnInit {

  constructor(
    private storeService: StoreService,
    public utils: UTILS,
    private internalToolsService: InternalToolsService,
    private toastr: ToastrService,
  ) { }

  @Input() hotels:any;
  hotelMenu:any[] = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  manageMenu:any = {
    hotel: false,
    channel: false
  }
  selectedHotel:any;
  bookingChannel:any[] = [];
  selectedBookingChannel:any;

  responseSuccess:boolean = false;

  retrieveForm = new FormGroup({
    externalNumber: new FormControl('', Validators.required),
  });
  get externalNumber() {
    return this.retrieveForm.get("externalNumber")
  }

  @HostListener('document:click', ['$event']) documentClickEvent($event: MouseEvent) {
    Object.keys(this.manageMenu).forEach((menu:any) => {
      this.manageMenu[menu] = false;
    })
  }

  ngOnInit(): void {
    let tmpChannels:any = [];
    CONSTANT.CHANNEL_BOOKING.forEach(channel => {
      if((CONSTANT.OTA_CHANNEL.includes(channel.value) && this.hotelConfig.MUIfeature.OTAServices.includes(channel.value))) {
        tmpChannels.push(channel);
      }
    })
    this.bookingChannel = tmpChannels;
    this.selectedBookingChannel = tmpChannels[0]
  }

  ngOnChanges() {
    if(this.hotels.length) {
      this.hotelMenu = this.hotels;
      this.selectedHotel = this.hotels[0];
    }
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error.description) errorMessage = err.error.description;
    if(err.error.message) errorMessage = err.error.message;
    if(!errorMessage) errorMessage = "Unknown error occurred!";
    this.toastr.error(errorMessage, 'Error!');
  }

  showDropdown(type:string, e:any) {
    e.stopPropagation();
    Object.keys(this.manageMenu).forEach((menu:any) => {
      if(menu !== type) {
        this.manageMenu[menu] = false;
      }
    })
    this.manageMenu[type] = !this.manageMenu[type];
  }

  hotelChange(hotel:any) {
    this.selectedHotel = hotel
  }

  channelChange(item:any) {
    this.selectedBookingChannel = item;
  }

  retrieve() {
    this.responseSuccess = false;
    this.retrieveForm.markAllAsTouched();
    if(this.retrieveForm.valid && this.externalNumber) {
      this.internalToolsService.retrieveOTAreservation(this.selectedBookingChannel.value, this.externalNumber.value, this.selectedHotel.label).subscribe(
        data => {
          this.responseSuccess = true;
        },
        error => {
          this.displayAPIerror(error)
        }
      )
    }
  }

}
