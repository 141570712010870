<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">Customer card</h1>
        </div>
        
        <div class="notice-container" *ngIf="customerNotFound">
          <div class="notice-icon">
            <span class="glyphicon glyphicon-info-sign"></span>
          </div>
          <div class="notice-content">Customer not found or there is a new customer who just recently booked a reservation but has not paid or confirmed yet.</div>
        </div>
        <div class="row" *ngIf="!customerNotFound">
          <div class="col-xs-12">
            <!-- <table class="table table-customer-card" *ngIf="!loading">
              <thead>
                <tr>
                  <th>
                    <h4 class="contact-infomation"><b>Contact Infomation</b></h4>
                  </th>
                  <th>
                    <h4 class="contact-infomation"><b>Status & Statistics</b></h4>
                  </th>
                  <th>
                    <h4 class="comments"><b>Comments</b></h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="30%">
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Email: </b></div>
                      <div class="col-sm-7 col-md-7 truncate-long-text" tooltip="{{customerCard.customer.email}}"
                        placement="right">
                        {{ customerCard.customer.email? customerCard.customer.email : '' }}
                      </div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Full name: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.firstName }} {{ customerCard.customer.lastName
                        }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Mobile: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.mobile }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Nationality: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.nationality ?
                        getCustomerNationality(customerCard.customer.nationality) : ''}}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>SSN: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.ssn }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Address: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.address }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>Postal code: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.postalCode }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-5 col-md-5"><b>City: </b></div>
                      <div class="col-sm-7 col-md-7">{{ customerCard.customer.city }}</div>
                    </div>
                  </td>
                  <td width="35%" *ngIf="isStatusStatistics">
                    <div class="row attr-title hidden">
                      <div class="col-sm-6 col-md-6"><b>Member since: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.memberSince ?
                        (customerCard.statusStatistics.memberSince | date:'dd.MM.yyyy') : 'na' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Lifetime bookings: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.lifeTimeBookings ?
                        customerCard.statusStatistics.lifeTimeBookings : customerCard.statusStatistics.lifeTimeBookings }}
                        ({{ customerCard.statusStatistics.asMember ? customerCard.statusStatistics.asMember : 0 }} as
                        member)</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Lifetime spend: </b></div>
                      <div class="col-sm-6 col-md-6" *ngIf="customerCard.statusStatistics.lifeTimeSpend">{{
                        formatCurrency(customerCard.statusStatistics.lifeTimeSpend) }}</div>
                      <div class="col-sm-6 col-md-6" *ngIf="!customerCard.statusStatistics.lifeTimeSpend">0</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Visits as guest only: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.visitAsGuest }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Subscribed: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.subscribed ? 'Yes' : 'No' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Last stay: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.lastStay | date:'dd.MM.yyyy' }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Discount codes: </b></div>
                      <div class="col-sm-6 col-md-6 truncate-long-text" tooltip="{{customerCard.statusStatistics.discountCodes}}" placement="right">
                        {{ truncateDiscountCodesForStatus(customerCard.statusStatistics.discountCodes) }}</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Cancellation rate: </b></div>
                      <div class="col-sm-6 col-md-6">{{ customerCard.statusStatistics.cancellationRate ?
                        customerCard.statusStatistics.cancellationRate : 0 }} %</div>
                    </div>
                    <div class="row attr-title">
                      <div class="col-sm-6 col-md-6"><b>Blocked: </b></div>
                      <div class="col-xs-2 col-sm-1 col-md-1">{{ customerCard.customer.state === 'BLOCKED' ? 'Yes' : 'No' }}
                      </div>
                      <div class="col-xs-4 col-sm-5 col-md-5">
                        <a  class="edit-btn" (click)="editBlocklist()">
                          <span class="glyphicon glyphicon glyphicon-edit"></span> Edit
                        </a>
                      </div>
                    </div>
                  </td>
                  <td width="35%" *ngIf="!isStatusStatistics">
                    <div class="row">
                      <div class="loading-container row loading_contact_info" [ngClass]="{active: loading}">
                        <div class="custom-loading"></div>
                      </div>
                    </div>
                  </td>
                  <td width="35%">
                    <div class="comments-customer-info customer-card-comment-view comment-width">
                      <div *ngFor="let comment of customerCard.commentSearchResult.results">
                        <div class=" wrapper-comment">
                          <div class="detail-comments">
                            <div class="comment-format">{{ comment.created | date:'dd.MM.yyyy HH:mm' }}</div>
                            <div class="comment-format">{{ comment.email }}</div>
                            <div class="comment-title">{{ comment.comment }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="comment-title see-more-comment"
                        *ngIf="customerCard.commentSearchResult.total > (commentOffset + commentLimit)"
                        (click)="showMoreComments()">See more comments</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table> -->

            <div class="row">
              <!-- Contact -->
              <div class="col-sx-12 col-sm-6 col-md-4">
                <h4><b>Contact Infomation</b></h4>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Email: </b></div>
                  <div class="col-xs-6 truncate-long-text" tooltip="{{customerCard.customer.email}}"
                    placement="right">
                    {{ customerCard.customer.email? customerCard.customer.email : '' }}
                  </div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Full name: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.firstName }} {{ customerCard.customer.lastName
                    }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Mobile: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.mobile }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Nationality: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.nationality ?
                    getCustomerNationality(customerCard.customer.nationality) : ''}}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>SSN: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.ssn }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Passport number: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.passportNumber }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Address: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.address }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>Postal code: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.postalCode }}</div>
                </div>
                <div class="row attr-title">
                  <div class="col-xs-6"><b>City: </b></div>
                  <div class="col-xs-6">{{ customerCard.customer.city }}</div>
                </div>
              </div>
              <!-- Status -->
              <div class="col-sx-12 col-sm-6 col-md-4 status-col">
                <h4><b>Status & Statistics</b></h4>
                <div *ngIf="isStatusStatistics">
                  <!-- All Member features needs to be disabled.  -->
                  <div [ngClass]="{hidden: !hotelConfig.feature.membership}" class="row attr-title">
                    <div class="col-xs-6"><b>Member since: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.memberSince ?
                      (customerCard.statusStatistics.memberSince | date:'dd.MM.yyyy') : 'na' }}</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Lifetime bookings: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.lifeTimeBookings ?
                      customerCard.statusStatistics.lifeTimeBookings : customerCard.statusStatistics.lifeTimeBookings }}
                      ({{ customerCard.statusStatistics.asMember ? customerCard.statusStatistics.asMember : 0 }} as
                      member)</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Lifetime spend: </b></div>
                    <div class="col-xs-6" *ngIf="customerCard.statusStatistics.lifeTimeSpend">{{
                      formatCurrency(customerCard.statusStatistics.lifeTimeSpend) }}</div>
                    <div class="col-xs-6" *ngIf="!customerCard.statusStatistics.lifeTimeSpend">0</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Visits as guest only: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.visitAsGuest }}</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Subscribed: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.subscribed ? 'Yes' : 'No' }}</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Last stay: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.lastStay | date:'dd.MM.yyyy' }}</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Discount codes: </b></div>
                    <div class="col-xs-6 truncate-long-text" tooltip="{{customerCard.statusStatistics.discountCodes}}" placement="right">
                      {{ truncateDiscountCodesForStatus(customerCard.statusStatistics.discountCodes) }}</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-6"><b>Cancellation rate: </b></div>
                    <div class="col-xs-6">{{ customerCard.statusStatistics.cancellationRate ?
                      customerCard.statusStatistics.cancellationRate : 0 }} %</div>
                  </div>
                  <div class="row attr-title">
                    <div class="col-xs-3 col-md-4"><b>Blocked: </b></div>
                    <div class="col-xs-1 col-sm-1 col-md-1">{{ customerCard.customer.state === 'BLOCKED' ? 'Yes' : 'No' }}
                    </div>
                    <div class="col-xs-4">
                      <a  class="edit-btn" (click)="editBlocklist()">
                        <span class="glyphicon glyphicon glyphicon-edit"></span> Edit
                      </a>
                    </div>
                  </div>
                </div>
                <div *ngIf="!isStatusStatistics">
                  <div class="row">
                    <!-- Loading icon -->
                    <div class="center-item" *ngIf="loading">
                      <ng-lottie
                        [options]="options"
                        width="200px"
                        height="200px"
                      ></ng-lottie>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Comment -->
              <div class="col-sx-12 col-sm-12 col-md-4">
                <h4><b>Comments</b></h4>
                <div class="comments-customer-info customer-card-comment-view comment-width">
                  <div *ngFor="let comment of customerCard.commentSearchResult.results">
                    <div class=" wrapper-comment">
                      <div class="detail-comments">
                        <div class="comment-format">{{ comment.created | date:'dd.MM.yyyy HH:mm' }}</div>
                        <div class="comment-format">{{ comment.email }}</div>
                        <div class="comment-title">{{ comment.comment }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="comment-title see-more-comment"
                    *ngIf="customerCard.commentSearchResult.total > (commentOffset + commentLimit)"
                    (click)="showMoreComments()">See more comments</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
<!-- Bookings -->
<div class="wrapper-outer-table" *ngIf="!customerNotFound">
  <div class="outer-table">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <label class="title-search">Bookings</label>
        </div>
      </div>
      <div class="row" *ngIf="!loading">
        <div class="col-xs-12 col-md-12">
          <div class="custom-table-container">
            <table class="table table-bordered table-hover table-striped table-bookings custom-table">
              <thead>
                <tr>
                  <th class="reservation">Reservation</th>
                  <th class="hotel">Hotel</th>
                  <th class="check-in">Check in</th>
                  <th class="check-out">Check out</th>
                  <th class="status">Status</th>
                  <th class="as-member">As member</th>
                  <th class="discount-code">Discount code</th>
                </tr>
              </thead>
              <tbody>
                <!-- At bookings table, booking row should be clickable and link to booking reservation detail page. -->
                <tr class="customer-row" *ngFor="let booking of customerCard.bookingSearchResult.results"
                [routerLink]="['/reservations/'+ booking.uuid]">
                  <td>{{ booking.reservationCode }}</td>
                  <td>{{ booking.hotelLabel }}</td>
                  <td>{{ booking.checkIn | date:'dd.MM.yyyy' }}</td>
                  <td>{{ booking.checkOut | date:'dd.MM.yyyy' }}</td>
                  <td>{{ booking.status }}</td>
                  <td>{{ booking.member ? 'Yes' : 'No' }}</td>
                  <td class="truncate-long-text" tooltip="{{booking.discountCode}}" placement="right">{{
                    truncateDiscountCodeForBookings(booking.discountCode) }}</td>
                </tr>
              </tbody>
            </table>
          </div>    
          <div class="past-booking text-center"
            *ngIf="customerCard.bookingSearchResult.total > (bookingOffset + bookingLimit)" (click)="goToPastBooking()">
            <a>Past booking >></a></div>
        </div>
        <div class=" row-bottom show-back">
          <div class="col-xs-12 text-left" >
            <label class="customer-total-count" (click)="backToCustomers()"><span class="glyphicon glyphicon-chevron-left"></span>Back</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Loading icon -->
<div class="center-item" *ngIf="loading">
  <ng-lottie
    [options]="options"
    width="200px"
    height="200px"
  ></ng-lottie>
</div>
<br />
</div> 
<br />