<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main"> 
          <div class="heading">
            <h1 class="view-title">Allotments</h1>
            <button type="button" class="btn btn-green btn-big" (click)="redirectAllotment()"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Create</button>
          </div>
        </div>
  
        <form [formGroup]="allotmentForm" (ngSubmit)="onSearch()">
          <div class="row">
            <div class="col-lg-3 col-sm-6">
              <label for="name">Name</label>
              <input type="text" formControlName="name" class="form-control custom-input" />
            </div>
            <div class="col-lg-3 col-sm-6">
              <label for="hotel">Description</label>
              <input type="text" formControlName="description" class="form-control custom-input" />
            </div>
            <div class="col-lg-3 col-sm-6">
              <label for="hotel">Start date</label>
              <!-- <div class="input-group datepicker">
                <input type="text" class="form-control text-input datepicker-input nls-input" placement="bottom"
                  formControlName="startDate" (ngModelChange)="mySelectStartDate($event)" maxlength="10">
                <span class="input-group-btn">
                  <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [(bsValue)]="bsStartDateValue"
                    (bsValueChange)="onValueChangeStartDate($event)" [bsConfig]="datepickerConfig">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </span>
              </div> -->
              <div class="datepicker-input">
                <input type="text" placeholder="Start date" class="form-control" bsDatepicker 
                  [(bsValue)]="bsStartDateValue" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                  (bsValueChange)="dateChange('startDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <label for="hotel">End date</label>
              <!-- <div class="input-group datepicker">
                <input type="text" class="form-control text-input datepicker-input nls-input" placement="bottom"
                  formControlName="endDate" (ngModelChange)="mySelectEndDate($event)">
                <span class="input-group-btn">
                  <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [(bsValue)]="bsEndDateValue"
                    (bsValueChange)="onValueChangeEndDate($event)" [bsConfig]="datepickerConfig">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </span>
              </div> -->
              <div class="datepicker-input ">
                <input type="text" placeholder="End date" class="form-control" bsDatepicker 
                  [(bsValue)]="bsEndDateValue" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                  (bsValueChange)="dateChange('endDate')" 
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
            </div>

            <div class="col-lg-6 col-sm-6 col-md-6">
              <label for="hotel">Hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>


            <div class="col-sm-6 search-wrapper">
              <!-- Some buttons for allotments -->
                <div>
                  <label for="hotel">&nbsp;</label>
                  <button type="button" class="btn btn-none-outline-green" (click)="clearSearch()"><span
                    class="glyphicon glyphicon-trash mr-9"></span>Clear Search
                  </button>
                </div>
                <div>
                  <label for="hotel">&nbsp;&nbsp;&nbsp;</label>
                  <button type="submit" [disabled]="!(isValidStartDate && isValidEndDate) || isLoading" class="btn btn-green btn-big">
                    <span class="glyphicon glyphicon-search"></span> Search
                  </button>
                </div>
                <!-- <div class="col-lg-6">
                  <label for="hotel">&nbsp;</label>
                  <button type="submit" [disabled]="!(isValidStartDate && isValidEndDate)" class="btn btn-green btn-big">
                    <span class="glyphicon glyphicon-search"></span> Search
                  </button>
                </div> -->            
            </div>
            
          </div>
        </form>
      </div>
      <!-- <loading *ngIf="isLoading"></loading> -->
    </div>
  </div>
  <div class="wrapper-outer-table" *ngIf="allotments.length>0">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <label class="title-search">History</label>
          </div>
        </div>
        <div class="row " *ngFor="let a of allotments" class="{{a.cancelled ? 'blur-more': ''}}">
          <!-- <div class="row row-bottom" *ngFor="let a of allotments | orderBy: 'name'" class="{{a.cancelled ? 'blur-more': ''}}"> -->
            <div class="col-sm-12 ">
              <div class="col-sm-12 allotment-info">
                <div class="col-sm-10 info-block" (click)="goDetailHotelAllotment(a)">
                  <div class="row">
                    <div class="col-sm-6">
                      <p class="name">{{a.name}}</p>
                    </div>
                    <div style="text-align: right;" class="col-sm-6">
                      <span class="created-date"> date create - {{a.created | date: 'dd.MM.yyyy'}}</span>
                    </div>
                  </div>
                  <p class="description">{{a.description || '&nbsp;'}}</p>
                </div>
                <div class="col-sm-1 sub-info-block">
                  <span class="type"
                    class="{{(a.allotmentType === 'SOFT') ? 'type-soft' : 'type-hard'}}">{{a.allotmentType}}</span>
                </div>
                <div class="col-sm-1 action-block" (click)="redirectAllotmentById(a.id)">
                  <span class="glyphicon glyphicon glyphicon-edit go-to-edit"></span>
                </div>
              </div>
              <div class="col-sm-12" *ngIf="a.isShowHotelAllotment">
                <!-- <loading *ngIf="isHotelAllotment"></loading> -->
                <div class="allotment-detail-info grey-border custom-table-container" *ngIf="!isHotelAllotment">
                  <table class="table custom-table">
                    <thead>
                      <tr>
                        <th>Hotel</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Day restriction</th>
                        <th>Usage</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let d of a.hotelAllotment">
                        <td>{{d.label}}</td>
                        <td>{{d.startDate | date:'dd.MM.yyyy'}}</td>
                        <td>{{d.endDate | date:'dd.MM.yyyy'}}</td>
                        <td dayAsCheckbox [dayRestriction]="d.dayOfWeeks">
                        </td>
                        <td>
                          <a tooltip="See usage" placement="right">
                            <span class="glyphicon glyphicon-link" (click)="goToUsage(d)"></span>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="allotment-voucher-block">
                    <strong class="allotment-voucher-title">Vouchers: </strong>
                    <span *ngIf="a.vouchers">{{a.vouchers.join(', ')}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>


<app-footer></app-footer>