import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const URL_HOTELS = "/api/hotel-chains/1/hotels";
const URL_REPORTS_EXPORT = "/api/management/secure/reports/";
const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8'});

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  public getHotels(): Observable<any> {
    return this.http.get(URL_HOTELS);
  }
  public reportsExport(url: any): Observable<any> {
    return this.http.get(url, { headers, responseType: 'blob' });
  }
}
