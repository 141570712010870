import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appEditPriceWithZero]'
})
export class EditPriceWithZeroDirective {

  constructor(private el: ElementRef) { 
    
  }

  @HostListener('input')
  onChange() {
    let element = this.el.nativeElement;
    let inputValue = element.value;
    let pricePattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
    if(pricePattern.test(inputValue)) {
      element.classList.remove('invalid');
    } else {
      element.classList.add('invalid');
    }
  }

}
