import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paymentProviderFilter'
})
export class PaymentProviderFilterPipe implements PipeTransform {

  transform(paymentProvider:any): any {
    if (!paymentProvider) return '';
    switch (paymentProvider) {
      case 'MONETARY_VOUCHER':
        return 'Monetary Voucher';
      case 'PAYTRAIL':
        return 'Paytrail';
      case 'PAYTRAIL_2':
        return 'Paytrail';
      case 'SVEA_INVOICE':
        return 'Svea Invoice';
      case 'FENNOA_INVOICE':
        return 'Fennoa';
      case 'MANUAL_INVOICE':
        return 'Manual Invoice'
      case 'TYL':
        return 'Tyl by NatWest';
      case 'BANK_TRANSFER':
        return 'Payment by Booking.com - Bank Transfer';
      case 'OTHER':
        return 'Other';
      default:
        return paymentProvider;
    }
  }

}
