import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { AuthService } from 'src/app/services/auth.service';
import { TenantService } from 'src/app/services/tenant.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { environment } from 'src/environments/environment';

type LoginRole = {
  roleId: string,
  roleName: string
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  content: any = {
    clientTitle: 'Omena Hotels',
    usernameLabel: 'Username',
    passwordLabel: 'Password',
    newPasswordLabel: 'New Password',
    confirmNewPasswordLabel: 'Confirm New Password',
    signInBtn: 'Sign in'
  }
  form: any = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  isLoggedIn: boolean = false;
  requirePasswordUpdate: boolean = false;
  newPasswordSession: string = '';
  uiTabs: any = [];
  disabledBtnWhileCallingAPI: boolean = false;
  lastUrl: string = "";
  constructor(
    private router: Router,
    public formBuilder: FormBuilder,
    private authService: AuthService,
    private tokenStorageService: TokenStorageService,
    public utils: UTILS,
    private toastr: ToastrService,
    private tenantService: TenantService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.router.navigateByUrl("/dashboard");
    } else {
      this.route.queryParamMap.subscribe(params => {
        let routeLastUrl = "";
        let paramKeys = params.keys;
        const paramLastUrl = params.get('lastUrl');
        if(paramKeys.includes('lastUrl') && paramLastUrl) {
          routeLastUrl = paramLastUrl;
        } else {
          this.lastUrl = "";
        }
        if(!this.utils.isLocalEnvironment() && environment.hotel !== "admin") {
          let loginUrl = "";
          const adminEnv = "admin.nelson.management";
          const getLastUrl = encodeURIComponent(routeLastUrl);
          loginUrl = `https://${adminEnv}/#/login`;
          // if(routeLastUrl) {
          //   loginUrl = `https://${adminEnv}/#/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`;
          // } else {
          //   loginUrl = `https://${adminEnv}/#/login`;
          // }
          window.open(loginUrl, '_self');
        } else {
          this.lastUrl = routeLastUrl;
        }
      })
    }
  }

  checkValidateForm(): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.form.controls.email.status === "INVALID") {
      if (this.form.controls.email.errors.required) {
        this.toastr.error("Email is required.", 'Error!');
        return isErrorForm;
      }
      if (this.form.controls.email.errors.email) {
        this.toastr.error("Please, enter valid email address.", 'Error!');
        return isErrorForm;
      }
    }
    if (!this.requirePasswordUpdate) {
      // Check password
      if (this.form.controls.password.status === "INVALID") {
        if (this.form.controls.password.errors.required) {
          this.toastr.error("Password is required.", 'Error!');
          return isErrorForm;
        }
        if (this.form.controls.password.errors) {
          this.toastr.error("Please enter a valid password.", 'Error!');
          return isErrorForm;
        }
      }
    }
    else {
      if (this.form.controls.newPassword.status === "INVALID") {
        if (this.form.controls.newPassword.errors.required) {
          this.toastr.error("Please enter a new password.", 'Error!');
          return isErrorForm;
        }
        if (this.form.controls.newPassword.errors) {
          this.toastr.error("Please enter a valid new password.", 'Error!');
          return isErrorForm;
        }
      }
      if (this.form.value.newPassword != this.form.value.confirmNewPassword) {
        this.toastr.error("New password and confirm password do not match.", 'Error!');
        return false;
      }
    }
    return !isErrorForm;
  }
  get watchSignInForm() {
    return this.form.controls;
  }
  get isDisabledSignInBtn() {
    return !!this.form.invalid;
  }
  onSubmit(): void {
    if (!this.checkValidateForm()) {
      return;
    }
    this.disabledBtnWhileCallingAPI = true;
    if (!this.requirePasswordUpdate) {
      this.authService.login(this.form.value.email, this.form.value.password).subscribe(
        data => {
          if (data.challenge) {
            this.requirePasswordUpdate = true;
            this.newPasswordSession = data.session;
            this.content.signInBtn = 'Update password';
          }
          else if (data.token) {
            this.toastr.success("Log in successfully", 'Success!', {
              timeOut: 2000
            });
            this.tokenStorageService.saveRefreshTokenToken(data.refreshtoken);
            this.performLogin(data.token, this.form.value.email);
          }
          this.disabledBtnWhileCallingAPI = false;
        },
        err => {
          this.toastr.error('Login failed. Please check credentials', 'Invalid credentials!');
          this.disabledBtnWhileCallingAPI = false;
          this.requirePasswordUpdate = false;
          this.content.signInBtn = 'Sign in';
        }
      );
    }
    else {
      this.authService.confirmUser(this.form.value.email, this.form.value.newPassword, this.newPasswordSession).subscribe(
        data => {
          if (data.token) {
            this.toastr.success("User confirmed successfully", 'Success!', {
              timeOut: 2000
            });
            this.performLogin(data.token, this.form.value.email);
          }
          this.disabledBtnWhileCallingAPI = false;
        },
        err => {
          this.toastr.error('Please try again', 'Error!');
          this.content.signInBtn = 'Sign in';
          this.disabledBtnWhileCallingAPI = false;
          this.requirePasswordUpdate = false;
          this.newPasswordSession = '';
        }
      );
    }
  }
  performLogin(token: string, email: string) {
    this.tokenStorageService.saveToken(token);
    this.tokenStorageService.saveUser(this.tokenStorageService.decodeJwtToken(token).name);
    const roles = this.tokenStorageService.decodeJwtToken(token).roles;
    window.localStorage.removeItem("role");
    this.isLoggedIn = true;
    localStorage.setItem("isUserManagement", `${this.utils.isRoleUserManagement() || this.tokenStorageService.getEnvironmentIds().includes(',')}`);
    this.uiTabs = this.tokenStorageService.getPagesFromUserRoles(roles);
    if (this.tokenStorageService.getEnvironmentIds().includes(',') || this.utils.isRoleUserManagement()) {
      if (this.uiTabs && this.uiTabs.length > 0) {
        this.router.navigateByUrl("/" + this.uiTabs[0]);
      }
    } else {
      this.tenantService.loadTenants({ tenantids: this.tokenStorageService.getTenantIds(), environmentids: this.tokenStorageService.getEnvironmentIds() }).subscribe(
        (data: any) => {
          let tokenStorage = this.tokenStorageService.getToken();
          let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
          if (!this.utils.isLocalEnvironment()) {
            this.isLoggedIn = false;
            window.localStorage.clear();
          }
          if(tokenStorage && refreshTokenStorage) {
            window.open(`${data.tenants[0].environments[0].managementsitehost}/#/${this.uiTabs[0]}?token=${tokenStorage}&refreshtoken=${refreshTokenStorage}`, '_self');
          }
        },
        (errResp: any) => {
          this.toastr.error("Error loading environment.", 'Error!');
          this.isLoggedIn = false;
          this.tokenStorageService.logOut();
        }
      )
    }
    // if(this.lastUrl) {
    //   let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
    //   const finalUrl = this.lastUrl.includes("?") ? `&token=${token}&refreshtoken=${refreshTokenStorage}` : `?token=${token}&refreshtoken=${refreshTokenStorage}`;
    //   window.open(`${this.lastUrl}${finalUrl}`, "_self")
    // } else {
    //   if (this.tokenStorageService.getEnvironmentIds().includes(',') || this.utils.isRoleUserManagement()) {
    //     if (this.uiTabs && this.uiTabs.length > 0) {
    //       this.router.navigateByUrl("/" + this.uiTabs[0]);
    //     }
    //   }
    //   else {
    //     this.tenantService.loadTenants({ tenantids: this.tokenStorageService.getTenantIds(), environmentids: this.tokenStorageService.getEnvironmentIds() }).subscribe(
    //       (data: any) => {
    //         let tokenStorage = this.tokenStorageService.getToken();
    //         let refreshTokenStorage = this.tokenStorageService.getRefreshToken();
    //         if (!this.utils.isLocalEnvironment()) {
    //           this.isLoggedIn = false;
    //           window.localStorage.clear();
    //         }
    //         window.open(`${data.tenants[0].environments[0].managementsitehost}/#/${this.uiTabs[0]}?token=${tokenStorage}&refreshtoken=${refreshTokenStorage}`, '_self');
    //       },
    //       (errResp: any) => {
    //         this.toastr.error("Error loading environment.", 'Error!');
    //         this.isLoggedIn = false;
    //         this.tokenStorageService.logOut();
    //       }
    //     )
    //   }
    // }
  }
}
