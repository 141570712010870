import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8'});


const hotelAPI = '/api/hotel-chains/1/hotels';
const hotelOfferAPI = '/api/hotel-offers';
const secureManagement = '/api/management/secure';
const secureManagementForInvoice = '/api/management/secure/manual-invoice';
const reservationsAPI = secureManagement + '/reservations';
const reservationsForInvoiceAPI = secureManagementForInvoice + '/reservations';
const lastestReservationForInvoiceAPI = secureManagementForInvoice + '/latest/reservation';
const reservationForInvoiceAPI = secureManagementForInvoice + '/reservation';
const createCreditInvoiceForInvoiceAPI = secureManagementForInvoice + '/credit';
const countreservationsForInvoiceAPI = secureManagementForInvoice + '/reservations-count';
const createPdfForInvoiceManualAPI = secureManagementForInvoice + '/create-pdf';
const reservationsCountAPI = secureManagement + '/reservations-count';
const userActivityAPI = secureManagement + '/user_activity_log';
const doorCodeAPI = `${ reservationsAPI }/{reservationUuid}/door-code`;
const contactInfoAPI = secureManagement + '/contactInfo/';
const addPaymentAPI = secureManagement + '/add-manual-payment';
const completeManualRefund = secureManagement + '/complete-manual-refund';
const refundAPI = secureManagement + '/payments/refund-broken-item';
const refundGiftcardAPI = secureManagement + '/payments/refund-gift-card';
const priceAPI = secureManagement + '/prices';
const blockListAPI = '/api/blocking/blocklist-check';
const markDirtyRoomAPI = secureManagement + '/rooms/mark-dirty-rooms';
const markCleanRoomAPI = secureManagement + '/rooms/mark-clean-rooms';
const proceedRefundAPI = '/api/payments/refund';
const fennoaCustomerAPI = secureManagement + '/fennoa/customers';
const fennoaSaveInvoice = secureManagement + '/fennoa/sales-invoice/add';
const fennoaApproveSend = secureManagement + '/fennoa/sales-invoice/approve-and-send';
const fennoaGetInvoice = secureManagement + '/fennoa/sales-invoice';
const domainSite = secureManagement + '/site-host';
const availabilityCheck = '/api/availability-check';
const checkCreatePdf = createPdfForInvoiceManualAPI + '/check';
const manualPaymentType = '/manual-payment-providers';

@Injectable({
  providedIn: 'root'
})
export class ReservationsService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  public getHotels(): Observable<any> {
    return this.http.get(hotelAPI);
  }

  public searchReservations(request: any): Observable<any> {
    return this.http.get<any>(reservationsAPI, {params: request});
  }

  public countReservations(request: any): Observable<any> {
    return this.http.get<any>(reservationsCountAPI, {params: request});
  }

  public getDetail(request:any): Observable<any> {
    return this.http.get<any>(reservationsAPI + '/' + request);
  }

  public getUserActivity(request:any): Observable<any> {
    return this.http.get<any>(userActivityAPI + '/comments/reservation/' + request);
  }
  public saveComment(request: any) {
    return this.http.post<any>(userActivityAPI + '/comment/save', request, {});
  }

  public saveCustomerEdit(request: any, uuid:string) {
    return this.http.post<any>(contactInfoAPI + uuid, request, {});
  }

  public saveGuest(request:any, uuid:string, guestId:number) {
    return this.http.put<any>(`/api/management/secure/reservations/${uuid}/guests/${guestId}`, request, {})
  }

  public getDoorCode(uuid:any, orderId:any): Observable<any> {
    return this.http.get<any>(`${ doorCodeAPI.replace('{reservationUuid}', uuid) }/get`, {
      params: {
        productOrderId: orderId
      }
    });
  }

  public resetDoorCode(orderId:any, uuid:any): Observable<any> {
    return this.http.get<any>(`${ doorCodeAPI.replace('{reservationUuid}', uuid) }/reset`, {
      params: {
        productOrderId: orderId
      }
    });
  }

  public addManualPayment(request: any) {
    return this.http.post<any>(addPaymentAPI, request, {});
  }

  public sendDoorCodeMessage(uuid:string, request:string, type:string) {
    let requestType;
    switch(type) {
      case 'sms':
        requestType = 'sendWelcomeSMS';
        break;
      case 'email':
        requestType = 'sendWelcomeEmail';
        break;
    }
    return this.http.put<any>(`${secureManagement}/${uuid}/${requestType}`, request, {});
  }

  public sendReminderMessage(uuid:string, request:string, type:string) {
    let requestType;
    switch(type) {
      case 'sms':
        requestType = 'sendReminderSMS';
        break;
      case 'email':
        requestType = 'sendReminderEmail';
        break;
    }
    return this.http.put<any>(`${secureManagement}/${uuid}/${requestType}`, request, {});
  }

  public sendConfirmation(uuid:string, request:string, type:string, sendExtra:boolean) {
    let requestType;
    let extra = '';
    if(sendExtra) {
      extra = '/extra';
    }
    switch(type) {
      case 'sms':
        requestType = 'sendSMSCustomer';
        break;
      case 'email':
        requestType = 'sendEmailCustomer';
        break;
    }
    return this.http.put<any>(`${secureManagement}/${uuid}/${requestType}${extra}`, request, {});
  }

  public changeNotifyCustomer(uuid:string, request:boolean) {
    return this.http.put<any>(`${secureManagement}/${uuid}/notifyCustomer/${request}`, {});
  }

  public completeManualRefund(request:number) {
    return this.http.put<any>(`${completeManualRefund}/${request}`, {});
  }

  public saveReservation(uuid:any, request:any): Observable<any> {
    return this.http.put<any>(reservationsAPI + '/' + uuid, request, {});
  }

  public startRefund(request: any, type:string) {
    if(type == 'giftcardAmount') {
      return this.http.post<any>(refundGiftcardAPI, {}, {params: request});
    } else {
      return this.http.put<any>(refundAPI, {}, {params: request});
    }
  }

  public getHotelOffer(request: any) {
    return this.http.get<any>(hotelOfferAPI, {params: request});
  }

  public getPrice(hotelId:number, productId:number, date:any): Observable<any> {
    return this.http.get<any>(`${priceAPI}/${hotelId}/${productId}/${date}`);
  }

  public blockCheck(request: any) {
    return this.http.post<any>(blockListAPI, request, {});
  }

  public cancelChange(uuid:any) {
    return this.http.put<any>(`${reservationsAPI}/${uuid}/cancel-change`, {});
  }

  public cancelReservation(uuid:any) {
    return this.http.put<any>(`${reservationsAPI}/${uuid}/cancel`, {});
  }

  public markCleanRoom(request: any) {
    return this.http.post<any>(markCleanRoomAPI, request, {});
  }
  public markDirtyRoom(request: any) {
    return this.http.post<any>(markDirtyRoomAPI, request, {});
  }

  public unblockReservation(uuid:any) {
    return this.http.put<any>(`${reservationsAPI}/${uuid}/unblock`, {isForced: true}, {});
  }

  public confirmReservation(uuid:any, request:any) {
    return this.http.put<any>(`${reservationsAPI}/${uuid}/confirm`, request, {});
  }

  public proceedToRefund(request:any) {
    return this.http.post<any>(proceedRefundAPI, {}, {params: request});
  }

  public getFennoaCustomer(hotelId:any) {
    return this.http.get<any>(fennoaCustomerAPI, {params: {'hotelId': hotelId}});
  }

  public saveFennoaInvoice(uuid:string, request:any) {
    return this.http.post<any>(fennoaSaveInvoice + '/' + uuid, request, {}); 
  }

  public approveSendFennoaInvoice(invoiceId:any, hotelId:any) {
    return this.http.post<any>(`${fennoaApproveSend}/${invoiceId}?hotelId=${hotelId}`, {}); 
  }

  public getFennoaInvoice(invoiceId:any, hotelId:any) {
    return this.http.get<any>(fennoaGetInvoice + '/' + invoiceId, {params: {'hotelId': hotelId}});
  }
  public getDomainSiteHost(): Observable<any>{
    return this.http.get(domainSite, { headers, responseType: 'text' });
  }

  // For manual invoice for Salo hotel
  public searchReservationsForInvoice(request: any): Observable<any> {
    return this.http.get<any>(reservationsForInvoiceAPI, {params: request});
  }
  public countReservationsForInvoice(request: any): Observable<any> {
    return this.http.get<any>(countreservationsForInvoiceAPI, {params: request});
  }
  public createPdfForInvoiceManual(request:any): Observable<any> {
    return this.http.post(createPdfForInvoiceManualAPI, request, {responseType: 'blob'});
  }
  public getReservationId(request:any): Observable<any> {
    return this.http.get<any>(lastestReservationForInvoiceAPI + '/' + request);
  }
  public getManualInvoiceId(request:any, lang = "fi"): Observable<any> {
    return this.http.get(secureManagementForInvoice + '/' + request + "?lang=" + lang, {responseType: 'blob'});
  }
  public availabilityCheck(request:any): Observable<any> {
    return this.http.get(availabilityCheck, {params: request})
  }
  public getManualInvoiceBaseOnInvoiceId(request:any): Observable<any> {
    return this.http.get(reservationForInvoiceAPI + '/' + request.reservationId, {params: {'isGetForCrediting': request.isGetForCrediting}});
  }
  public createCreditInvoice(request:any, lang = "fi"): Observable<any> {
    return this.http.post(createCreditInvoiceForInvoiceAPI + '/' + request + "?lang=" + lang,{}, { headers, responseType: 'blob' });
  }
  public checkCreatePdfForInvoiceManual(request:any): Observable<any> {
    return this.http.post(checkCreatePdf, request);
  }
  public getManualPaymentTypeList(hotelChainName: string): Observable<any> {
    return this.http.get(secureManagement + "/" + hotelChainName + manualPaymentType);
  }
  public saveManualPaymentTypeList(hotelChainName: string, request: any): Observable<any> {
    return this.http.post(secureManagement + "/" + hotelChainName + manualPaymentType, request, {observe: "response"});
  }
  public getReservationLog(reservationUuid: string): Observable<any> {
    return this.http.get(secureManagement + "/reservations/" + reservationUuid + "/log");
  }
  public saveReservationComment(reservationUuid: string, request: any): Observable<any> {
    return this.http.post(secureManagement + "/reservations/" + reservationUuid + "/comment", request);
  }

  public getFennoaCreditInvoiceList(uuid:string): Observable<any> {
    return this.http.get<any>(`${secureManagement}/fennoa/${uuid}/invoices-to-credit`);
  }
  public getFennoaInvoiceItems(uuid:string): Observable<any> {
    return this.http.get<any>(`${secureManagement}/invoice-details/${uuid}`);
  }
  public getFennoaProducts():Observable<any> {
    return this.http.get<any>(`${secureManagement}/fennoa-product`);
  }
  public saveFennoaProducts(request: any): Observable<any> {
    return this.http.post(`${secureManagement}/fennoa-product/save`, request);
  }
  public updateLang(reservationCode:string, lang:string) {
    return this.http.put<any>(`${secureManagement}/reservation/update-lang?reservationCode=${reservationCode}&lang=${lang}`, {})
  }

  // Partial payment link
  public createPartialPaymentLink(reservationUuid:string, amount: string): Observable<any> {
    return this.http.post(`${secureManagement}/reservations/${reservationUuid}/payment-links?amount=${amount}`, {});
  }
  public getPartialPaymentLinkHistory(reservationUuid: string):Observable<any> {
    return this.http.get<any>(`${secureManagement}/reservations/${reservationUuid}/payment-links`);
  }
}
