<div class="container main-container login-page">
  <div class="login-box">
    <img class="brand-login-logo" src="assets/images/nelson-logo.svg">
    <!-- <h1 class="text-center">{{ content.clientTitle }} </h1>      -->
    <form [formGroup]="forgotForm" (ngSubmit)="submitForgotForm()">
      <div class="password-criteria" style="text-align: center;">
        <h2>Forgot password</h2>
      </div>
      <div class="custom-float-input">
        <input type="text" min="1" formControlName="email" id="email">
        <label>Username</label>
      </div>
      <button type="submit" [disabled]="loading" class="btn btn-lg btn-block btn-green btn-big" *ngIf="!requestedUsername">Send verification code</button>
    </form>

    <div *ngIf="requestedUsername">
      <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPasswordForm()">
        <div class="password-criteria" style="text-align: center;">
          <p>An email with verification code has been sent to <b>{{requestedUsername}}</b><br><h6>Please check your spam folder if you didn't receive the code</h6></p>
        </div>
        <div class="custom-float-input">
          <input type="tel" formControlName="code" id="code" autocomplete="off">
          <label>Verification code</label>
        </div>
        <div class="custom-float-input">
          <input type="password" min="1" formControlName="newPassword" id="newPassword">
          <label>New password</label>
        </div>
        <div class="custom-float-input">
          <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword">
          <label>Confirm new password</label>
        </div>
        <div class="password-criteria">
          <p>Password must contain at least:</p>
          <ul>
            <li>At least 8 characters—the more characters, the better</li>
            <li>A mixture of both uppercase and lowercase letters</li>
            <li>Contains at least one number and one special character</li>
          </ul>
        </div>
        <button type="submit" [disabled]="loading" class="btn btn-lg btn-block btn-green btn-big">Reset password</button>
      </form>
    </div>
    <div class="forgot-btn" style="margin-top: 20px">
      <span *ngIf="requestedUsername"><a (click)="resendVerificationCode()">Resend code</a> | </span><a routerLink="/login">Return to login</a>
    </div>
  </div>
</div>