<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div class="title-wrapper" id="main">
          <h1 class="view-title">{{ title }}</h1>
          <button class="btn btn-green" routerLink="/maintenance/create"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Create</button>
        </div>
        <form [formGroup]="searchForm">
          <div class="row">
            <div class="col-md-4 col-lg-2" *ngIf="hotels.length > 1">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
            <div class="col-md-8 col-lg-8">
              <div class="row">
                <div class="col-sm-6">
                  <label>Start date</label>
                  <div class="row no-gutters">
                    <div class="col-xs-7">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                          [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('startDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                    <div class="col-xs-5">
                      <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Start time" class="form-control" value="{{startDate | date:'HH:mm'}}"
                            disabled>
                          <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                            <span class="glyphicon glyphicon-time"></span>
                          </button>
                        </div>
                        <div class="dropdown-menu menu-content start-time-cal" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                          <timepicker [(ngModel)]="startDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <label>End date</label>
                  <div class="row no-gutters">
                    <div class="col-xs-7">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                          bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('endDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                    <div class="col-xs-5">
                      <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                        <div class="datepicker-input">
                          <input type="text" placeholder="End time" class="form-control" value="{{endDate | date:'HH:mm'}}"
                            disabled>
                          <button type="button" class="datepicker-btn" dropdownToggle aria-controls="endTimePicker">
                            <span class="glyphicon glyphicon-time"></span>
                          </button>
                        </div>
                        <div class="dropdown-menu menu-content start-time-cal" id="endTimePicker" *dropdownMenu (click)="preventClose($event)">
                          <timepicker [(ngModel)]="endDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-md-4 col-lg-2">
              <label>Category</label>
              <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="category">
                  <div class="menu-selected">{{selectedCategory}}</div>
                  <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
                <div class="dropdown-menu menu-content" id="category" *dropdownMenu (click)="preventClose($event)">
                  <ng-container *ngFor="let category of categories">
                    <div class="normal-checkbox">
                      <input type="checkbox" name="category" formControlName="selectedCategory"
                        (ngModelChange)="categoryChange($event,category.name)">
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      <div class="checkbox-label">{{category.label}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-sm-4 col-md-6 col-lg-10"></div>
            <div class="col-sm-4 col-md-2 col-lg-2">
              <button type="submit" class="btn btn-green full-width input-height" style="margin-top: 27px" (click)="onSearch('new')"
                [disabled]="!isFormValid">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <ng-container *ngIf="searchResult.length > 0">
          <div class="custom-table-wrapper">
            <table class="custom-table" [ngClass]="{hide: !showResult}">
              <thead>
                <tr>
                  <th style="width:110px">Hotel</th>
                  <th>Room</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Created by</th>
                  <th style="width:180px">Created time</th>
                  <th>Category</th>
                  <th>Reason</th>
                  <th>Usage</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let result of searchResult" routerLink="/maintenance/{{result.id}}">
                  <td *ngIf="hotels.length > 1">{{utils.hotelDisplayByLabel(hotelConfig, hotels, result.hotel.dropdownHotelName)}}</td>
                  <td>
                    <ul class="nopadding">
                      <li *ngFor="let room of result.rooms">{{room}}</li>
                    </ul>
                  </td>
                  <td>{{result.startDate}}</td>
                  <td>{{result.endDate}}</td>
                  <td>{{result.createdBy}}</td>
                  <td>{{result.createdTime}}</td>
                  <td>{{result.category}}</td>
                  <td>{{result.reason}}</td>
                  <td>
                    <div (click)="openUsage(result.hotel.hotelId, result.id, result.sendStartDate, result.sendEndDate)">
                      <span class="glyphicon glyphicon-link custom-link" tooltip="See usage" placement="top"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
      
          <div class="show-more">
            <label>Showing {{currentCount}}/{{totalCount}}</label>
            <button class="btn btn-green large-btn show-more-btn" *ngIf="currentCount != totalCount && totalCount > 10"
              (click)="onSearch('more')"><span class="glyphicon glyphicon-refresh"></span> Show {{remainingCount}}
              more</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div> 
  <div class="center-item" *ngIf="isSearching">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
</div>
<app-footer></app-footer>