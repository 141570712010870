<app-header></app-header>
<div class="wrapper">
  <div class="wrapper-header-container">
    <div class="inner-header-container">
      <button
        (click)="handleGoBackBtn()"
        type="button"
        class="btn btn-outline-green height-40 back-btn"
      >
        <span class="glyphicon glyphicon-menu-left"></span>
        Back
      </button>
      <h1 class="view-title"><b>Activity log</b></h1>
      <div class="row">
        <div class="col-sm-9 left-header">
          <p class="reservation-title">
            Reservation {{ this.reservationCode }}
          </p>
        </div>
        <div class="col-sm-3 right-header">
          <label for="sort-label">Sort</label>
          <div class="dropdown">
            <button
              class="dropbtn"
              (click)="handleExpandSortBtn(!isExpandSort, $event)"
            >
              {{ selectedSortItem }}
              <span
                ><img class="drop-icon" src="assets/images/triangle-down.svg"
              /></span>
            </button>
            <div *ngIf="isExpandSort" class="dropdown-content">
              <ul>
                <ng-container *ngFor="let sort of sorts">
                  <li
                    [class.active]="selectedSortItem === sort"
                    (click)="handleChangeSortItem(sort)"
                  >
                    {{ sort }}
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper-activity-log">
    <div class="inner-activity-log">
      <div class="activity-item" *ngFor="let item of activityLogData">
        <div class="active-item-header">
          <div
            class="activity-avatar"
            [class]="'avatar-' + this.getBgColorAvatarNumber(item.author.name)"
          >
            {{ this.getSortAuthorName(item.author.name) }}
          </div>
          <div>
            <div class="activity-category">
              <div class="full-name">{{ item.author.name }}</div>
            </div>
            <div class="activity-status">
              <div class="status">
                {{ this.convertActionName(item.action) }}
              </div>
            </div>
            <div class="activity-date">
              <div class="date">
                {{ utils.convertISOFullDate(item.timestamp) }}
              </div>
            </div>
          </div>
        </div>
        <!-- TYPE 0 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 0">
          <div class="wrapper-activity" *ngIf="item.data !== null">
            <div
              class="expand-wrapper"
              [class]="{ 'expand-component': item.expanded }"
            >
            <div class="pre-log">
              {{ this.renderLogUI(item.data) }}
            </div>
              <div
                class="expand-trigger"
                [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
              >
                <div
                  class="expand-trigger-inner"
                  (click)="item.expanded = !item.expanded"
                >
                  Read more
                </div>
              </div>
            </div>
            <p
              [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
              class="collapse-trigger"
              (click)="item.expanded = !item.expanded"
            >
              Read less
            </p>
          </div>
        </ng-container>
        <!-- TYPE 1 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 1">
          <div class="wrapper-activity">
            <div
              class="row activity-detail expand-wrapper"
              [class]="{ 'expand-component': item.expanded }"
            >
              <div>
                <div
                class="col-sm-1 detail-left"
                *ngIf="item.data.beforeChange === null"
                >
                  (Empty)
                </div>
                <div
                  class="col-sm-1 detail-left"
                  *ngIf="item.action === 'UPDATE_LANGUAGE'"
                >
                  <div class="pre-log">
                    <br/>
                    {{ item.data.beforeChange }}
                  </div>
                </div>
                <div
                  class="col-sm-5 detail-left"
                  *ngIf="item.data.beforeChange !== null && item.action !== 'UPDATE_LANGUAGE'"
                >
                  <div class="pre-log">
                    <ng-container *ngIf="item.action === 'UPDATE_LANGUAGE'">
                      <br/>
                    </ng-container>
                    {{ item.action === "UPDATE_LANGUAGE" ? item.data.beforeChange : this.renderLogUI(item.data.beforeChange) }}
                  </div>
                </div>
                <div class="col-sm-1 detail-center">
                  <img src="assets/images/right-arrow.svg" />
                </div>
              </div>
              <!-- After change -->
              <div
                class="col-sm-10 detail-right"
                *ngIf="item.data.beforeChange === null"
              >
                <div class="pre-log">
                  {{ this.renderLogUI(item.data.afterChange) }}
                </div>
              </div>
              <div
                class="col-sm-1 detail-right"
                *ngIf="item.action === 'UPDATE_LANGUAGE'"
              >
                <div class="pre-log">
                  <br/>
                  {{ item.data.afterChange }}
                </div>
              </div>
              <div
                class="col-sm-6 detail-right"
                *ngIf="item.data.beforeChange !== null && item.action !== 'UPDATE_LANGUAGE'"
              >
                <div class="pre-log">
                  {{ this.renderLogUI(item.data.afterChange) }}
                </div>
              </div>
              <div
                class="expand-trigger"
                [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
              >
                <div
                  class="expand-trigger-inner"
                  (click)="item.expanded = !item.expanded"
                >
                  Read more
                </div>
              </div>
            </div>
            <p
              [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
              class="collapse-trigger"
              (click)="item.expanded = !item.expanded"
            >
              Read less
            </p>
          </div>
        </ng-container>
        <!-- TYPE 2 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 2">
          <div class="wrapper-activity">
            <div
              class="expand-wrapper"
              [class]="{ 'expand-component': item.expanded }"
            >
              {{ item.data.afterChange.comment }}
              <div
                class="expand-trigger"
                [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
              >
                <div
                  class="expand-trigger-inner"
                  (click)="item.expanded = !item.expanded"
                >
                  Read more
                </div>
              </div>
            </div>
            <p
              [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
              class="collapse-trigger"
              (click)="item.expanded = !item.expanded"
            >
              Read less
            </p>
          </div>
        </ng-container>
        <!-- TYPE 4 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 4">
          <div class="wrapper-activity">
            <div
              class="expand-wrapper"
              [class]="{ 'expand-component': item.expanded }"
            >
              {{ this.getStringType4(item) }}
              <div
                class="expand-trigger"
                [ngStyle]="{ display: item.expanded ? 'none' : 'block' }"
              >
                <div
                  class="expand-trigger-inner"
                  (click)="item.expanded = !item.expanded"
                >
                  Read more
                </div>
              </div>
            </div>
            <p
              [ngStyle]="{ display: item.expanded ? 'block' : 'none' }"
              class="collapse-trigger"
              (click)="item.expanded = !item.expanded"
            >
              Read less
            </p>
          </div>
        </ng-container>
        <!-- TYPE 6 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 6">
          <div class="wrapper-activity">
            <div class="row activity-detail">
              <div class="col-sm-3 detail-left">
                Room {{ item.data.sourceRoomLabel }}
              </div>
              <div class="col-sm-1 detail-center">
                <img src="assets/images/right-arrow.svg" />
              </div>
              <div class="col-sm-8 detail-right">
                Room {{ item.data.destinationRoomLabel }}
              </div>
            </div>
          </div>
        </ng-container>
        <!-- TYPE 7 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 7">
          <div class="wrapper-activity">
            <div class="row activity-detail">
              <div class="col-xs-12">
                User: {{ item.data.user }}
                <div *ngIf="item.action === 'USER_OPEN_EMAIL'">
                  Email type: {{convertActionNameCamelCase(item.data.emailType)}}
                </div>
                <div *ngIf="item.action === 'USER_OPEN_LINK_ACCESS'">
                  Message source: {{ convertActionNameCamelCase(item.data.messageSource) }}
                  <br>Guest Comms Type: {{convertActionNameCamelCase(item.data.guestCommsType)}}
                  <br>Link type: {{convertActionNameCamelCase(item.data.linkType)}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- TYPE 8 -->
        <ng-container *ngIf="this.getActivityItemTypeNumber(item.action) === 8">
          <div class="wrapper-activity">
            <div class="row activity-detail">
              <div class="col-xs-12">
                <div *ngIf="item.data.user">
                  User: {{item.data.user}}
                </div>
                <div *ngIf="item.data.firstName">
                  First name: {{item.data.firstName}}
                </div>
                <div *ngIf="item.data.lastName">
                  Last name: {{item.data.lastName}}
                </div>
                <div *ngIf="item.data.email">
                  Email: {{item.data.email}}
                </div>
                <div *ngIf="item.data.dateOfBirth">
                  Date of birth: {{item.data.dateOfBirth}}
                </div>
                <div *ngIf="item.data.isoCountryCode">
                  Dial code: {{item.data.isoCountryCode}}
                </div>
                <div *ngIf="item.data.mobile">
                  Phone number: {{item.data.mobile}}
                </div>
                <div *ngIf="item.data.nationality">
                  Nationality: {{item.data.nationality}}
                </div>
                <div *ngIf="item.data.completedSsn">
                  Completed SSN: {{item.data.completedSsn}}
                </div>
                <div *ngIf="item.data.ssn">
                  SSN: {{item.data.ssn}}
                </div>
                <div *ngIf="item.data.passportNumber">
                  Passport number: {{item.data.passportNumber}}
                </div>
                <div *ngIf="item.data.purposeOfVisit">
                  Purpose of visit: {{item.data.purposeOfVisit}}
                </div>
                <div *ngIf="item.data.address">
                  Address: {{item.data.address}}
                </div>
                <div *ngIf="item.data.city">
                  City: {{item.data.city}}
                </div>
                <div *ngIf="item.data.postalCode">
                  Postal code: {{item.data.postalCode}}
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
