<ng-container *ngIf="!showHotelConfigError && !loading">
  <router-outlet *ngIf="utils.checkObjectIsNotEmpty(hotelConfig)"></router-outlet>
</ng-container>
<div class="center-container" *ngIf="showHotelConfigError">
  <div class="error-alert">
    <div class="title">An error occurred while loading</div>
    <div class="desc">Management page encountered an error while loading, please reload to try again. If this problem persist, please report to Nelson!</div>
    <button type="button" class="mt-7 btn btn-green btn-big" (click)="reloadPage()">Reload page</button>
  </div>
</div>
<div class="center-container" *ngIf="loading">
  <ng-lottie
    [options]="options"
    width="200px"
    height="200px"
  ></ng-lottie>
</div>
<app-footer></app-footer>