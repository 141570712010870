import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appEditDate]'
})
export class EditDateDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input')
  onChange() {
    let element = this.el.nativeElement;
    let inputValue = element.value;
    let datePattern = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/);
    if(datePattern.test(inputValue) || !inputValue) {
      element.classList.remove('invalid');
    } else {
      element.classList.add('invalid');
    }
  }
  @HostListener('bsValueChange')
  @HostListener('focusout')
  onBlur() {
    let element = this.el.nativeElement;
    setTimeout(function(){
      let inputValue = element.value;
      let datePattern = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/);
      if(datePattern.test(inputValue) || !inputValue) {
        element.classList.remove('invalid');
      } else {
        element.classList.add('invalid');
      }
    }, 200)
  }

}
