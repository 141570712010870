<div class="feature-container">
  <div class="feature-title have-label-under">
    <h3>Change reservation language</h3>
    <div class="feature-desc">Change a reservation language, which will affect the language of guest comms and BUI.</div>
  </div>
  <div class="row">
    <form [formGroup]="retrieveForm" (ngSubmit)="updateReservationLang()">
      <div class="col-xs-12 col-sm-4">
        <label>Reservation code</label>
        <input class="form-control custom-input" [ngClass]="{'error': reservationCode?.invalid && (reservationCode?.dirty || reservationCode?.touched)}" formControlName="reservationCode">
        <div class="input-error-desc" *ngIf="reservationCode?.invalid && (reservationCode?.dirty || reservationCode?.touched)">This field is required</div>
      </div>
      <div class="col-xs-12 col-sm-3">
        <div class="dropdown"> 
          <label for="select-report">Language</label>
          <br/>
          <button class="dropbtn" (click)="showDropdown('lang', $event)">{{selectedLang.label}} <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></button>
          <div *ngIf="manageMenu.lang" class="dropdown-content">
            <ul>
              <ng-container *ngFor="let lang of langList" >
                <li [class.active]="selectedLang.value === lang.value" (click)="langChange(lang)">{{ lang.label }}</li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-2">
        <div class="button-no-label">
          <button type="submit" class="btn btn-green btn-big">Update</button>
        </div>
      </div>
    </form>
  </div>
</div>