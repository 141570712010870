import { trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from './components/header/header.component';
import { CONSTANT } from './helpers/constants';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import * as jose from 'jose';
import { StoreService } from './services/store.service';
import { HotelWebConfig } from './@types/app';
import { UTILS } from './helpers/utils';
import { TenantService } from './services/tenant.service';
import { AnimationOptions } from 'ngx-lottie';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  title = 'nelson-management-ui';
  token:String = '';
  hotelConfig:HotelWebConfig = {} as HotelWebConfig;
  showHotelConfigError:boolean = false;
  loading:boolean = true;
  constructor(
    private tokenStorage: TokenStorageService,
    private authService: AuthService, 
    private http: HttpClient,
    private storeService: StoreService,
    private tenantService: TenantService,
    public utils: UTILS) { }
  getProdConfig() {
    let requestArray = [
      this.http.get(`/config/hotel-config.json`, {responseType:"json"}),
      this.http.get(`/config/feature-config.json`, {responseType:"json"}),
      this.http.get(`/config/mui-config.json`, {responseType:"json"})
    ]
    forkJoin(requestArray).subscribe(data => {
      const config:any = {
        hotel: data[0],
        feature: data[1],
        MUIfeature: data[2]
      }
      this.storeService.updateConfig(config as HotelWebConfig);
      this.hotelConfig = this.storeService.getConfig();
      this.loading = false;
    })
  }
  checkTenant(siteHost:string) {
    const refreshToken = this.tokenStorage.getRefreshToken();
    this.tenantService.loadTenants({ tenantids: this.tokenStorage.getTenantIds(), environmentids: this.tokenStorage.getEnvironmentIds() }).subscribe(
      (data:any) => {
        if(data) {
          if(environment.hotel === "admin" && !this.tokenStorage.getEnvironmentIds().includes(',') && this.token && refreshToken && !this.utils.isRoleUserManagement()) {
            window.open(`${data.tenants[0].environments[0].managementsitehost}/#/reservations?token=${this.token}&refreshtoken=${refreshToken}`, '_self');
          } else {
            if(environment.hotel === "admin" || !environment.production) {
              this.getProdConfig();
            } else {
              let allowAccess = false;
              data.tenants.forEach((tenant:any) => {
                tenant.environments.forEach((env:any) => {
                  if(env.sitehost === siteHost) {
                    allowAccess = true;
                  }
                });
              });
              if(allowAccess) {
                this.getProdConfig();
              } else {
                this.tokenStorage.logOutBackToAdmin();
                this.loading = false;
              }
            }
          }
        }
      }
    )
  }
  ngOnInit(): void {
    this.getProdConfig();
    const getToken = this.tokenStorage.getToken();
    if(getToken && environment.hotel !== "admin") {
      this.authService.getDomainSiteHost().subscribe((data: any)=>{
        const siteHost = "https://" + data;
        this.checkTenant(siteHost);
      });
    } else {
      if(environment.hotel === "admin") {
        this.checkTenant("");
      }
    }
    if (this.tokenStorage.getToken()) {
      this.getDomainSite();
    }
    CONSTANT.ROLE = this.tokenStorage.getRole();
  }
  reloadPage() {
    window.location.reload();
  }
  backToAdmin() {
    window.open("https://admin.nelson.management", "_self");
  }
  getDomainSite(){
    const secureHttp = this.utils.isLocalEnvironment() ? "http://" : "https://"
    if(environment.hotel === "admin") {
      CONSTANT.SITEHOST = secureHttp + window.location.hostname;
    } else {
      this.authService.getDomainSiteHost().subscribe((data: any)=>{
        CONSTANT.SITEHOST = secureHttp + data;
      });
    }
  }
}
