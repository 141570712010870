<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ title }}</h1>
          <div class="right-button">
            <button class="btn btn-none-outline-green large-btn" (click)="clearSearch()"><span class="glyphicon glyphicon-trash"></span> Clear search</button>
            <button class="btn btn-green large-btn" (click)="onSearch()"><span class="glyphicon glyphicon-search"></span>
              Search</button>
          </div>
        </div>
      
        <form [formGroup]="voucherSearch">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <label>Search for</label>
              <input class="form-control custom-input searchQuery" formControlName="searchQuery">
      
              <label>Optional filters</label>
              <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                  <div class="menu-selected">
                    <ng-container *ngFor="let filter of getThreeFilter(); let i = index">
                      <span *ngIf="filter.checked" class="filter-item">
                        <span class="filter-label">{{filter.label}}</span>
                        <span (click)="removeFilter(filter, $event)" class="glyphicon glyphicon-remove"></span>
                      </span>
                    </ng-container>
                    <span *ngIf="getAllFilter().length > 3" class="filter-item">
                      <span class="filter-label"> + {{getAllFilter().length - 3}}</span>
                    </span>
                  </div>
                  <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
                <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                  (click)="utils.preventClose($event)">
                  <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                  <ng-container *ngFor="let filter of optionalFilter">
                    <div class="normal-checkbox" [ngClass]="{disabled: !filter.enabled, child: filter.voucherType != 'main'}">
                      <input type="checkbox" (click)="filterChange($event,filter.value)" [checked]="filter.checked">
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      <div class="checkbox-label">{{filter.label}}</div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-md-2" *ngIf="hotels.length > 1">
              <label for="select-report">Search by hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
            <div class="col-xs-6 col-md-2">
              <label>Start date</label>
              <div class="datepicker-input">
                <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                  [(bsValue)]="startDate" #startDateInput="bsDatepicker" (bsValueChange)="dateChange('startDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
      
            </div>
            <div class="col-xs-6 col-md-2">
              <label>End date</label>
              <div class="datepicker-input">
                <input type="text" placeholder="End date" class="form-control" appEditDate [(bsValue)]="endDate"
                  bsDatepicker #endDateInput="bsDatepicker" (bsValueChange)="dateChange('endDate')"
                  [dateCustomClasses]="datepickerCustom"
                  [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                  <span class="glyphicon glyphicon-calendar"></span>
                </button>
              </div>
              
              <label>Date selection</label>
              <div class="weekday-selector">
                <div class="custom-radio">
                  <input type="radio" name="date-selection" (click)="dateMode = 'OVERLAP'" [checked]="dateMode == 'OVERLAP'">
                  <div class="radio-box">Overlap</div>
                </div>
                <div class="custom-radio">
                  <input type="radio" name="date-selection" (click)="dateMode = 'EXACT'" [checked]="dateMode == 'EXACT'">
                  <div class="radio-box">Exact</div>
                </div>
              </div>
            </div>
          </div>
        </form>
      
       
      </div>
    </div>
  </div>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <app-discount-table [discountResult]="discountResult" [discountCount]="discountCount" (showMore)="showMore('discount')"></app-discount-table>
      
        <app-monetary-table [monetaryResult]="monetaryResult" [monetaryCount]="monetaryCount" (showMore)="showMore('monetary')"></app-monetary-table>
      </div>
    </div>
  </div> 
</div>
<app-footer></app-footer>