<app-header-user-management></app-header-user-management>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">Hi {{ firstName }}</h1>
        </div>
        <b>Choose the client:</b>
      </div>
    </div>
  </div>
  <!-- Loading icon -->
  <div class="center-item" *ngIf="loadingContainer">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
  <div class="wrapper-outer-table" *ngIf="!loadingContainer">
    <div class="outer-table">
      <div class="container-fluid">
        <table class="custom-table dashboard-table" *ngIf="tenants.length > 0">
          <thead>
            <th class="client-name">
              <div class="padding">Client name</div>
            </th>
            <th class="column-format" *ngIf="isNotClientNonAdminRole">
              <div class="flex-container padding">
                <div class="table-title">Active users</div>
                <div class="table-sort">
                  <span class="glyphicon glyphicon-sort-by-attributes-alt"></span>
                </div>
              </div>
            </th>
            <th class="column-format" *ngIf="isNotClientNonAdminRole">
              <div class="flex-container padding">
                <div class="table-title">Issues</div>
                <div class="table-sort">
                  <span class="glyphicon glyphicon-sort-by-attributes-alt"></span>
                </div>
              </div>
            </th>
            <!-- <th class="switch-column" *ngIf="isAdminRole">
              <div class="flex-container padding">
              </div>
            </th> -->
          </thead>
          <tbody>
            <ng-container>
              <tr *ngFor="let environment of environments">
                <ng-container>
                  <td (click)="chooseEnvironment(environment)">
                    <b class="client-name-color">{{ environment.name }}</b>
                  </td>
                  <td *ngIf="isNotClientNonAdminRole">
                    <div>{{ environment.activeUsers }}</div>
                  </td>
                  <td *ngIf="isNotClientNonAdminRole">
                    <div class="{{ environment.issue > 1 ? 'warn-text' : ''}}">
                      {{ environment.issues }}
                    </div>
                  </td>
                  <!-- <td *ngIf="isAdminRole">
                    <img class="switch-icon" src="assets/images/switch.svg">
                  </td> -->
                </ng-container>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>