import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const loadTenantUrl = '/api/tenant/listtenants';
@Injectable({
    providedIn: 'root'
})
export class TenantService {
    constructor(private http: HttpClient, private token: TokenStorageService) {}
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.token.getToken()!
        })
    };
    loadTenants(request: any): Observable<any> {
        return this.http.get(loadTenantUrl, { params: request });
    }
}