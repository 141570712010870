import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { InternalToolsService } from 'src/app/services/internal-tools.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-update-reservation-lang',
  templateUrl: './update-reservation-lang.component.html',
  styleUrls: ['../internal-tools-mui.component.sass']
})
export class UpdateReservationLangComponent implements OnInit {

  constructor(
    private storeService: StoreService,
    public utils: UTILS,
    private internalToolsService: InternalToolsService,
    private toastr: ToastrService,
  ) { }

  @HostListener('document:click', ['$event']) documentClickEvent($event: MouseEvent) {
    Object.keys(this.manageMenu).forEach((menu:any) => {
      this.manageMenu[menu] = false;
    })
  }

  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  manageMenu:any = {
    lang: false
  }
  langList:any[] = [];
  selectedLang:any;

  retrieveForm = new FormGroup({
    reservationCode: new FormControl('', Validators.required),
  });
  get reservationCode() {
    return this.retrieveForm.get("reservationCode")
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error.description) errorMessage = err.error.description;
    if(err.error.message) errorMessage = err.error.message;
    if(!errorMessage) errorMessage = "Unknown error occurred!";
    this.toastr.error(errorMessage, 'Error!');
  }

  showDropdown(type:string, e:any) {
    e.stopPropagation();
    Object.keys(this.manageMenu).forEach((menu:any) => {
      if(menu !== type) {
        this.manageMenu[menu] = false;
      }
    })
    this.manageMenu[type] = !this.manageMenu[type];
  }

  ngOnInit(): void {
    let tmpLangList:any[] = [];
    CONSTANT.SUPPORTED_LANGUAGES.forEach(lang => {
      if(this.hotelConfig.hotel.language.includes(lang.value)) {
        tmpLangList.push(lang);
      }
    })
    this.langList = tmpLangList;
    this.selectedLang = tmpLangList[0]
  }

  langChange(item:any) {
    this.selectedLang = item;
  }

  updateReservationLang() {
    this.retrieveForm.markAllAsTouched();
    if(this.retrieveForm.valid && this.reservationCode) {
      this.internalToolsService.updateReservationLanguage(this.reservationCode.value, this.selectedLang.value).subscribe(
        data => {
          this.toastr.success("Reservation language has been updated!", 'Success!');
        },
        error => {
          this.displayAPIerror(error)
        }
      )
    }
  }

}
