import { Component, OnInit, Output, EventEmitter, TemplateRef, HostListener } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common'
import { CONSTANT } from 'src/app/helpers/constants';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {BreakfastService} from 'src/app/services/breakfast.service';
import * as _ from 'lodash';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { StoreService } from 'src/app/services/store.service';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

type RestaurantEmailType = {
  [key: number]: string
}

@Component({
  selector: 'app-breakfast',
  templateUrl: './breakfast.component.html',
  styleUrls: ['./breakfast.component.sass']
})
export class BreakfastComponent implements OnInit {

  title = "Breakfast";

  actionList:MenuType[] = [
    {
      label: "Mark Available",
      value: "markAvailable",
      disabled: false
    },
    {
      label: "Mark Unavailable",
      value: "markUnavailable",
      disabled: false
    },
    {
      label: "Set Price",
      value: "setPrice",
      disabled: false
    }
  ]
  selectedAction?:MenuType;

  dates: any[] = [];
  days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  modalRef!: BsModalRef;
  emailModal!: BsModalRef;
  productId: any;
  selectedDate?:any;
  onlyWeekdays: boolean = false;
  selectedWeekdays = [
    {'label':'Sun','checked':false},
    {'label':'Mon','checked':false},
    {'label':'Tue','checked':false},
    {'label':'Wed','checked':false},
    {'label':'Thu','checked':false},
    {'label':'Fri','checked':false},
    {'label':'Sat','checked':false},
  ]
  infiniteEnd: boolean = false;
  oldAvailable?: string;
  oldPrice?: any;
  oldAvailableId?: number;

  inputMonth:any = new Date();
  today:any = new Date();
  startDate:any = new Date(new Date().setHours(0,0,0,0));
  endDate:any = new Date(new Date().setHours(0,0,0,0) + 3*24*3600*1000);

  hotels: any[] = [];
  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;

  restaurantEmailList?:RestaurantEmailType;

  updateBreakfastFormDiffer: object = {};
  breakfastData: any[] = [];
  isValidPrice: boolean = true;
  isValidPriceWhenChoosingPriceAction: boolean = false;
  isDisablePriceField: boolean = false;
  isFormValid:boolean = true;

  disabledBtnWhileCallingAPI: boolean = false;
  currency:string = "EUR";

  calendarForm = new FormGroup({
    monthYear: new FormControl('', [Validators.required, Validators.maxLength(7), Validators.pattern(/^(0?[1-9]|1[012])[\.]\d{4}$/)]),
  });

  actionForm = new FormGroup({
    onlyWeekdays: new FormControl(Validators.required),
    infiniteEnd: new FormControl(Validators.required),
    selectedWeekday: new FormControl(Validators.required),
    actionPrice: new FormControl('', [Validators.required, Validators.pattern(/^\d{1,4}(\,|\.)?(\d{1,2})?$/)]),
    startDate: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/)]),
    endDate: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/)]),
  });

  updateBreakfastForm = new FormGroup({
    selectedAvailable: new FormControl('', [Validators.required]),
    selectedPrice: new FormControl('', [Validators.required, Validators.pattern(/^\d{1,4}(\,|\.)?(\d{1,2})?$/)])
  });

  emailForm = new FormGroup({
    selectedEmail: new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)])
  });

  breakfastAvailable: any[] = [];

  datepickerCustom: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(
    private breakfastService: BreakfastService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private storeService: StoreService,
    public utils: UTILS
  ) {
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.updateDatepicker();
    this.getData();
    this.getActionData();
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    const findHotel = this.hotels.find(hotel => {
      return hotel.hotelId === Number(item.value);
    });
    this.createCalendar();
    if(findHotel) {
      this.currency = findHotel.currency;
      if(this.restaurantEmailList) {
        this.emailForm.patchValue({
          selectedEmail: this.restaurantEmailList[findHotel.hotelId],
        });
      }
    }
  }
  selectHotelByValue(value:string) {
    const findHotel = this.hotels.find(hotel => {
      return hotel.hotelId === Number(value);
    });
    if(findHotel) {
      return findHotel
    }
  }

  selectAction(item:MenuType) {
    this.selectedAction = item;
    switch(item.value) {
      case 'setPrice':
        this.onlyWeekdays = false;
        this.infiniteEnd = true
        break;
      default:
        this.onlyWeekdays = false;
        this.infiniteEnd = false;
    }
  }

  updateDatepicker() {
    let custom = [
      {date: this.startDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.startDate.getTime() + 24*3600*1000;
    while(tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }

  updateRadioForAvaibleBreakfast(selectedAvailable: any): void {
    if (selectedAvailable === 'notAvailable') {
      this.isDisablePriceField = true;
    } else {
      this.isDisablePriceField = false;
    }
  }

  getData(): void {
    this.breakfastService.getHotels().subscribe(data => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.fetchRestaurantEmailList();
      
      this.calendarForm.patchValue({
        monthYear: formatDate(this.inputMonth, "MM.YYYY", CONSTANT.EN_US),
      });
    });
  }

  getActionData():void {
    this.actionForm.patchValue({
      selectedWeekday: false,
      onlyWeekdays: false,
      infiniteEnd: false
    });
    this.selectAction(this.actionList[0])
  }


  onSelectDate(getDate:any, template: TemplateRef<any>) {
    this.selectedDate = getDate;
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('custom-modal');
    if(getDate.available) {
      this.isDisablePriceField = false;
    } else {
      this.isDisablePriceField = true;
    }
    this.updateBreakfastForm.patchValue({
      selectedAvailable: getDate.available ? 'available' : 'notAvailable',
      selectedPrice: getDate.price
    });
    this.oldAvailable = getDate.available ? 'available' : 'notAvailable';
    this.oldPrice = getDate.price;
    this.oldAvailableId = getDate.availableId;
  }

  onOpenEmailModal(template: TemplateRef<any>) {
    this.emailModal = this.modalService.show(template);
    this.emailModal.setClass('email-modal');
  }
  fetchRestaurantEmailList(setDefaultHotel:boolean = true) {
    if(this.hotelList) {
      let requestHotel: any[] = [];
      this.hotels.forEach((data:any) => {
        requestHotel.push(data.hotelId);
      });
      this.breakfastService.getRestaurantEmail(requestHotel).subscribe(data => {
        this.restaurantEmailList = data;
        if(setDefaultHotel) {
          this.selectHotel(this.hotelList[0])
        }
      });
    }
  }

  weekdayChange(e:any,day:any) {
    let selectedIndex = this.selectedWeekdays.findIndex((weekday:any) => {
      return weekday.label === day;
    });
    this.selectedWeekdays[selectedIndex].checked = e;
  }

  updateActionCheckbox(type:string) {
    switch(type) {
      case 'onlyWeekdays':
        this.onlyWeekdays = !this.onlyWeekdays;
        if(this.infiniteEnd && this.onlyWeekdays) {
          this.infiniteEnd = false;
        }
        break;
      case 'infiniteEnd':
        this.infiniteEnd = !this.infiniteEnd;
        if(this.infiniteEnd && this.onlyWeekdays) {
          this.onlyWeekdays = false;
        }
        break;
    }
  }

  updatePrice(startDate:any,endDate:any,price:any,updatePriceDates:[]) {
    if(this.selectedHotel) {
      let priceEndDate;
      if(endDate == null) {
        priceEndDate = null;
      } else {
        priceEndDate = this.convertDate(endDate,'YYYY-MM-dd');
      }
      let priceRequest = {
        productId: this.productId,
        hotelId: Number(this.selectedHotel.value),
        startDate: this.convertDate(startDate,'YYYY-MM-dd'),
        endDate: priceEndDate,
        vatPercentage: null,
        price: price,
        updatePriceDates: updatePriceDates
      };
      return this.breakfastService.updateBreakfastPrice(priceRequest).subscribe(
        data => {
          this.toastr.success('Price updated!', 'Success')
          return true;
        },
        err => {
          console.log("err:", err.error);
          this.toastr.error(err.error.description, 'Price update error!');
          return false;
      });
    }
  }

  updateNotAvailable(availableRequest:any) {
    return this.breakfastService.updateBreakfastAvailable(availableRequest).subscribe(
      data => {
        this.toastr.success('Availability updated!', 'Success')
        return true;
      },
      err => {
        console.log("err:", err.error);
        this.toastr.error(err.error.description, 'Availability update error!');
        return false;
    });
  }

  onUpdateSingleDay(): void {
    let checkUpdatePrice, checkUpdateAvailable;
    let selectedPrice = this.updateBreakfastForm.controls.selectedPrice.value;
    let selectedAvailable = this.updateBreakfastForm.controls.selectedAvailable.value;
    if(selectedPrice != this.oldPrice) {
      checkUpdatePrice = this.updatePrice(this.selectedDate.day,this.selectedDate.day,selectedPrice, []);
    } else {
      checkUpdatePrice = false;
    }
    if(selectedAvailable != this.oldAvailable && this.selectedHotel) {
      let availableRequest = [];
      if(selectedAvailable == 'notAvailable') {
        availableRequest.push({
          hotelId: Number(this.selectedHotel.value),
          notAvailableDate: this.convertDate(this.selectedDate.day,'YYYY-MM-dd'),
          markForDelete: false
        });
      } else {
        availableRequest.push({
          hotelId: Number(this.selectedHotel.value),
          id: this.oldAvailableId,
          notAvailableDate: this.convertDate(this.selectedDate.day,'YYYY-MM-dd'),
          markForDelete: true
        });
      }
      checkUpdateAvailable = this.updateNotAvailable(availableRequest);
    } else {
      checkUpdateAvailable = false;
    }
    if(!checkUpdatePrice && !checkUpdateAvailable) {
      this.modalRef.hide();
    } else {
      this.createCalendar();
      this.modalRef.hide();
    }
  }

  onUpdateAction(): void {
    if(this.selectedAction && this.selectedHotel) {
      this.disabledBtnWhileCallingAPI = true;
      let currentDate = new Date(this.startDate.getFullYear(),this.startDate.getMonth(),this.startDate.getDate());
      let dateLength = (this.endDate.getTime() - this.startDate.getTime())/(1000*3600*24);
      switch (this.selectedAction.value) {
        case "markAvailable":
          let availableRequest = {
            'hotelId': Number(this.selectedHotel.value), 
            'startDate': this.convertDate(this.startDate,'YYYY-MM-dd'), 
            'endDate': this.convertDate(this.endDate,'YYYY-MM-dd')
          };
          this.breakfastService.getBreakfastAvailable(availableRequest).subscribe(data => {
            let availableRequest:any = [];
            availableRequest = data.breakfastAvailabilityEntries;
            for (let i = 0; i < availableRequest.length; i++) {
              availableRequest[i].markForDelete = true;
            }
            let updateAvailable = this.updateNotAvailable(availableRequest);
            if(updateAvailable) {
              this.createCalendar();
            }
            this.disabledBtnWhileCallingAPI = false;
          });
          break;
        case "markUnavailable":
          let notAvailableRequest = {
            'hotelId': Number(this.selectedHotel.value), 
            'startDate': this.convertDate(this.startDate,'YYYY-MM-dd'), 
            'endDate': this.convertDate(this.endDate,'YYYY-MM-dd')
          };
          this.breakfastService.getBreakfastAvailable(notAvailableRequest).subscribe(data => {
            if(this.selectedHotel) {
              let availableRequest:any = [];
              let notAvailableDate = _.map(data.breakfastAvailabilityEntries, 'notAvailableDate');
              for (let i = 0; i <= dateLength; i++) {
                let convertCurrentDate = this.convertDate(currentDate,'YYYY-MM-dd')
                if((!_.includes(notAvailableDate, convertCurrentDate) && this.onlyWeekdays && this.selectedWeekdays[currentDate.getDay()].checked) || (!_.includes(notAvailableDate, convertCurrentDate) && !this.onlyWeekdays)) {
                  availableRequest.push({
                    hotelId: Number(this.selectedHotel.value),
                    notAvailableDate: convertCurrentDate,
                    markForDelete: false
                  });
                }
                currentDate.setDate(currentDate.getDate() + 1);
              }
              let updateAvailable = this.updateNotAvailable(availableRequest);
              if(updateAvailable) {
                this.toastr.success("Updated successfully", 'Success!');
                this.createCalendar();
              }
              this.disabledBtnWhileCallingAPI = false;
            }
          });
          break;
        case "setPrice":
          let actionPrice = this.actionForm.controls.actionPrice.value;
          let priceStartDate = this.startDate;
          let priceEndDate;
          let updatePriceDates:any = [];
          if(this.infiniteEnd) {
            priceEndDate = null;
          } else {
            priceEndDate = this.endDate;
            if (this.onlyWeekdays) {
              for (let i = 0; i <= dateLength; i++) {
                let convertCurrentDate = this.convertDate(currentDate,'YYYY-MM-dd')
                if((this.onlyWeekdays && this.selectedWeekdays[currentDate.getDay()].checked)) {
                  updatePriceDates.push(convertCurrentDate);
                }
                currentDate.setDate(currentDate.getDate() + 1);
              }
            }
          }
          if(actionPrice == '') {
            this.toastr.error("Please input the price!", "Update error");
          } else if (!this.actionForm.controls.actionPrice.valid) {
            this.toastr.error("Price format is not valid!", "Update error");
          } else {
            let updatePrice = this.updatePrice(priceStartDate, priceEndDate, actionPrice, updatePriceDates);
            if(updatePrice) this.createCalendar();
          }
          this.disabledBtnWhileCallingAPI = false;
          break;
      }
    }
  }

  onUpdateEmail(): void {
    if(this.restaurantEmailList && this.selectedHotel) {
      const currentMail = this.restaurantEmailList[Number(this.selectedHotel.value)];
      const newEmail = this.emailForm.controls.selectedEmail.value;
      if(currentMail != newEmail) {
        let request = {
          'hotelId': Number(this.selectedHotel.value),
          'newEmail': newEmail
        }
        this.breakfastService.updateRestaurantEmail(request).subscribe(
          data => {
            this.toastr.success("Restaurant email updated successfully!","Success!");
            this.fetchRestaurantEmailList(false);
            return true;
          },
          err => {
            console.log("err:", err.error);
            this.toastr.error(err.error.description, 'Error!');
            return false;
          }
        );
      }
      this.emailModal.hide();
    }
  }

  checkPriceValidWhenChoosingPriceAction(): void {
    if(this.actionForm.controls.actionPrice.valid) {
      this.isValidPriceWhenChoosingPriceAction = true;
    } else {
      this.isValidPriceWhenChoosingPriceAction = false;
    }
  }

  checkPriceValid(): void {
    if(this.updateBreakfastForm.controls.selectedPrice.valid) {
      this.isValidPrice = true;
    } else {
      this.isValidPrice = false;
    }
  }

  convertDate(date:any, type:string) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    switch (type) {
      case 'YYYY-MM-dd':
        return `${year}-${month}-${day}`;
      case 'dd.MM.YYYY':
        return `${day}.${month}.${year}`;
      case 'MM.YYYY':
        return `${month}.${year}`;
    }
    return;
  }

  //Month input
  dateInputChange(e:any) {
    if(this.calendarForm.controls.monthYear.valid) {
      let inputDate = e.split('.');
      let newMonth = inputDate[0];
      let newYear = inputDate[1];
      this.inputMonth = new Date(newYear, newMonth - 1, 1);
      this.createCalendar();
    }
  }
  updateCal(e:any) {
    this.calendarForm.patchValue({
      monthYear: this.convertDate(e,'MM.YYYY'),
    });
  }

  //Action form
  disableSearch() {
    this.isFormValid = false;
  }
  validateDate() {
    if(this.startDate && this.endDate && this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      this.isFormValid = true;
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
    } else {
      this.isFormValid = false;
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.endDate = new Date(this.startDate.getTime() + 24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }

  //Generate calendar
  currentTimeout:any = null;
  cancelTimeout(): void {
    clearTimeout(this.currentTimeout);
    this.currentTimeout = undefined;
  }
  createDate(stringDate:any) {
    let extract = stringDate.split('-');
    return new Date(extract[0], extract[1]-1, extract[2]);
  }
  createCalendar() {
    if(this.selectedHotel) {
      this.cancelTimeout();
      this.currentTimeout = setTimeout(() => {
        let prev,next;
        let year = this.inputMonth.getFullYear();
        let month = this.inputMonth.getMonth();
        let currentDate = new Date(year,month,1);
        let calDate: any[] = [];
        while(currentDate.getMonth() === month) {
          let calDay = new Date(currentDate);
          calDate.push({day: calDay, available: true, availableId: null});
          currentDate.setDate(currentDate.getDate() + 1);
        }
        let prevDay = 1 - calDate[0].day.getDay();
        if (prevDay == 1) {
          prevDay = -6;
        }
        for(prev = 0; prev > prevDay; prev--) {
          let calDay = new Date(year, month, prev);
          calDate.unshift({day: calDay, available: true, availableId: null});
        }
        let finalDay = calDate[calDate.length - 1].day.getDate();
        let finalWeekDay = calDate[calDate.length - 1].day.getDay();
        let nextDay = finalDay + 2 + 6 - finalWeekDay;
        for(next = finalDay + 1; next < nextDay; next++) {
          let calDay = new Date(year, month, next);
          calDate.push({day: calDay, available: true, availableId: null});
        }
        let calStartDate = calDate[0].day;
        let calEndDate = calDate[calDate.length - 1].day;
        
        //Get data
        const hotelObj = this.hotels.find((obj:any) => {
          return obj.hotelId == Number(this.selectedHotel?.value);
        });
        let request = {
          'hotelId': hotelObj.hotelId, 
          'startDate': this.convertDate(calStartDate,'YYYY-MM-dd'), 
          'endDate': this.convertDate(calEndDate,'YYYY-MM-dd')
        };
        this.breakfastService.getBreakfastAvailable(request).subscribe(data => {
          let tmpLoopPos = 0;
          data.breakfastAvailabilityEntries.forEach((result:any) => {
            let tmpAvailableDate = this.createDate(result.notAvailableDate);
            for(let i = 0; i < calDate.length; i++) {
              if(calDate[i].day.getTime() == tmpAvailableDate.getTime()) {
                calDate[i].available = false;
                calDate[i].availableId = result.id;
              }
            }
          });
          data.productPriceVats.forEach((result:any) => {
            let tmpPrice = Number(result.price).toFixed(2);
            let tmpStartDate = (result.startDate == null) ? result.startDate : this.createDate(result.startDate);
            let tmpEndDate = (result.endDate == null) ? result.endDate : this.createDate(result.endDate);
            if(tmpStartDate < calStartDate) {
              tmpStartDate = calStartDate;
            }
            if(tmpEndDate == null) {
              tmpEndDate = calEndDate;
            } else if(tmpEndDate > calEndDate) {
              tmpEndDate = calEndDate;
            }
            let tmpDateLength = ((tmpEndDate.getTime() - tmpStartDate.getTime())/(1000*3600*24)) + tmpLoopPos;
            
            for(let i = tmpLoopPos; i <= tmpDateLength; i++) {
              calDate[i].price = tmpPrice;
              tmpLoopPos++;
            }
          });
          this.productId = data.productPriceVats[0].productId;
          this.dates = calDate;
        });
      }, 200);
    }
  }

  isSameMonth(date:any) {
    return this.inputMonth.getMonth() == date.getMonth();
  }

  isToday(date:any) {
    return this.today.setHours(0,0,0,0) == date.setHours(0,0,0,0)
  }
}
