<app-header></app-header>
<div class="main-container">
  <div class="container-fluid inner-container">
    <div id="main" class="row">
      <div class="col-md-12">
        <h1 class="view-title">Hotel Settings</h1>
        <h3 class="id-requirement">ID requirement</h3>
        <!-- Loading icon -->
        <div class="center-item" *ngIf="loading">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
        <table class="hotel-table" width=50% *ngIf="!loading">
          <thead>
            <tr>
              <th>Hotel</th>
              <th>From</th>
              <th>To</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let hotel of hotels" class="hotel-view">
              <td>{{hotel.dropdownHotelName}}</td>
              <td class="start-date">{{ hotel.forceSsnStartDate ? (hotel.forceSsnStartDate | date: 'dd.MM.yyyy') : '-' }}</td>
              <td class="end-date {{ isAllStartDateEndDateNull ? 'format-start-date-end-date-null' : ''}}">
                {{hotel.forceSsnEndDate ? (hotel.forceSsnEndDate | date: 'dd.MM.yyyy') : '-' }}
              </td>
              <td class="is-enabled-body {{ isAllStartDateEndDateNull ? 'format-is-enabled' : '' }}">{{ isEnabledForcingSSN(hotel)
                }}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div class="col-md-6 text-right edit-btn-hotels" *ngIf="!loading">
          <button class="btn btn-green w-20" (click)="updateForcingHotel.show()">Edit</button>
        </div>
      </div>
    </div>
  </div>
</div>  
<!-- Modal -->
<div bsModal #updateForcingHotel="bs-modal" class="modal fade">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12 col-xs-12 col-md-12">
            <label for="select-report">Hotel</label>
            <dropdown-menu
              [menuList]="hotelList"
              [selectedItem]="selectedHotel"
              [fullWidth]="true"
              (selectItem)="selectHotel($event)"
            ></dropdown-menu>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12 col-md-12">
            <label for="start-date">Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker [(bsValue)]="startDate"
                #startDateInput="bsDatepicker" (input)="disableForm()" (blur)="validateDate()" (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-xs-12 col-md-12">
            <label for="end-date">End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control" bsDatepicker [(bsValue)]="endDate"
                #endDateInput="bsDatepicker" (input)="disableForm()" (blur)="validateDate()" (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
        </div>
        <div class="row text-right save-btn">
          <button class="btn btn-green" [disabled]="!isFormValid"
            (click)="saveHotelSettings();updateForcingHotel.hide()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>