import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appEditPercentage]'
})
export class EditPercentageDirective {

  constructor(private el: ElementRef) { 
    
  }

  @HostListener('input')
  onChange() {
    let element = this.el.nativeElement;
    let inputValue = element.value;
    let pricePattern = new RegExp(/^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/);
    if(pricePattern.test(inputValue)) {
      element.classList.remove('invalid');
    } else {
      element.classList.add('invalid');
    }
  }

}
