import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cleanSource'
})
export class CleanSourcePipe implements PipeTransform {

  transform(source:any):any {
    if(source == "HIBOX") {
      return "TV";
    }
    if(['BOOKING_STARTS', 'DAILY_SCHEDULER', 'MIGRATION', 'MAINTENANCE_RESERVATION_STARTS', 'MAINTENANCE_RESERVATION_ENDS'].includes(source)) {
      return "Auto";
    } else {
      return source;
    }
  }
  
}
