import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { UTILS } from 'src/app/helpers/utils';
import { ReservationsService } from 'src/app/services/reservations.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import * as paytrailsData from 'src/assets/resources/paytrails.json';
import { formatDate, Location } from '@angular/common';
import { CONSTANT } from 'src/app/helpers/constants';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import moment from 'moment';
import * as locale from 'locale-codes';
import { cloneDeep, forEach, indexOf } from 'lodash';
import { FinnishSSN } from 'finnish-ssn-validator';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import COUNTRIES from 'src/assets/resources/country-codes.json';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { environment } from 'src/environments/environment';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';
import {default as countryData} from 'src/assets/resources/country.json';
import { VoucherService } from 'src/app/services/voucher.service';
const TOKEN_KEY = 'jwt';
const phoneUtil = PhoneNumberUtil.getInstance();
const PNF = PhoneNumberFormat;

type InvoiceItemListType = {
  selectedMenu: MenuType,
  value: number
}

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.sass', '../../../../styles.sass']
})
export class ReservationDetailComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }

  hotels: any = [];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  availabilityExtra: any = [];
  timeSecif: any = CONSTANT.TIME_SECIF.filter(secif => this.hotelConfig.MUIfeature.timeSecif.includes(secif.name));
  isShowBookingChannel: boolean = false;
  isBookingChannel: boolean = false;
  editCustomBookingChannelLabel: string = '';
  datepickerCustom: DatepickerDateCustomClasses[];
  reservation: any;
  countriesSearch: any = COUNTRIES;
  paytrails: any = paytrailsData;
  languages: any = CONSTANT.LOCALE_LANGUAGES;
  eInvoiceOperaters: any = CONSTANT.E_INVOICE_OPERATER;
  fennoaProducts: any[] = [];
  comments: any;
  payments: any;
  totalBalance: any = 0;
  selectedRefundPayment: number = 0;
  refundFormSelection: any;
  refundGiftcardDate: any;
  customerEditForm!: FormGroup;
  addPaymentForm!: FormGroup;
  partialPaymentForm!: FormGroup;
  notificationForm!: FormGroup;
  refundForm!: FormGroup;
  invoiceForm!: FormGroup;
  currency:string = "EUR";
  orderProductAccordion:string[] = [];
  segmentList:any = [];

  hasRefund: boolean = false;
  hasDisabled: boolean = false;
  hasChildren: boolean = false;
  hasPendingRefund: boolean = false;

  pendingCancelItems: any[] = [];

  orderDatepicker: any = {};
  doorCodeTimeout: any = {};
  orders: any = {
    productsInReservation: [],
    roomOrders: [],
  };

  resetDoorCodeValue: any = {
    roomNumber: null,
    orderId: null,
    reservationCode: null,
    uuid: null
  }

  refundRemain: any = {
    money: 0,
    percentage: 0,
    giftcard: 0
  }
  isSSN: boolean = false;
  isSSNEdit: boolean = false;
  isLoggedIn: boolean = false;
  editAllowed: boolean = false;
  paymentAllowed: boolean = false;
  inputEdited: boolean = false;
  showCancelled: boolean = false;
  isPassedBlockList: boolean = false;
  confirmedCancel: boolean = false;
  confirmCancelItem: boolean = false
  sendExtra: boolean = false;
  isAddingManualPayment: boolean = true;
  isAddingInvoiceOmena: boolean = false;
  isAddedInvoice: boolean = false;
  indexAddedInvoice: any;
  cancelLineItemRefund: string = '';
  isAddedAdvanceInvoice: boolean = false;
  messageType: string = "CONFIRMATION";

  isManualPaidForOtaExtras: boolean = false;

  orderOfferSelection: any = [];

  fennoaCustomer: any;
  invoiceVar: any;
  invoiceDate: any = null;
  dueDate: any = null;
  currentInvoiceNumber: any;
  currentInvoiceId: any;
  currentInvoiceIdAdded: any;
  invoiceData: any;
  invoiceStatus: string = "Not sent";
  invoiceApproved: boolean = false;
  customerName: any;
  eInvoiceOperaterVar: any;
  disableInvoiceForm: boolean = false;
  originalAmount: any;
  invoicePaymentPercentage: any;
  invoicePaymentAmount: any;
  invoiceValidate: any;
  invoiceSaving: boolean = false;
  invoiceApproving: boolean = false;
  isLoadingConfirm: boolean = false;
  isLoadingCancel: boolean = false;
  isAddingPaymentLink: boolean = false;

  // Improved menu
  countryList: MenuType[] = [];
  selectedEditCustomerCountry?: MenuType;

  otaChannelList: MenuType[] = [];
  selectedOtaChannel?: MenuType;

  // Integrated invoice (improved)
  selectedInvoiceCountry?: MenuType;
  selectedInvoiceLang?: MenuType;

  invoiceDeliveryMethodList: MenuType[] = [];
  selectedInvoiceDeliveryMethod?: MenuType;

  invoiceTypeList: MenuType[] = [];
  selectedInvoiceType?: MenuType;

  invoiceTermsOfPaymentList: MenuType[] = [];
  selectedInvoiceTermsOfPayment?: MenuType;

  invoiceAdditionalListFull: any;
  invoiceAdditionalList: MenuType[] = [];
  selectedInvoiceAdditional: MenuType = {
    label: "Add item",
    value: "add-item",
    disabled: false
  };

  // Payment link
  paymentLink: string = "";
  paymentLinkOptions: any = [
    {
      label: "Balance",
      value: "full"
    },
    {
      label: "Partial amount",
      value: "partial"
    }
  ]
  selectedPaymentLinkOption: any = this.paymentLinkOptions[0];
  paymentLinkHistory: any[] = [];
  filteredPaymentLinkHistory: any[] = [];
  generatingLink: boolean = false;
  paymentIdSearchValue: string = "";

  // Credit invoice
  creditInvoiceList: any = [];
  creditInvoiceVar: any;
  editInvoice: boolean = false;

  showInvoiceItem: boolean = false;
  showInvoiceItemType: boolean = false;
  invoiceItemList: InvoiceItemListType[] = [];

  // Invoice for Salo Hotels
  isAddingInvoiceSalo: boolean = false;
  invoiceSaloForm!: FormGroup;
  invoiceSaloVar: any;
  startTime: any = new Date(new Date().setHours(0, 0, 0, 0));
  endTime: any = new Date(this.startTime.getTime() + 1 * 24 * 60 * 60 * 1000);
  startDate: any = new Date(new Date().setHours(0, 0, 0, 0));
  endDate: any = new Date(this.startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  filters: any = cloneDeep(CONSTANT.FILTER_INVOICE_MANUAL);
  selectedFilterLabel: string = "[Default]";
  searchObjects: any = cloneDeep(CONSTANT.SEARCH_OBJECT);
  isLoadingInvoiceManual: boolean = false;
  isLoadingCreatePdf: boolean = false;
  isMoreLoading: boolean = false;
  searchResult: any = [];
  bookingChannel: any = CONSTANT.CHANNEL_BOOKING;
  orderBy: string = "CHECK_OUT";
  orderDir: string = "ASC";
  numberLimitItems: number = 5;
  isHiddenSearch: boolean = false;
  cleanEvent: any = {
    totalItem: 0,
    current: 1,
    finalPage: 0,
    items: this.numberLimitItems,
    showNav: 4,
    numberOfPage: 0
  };
  surchargePrice: number = 5;
  surchargePriceTemp: number = 5;
  includeSurcharge: boolean = true;
  sumTotalRemainingPrice: number = 0;
  reservationCodes: any = [];
  isCheckAllReservations: boolean = false;
  invoiceManualValidate: any;
  allTotalInvoice: any = [];
  integratedInvoiceData: any;

  hasPdf: boolean = false;
  manualInvoiceId: any = null;
  creditInvoiceFormSelection: any = "downloadPdf";
  manualInvoices: any = [];
  downloadCreditInvoiceFormSelection: any = "";

  orderProductOfferLabel: any = [];
  hasNationality: boolean = true;

  isCustomerSave: boolean = false;
  request: any = null;

  isLoadingDoorCode: boolean = false;
  manualPaymentTypeList: string[] = [];
  selectedPaymentTypeRadio: string = "";
  addPaymentTypeMode: boolean = false;
  editPaymentTypeMode: boolean = false;
  inputAddPaymentType = "";

  summaryView: MenuType[] = [
    {
      value: "allProducts",
      label: "All products",
      disabled: false
    },
    {
      value: "extras",
      label: "Extras",
      disabled: false
    },
    {
      value: "stayRange",
      label: "Stay range",
      disabled: false
    },
  ]
  selectedSummaryView: any = this.summaryView[0];

  langList: MenuType[] = [];
  selectedLang: MenuType | undefined;
  changingLang: boolean = false;

  //Modal
  modalRef!: BsModalRef;
  @ViewChild('resetDoorCodeModal') resetDoorCodeTemplate!: TemplateRef<any>;
  @ViewChild('customerEditModal') customerEditTemplate!: TemplateRef<any>;
  @ViewChild('paymentTypeModal') paymentTypeTemplate!: TemplateRef<any>;
  @ViewChild('invoiceModal') invoiceTemplate!: TemplateRef<any>;
  @ViewChild('creditInvoiceModal') creditInvoiceTemplate!: TemplateRef<any>;
  @ViewChild('downloadCreditInvoiceModal') downloadCreditInvoiceTemplate!: TemplateRef<any>;
  @ViewChild('createCreditInvoiceModal') createCreditInvoiceTemplate!: TemplateRef<any>;
  @ViewChild('invoiceSaloModal') invoiceSaloTemplate!: TemplateRef<any>;
  @ViewChild('paymentModal') paymentTemplate!: TemplateRef<any>;
  @ViewChild('paymentLinkModal') paymentLinkTemplate!: TemplateRef<any>;
  @ViewChild('resultModal') resultTemplate!: TemplateRef<any>;
  @ViewChild('notificationModal') notificationTemplate!: TemplateRef<any>;
  @ViewChild('messageTypeModal') messageTypeTemplate!: TemplateRef<any>;
  @ViewChild('manualRefundModal') manualRefundTemplate!: TemplateRef<any>;
  @ViewChild('refundModal') refundTemplate!: TemplateRef<any>;
  @ViewChild('confirmBlockedModal') confirmBlockedTemplate!: TemplateRef<any>;
  @ViewChild('unblockModal') unblockTemplate!: TemplateRef<any>;
  @ViewChild('cancelReservationModal') cancelReservationTemplate!: TemplateRef<any>;
  @ViewChild('cancelLineItemModal') cancelLineItemTemplate!: TemplateRef<any>;
  @ViewChild('confirmSSNModal') confirmSSNModalTemplate!: TemplateRef<any>;
  @ViewChild('saveBlockedModal') saveBlockedModalTemplate!: TemplateRef<any>;
  @ViewChild('overlapOnGoingRoomUsage') overlapOnGoingRoomUsageTemplate!: TemplateRef<any>;
  @ViewChild('checkCreatePdfModal') checkCreatePdfModalTemplate!: TemplateRef<any>;
  disabledBtnWhileCallingAPI: boolean = false;
  @ViewChild('additionalServicesModal') additionalServicesModalTemplate!: TemplateRef<any>;
  //Result modal
  resultElements: any = {
    result: 'error',
    type: '',
    description: ''
  }

  notification: any = {
    email: true,
    sms: false,
  }
  selectSummaryView(item: any) {
    this.selectedSummaryView = item;
  }
  selectReservationLang(item: any) {
    this.changingLang = true;
    this.selectedLang = item;
    this.reservationsService.updateLang(this.reservation.reservationCode, item.value).subscribe(
      data => {
        this.toastr.success('Reservation language has been updated!', 'Success!', { timeOut: 5000 });
        this.changingLang = false;
      },
      err => {
        this.setLangFromRes();
        this.toastr.error('An error occurred while updating language, please try again!', 'Error!', { timeOut: 5000 });
        this.changingLang = false;
      }
    )
  }

  showHotelText() {
    if (this.hotelConfig.MUIfeature.useHotelCode) {
      return "Hotel ";
    } else {
      return ""
    }
  }

  openResetAlert(roomNumber: number, orderId: number, reservationCode: number, uuid: string) {
    this.resetDoorCodeValue = {
      roomNumber: roomNumber,
      orderId: orderId,
      reservationCode: reservationCode,
      uuid: uuid
    }
    this.openModal('alert', this.resetDoorCodeTemplate);
  }
  openCustomerEditModal() {
    this.assignDataCustomerEditForm();
    this.openModal('modal', this.customerEditTemplate);
  }

  assignDataCustomerEditForm() {
    let customer = this.reservation.customer;
    let bookingInfo = this.reservation;
    const currentCountryName = this.countryList.find((country) => country.value === customer.nationality);
    this.customerEditForm.patchValue({
      firstName: customer.firstName,
      lastName: customer.lastName,
      realEmail: customer.emailReal,
      mobile: customer.mobile,
      country: currentCountryName ? currentCountryName.label : null,
      ssn: customer.ssn,
      passportNumber: customer.passportNumber,
      address: customer.address,
      postalCode: customer.postalCode,
      city: customer.city,
      companyName: this.reservation.company.name,
      emailVirtual: customer.emailVirtual,
      bookingChannelReservationId: bookingInfo.bookingChannelReservationId
    });
    this.isShowBookingChannel = false;
    const findSelectedOta = this.otaChannelList.find(channel => channel.value === bookingInfo.bookingChannel);
    if (this.isBookingChannel) {
      this.customerEditForm.controls['bookingChannelReservationId'].disable();
      this.customerEditForm.controls['emailVirtual'].disable();
    } else {
      this.customerEditForm.controls['bookingChannelReservationId'].enable();
      this.customerEditForm.controls['emailVirtual'].enable();
    }
    if (findSelectedOta) {
      this.selectOtaChannel(findSelectedOta)
    } else {
      this.selectOtaChannel(this.otaChannelList[0])
    }
    if (currentCountryName) {
      this.editCustomerSelectCountry(currentCountryName)
    }
  }

  toggleDisplayBookingChannel() {
    this.isShowBookingChannel = !this.isShowBookingChannel;
  }

  filterCountry(countryName: any): void {
    if (!!countryName && typeof countryName === 'string') {
      this.countriesSearch = COUNTRIES.filter((country: any) => {
        return country.name.toLowerCase().startsWith(countryName.toLowerCase())
      });
      return;
    }
    this.countriesSearch = COUNTRIES;
  }

  openPaymentTypeModal() {
    this.isAddingManualPayment = true;
    this.isAddingInvoiceOmena = false;
    this.isAddingInvoiceSalo = false;
    this.isAddingPaymentLink = false;
    this.paymentLink = "";
    this.openModal('modal', this.paymentTypeTemplate);
  }
  oldcopyPaymentLink(showToast: boolean = false, link: string) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = link;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    if (showToast) {
      this.toastr.success("Payment link copied!", 'Success!', { timeOut: 5000 });
    }
  }
  async copyPaymentLink(showToast: boolean = false, link: string) {
    try {
      await navigator.clipboard.writeText(link);
      if (showToast) {
        this.toastr.success("Payment link copied!", 'Success!', { timeOut: 5000 });
      }
    } catch (err) {
      console.log(err)
      this.toastr.error("An error occurred while copying payment link, please try again", 'Error!');
    }
  }
  openSelectedType() {
    this.modalRef.hide();
    if (this.isAddingInvoiceOmena) {
      let currentInvoiceAdded = this.findCurrentlyInvoiceAdded();
      this.isAddedInvoice = currentInvoiceAdded && currentInvoiceAdded.fennoaInvoiceId ? true : false;
      if (this.isAddedInvoice) {
        this.currentInvoiceIdAdded = currentInvoiceAdded.fennoaInvoiceId;
        this.findIndexCurrentlyInvoiceAdded();
        this.openInvoiceModal(this.currentInvoiceIdAdded, this.indexAddedInvoice);
      } else {
        this.openInvoiceModal(null, null);
      }
    } else if (this.isAddingInvoiceSalo) {
      this.removeInvoiceManualInvalid();
      this.openInvoiceSaloModal('modal', this.invoiceSaloTemplate);
    } else if (this.isAddingPaymentLink) {
      this.openPaymentLinkModal();
    } else {
      this.openModal('modal', this.paymentTemplate);
    }
  }
  findIndexCurrentlyInvoiceAdded() {
    if (this.reservation.payments.length) {
      return this.reservation.payments.find((payment: any, index: number) => {
        if (payment.fennoaInvoiceId === this.currentInvoiceIdAdded) {
          this.indexAddedInvoice = index;
          return true;
        }
      });
    }
    return;
  }
  findCurrentlyInvoiceAdded() {
    if (this.reservation.payments.length) {
      let reversePayments = cloneDeep(this.reservation.payments).reverse();
      return reversePayments.find((payment: any, index: number) => {
        if (payment.fennoaInvoiceId) {
          this.indexAddedInvoice = index;
          return payment.fennoaInvoiceId;
        }
      });
    }
    return null;
  }
  findCustomerFromCustomerID() {
    let customerResult: any;
    if (this.invoiceData) {
      customerResult = this.fennoaCustomer.find((customerFennoa: any) => {
        return customerFennoa.Customer.id === this.invoiceData.SalesInvoice.customer_id;
      });
    }
    return customerResult;
  }
  openInvoiceSaloModal(invoiceId: any = null, index: any) {
    const findDefaultCountry = this.countryList.find(country => country.value === this.hotelConfig.feature.defaultGuestNationality);
    this.selectedInvoiceCountry = findDefaultCountry;

    this.currentInvoiceId = null;
    this.invoiceSaloVar = {
      showSearch: false,
      searchKeyword: '',
      isSelectedFennoaCustomer: false,
      selectedCustomer: null,
      copyReservation: false,
      createNew: true,
      filterResult: this.fennoaCustomer,
      existCustomerAlert: false
    }
    let customer = this.reservation.customer;
    this.reservationSearch.patchValue({
      searchQuery: customer.firstName,
    });
    this.invoiceSaloForm.patchValue({
      name: customer.firstName + " " + customer.lastName,
      postalcode: customer.postalCode,
      companyName: this.reservation.company.name,
      address: customer.address,
      city: customer.city,
      invoiceDate: new Date(),
      dueDate: null,
      your_reference: this.yourReferenceCustomer(),
    });
    let tmpInvoiceTermsOfPayment: MenuType[] = []
    CONSTANT.TERMS_OF_PAYMENT.forEach(item => {
      tmpInvoiceTermsOfPayment.push({
        label: item.name,
        value: item.value.toString(),
        disabled: false
      })
    })
    this.invoiceTermsOfPaymentList = tmpInvoiceTermsOfPayment;

    this.setDefaultLanguage();
    this.openModal('invoice', this.invoiceSaloTemplate);
  }
  openInvoiceModal(invoiceId: any = null, index: any) {
    this.reservationsService.getFennoaProducts().subscribe(
      (data) => {
        this.fennoaProducts = data;

        const tmpInvoiceAdditional = data.filter((item: any) => item.additionalProduct === true && item.productId > 0);
        this.invoiceAdditionalListFull = tmpInvoiceAdditional;
        let tmpInvoiceAdditionalList: MenuType[] = []
        tmpInvoiceAdditional.forEach((product: any) => {
          tmpInvoiceAdditionalList.push({
            label: product.name_en,
            value: product.code,
            disabled: false
          })
        })
        this.invoiceAdditionalList = tmpInvoiceAdditionalList;

        this.reservationsService.getFennoaInvoiceItems(this.reservation.uuid).subscribe(
          (data) => {
            this.integratedInvoiceData = data;
            if (this.isAddedInvoice) {
              this.currentInvoiceId = null;
              this.invoiceVar = {
                showSearch: false,
                searchKeyword: '',
                isSelectedFennoaCustomer: false,
                selectedCustomer: null,
                copyReservation: false,
                createNew: true,
                filterResult: this.fennoaCustomer,
                existCustomerAlert: false
              }
            } else {
              this.currentInvoiceId = invoiceId;
            }
            this.invoiceApproved = false;
            this.currentInvoiceNumber = null;
            this.clearInvoiceForm();
            this.invoiceForm.patchValue({
              your_reference: this.yourReferenceCustomer()
            });
            setTimeout(() => {
              this.openModal('invoice', this.invoiceTemplate);
            }, 200);
            if (invoiceId) {
              this.getInvoice(() => {
                this.editInvoice = true;
                this.invoiceVar.isSelectedFennoaCustomer = true;
                let invoiceInfo = this.invoiceData.SalesInvoice;
                if (invoiceInfo.approved) {
                  this.invoiceApproved = true;
                }
                this.invoiceVar.searchKeyword = invoiceInfo.name;
                let salesInvoiceDeliveryData = {
                  salesInvoiceDelivery: this.invoiceData.SalesInvoiceDelivery,
                  index: index
                }
                this.showFilterOptional(invoiceInfo, salesInvoiceDeliveryData);
                setTimeout(() => {
                  this.fillInvoiceForm(invoiceInfo.name, invoiceInfo.address, invoiceInfo.postalcode, invoiceInfo.city, salesInvoiceDeliveryData.salesInvoiceDelivery[0].address, invoiceInfo.vat_number, invoiceInfo.your_reference, invoiceInfo.notes_fixed_width);
                  let fennoaPayment = this.reservation.payments.find((payment: any) => {
                    return payment.fennoaInvoiceId == invoiceId;
                  });
                  let fennoaPaymentStatus = "";
                  if (fennoaPayment) {
                    if (fennoaPayment.fennoaPaymentStatus) {
                      fennoaPaymentStatus = fennoaPayment.fennoaPaymentStatus.replace("_", " ").toLowerCase();
                      this.invoiceStatus = fennoaPaymentStatus.charAt(0).toUpperCase() + fennoaPaymentStatus.slice(1);
                    } else {
                      // Set invoice status for credit invoice is empty
                      this.invoiceStatus = "";
                    }
                  }
                  if (!this.isAddedInvoice) {
                    let invoiceDateobj = new Date(invoiceInfo.invoice_date);
                    let dueDateobj = new Date(invoiceInfo.due_date);
                    this.invoiceForm.patchValue({
                      invoiceDate: invoiceDateobj.toLocaleString("en-GB", { timeZone: this.reservation.hotel.zone }),
                      dueDate: dueDateobj.toLocaleString("en-GB", { timeZone: this.reservation.hotel.zone }),
                    });
                    this.disableInvoice(true);
                    this.getTermsOfPayment();
                    let tmpAmount: any = 0;
                    if (fennoaPayment.fennoaPaymentType === "ADVANCE") {
                      let hasNonAdditionalProducts = false;
                      this.invoiceData.SalesInvoiceRow.forEach((row: any) => {
                        const findProduct = this.fennoaProducts.find(product => product.code === row.code);
                        if (findProduct) {
                          const findInvoiceProduct = this.invoiceAdditionalListFull.find((product: any) => product.code === findProduct.code);
                          if (!findInvoiceProduct) {
                            hasNonAdditionalProducts = true;
                            tmpAmount += Math.abs(Number(row.price_with_vat) * row.quantity);
                          }
                        }
                      });
                      if (!hasNonAdditionalProducts) {
                        const findNoneItem = this.invoiceTypeList.find(item => item.value === "NONE");
                        if (findNoneItem) {
                          this.selectedInvoiceType = findNoneItem;
                        }
                      }
                    } else {
                      this.invoiceData.SalesInvoiceRow.forEach((row: any) => {
                        const findProduct = this.fennoaProducts.find(product => product.code === row.code);
                        if (findProduct) {
                          const findInvoiceProduct = this.invoiceAdditionalListFull.find((product: any) => product.code === findProduct.code);
                          if (!findInvoiceProduct) {
                            tmpAmount += Math.abs(Number(row.price_with_vat) * row.quantity);
                          }
                        }
                      });
                    }
                    // Display additional product
                    this.invoiceData.SalesInvoiceRow.forEach((row: any) => {
                      const findProduct = this.fennoaProducts.find(product => product.code === row.code);
                      if (findProduct) {
                        const findInvoiceProduct = this.invoiceAdditionalListFull.find((product: any) => product.code === findProduct.code);
                        if (findInvoiceProduct) {
                          const findAdditionalProduct = this.invoiceAdditionalList.find(item => item.value === findInvoiceProduct.code);
                          if (findAdditionalProduct) {
                            this.invoiceItemList.push({
                              selectedMenu: findAdditionalProduct,
                              value: Math.abs(Number(row.price_with_vat))
                            })
                          }
                        }
                      }
                    });
                    if (this.invoiceItemList.length) {
                      this.showInvoiceItem = true;
                    }
                    this.invoicePaymentAmount = tmpAmount;
                    this.invoicePaymentChange('amount', invoiceId);
                  } else {
                    this.invoiceVar.selectedCustomer = this.findCustomerFromCustomerID(),
                      this.disableInvoice(false);
                  }
                  setTimeout(() => {
                    if (this.selectedInvoiceType && this.selectedInvoiceType.value === "CREDIT_INVOICE") {
                      this.creditInvoiceVar.selectedInvoice = {
                        invoiceId: invoiceId,
                        invoiceNo: invoiceInfo.creditedInvoiceNo
                      }
                      let tmpSalesRow = JSON.parse(JSON.stringify(this.invoiceData.SalesInvoiceRow));
                      let tmpCreditAmount: any = 0;
                      tmpSalesRow.forEach((row: any) => {
                        row.price_with_vat = Math.abs(Number(row.price_with_vat));
                        tmpCreditAmount += Math.abs(Number(row.price_with_vat) * row.quantity);
                      });
                      this.invoiceData.SalesInvoiceRow = tmpSalesRow;
                      this.creditInvoiceVar.searchKeyword = `${invoiceInfo.creditedInvoiceNo} - ${Math.abs(tmpCreditAmount)} ${UTILS.getCurrencySymbol(this.currency)}`;
                    }
                  }, 100);
                }, 400);
              });
            } else {
              this.editInvoice = false;
              this.disableInvoice(false);
            }
          }
        )
      }
    )
  }
  setDefaultInvoiceVar() {
    const findDefaultCountry = this.countryList.find(country => country.value === this.hotelConfig.feature.defaultGuestNationality);
    if (findDefaultCountry) {
      this.selectInvoiceCountry(findDefaultCountry)
    }

    this.setDefaultLanguage();

    let tmpDeliveryMethodList: MenuType[] = [];
    CONSTANT.DELIVERY_METHODS.forEach(item => {
      tmpDeliveryMethodList.push({
        label: item.name,
        value: item.value,
        disabled: false
      })
    })
    this.invoiceDeliveryMethodList = tmpDeliveryMethodList;
    const findDefaultDeliveryMethod = tmpDeliveryMethodList.find(item => item.value === "email");
    if (findDefaultDeliveryMethod) {
      this.selectInvoiceDeliveryMethod(findDefaultDeliveryMethod, false)
    }

    let tmpInvoiceType: MenuType[] = [];
    CONSTANT.FENNOA_INVOICE_RESERVATION_TYPE.forEach(item => {
      tmpInvoiceType.push({
        label: item.name,
        value: item.value,
        disabled: ["ADVANCE", "CUSTOM"].includes(item.value) && this.getPriceNumber(this.reservation.totalPaid) > 0
      })
    })
    this.invoiceTypeList = tmpInvoiceType;

    let tmpInvoiceTermsOfPayment: MenuType[] = []
    CONSTANT.TERMS_OF_PAYMENT.forEach(item => {
      tmpInvoiceTermsOfPayment.push({
        label: item.name,
        value: item.value.toString(),
        disabled: false
      })
    })
    this.invoiceTermsOfPaymentList = tmpInvoiceTermsOfPayment;

    this.invoiceVar = {
      showSearch: false,
      searchKeyword: '',
      isSelectedFennoaCustomer: false,
      selectedCustomer: null,
      copyReservation: false,
      createNew: false,
      filterResult: this.fennoaCustomer,
      existCustomerAlert: false
    }
    this.eInvoiceOperaterVar = {
      showSearch: false,
      searchKeyword: '',
      filterResult: this.eInvoiceOperaters,
      swiftCode: ''
    }
    this.creditInvoiceVar = {
      showSearch: false,
      searchKeyword: "",
      filterResult: [],
      selectedInvoice: null
    }
    this.showInvoiceTypeDefault();
  }
  showInvoiceTypeDefault() {
    const findBalanceItem = this.invoiceTypeList.find(item => item.value === "FULL");
    const findAdvanceItem = this.invoiceTypeList.find(item => item.value === "ADVANCE");
    if (this.reservation.payments.length) {
      if (this.reservation.type === "GROUP" && this.isNoOtherInvoices()) {
        // A group reservation and there are no other invoices, in which case it should be Advance Payment
        if (findAdvanceItem) {
          this.selectInvoiceType(findAdvanceItem);
        }
        return true;
      }
      this.reservation.payments.find((payment: any) => {
        if (payment.fennoaPaymentType === "ADVANCE") {
          // When creating an invoice for a reservation that already has an advance payment issued, the dropdown menu should show 'Final payment' by default
          this.isAddedAdvanceInvoice = true;
          if (findBalanceItem) {
            this.selectInvoiceType(findBalanceItem);
          }
          return true;
        }
        this.setFullForInvoiceTypeDefault();
      });
    } else {
      if (this.reservation.type === "GROUP") {
        // A group reservation and there are no other invoices, in which case it should be Advance Payment
        if (findAdvanceItem) {
          this.selectInvoiceType(findAdvanceItem);
        }
        return true;
      } else {
        this.setFullForInvoiceTypeDefault();
      }
    }
  }
  isNoOtherInvoices() {
    let isNoOtherInvoices = true;
    this.reservation.payments.find((payment: any) => {
      if (payment.fennoaInvoiceId) {
        isNoOtherInvoices = false;
        return false;
      }
    });
    return isNoOtherInvoices;
  }
  setFullForInvoiceTypeDefault() {
    const findBalanceItem = this.invoiceTypeList.find(item => item.value === "FULL");
    if (findBalanceItem) {
      this.selectInvoiceType(findBalanceItem)
    }
  }

  // Invoice item type
  toggleInvoiceItem() {
    this.showInvoiceItem = !this.showInvoiceItem;
  }
  addInvoiceItem(invoiceItem: MenuType) {
    this.invoiceItemList.push({
      selectedMenu: invoiceItem,
      value: 0
    })
  }
  removeInvoiceItem(index: number) {
    this.invoiceItemList.splice(index, 1);
  }
  selectInvoiceItem(index: number, invoiceItem: MenuType) {
    this.invoiceItemList[index].selectedMenu = invoiceItem;
  }

  changeMessageType(messageType: string) {
    this.messageType = messageType;
  }
  openMessageTypeModal() {
    this.messageType = this.isExternalReservation() ? "CONFIRMATION_ROOM" : "CONFIRMATION";
    this.openModal('message-type', this.messageTypeTemplate);
  }
  openNotificationModal(extra: boolean, type: any = null) {
    this.notification.email = true;
    this.notification.sms = false;
    this.sendExtra = extra;
    this.notificationForm.patchValue({
      email: (this.reservation.bookingChannel === "BOOKINGCOM" && type === 'room') ? this.reservation.customer.emailVirtual : this.reservation.customer.emailReal,
      mobile: this.reservation.customer.mobile
    })
    this.openModal('modal', this.notificationTemplate);
  }
  openResultModal(result: string, type: string, description: any = null) {
    this.resultElements.result = result;
    switch (type) {
      case 'payment':
        this.resultElements.type = "Payment";
        if (result == 'success') {
          this.resultElements.description = "Payment is added to the reservation";
        } else {
          this.resultElements.description = description;
        }
        break;
      case 'refund':
        this.resultElements.type = "Refund";
        if (result == 'success') {
          this.resultElements.description = "Refunding money normally takes 2-10 days, depending on the bank or card company";
        } else {
          this.resultElements.description = description;
        }
        break;
    }
    this.openModal('alert', this.resultTemplate);
  }
  openManualRefundModal(paymentId: number) {
    this.selectedRefundPayment = paymentId;
    this.openModal('alert', this.manualRefundTemplate);
  }
  openRefundModal() {
    let totalPaid = this.reservation.totalPaid;
    this.refundFormSelection = 'moneyAmount';
    this.changeRefundOption('moneyAmount');
    this.refundRemain = {
      money: totalPaid,
      percentage: totalPaid,
      giftcard: totalPaid
    }
    this.refundForm.patchValue({
      moneyAmount: 0,
      percentageAmount: 0,
      giftcardAmount: 0,
      giftcardExpireDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      reason: '',
    })
    this.refundGiftcardDate = {
      minDate: new Date(),
      maxDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    }
    this.openModal('modal', this.refundTemplate);
  }
  openCancelReservationModal() {
    this.confirmedCancel = false;
    this.openModal('alert', this.cancelReservationTemplate);
  }
  openCancelLineItemModal() {
    this.openModal('alert', this.cancelLineItemTemplate);
  }
  openUnblockModal() {
    this.openModal('alert', this.unblockTemplate);
  }
  openConfirmUnblockedModal() {
    this.openModal('alert', this.confirmBlockedTemplate);
  }
  openPaymentLinkModal() {
    this.openModal("modal-medium", this.paymentLinkTemplate);
    this.setPaymentLinkOption(this.paymentLinkOptions[0]);
  }
  openModal(type: string, template: TemplateRef<any>) {
    this.modalRef && this.modalRef.hide();
    this.modalRef = this.modalService.show(template);
    switch (type) {
      case 'alert':
        this.modalRef.setClass('custom-alert');
        break;
      case 'message-type':
        this.modalRef.setClass('custom-modal-message-type');
        break;
      case 'modal':
        this.modalRef.setClass('custom-modal-reservation-detail');
        break;
      case 'modal-medium':
        this.modalRef.setClass('custom-modal-medium');
        break;
      case 'modal-large':
        this.modalRef.setClass('custom-modal-large');
        break;
      case 'invoice':
        this.modalRef.setClass('custom-modal-invoice');
        break;
      case 'credit-invoice':
        this.modalRef.setClass('custom-modal-credit-invoice');
        break;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public utils: UTILS,
    private reservationsService: ReservationsService,
    private voucherService: VoucherService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private storeService: StoreService,
  ) {
    this.datepickerCustom = [];
  }

  ngOnInit(): void {
    this.initData();
    this.getDomainSite();
  }

  getDomainSite() {
    this.reservationsService.getDomainSiteHost().subscribe(data => {
      CONSTANT.SITEHOST = "http://" + data;
    });
  }
  changePaymentType(type: string) {
    this.isAddingManualPayment = type === "manualPayment";
    this.isAddingInvoiceOmena = type === "invoiceForOmena";
    this.isAddingInvoiceSalo = type === "invoiceForSalo";
    this.isAddingPaymentLink = type === "paymentLink";
  }
  selectAllPaymentLink(e: any) {
    e.target.select();
  }
  generatePaymentLink(type: string) {
    let tmpLink = "";
    if (this.utils.isLocalEnvironment()) {
      tmpLink = `http://${environment.buiLocalUrl}/${this.reservation.lang}/booking/#/manage-booking-payment/${this.reservation.uuid}?newSession=true&payLink=true`;
    } else {
      tmpLink = `${CONSTANT.SITEHOST}/${this.reservation.lang}/booking/#/manage-booking-payment/${this.reservation.uuid}?newSession=true&payLink=true`;
    }
    if (type === "partial") {
      if (this.partialAmount.valid) {
        this.generatingLink = true;
        this.reservationsService.createPartialPaymentLink(this.reservation.uuid, this.partialAmount.value).subscribe(
          data => {
            this.paymentLink = `${tmpLink}&paymentUuid=${data.uuid}`
            this.toastr.success('Payment link has been created!', 'Success!', { timeOut: 5000 });
            this.getPaymentLinkHistory();
            setTimeout(() => {
              this.generatingLink = false;
            }, 300);
          },
          error => {
            this.generatingLink = false;
            this.toastr.error("An error occurred when generating link, please try again!", 'Error!');
          }
        )
      } else {
        if (!this.partialAmount.value) {
          this.toastr.error("Please enter amount!", 'Error!');
        } else {
          this.toastr.error("Please enter valid amount equal or under maximum amount!", 'Error!');
        }
      }
    } else {
      this.paymentLink = tmpLink;
    }
  }
  setPaymentLinkOption(option: any) {
    this.selectedPaymentLinkOption = option;
    if (option.value === "full") {
      this.generatePaymentLink("full");
    } else {
      this.paymentLink = "";
      this.paymentLinkHistory = [];
      this.getPaymentLinkHistory();
    }
  }
  copySelectedLinkUuid(uuid: string) {
    let tmpLink = "";
    if (this.utils.isLocalEnvironment()) {
      tmpLink = `http://${environment.buiLocalUrl}/${this.reservation.lang}/booking/#/manage-booking-payment/${this.reservation.uuid}?newSession=true&payLink=true&paymentUuid=${uuid}`;
    } else {
      tmpLink = `${CONSTANT.SITEHOST}/${this.reservation.lang}/booking/#/manage-booking-payment/${this.reservation.uuid}?newSession=true&payLink=true&paymentUuid=${uuid}`;
    }
    this.copyPaymentLink(true, tmpLink);
  }
  getPaymentLinkHistory() {
    this.reservationsService.getPartialPaymentLinkHistory(this.reservation.uuid).subscribe(
      data => {
        let tmpResult = data;
        tmpResult.sort((a: any, b: any) => Number(new Date(b.created)) - Number(new Date(a.created)));
        let tmpLinkHistory: any[] = [];
        tmpResult.forEach((link: any) => {
          let tmpLink = {
            uuid: link.uuid,
            amount: `${link.amount} ${UTILS.getCurrencySymbol(this.currency)}`,
            created: moment(link.created).tz(this.reservation.hotel.zone).format("DD.MM.YYYY HH:mm"),
            expire: moment(link.expired).tz(this.reservation.hotel.zone).format("DD.MM.YYYY HH:mm"),
            status: "Active",
            linkAvailable: true
          }
          if (link.used) {
            tmpLink.status = "Already used";
            tmpLink.linkAvailable = false;
          } else if (link.amount < 0) {
            tmpLink.status = "Amount is negative";
            tmpLink.linkAvailable = false;
          } else if (moment().tz(this.reservation.hotel.zone).isSameOrAfter(moment(link.expired).tz(this.reservation.hotel.zone))) {
            tmpLink.status = "Expired";
            tmpLink.linkAvailable = false;
          } else if (link.amount > this.getPriceNumber(this.reservation.balance)) {
            tmpLink.status = "Amount greater than balance";
            tmpLink.linkAvailable = false;
          }
          tmpLinkHistory.push(tmpLink)
        });
        this.paymentLinkHistory = tmpLinkHistory;
        this.filteredPaymentLinkHistory = tmpLinkHistory;
      },
      error => {
        this.toastr.error("An error occurred while getting data, please close and reopen this modal again!", 'Error!');
      }
    )
  }
  filterPaymentHistory() {
    if (!this.paymentIdSearchValue) {
      this.filteredPaymentLinkHistory = this.paymentLinkHistory;
    } else {
      this.filteredPaymentLinkHistory = this.paymentLinkHistory.filter((link: any) => link.uuid.includes(this.paymentIdSearchValue));
    }
  }

  reservationSearch = new FormGroup({
    hotelLabel: new FormControl('', Validators.required),
    searchQuery: new FormControl('', Validators.required),
    channelLabel: new FormControl('', Validators.required),
  });

  commentForm = new FormGroup({
    commentInput: new FormControl('', [Validators.required])
  })

  initData() {
    let token = window.localStorage.getItem(TOKEN_KEY);
    if (token) {
      let roles = this.tokenStorageService.getRole();
      if (roles) {
        if (["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE"].includes(roles)) {
          this.editAllowed = true;
        }
        if (["NELSON_MANAGER", "CLIENT_ADMIN", "CUSTOMER_SERVICE", "REVENUE_MANAGER"].includes(roles)) {
          this.paymentAllowed = true;
        }
      }
    }
    this.reservationsService.getHotels().subscribe(data => {
      this.hotels = data;
    });

    this.countryList = this.utils.getCountryListForMenu();

    let tmpLangList: MenuType[] = [];
    CONSTANT.SUPPORTED_LANGUAGES.forEach(lang => {
      if (this.hotelConfig.hotel.language.includes(lang.value)) {
        tmpLangList.push({
          label: lang.label,
          value: lang.value,
          disabled: false
        })
      }
    })
    this.langList = tmpLangList

    let tmpOtaChannelList: MenuType[] = [
      {
        label: "-",
        value: "none",
        disabled: false
      }
    ];
    CONSTANT.EXTERNAL_CHANNEL_BOOKING.forEach(item => {
      if (this.hotelConfig.MUIfeature.OTAServices.includes(item.value)) {
        tmpOtaChannelList.push({
          label: item.label,
          value: item.value,
          disabled: false
        })
      }
    })
    this.otaChannelList = tmpOtaChannelList;

    this.voucherService.getAllSegment().subscribe(data => {
      this.segmentList = data;
    });

    this.getReservationData((data:any) => {
      this.blocklistCheck();
      const findCustomerCountry = this.countryList.find(country => country.value === data.customer.nationality)
      if (findCustomerCountry) {
        const useSSN = findCustomerCountry.value === "FIN" && this.hotelConfig.feature.defaultGuestNationality === "FIN";
        this.isSSN = useSSN;
        this.isSSNEdit = useSSN;
        this.hasNationality = true;
      } else {
        this.hasNationality = false;
      }

      const findOtaChannel = this.otaChannelList.find(channel => channel.value === data.bookingChannel);
      this.isBookingChannel = findOtaChannel ? true : false;
      this.initCustomerEditForm(data.customer);
      this.editCustomBookingChannelLabel = data.bookingChannel;
      this.initAddPaymentForm();
      this.initPartialPaymentForm();
      this.initNotificationForm();
      this.initRefundForm();
      this.initOrders();
      this.initInvoiceForm();
      this.getInvoicePdf(data.reservationId);
      this.initManualPaymentTypeList((data: any) => { this.setSelectedPaymentTypeRadio(data, this.selectedPaymentTypeRadio) });
      this.route.queryParamMap.subscribe(params => {
        let paramKeys = params.keys;
        let checkBasicParams = ['openPayment'].every((key: any) => paramKeys.includes(key));
        if (checkBasicParams) {
          if (params.get('openPayment') === "true") {
            this.openPaymentTypeModal();
          }
        }
      })
    });
  }

  initManualPaymentTypeList(callback: any = null) {
    this.reservationsService.getManualPaymentTypeList(this.reservation.hotel.hotelChainName).subscribe(data => {
      if (data) {
        this.manualPaymentTypeList = data;
        if (callback) callback(data);
      }
    })
  }

  saveManualPaymentTypeList(callback: any = null, newPaymentTypeList: string[]) {
    this.reservationsService.saveManualPaymentTypeList(this.reservation.hotel.hotelChainName, newPaymentTypeList).subscribe(data => {
      if (+data.status === 200) {
        this.manualPaymentTypeList = newPaymentTypeList;
        if (callback) callback(newPaymentTypeList);
        this.inputAddPaymentType = "";
      }
    })
  }

  setSelectedPaymentTypeRadio(data: string[], selectedType: string) {
    if (data.length) {
      if (selectedType !== "" && data.findIndex(type => type === selectedType) !== -1) {
        this.selectedPaymentTypeRadio = selectedType;
        this.addPaymentForm.patchValue({ paymentType: selectedType });
        return;
      }
      if (!data.includes(this.selectedPaymentTypeRadio)) {
        this.selectedPaymentTypeRadio = data[0];
        this.addPaymentForm.patchValue({ paymentType: data[0] });
      }
    } else {
      this.selectedPaymentTypeRadio = "";
      this.addPaymentForm.patchValue({ paymentType: "" });
    }
  }

  markEdited() {
    this.inputEdited = true;
  }

  displayAPIerror(err: any) {
    let errorMessage;
    if (err.error.description) errorMessage = err.error.description;
    if (err.error.message) errorMessage = err.error.message;
    if (!errorMessage) errorMessage = "Unknown error occurred!";
    this.toastr.error(errorMessage, 'Error!');
  }

  getAvailabilityCheck() {
    let queryParams = {
      'hotel': this.reservation.hotel.hotelId,
      'startDate': formatDate(this.startTime, "yyyy-MM-dd", "en-US"),
      'endDate': formatDate(this.endTime, "yyyy-MM-dd", "en-US"),
    }
    this.reservationsService.availabilityCheck(queryParams).subscribe(data => {
      if (data) {
        _.each(data.samedateEciFlexOptionsAvail, (item) => {
          this.availabilityExtra.push(item)
        })
      }
    })
  }

  setLangFromRes() {
    const findLang: any = this.langList.find((item: any) => item.value === this.reservation.lang);
    if (findLang) {
      this.selectedLang = findLang;
    }
  }

  getReservationData(callback: any = null) {
    this.reservationsService.getDetail(this.route.snapshot.paramMap.get('request')).subscribe((data: any) => {
      this.currency = data.currencyCode;
      this.reservation = data;
      this.convertReservatioToArray(data);
      this.getComments(data.uuid);
      this.getPayments();
      if (this.currentInvoiceId) {
        this.updateIdAndStatusForInvoice();
      }
      this.getInvoicePdf(data.reservationId); // Show the PDF icon after created invoice manual for Salo hotels
      this.reservationsService.getFennoaCustomer(data.hotel.hotelId).subscribe(customers => {
        this.fennoaCustomer = customers;
      });
      this.setLangFromRes();
      if (callback) callback(data);
    });
  }
  convertReservatioToArray(reservation: any) {
    var convertReservation: any = [
      {
        reservationState: reservation.state,
        isChecked: true,
        reservationCode: reservation.reservationCode,
        bookingChannel: reservation.bookingChannel,
        bookingChannelReservationId: reservation.bookingChannelReservationId,
        customer: {
          firstName: reservation.customer.firstName,
          lastName: reservation.customer.lastName,
          emailReal: reservation.customer.emailReal,
          emailVirtual: reservation.customer.emailVirtual,
          mobile: reservation.customer.mobile,
          companyName: reservation.company.name,
        },
        checkOut: reservation.endDate,
        remainingAmount: Number(reservation.balance.slice(4, reservation.balance.length)),
      }
    ];
    // Update guestsByRoomLabel
    let guestsByRoomLabel: any = {};
    this.reservation.orders.forEach((order: any) => {
      let guests: any[] = [];
      this.reservation.guests.forEach((guest: any) => {
        if (order.roomAlias === guest.roomAlias) {
          if (guest.firstName !== "" && guest.lastName !== "") {
            guests.push(guest);
          }
          guestsByRoomLabel[order.roomNumber] = guests;
        }
      });
    });
    convertReservation[0].guestsByRoomLabel = guestsByRoomLabel;
    this.searchResult = convertReservation;
    // Update Invoicing surcharge
    this.sumTotalRemainingPrice = convertReservation[0].remainingAmount + this.surchargePrice;
    if (!this.reservationCodes.includes(reservation.reservationCode)) {
      this.reservationCodes.push(reservation.reservationCode);
      this.allTotalInvoice.push(convertReservation[0].remainingAmount);
    }
    this.isCheckAllReservations = true;
  }
  showOptionsForCreditInvoiceModal() {
    this.creditInvoiceFormSelection = "downloadPdf";
    this.setDefaultLanguage();
    this.openModal('credit-invoice', this.creditInvoiceTemplate);
  }
  changeCreditInvoiceOption(type: string) {
    this.creditInvoiceFormSelection = type;
  }
  changedownloadCreditInvoiceOption(manualInvoice: any) {
    this.manualInvoiceId = manualInvoice.invoiceId;
    this.downloadCreditInvoiceFormSelection = manualInvoice.invoiceNo;
  }
  proceedForCreditInvoice() {
    this.modalRef.hide();
    let request: object;
    request = {
      reservationId: this.reservation.reservationId,
      isGetForCrediting: this.creditInvoiceFormSelection === "createCreditInvoice"
    }
    this.reservationsService.getManualInvoiceBaseOnInvoiceId(request).subscribe((response: any) => {
      this.manualInvoices = response;
      this.downloadCreditInvoiceFormSelection = "";
      if (this.creditInvoiceFormSelection === "downloadPdf") {
        this.showDownloadCreditInvoiceModal();
      } else {
        this.showCreateCreditInvoiceModal();
      }
    });
  }
  showDownloadCreditInvoiceModal() {
    this.openModal('credit-invoice', this.downloadCreditInvoiceTemplate);
  }
  downloadForCreditInvoice() {
    this.isLoadingInvoiceManual = true;
    this.getFilePdf();
  }
  showCreateCreditInvoiceModal() {
    this.openModal('credit-invoice', this.createCreditInvoiceTemplate);
  }
  createCreditInvoiceFunc() {
    this.isLoadingInvoiceManual = true;
    if (this.manualInvoiceId && this.selectedInvoiceLang) {
      this.reservationsService.createCreditInvoice(this.manualInvoiceId, this.selectedInvoiceLang.value).subscribe(
        (response: any) => {
          this.isLoadingInvoiceManual = false;
          this.toastr.success('Created pdf for invoice manual', 'Success!', { timeOut: 2000 });
          this.showFilePdf(response);
          this.modalRef.hide();
          this.getReservationData();
        },
        err => {
          this.isLoadingInvoiceManual = false;
          this.displayAPIerror(err);
        });
    }
  }
  getInvoicePdf(id: any) {
    if (!id) return;
    this.reservationsService.getReservationId(id).subscribe((data: any) => {
      if (data) {
        this.hasPdf = true;
        this.manualInvoiceId = data;
      }
      else {
        this.hasPdf = false;
        this.manualInvoiceId = null;
      }
    });
  }
  getFilePdf() {
    if (this.manualInvoiceId && this.selectedInvoiceLang) {
      this.reservationsService.getManualInvoiceId(this.manualInvoiceId, this.selectedInvoiceLang.value).subscribe((response: any) => {
        this.modalRef.hide();
        this.isLoadingInvoiceManual = false;
        this.showFilePdf(response);
      }, err => {
        this.isLoadingInvoiceManual = false;
        this.displayAPIerror(err);
      });
    }
  }
  showFilePdf(responsePDF: any) {
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(responsePDF);
    link.download = 'invoice.pdf';
    link.dispatchEvent(new MouseEvent('click'));
  }
  updateIdAndStatusForInvoice() {
    if (this.reservation.payments.length) {
      this.reservation.payments.forEach((payment: any) => {
        let fennoaPaymentStatus: any;
        if (payment.fennoaInvoiceId === this.currentInvoiceId) {
          this.currentInvoiceNumber = payment.fennoaInvoiceNo;
          if (payment.fennoaPaymentStatus) {
            fennoaPaymentStatus = payment.fennoaPaymentStatus.replace("_", " ").toLowerCase();
            this.invoiceStatus = fennoaPaymentStatus.charAt(0).toUpperCase() + fennoaPaymentStatus.slice(1);
          } else {
            // Set invoice status for credit invoice is empty
            this.invoiceStatus = "";
          }
        }
      });
    }
  }

  getCustomerInfoAfterSavedInvoice(hotelId: any, customerId: any) {
    let eInvoiceOperater: any;
    this.reservationsService.getFennoaCustomer(hotelId).subscribe(customer => {
      this.fennoaCustomer = customer;
      if (this.fennoaCustomer && this.fennoaCustomer.length) {
        customer = this.fennoaCustomer.find((customer: any) => {
          return customer.Customer.id == customerId;
        });
        // Update Address when the einvoice haven't approved and sent
        this.invoiceForm.patchValue({
          einvoice_address: customer.Customer.einvoice_address
        });
        // Update E-Invoice Operator when the einvoice haven't approved and sent
        if (customer.Customer.einvoice_operator_id) {
          eInvoiceOperater = CONSTANT.E_INVOICE_OPERATER.find((eInvoiceOper) => {
            return eInvoiceOper.swiftCode.toLowerCase() == customer.Customer.einvoice_operator_id.toLowerCase();
          });
        }
        this.eInvoiceOperaterVar.searchKeyword = eInvoiceOperater ? (eInvoiceOperater.name + " (" + eInvoiceOperater.swiftCode + ")") : "";
        this.eInvoiceOperaterVar.swiftCode = eInvoiceOperater ? eInvoiceOperater.swiftCode : "";
      }
    });
  }

  checkInvoice(invoiceId: any, index: any) {
    if (invoiceId) {
      this.setDefaultForCurrentInvoiceAdded();
      this.openInvoiceModal(invoiceId, index);
    }
  }

  setDefaultForCurrentInvoiceAdded() {
    this.isAddedInvoice = false;
    this.currentInvoiceIdAdded = null;
    this.indexAddedInvoice = null;
    this.currentInvoiceIdAdded = null;
  }

  getInvoice(callback: any = null) {
    let invoiceId = this.currentInvoiceId ? this.currentInvoiceId : this.currentInvoiceIdAdded;
    this.reservationsService.getFennoaInvoice(invoiceId, this.reservation.hotel.hotelId).subscribe(
      data => {
        this.invoiceData = data;
        if (data.SalesInvoice.invoice_no) {
          this.currentInvoiceNumber = data.SalesInvoice.invoice_no;
        }
        if (callback) callback();
      },
      err => {
        this.displayAPIerror(err)
      }
    )
  }

  initHotelOffer(callback: any = null) {
    let request = {
      startDate: formatDate(this.reservation.startDate, "yyyy-MM-dd", "en-US"),
      endDate: formatDate(this.reservation.endDate, "yyyy-MM-dd", "en-US"),
      hotel: this.reservation.hotel.hotelId
    }
    this.reservationsService.getHotelOffer(request).subscribe((data: any) => {
      if (callback) callback(data);
    })
  }

  initCustomerEditForm(customer: any) {
    const findCustomerCountry = this.countryList.find(country => country.value === customer.nationality)
    this.customerEditForm = new FormGroup({
      firstName: new FormControl(customer.firstName, [Validators.required]),
      lastName: new FormControl(customer.lastName, [Validators.required]),
      realEmail: new FormControl(customer.emailReal, [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      emailVirtual: new FormControl({ value: customer.emailVirtual }),
      mobile: new FormControl(customer.mobile, [this.validateMobile.bind(this)]),
      country: new FormControl(findCustomerCountry ? findCustomerCountry.label : "", [Validators.required]),
      ssn: new FormControl(customer.ssn),
      passportNumber: new FormControl(customer.passportNumber),
      address: new FormControl(customer.address),
      postalCode: new FormControl(customer.postalCode),
      city: new FormControl(customer.city),
      companyName: new FormControl(this.reservation.company.name),
      bookingChannelReservationId: new FormControl({ value: "" }),
    });
    if (findCustomerCountry) {
      this.editCustomerSelectCountry(findCustomerCountry)
    }
  }

  getCountryLabelBasedOnCode(code: string) {
    const findCountry = this.countryList.find(country => country.value === code)
    if (findCountry) {
      return findCountry.label
    } else {
      return "n/a"
    }
  }

  validateSSN(control: AbstractControl): { [key: string]: boolean } | null {
    let ssn = control.value;
    let invalidSSNObject = { 'invalidSSN': true };
    // SSN is optional field
    if (ssn === "") {
      return null;
    }
    if (!FinnishSSN.validate(ssn)) {
      return invalidSSNObject;
    }
    return null;
  }
  validateMobile(control: AbstractControl): { [key: string]: boolean } | null {
    let invalidMobileObject = { 'invalidMobile': true };
    if (this.normalizePhoneNumber()) {
      return null;
    }
    return invalidMobileObject;
  }
  normalizePhoneNumber() {
    let self = this;
    let mobile = this.customerEditForm && this.customerEditForm.controls ? self.customerEditForm.controls.mobile.value : self.reservation.customer.mobile;
    let number;
    try {
      number = phoneUtil.parseAndKeepRawInput(mobile);
      if (!phoneUtil.isValidNumber(number)) {
        return null;
      }
    } catch (error) {
      return null;
    }
    return phoneUtil.format(number, PNF.E164);
  }
  get firstName() { return this.customerEditForm.get('firstName')!; }
  get lastName() { return this.customerEditForm.get('lastName')!; }
  get realEmail() { return this.customerEditForm.get('realEmail')!; }
  get emailVirtual() { return this.customerEditForm.get('emailVirtual')!; }
  get mobile() { return this.customerEditForm.get('mobile')!; }
  get ssn() { return this.customerEditForm.get('ssn')!; }
  get passportNumber() { return this.customerEditForm.get('passportNumber')!; }
  get address() { return this.customerEditForm.get('address')!; }
  get postalCode() { return this.customerEditForm.get('postalCode')!; }
  get city() { return this.customerEditForm.get('city')!; }
  get companyName() { return this.customerEditForm.get('companyName')!; }
  get bookChannel() { return this.customerEditForm.get('bookingChannel')!; }
  get bookingChannelReservationId() { return this.customerEditForm.get('bookingChannelReservationId')!; }

  initAddPaymentForm() {
    this.addPaymentForm = new FormGroup({
      paymentDate: new FormControl(new Date(), [Validators.required, this.checkValidDate]),
      amount: new FormControl('', [Validators.required, Validators.pattern(/^\s*-?(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/), (control: AbstractControl) => Validators.min(this.getPriceNumber(this.reservation.totalPaid) * -1)(control), (control: AbstractControl) => Validators.max(this.getPriceNumber(this.totalBalance))(control)]),
      paymentProvider: new FormControl('', [Validators.required]),
      paymentReference: new FormControl('', [Validators.required]),
      paymentType: new FormControl('')
    })
  }
  checkValidDate(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value === "Invalid Date") {
      return { 'invalidDate': true };
    }
    return null;
  }
  get paymentDate() { return this.addPaymentForm.get('paymentDate')!; }
  get paymentAmount() { return this.addPaymentForm.get('amount')!; }
  get paymentProvider() { return this.addPaymentForm.get('paymentProvider')!; }
  get paymentReference() { return this.addPaymentForm.get('paymentReference')!; }
  get paymentType() { return this.addPaymentForm.get('paymentType')!; }

  initPartialPaymentForm() {
    this.partialPaymentForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/), (control: AbstractControl) => Validators.max(this.getPriceNumber(this.totalBalance))(control)])
    })
  }
  get partialAmount() { return this.partialPaymentForm.get('amount')!; }

  initNotificationForm() {
    this.notificationForm = new FormGroup({
      email: new FormControl('', [Validators.pattern(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
      mobile: new FormControl('', [Validators.minLength(10)]),
    })
  }
  get notificationEmail() { return this.notificationForm.get('email')!; }
  get notificationSMS() { return this.notificationForm.get('mobile')!; }

  initRefundForm() {
    this.refundForm = new FormGroup({
      moneyAmount: new FormControl('0', [Validators.required, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/), (control: AbstractControl) => Validators.max(this.getPriceNumber(this.reservation.totalPaid))(control)]),
      percentageAmount: new FormControl('0', [Validators.required, Validators.pattern(/^[1-9][0-9]?$|^100$/)]),
      giftcardAmount: new FormControl('0', [Validators.required, Validators.pattern(/^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/), (control: AbstractControl) => Validators.max(this.getPriceNumber(this.reservation.totalPaid))(control)]),
      giftcardExpireDate: new FormControl('', [Validators.required, Validators.maxLength(10)]),
      reason: new FormControl('', [Validators.required]),
    })
  }
  get moneyAmount() { return this.refundForm.get('moneyAmount')!; }
  get percentageAmount() { return this.refundForm.get('percentageAmount')!; }
  get giftcardAmount() { return this.refundForm.get('giftcardAmount')!; }
  get giftcardExpireDate() { return this.refundForm.get('giftcardExpireDate')!; }
  get reason() { return this.refundForm.get('reason')!; }

  initInvoiceForm() {
    this.invoiceForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      einvoice_address: new FormControl('', [Validators.required, this.checkValidAddress.bind(this)]),
      invoiceDate: new FormControl('', [Validators.required, this.checkValidDate]),
      dueDate: new FormControl('', [Validators.required, this.checkValidDate]),
      vat_number: new FormControl(''),
      your_reference: new FormControl(''),
    });
    this.invoiceSaloForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      postalcode: new FormControl('', [Validators.required]),
      companyName: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      einvoice_address: new FormControl('', [Validators.required, this.checkValidAddress.bind(this)]),
      invoiceDate: new FormControl('', [Validators.required, this.checkValidDate]),
      dueDate: new FormControl('', [Validators.required, this.checkValidDate]),
      vat_number: new FormControl(''),
      your_reference: new FormControl(''),
    })
  }
  checkValidAddress(control: AbstractControl): { [key: string]: boolean } | null {
    if (this.selectedInvoiceDeliveryMethod) {
      if (this.selectedInvoiceDeliveryMethod.value === 'email') {
        let emailPattern = new RegExp(CONSTANT.REGEX_EMAIL);
        if (!emailPattern.test(control.value)) {
          return { 'invalid': true };
        }
      }
      return null;
    }
    return null;
  }
  get invoiceName() { return this.invoiceForm.get('name')!; }
  get invoiceAddress() { return this.invoiceForm.get('address')!; }
  get invoicePostalcode() { return this.invoiceForm.get('postalcode')!; }
  get invoiceCity() { return this.invoiceForm.get('city')!; }
  get einvoice_address() { return this.invoiceForm.get('einvoice_address')!; }
  get invoiceDescription() { return this.invoiceForm.get('description')!; }
  get invoiceDateForm() { return this.invoiceForm.get('invoiceDate')!; }
  get dueDateForm() { return this.invoiceForm.get('dueDate')!; }
  get yourReferenceForm() { return this.invoiceForm.get('your_reference')!; }

  get invoiceManualName() { return this.invoiceSaloForm.get('name')!; }
  get invoiceManualAddress() { return this.invoiceSaloForm.get('address')!; }
  get invoiceManualPostalcode() { return this.invoiceSaloForm.get('postalcode')!; }
  get invoiceManualCompanyName() { return this.invoiceSaloForm.get('companyName')!; }
  get invoiceManualCity() { return this.invoiceSaloForm.get('city')!; }
  get invoiceManualDateForm() { return this.invoiceSaloForm.get('invoiceDate')!; }
  get invoiceManualDueDateForm() { return this.invoiceSaloForm.get('dueDate')!; }

  editCustomerSelectCountry(country: MenuType) {
    this.selectedEditCustomerCountry = country;
    if (country.value === "FIN" && this.hotelConfig.feature.defaultGuestNationality === "FIN") {
      this.ssn.enable();
      this.isSSNEdit = true;
    } else {
      this.customerEditForm.patchValue({
        ssn: '',
      });
      this.ssn.disable();
      this.isSSNEdit = false;
    }
  }

  paymentForChange(check: boolean) {
    this.isManualPaidForOtaExtras = check;
  }

  changeRefundOption(type: string) {
    this.refundFormSelection = type;
    this.refundForm.disable();
    this.refundForm.controls[type].enable();
    this.reason.enable();
    if (type == 'giftcardAmount') {
      this.giftcardExpireDate.enable();
    }
  }

  notificationMethodChange(method: string, event: any) {
    let checked = event.target.checked
    this.notification[method] = checked;
  }

  changeNotifyCustomer(event: any) {
    this.reservationsService.changeNotifyCustomer(this.reservation.uuid, event.target.checked).subscribe();
  }

  toggleShowCancelled(event: any) {
    this.showCancelled = event.target.checked;
  }

  isExternalReservation() {
    let externalChannel = ['BOOKINGCOM', 'EXPEDIA']
    return externalChannel.includes(this.reservation.bookingChannel) ? true : false;
  }
  isExternalWithExtra() {
    return this.reservation.isExternalWithExtras;
  }
  isEditDisabled() {
    return (!this.editAllowed || this.isExternalReservation() && !this.hotelConfig.MUIfeature.allowExternalReservationEdit || this.isPendingConfirmation()) ? true : false;
  }
  isAllowExternalReservationAddExtra() {
    return this.editAllowed && this.hotelConfig.MUIfeature.allowExternalReservationAddExtra && !this.isPendingConfirmation();
  }
  isPendingConfirmation() {
    let pendingState = ['PENDING_CONFIRMATION', 'BLOCKED'];
    return pendingState.includes(this.reservation.state) ? true : false;
  }
  isBlocked() {
    return this.reservation.state === 'BLOCKED';
  }
  isCancelled() {
    return this.reservation.state === 'CANCELLED';
  }
  isGiftCardPayment(method: any) {
    if (!isNaN(method)) {
      return true;
    } else {
      return false;
    }
  }
  blocklistCheck() {
    let requestCustomer = _.assign(this.reservation.customer, { reservationUuid: this.reservation.uuid });
    this.reservationsService.blockCheck(requestCustomer).subscribe((data: any) => {
      this.isPassedBlockList = data;
    })
  }

  getPayments() {
    let totalBalance = 0;
    this.hasPendingRefund = false;
    this.payments = this.reservation.payments;
    this.payments.forEach((payment: any) => {
      if (this.getPriceNumber(payment.amount) < 0) {
        payment.isRefund = true;
        this.hasRefund = true;
        if (payment.paymentStatus == 'MANUAL_REFUND') {
          totalBalance = this.calculatePrice('plus', totalBalance, payment.amount);
          this.hasPendingRefund = true;
        }
      } else {
        payment.isRefund = false;
      }
      if (payment.paymentProvider == 'MOBILE_NETS') {
        payment.paymentMethod = `${payment.type} - ${payment.mobileNetsPaymentMethod} - ${payment.mobileNetsTxId}`;
      } else if (payment.paymentProvider == 'PAYTRAIL') {
        let paytrail = _.find(this.paytrails.default, (paytrail) => {
          return paytrail.code == payment.paymentMethod;
        })
        payment.paymentMethod = paytrail.paymentType + ' - ' + paytrail.name;
      } else if (payment.paymentProvider == 'PAYTRAIL_2') {
        payment.paymentMethod = payment.paymentMethod;
      }
    });
    if (this.getPriceNumber(totalBalance) >= 0) {
      this.totalBalance = this.reservation.balance;
    } else {
      this.totalBalance = totalBalance;
    }
  }

  getState(lineItem: any) {
    if (lineItem.cancelled) {
      return 'minus';
    } else if (lineItem.toBeCancelled) {
      return 'remove';
    } else if (lineItem.confirmed) {
      return 'check';
    } else {
      return 'unchecked';
    }
  }

  campaignCodes(orders: any) {
    let campaignCodeArr: any = [];
    _.forEach(orders, function (order) {
      _.filter(order.lineItems, function (lineItem) {
        if (lineItem.voucherCode) {
          campaignCodeArr.push(lineItem.voucherCode);
        }
      });
    });
    let results = [];
    if (campaignCodeArr.length != 0) {
      // Filter duplicate campaign code
      results = _.filter(campaignCodeArr, function (campaignCode, index) {
        return campaignCodeArr.indexOf(campaignCode) === index;
      });
      return results.join(", ");
    } else {
      return "n/a";
    }
  }

  getSegment(segmentId?:number) {
    if(segmentId) {
      const findSegment = this.segmentList.find((segment:any) => segment.id === segmentId);
      if(findSegment) {
        return findSegment.name;
      } else {
        return "n/a";
      }
    } else {
      return "n/a";
    }
  }

  getComments(reservationUuid: string) {
    this.reservationsService.getReservationLog(reservationUuid).subscribe((data: any) => {
      if (!data) return [];
      this.comments = (data as any[]).filter(item => item.action === "ADD_COMMENT");
    })
  }
  saveComment() {
    this.disabledBtnWhileCallingAPI = true;
    if (!this.commentForm.controls.commentInput.valid) {
      this.toastr.error('Please input your comment!', "Error!");
      this.disabledBtnWhileCallingAPI = false;
    } else {
      let request: any = {
        comment: this.commentForm.controls.commentInput.value,
      }
      this.reservationsService.saveReservationComment(this.reservation.uuid, request).subscribe((data: any) => {
        this.commentForm.patchValue({
          commentInput: ''
        })
        let getCommentBox = document.querySelector('.comment-box');
        if (getCommentBox) getCommentBox.scrollTop = 0;
        this.comments.unshift(data);
        this.disabledBtnWhileCallingAPI = false;
      })
    }
  }
  getPriceNumber(fullPrice: any) {
    if (fullPrice == 0) {
      return 0;
    } else {
      let priceSplit = fullPrice.split(" ");
      return Number(priceSplit[1]);
    }
  }
  calculatePriceNonRefundable(operator: string, price1: any, price2: any, countNonRefundable: number) {
    if (price1 == 0 && operator == 'plus') {
      return price2;
    } else if (price2 == 0 && operator == 'plus') {
      return price1;
    } else {
      let price1Split = price1.split(" ");
      let price2Split = price2.split(" ");
      if (price1Split.length != 2 || price2Split.length != 2) {
        this.toastr.error("Invalid price format", "Price calculation error!");
        return 0;
      } else if (price1Split[0] != price2Split[0]) {
        this.toastr.error("Price currency mismatch", "Price calculation error!");
        return 0;
      } else {
        let priceResult;
        switch (operator) {
          case 'plus':
            priceResult = (Number(price1Split[1])) + (Number(price2Split[1]) * countNonRefundable);
            break;
          case 'minus':
            priceResult = (Number(price1Split[1])) - (Number(price2Split[1]) * countNonRefundable);
            break;
          default:
            priceResult = 0;
        }
        return `${price1Split[0]} ${priceResult.toFixed(2)}`;
      }
    }
  }
  calculatePrice(operator: string, price1: any, price2: any) {
    if (price1 == 0 && operator == 'plus') {
      return price2;
    } else if (price2 == 0 && operator == 'plus') {
      return price1;
    } else {
      let price1Split = price1.split(" ");
      let price2Split = price2.split(" ");
      if (price1Split.length != 2 || price2Split.length != 2) {
        this.toastr.error("Invalid price format", "Price calculation error!");
        return 0;
      } else if (price1Split[0] != price2Split[0]) {
        this.toastr.error("Price currency mismatch", "Price calculation error!");
        return 0;
      } else {
        let priceResult;
        switch (operator) {
          case 'plus':
            priceResult = Number(price1Split[1]) + Number(price2Split[1]);
            break;
          case 'minus':
            priceResult = Number(price1Split[1]) - Number(price2Split[1]);
            break;
          default:
            priceResult = 0;
        }
        return `${price1Split[0]} ${priceResult.toFixed(2)}`;
      }
    }
  }
  getProductName(productId: number, orderIndex: any = null) {
    let productName: any;
    if (this.orders.accommodationProducts.includes(productId) || orderIndex == -1) {
      productName = this.reservation.productsById[productId].name;
    } else if (orderIndex != -1) {
      this.orders.orderProductOffer[orderIndex].every((product: any) => {
        if (product.productId == productId) {
          productName = product.title;
          return false;
        } else {
          return true;
        }
      });
    }
    let result = _.find(this.timeSecif, function (time) {
      return time.name === productName;
    });
    return result ? result.friendlyName : productName;
  }

  initNewOrderProduct(item: any, isInvoiceItem: boolean) {
    let newProduct = {
      productId: item.productId,
      count: 0,
      price: 0,
      listPrice: 0,
      nonRefundablePrice: 0,
      nonRefundableListPrice: 0,
      isInvoiceItem: isInvoiceItem,
      countNonRefundable: 0
    }
    if (!item.cancelled && !item.toBeCancelled) {
      newProduct.count++;
      newProduct.price = item.price.amount;
      newProduct.listPrice = item.listPrice.amount;
    }
    if (item.cancelled && item.billable) {
      newProduct.nonRefundablePrice = item.price.amount;
      newProduct.nonRefundableListPrice = item.listPrice.amount;
      newProduct.countNonRefundable++;
    }
    return newProduct;
  }
  createOrderTotalList(lineItem: any, isInvoiceItem: boolean) {
    let orderTotal: any = [];
    lineItem.forEach((item: any) => {
      let productIndex = orderTotal.findIndex((orderItem: any) => {
        return orderItem.productId === item.productId;
      });
      if (productIndex == -1) {
        orderTotal.push(this.initNewOrderProduct(item, isInvoiceItem));
      } else {
        let price = item.price.amount;
        let listPrice = item.listPrice.amount;
        let productItem = orderTotal[productIndex];
        if (!item.cancelled) {
          productItem.count++;
          productItem.price = this.calculatePrice('plus', productItem.price, price);
          productItem.listPrice = this.calculatePrice('plus', productItem.listPrice, listPrice);
        }
        if (!item.refundable && item.cancelled) {
          productItem.nonRefundablePrice = this.calculatePrice('plus', productItem.nonRefundablePrice, price);
          productItem.nonRefundableListPrice = this.calculatePrice('plus', productItem.nonRefundableListPrice, listPrice);
          productItem.countNonRefundable++;
        }
      }
    });
    return orderTotal;
  }

  initOrders(init: boolean = true) {
    let productList = Object.keys(this.reservation.productsById);
    let tmpOrders: any = this.reservation.orders;
    let roomOrders: any = [];
    tmpOrders.forEach((order: any, index: number) => {
      if (order.forDisabled) this.hasDisabled = true;
      if (order.thereChildren) this.hasChildren = true;
      this.orderDatepicker[order.id] = {
        opened: false
      };
      let totalProducts = this.createOrderTotalList(order.lineItems, false);
      // Add fennoaProductLineItems into orders (include: Damages, extra cleaning, security guard)
      // index === 0: Call one time when the fennoaProductLineItems exist
      if (this.reservation.fennoaProductLineItems.length && index === 0) {
        let productLineItemFennoa = this.createOrderTotalList(this.reservation.fennoaProductLineItems, true);
        totalProducts = totalProducts.concat(productLineItemFennoa);
      }
      roomOrders.push(totalProducts);
      this.orderOfferSelection.push({
        productId: null,
        selectedDate: null,
        price: null,
        listPrice: null,
        vat: null,
        notAvailable: false,
        enableAdd: false,
      })
    });
    this.orders.roomOrders = roomOrders;
    let productsInReservation: any = [];
    let accommodationProducts: any = [];
    let extraProducts: any = [];
    productList.forEach((productId: any) => {
      let orderIndexList: any = [];
      roomOrders.forEach((order: any, index: any) => {
        order.forEach((product: any) => {
          if (product.productId == productId && product.count > 0) {
            orderIndexList.push(index);
          }
        });
      });
      productsInReservation.push({
        productId: productId,
        orderIndexList: orderIndexList,
        // price:
      })
      let productType = this.reservation.productsById[productId].type;
      accommodationProducts.push(Number(productId));
      if (productType !== "ACCOMMODATION") {
        extraProducts.push(Number(productId));
      }
    });
    this.orders.productsInReservation = productsInReservation;
    this.orders.accommodationProducts = accommodationProducts;
    this.orders.extraProducts = extraProducts;
    this.setupExtrasSummary();
    this.setupDateRangeSummary();
    if (init) {
      this.setupProductOfferList();
    }
  }
  filterInvoiceTypeForOrders(roomOrders: any) {
    // List all Products associated the reservation, including products added as part of invoicing.
    let orders: any = [];
    roomOrders.forEach((roomOrder: any, index: any) => {
      if (!roomOrder.isInvoiceItem) {
        orders.push(roomOrder);
      }
    });
    return orders.length !== 0 ? orders : roomOrders;
  }

  getTotalSummary(type: string, productId: any = null) {
    let total: any = 0;
    productId = Number(productId);
    this.orders.roomOrders.forEach((room: any) => {
      room.forEach((product: any) => {
        if (product.productId === productId && product.count > 0) {
          switch (type) {
            case 'price':
            case 'listPrice':
              total = this.calculatePrice('plus', total, product[type]);
              break;
            case 'count':
              total = total + product['count'];
              break;
          }
        }
        if (!productId) {
          switch (type) {
            case 'totalPrice':
              total = this.calculatePrice('plus', total, product['price']);
              break;
            case 'totalListPrice':
              total = this.calculatePrice('plus', total, product['listPrice']);
              break;
            case 'totalNonRefundablePrice':
              total = this.calculatePriceNonRefundable('plus', total, product['nonRefundablePrice'], product['countNonRefundable']);
              break;
            case 'totalNonRefundableListPrice':
              total = this.calculatePriceNonRefundable('plus', total, product['nonRefundableListPrice'], product['countNonRefundable']);
              break;
          }
        }
      });
    });
    return total === 0 ? (type === 'count' ? 0 : this.currency + " 0") : total;
  }

  getUnitSummary(productId: number) {
    const totalPrice = this.getTotalSummary('price', productId);
    const totalCount = this.getTotalSummary('count', productId);
    const priceSplit = Number(totalPrice.split(" ")[1]);
    return `${this.currency} ${(priceSplit / totalCount).toFixed(2)}`;
  }
  getUnitRoomSummary(productId: any) {
    const findProductInRes = this.orders.productsInReservation.find((product: any) => Number(product.productId) === Number(productId));
    if (findProductInRes) {
      return findProductInRes.orderIndexList.length;
    } else {
      return 0;
    }
  }

  getExtrasTotalSummary(type: string) {
    let total = `${this.currency} 0`;
    this.orders.extrasSummary.forEach((extra: any) => {
      switch (type) {
        case "unit":
          total = this.calculatePrice('plus', total, extra.unitPrice);
          break;
        case "price":
          total = this.calculatePrice('plus', total, extra.price);
          break;
        case "list":
          total = this.calculatePrice('plus', total, extra.listPrice);
          break;
        default:
          break;
      }
    })
    return total;
  }

  getDateRangeTotalSummary(type: string) {
    let total = `${this.currency} 0`;
    this.orders.dateRangeSummary.forEach((range: any) => {
      switch (type) {
        case "unit":
          total = this.calculatePrice('plus', total, range.unitPrice);
          break;
        case "price":
          total = this.calculatePrice('plus', total, range.price);
          break;
        case "list":
          total = this.calculatePrice('plus', total, range.listPrice);
          break;
        default:
          break;
      }
    })
    return total;
  }

  getReferenceNumber(type: string) {
    switch (type) {
      case 'code':
        let rf = this.reservation.referenceNumber.substring(4);
        return rf.replace(/\B(?=(\d{5})+(?!\d))/g, " ");
      case 'number':
        let refNumber = this.reservation.referenceNumber.match(/.{1,4}/g);
        return refNumber.join(' ');
    }
  }

  showDoorCode(orderId: any) {
    this.reservationsService.getDoorCode(this.reservation.uuid, orderId).subscribe((data: any) => {
      this.setDoorCode('show', orderId, data.doorCode);
    })
  }
  resetDoorCode(orderId: number, uuid: any) {
    this.isLoadingDoorCode = true;
    this.reservationsService.resetDoorCode(orderId, uuid).subscribe((data: any) => {
      this.setDoorCode('hide', orderId);
      this.modalService.hide();
      this.isLoadingDoorCode = false;
      if (data) {
        this.toastr.success('Door code updated', 'Success!', { timeOut: 2000 });
      } else {
        this.toastr.warning('Door code can not change', 'Warning!', { timeOut: 2000 });
      }
    }, (error) => {
      this.isLoadingDoorCode = false;
      this.toastr.error('Reset door code failed', 'Error!', { timeOut: 2000 });
    });
  }
  setDoorCode(type: string, orderId: number, doorCode: any = null) {
    let getOrder = `.row[data-order='${orderId}']`;
    let getRoomCodeField = document.querySelector(`${getOrder} .door-code`);
    let getCodeBar = `${getOrder} .code-toggle .timeout-bar .bar`;
    switch (type) {
      case 'show':
        if (getRoomCodeField) {
          this.doorCodeTimeout[`show${orderId}`] = true;
          setTimeout(() => {
            //document.querySelector(getCodeBar)!.classList.add('full-bar');
            setTimeout(() => {
              getRoomCodeField!.innerHTML = doorCode;
              clearTimeout(this.doorCodeTimeout[orderId]);
              //document.querySelector(getCodeBar)!.classList.remove('full-bar');
              this.doorCodeTimeout[orderId] = setTimeout(() => {
                this.setDoorCode('hide', orderId);
                this.doorCodeTimeout[`show${orderId}`] = false;
              }, 60000);
            }, 50);
          }, 50);
        } else console.error("Order does not exist");
        break;
      case 'hide':
        clearTimeout(this.doorCodeTimeout[orderId]);
        this.doorCodeTimeout[`show${orderId}`] = false;
        if (getRoomCodeField) getRoomCodeField.innerHTML = "•••••";
        else console.error("Order does not exist");
        break;
      default:
        console.error('Invalid request!');
    }
  }

  saveCustomerEdit() {
    let request: any = _.assign({}, this.reservation.customer);
    request.address = this.address.value;
    request.city = this.city.value;
    request.emailReal = this.realEmail.value;
    request.emailVirtual = this.emailVirtual.value ? this.emailVirtual.value : null;
    request.firstName = this.firstName.value;
    request.lastName = this.lastName.value;
    request.nationality = this.selectedEditCustomerCountry ? this.selectedEditCustomerCountry.value : "";
    request.mobile = this.mobile.value;
    request.ssn = this.ssn.value;
    request.passportNumber = this.passportNumber.value;
    request.postalCode = this.postalCode.value;
    request.companyName = this.companyName.value;
    request.bookingChannelReservationId = this.bookingChannelReservationId.value ? this.bookingChannelReservationId.value : null;
    this.request = request;
    request.bookingChannel = this.selectedOtaChannel && this.selectedOtaChannel.value !== "none" ? this.selectedOtaChannel.value : this.reservation.bookingChannel;
    if (this.isSSNEdit && !this.utils.isValidSSN(this.ssn.value)) {
      this.openModal('alert', this.confirmSSNModalTemplate);
    }
    else {
      this.callingReservationsService(request);
      // const blockCheckRequest = {
      //   emailReal: request.emailReal,
      //   firstName: request.firstName,
      //   lastName: request.lastName,
      //   mobile: request.mobile,
      //   passportNumber: request.passportNumber,
      //   ssn: request.ssn,
      // }
      // this.reservationsService.blockCheck(blockCheckRequest).subscribe((data: any) => {
      //   if(data) {
      //     this.callingReservationsService(request);
      //   } else {
      //     this.openModal('alert', this.saveBlockedModalTemplate);
      //   }
      // })
    }
  }
  saveInfor() {
    this.callingReservationsService(this.request);
  }
  callingReservationsService(request: any) {
    this.isCustomerSave = true;
    let firstGuestId = -1;
    let guestIsCustomer = false;

    const firstRoomAlias = () => {
      let roomAlias = -1;
      this.reservation.orders.every((order: any) => {
        if (!order.cancelled) {
          roomAlias = order.roomAlias;
          return false;
        }
        return true;
      })
      return roomAlias;
    }

    const guestsOfFirstRoom = this.reservation.guests.filter((guest: any) => guest.roomAlias === firstRoomAlias())

    if (guestsOfFirstRoom.length) {
      firstGuestId = guestsOfFirstRoom[0].id;
    }

    if (this.reservation.externalChannel || this.reservation.customer.emailReal === guestsOfFirstRoom[0].email) {
      guestIsCustomer = true;
    }

    this.reservationsService.saveCustomerEdit(request, this.reservation.uuid).subscribe(
      (data: any) => {
        const finishSavingCustomer = () => {
          this.isCustomerSave = false;
          this.isShowBookingChannel = false;
          this.reservation.customer = data;
          this.reservation.bookingChannel = data.bookingChannel;
          this.reservation.bookingChannelReservationId = data.bookingChannelReservationId;
          this.reservation.company.name = data.companyName
          delete this.reservation.customer.bookingChannel;
          delete this.reservation.customer.bookingChannelReservationId;

          this.toastr.success("Contact information saved!", 'Success!');
          this.modalRef.hide();
        }
        if (guestIsCustomer) {
          let guestRequest = JSON.parse(JSON.stringify(request));
          guestRequest.roomAlias = firstRoomAlias();
          guestRequest.email = guestRequest.emailReal;
          this.reservationsService.saveGuest(guestRequest, this.reservation.uuid, firstGuestId).subscribe(
            (data2: any) => {
              finishSavingCustomer();
            },
            (err: any) => {
              this.getReservationData((data2: any) => {
                this.assignDataCustomerEditForm();
              });
              this.isCustomerSave = false;
              this.modalRef.hide();
              this.toastr.error(err.error.description, "Error!");
            }
          )
        } else {
          finishSavingCustomer();
        }
      },
      (err: any) => {
        this.isCustomerSave = false;
        if (err.error.message) {
          const errorCode = JSON.parse(err.error.message).code ? JSON.parse(err.error.message).code : -1;
          switch (errorCode) {
            case 10038:
              this.toastr.error("Email is blocked", "Error!");
              this.customerEditForm.controls["realEmail"].setErrors({ 'invalid': true });
              break;
            case 10043:
              this.toastr.error("Mobile number is blocked", "Error!");
              this.customerEditForm.controls["mobile"].setErrors({ 'invalid': true });
              break;
            case 10044:
              this.toastr.error("SSN is blocked", "Error!");
              this.customerEditForm.controls["ssn"].setErrors({ 'invalid': true });
              break;

            default:
              this.toastr.error("An error occurred, please try again later!", "Error!");
              break;
          }
        } else if (err.error.description) {
          this.displayAPIerror(err)
        }
      }
    )

  }

  addPayment() {
    this.disabledBtnWhileCallingAPI = true;
    let request: any = this.addPaymentForm.value;
    request.amount = request.amount.trim();
    request.paymentDate = this.utils.convertDate(request.paymentDate, 'YYYY-MM-dd');
    request.isManualPaidForOtaExtras = this.isManualPaidForOtaExtras;
    request.reservationCode = this.reservation.reservationCode;
    if (!this.hotelConfig.MUIfeature.manualPaymentPreset) {
      delete request["paymentType"]
    }
    this.reservationsService.addManualPayment(request).subscribe(
      (data: any) => {
        this.modalRef.hide();
        this.isManualPaidForOtaExtras = false;
        setTimeout(() => {
          this.addPaymentForm.patchValue({
            amount: '',
            paymentProvider: '',
            paymentReference: '',
            paymentType: ""
          })
          this.getReservationData();
          this.openResultModal('success', 'payment');
          this.disabledBtnWhileCallingAPI = false;
          const urlWithoutParam = window.location.href.split("?")[0];
          window.open(urlWithoutParam, "_self")

        }, 300);
      },
      (err: any) => {
        this.modalRef.hide();
        setTimeout(() => {
          let errorMessage;
          if (err.error.description) errorMessage = err.error.description;
          if (err.error.message) errorMessage = err.error.message;
          this.openResultModal('error', 'payment', errorMessage);
          this.disabledBtnWhileCallingAPI = false;
        }, 300);
      }
    )
  }

  sendConfirmation() {
    if (this.messageType === "DOOR_CODE") {
      if (this.notification.sms) {
        this.reservationsService.sendDoorCodeMessage(this.reservation.uuid, this.notificationSMS.value, 'sms').subscribe(
          (data: any) => {
            this.toastr.success('Door code has been sent through SMS', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
      if (this.notification.email) {
        this.reservationsService.sendDoorCodeMessage(this.reservation.uuid, this.notificationEmail.value, 'email').subscribe(
          (data: any) => {
            this.toastr.success('Door code has been sent through email', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
    } else if (this.messageType === "REMINDER") {
      if (this.notification.sms) {
        this.reservationsService.sendReminderMessage(this.reservation.uuid, this.notificationSMS.value, 'sms').subscribe(
          (data: any) => {
            this.toastr.success('Reminder has been sent through SMS', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
      if (this.notification.email) {
        this.reservationsService.sendReminderMessage(this.reservation.uuid, this.notificationEmail.value, 'email').subscribe(
          (data: any) => {
            this.toastr.success('Reminder has been sent through email', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
    } else {
      // The remaining case: The message type includes both CONFIRMATION and EXTRA
      if (this.notification.sms) {
        this.reservationsService.sendConfirmation(this.reservation.uuid, this.notificationSMS.value, 'sms', this.sendExtra).subscribe(
          (data: any) => {
            this.toastr.success('Confirmation has been sent through SMS', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
      if (this.notification.email) {
        this.reservationsService.sendConfirmation(this.reservation.uuid, this.notificationEmail.value, 'email', this.sendExtra).subscribe(
          (data: any) => {
            this.toastr.success('Confirmation has been sent through email', 'Success!');
          },
          (err: any) => {
            this.displayAPIerror(err);
          }
        )
      }
    }
    this.modalRef.hide();
  }

  completeManualRefund() {
    this.reservationsService.completeManualRefund(this.selectedRefundPayment).subscribe(
      (data: any) => {
        this.toastr.success('Refund marked as complete.', 'Success!');
        this.getReservationData();
        this.modalRef.hide();
      },
      (err: any) => {
        this.displayAPIerror(err);
      }
    )
  }
  checkValidPricePattern(value: any) {
    let pricePattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
    if (pricePattern.test(value)) {
      return true;
    } else {
      return false;
    }
  }
  summaryChange(productId: any, prevPrice: string, event: any) {
    productId = Number(productId);
    let newPrice = Number(event.target.value);
    let oldPrice = Number(prevPrice.split(" ")[1]);
    let priceCurrency = prevPrice.split(" ")[0];
    if (this.checkValidPricePattern(newPrice)) {
      let productInResIndex = this.orders.productsInReservation.findIndex((product: any) => {
        return Number(product.productId) === productId;
      })
      let orderIndexList = this.orders.productsInReservation[productInResIndex].orderIndexList;
      let productOrderCount = orderIndexList.length;
      let priceDifference = oldPrice / newPrice;

      let totalAfter: any = 0;
      orderIndexList.forEach((orderIndex: any, index: any) => {
        let roomOrder = this.orders.roomOrders[orderIndex];
        let productIndex = roomOrder.findIndex((product: any) => {
          return product.productId == productId;
        })
        let productCount = roomOrder[productIndex].count;
        const roomOrderPrice = roomOrder[productIndex].price;
        let currentProductTotal = isNaN(roomOrderPrice) ? Number(roomOrderPrice.split(" ")[1]) : roomOrderPrice;
        let newProductTotal: any;
        if (priceDifference == 0) {
          newProductTotal = Number((currentProductTotal / productOrderCount).toFixed(2))
        } else {
          newProductTotal = Number((currentProductTotal / priceDifference).toFixed(2))
        }
        let finalProductTotal = newProductTotal;
        if (index == productOrderCount - 1) {
          finalProductTotal = newPrice - totalAfter;
        } else {
          totalAfter = totalAfter + newProductTotal;
        }

        let productFlag = 0;
        let tmpProductTotal = 0;
        this.reservation.orders[orderIndex].lineItems.forEach((lineItem: any) => {
          if (lineItem.productId == productId && !lineItem.cancelled && !lineItem.toBeCancelled) {
            productFlag++;
            let currentItemPrice = this.getPriceNumber(lineItem.price.amount);
            if (productFlag == productCount) {
              lineItem.price.amount = `${priceCurrency} ${finalProductTotal - tmpProductTotal}`;
            } else {
              let newItemPrice: any;
              if (priceDifference == 0) {
                newItemPrice = Number((finalProductTotal / productCount).toFixed(2))
              } else {
                newItemPrice = Number((currentItemPrice / priceDifference).toFixed(2));
              }
              tmpProductTotal = tmpProductTotal + newItemPrice;
              lineItem.price.amount = `${priceCurrency} ${newItemPrice}`;
            }
          }
        });
      });
      this.initOrders(false);
    }
  }
  unitSummaryChange(productId: any, event: any) {
    let newPrice = Number(event.target.value);
    if (this.checkValidPricePattern(newPrice)) {
      this.reservation.orders.forEach((order: any) => {
        order.lineItems.forEach((lineItem: any) => {
          if (!lineItem.cancelled && !lineItem.toBeCancelled && lineItem.productId === Number(productId)) {
            lineItem.price.amount = `${this.currency} ${newPrice}`;
          }
        })
      })
      this.initOrders(false);
    }
  }
  extraSummaryChange(type: string, amount: number, productId: any, date: string, event: any) {
    productId = Number(productId);
    let newPrice = Number(event.target.value);
    let offset = 0;
    if (type === "total") {
      const tmpUnitPrice = Number((newPrice / amount).toFixed(2));
      offset = newPrice - (tmpUnitPrice * amount);
      newPrice = tmpUnitPrice;
    }
    this.reservation.orders.forEach((order: any) => {
      order.lineItems.forEach((lineItem: any) => {
        if (!lineItem.toBeCancelled && !lineItem.cancelled && lineItem.productId === productId && lineItem.serviceDate === date) {
          lineItem.price.amount = `${this.currency} ${newPrice + offset}`;
          offset = 0;
        }
      })
    })
    this.initOrders(false);
  }
  productTotalChange(orderIndex: number, productId: number, prevPrice: string, event: any) {
    let newPrice = Number(event.target.value);
    let oldPrice = Number(prevPrice.split(" ")[1]);
    let priceCurrency = prevPrice.split(" ")[0];
    if (this.checkValidPricePattern(newPrice)) {
      let priceDifference = oldPrice / newPrice;
      let roomOrder = this.orders.roomOrders[orderIndex];
      let productIndex = roomOrder.findIndex((product: any) => {
        return product.productId == productId;
      })
      let productCount = roomOrder[productIndex].count;
      let finalProductTotal = newPrice;

      let productFlag = 0;
      let tmpProductTotal = 0;
      this.reservation.orders[orderIndex].lineItems.forEach((lineItem: any) => {
        if (lineItem.productId == productId && !lineItem.cancelled && !lineItem.toBeCancelled) {
          productFlag++;
          let currentItemPrice = this.getPriceNumber(lineItem.price.amount);
          if (productFlag == productCount) {
            lineItem.price.amount = `${priceCurrency} ${finalProductTotal - tmpProductTotal}`;
          } else {
            let newItemPrice: any;
            if (priceDifference == 0) {
              newItemPrice = Number((newPrice / productCount).toFixed(2));
            } else {
              newItemPrice = Number((currentItemPrice / priceDifference).toFixed(2));
            }
            tmpProductTotal = tmpProductTotal + newItemPrice;
            lineItem.price.amount = `${priceCurrency} ${newItemPrice}`;
          }
        }
      });
    }
    this.initOrders(false);
  }
  productPriceChange(orderIndex: number, itemId: number, prevPrice: string, event: any, itemIndex: number) {
    let newPrice = Number(event.target.value);
    let priceCurrency = prevPrice.split(" ")[0];
    if (this.checkValidPricePattern(newPrice)) {
      // this.reservation.orders[orderIndex].lineItems.every((lineItem:any) => {
      //   if(lineItem.id == itemId) {
      //     lineItem.price.amount = `${priceCurrency} ${newPrice}`;
      //     this.initOrders();
      //     return true;
      //   } else {
      //     return true;
      //   }
      // })
      this.reservation.orders[orderIndex].lineItems[itemIndex].price.amount = `${priceCurrency} ${newPrice}`;
      this.initOrders(false);
    }
  }

  saveReservationCheck() {
    if (this.pendingCancelItems.length) {
      this.openCancelLineItemModal();
    } else {
      this.saveReservation();
    }
  }

  saveReservation() {
    this.disabledBtnWhileCallingAPI = true;
    setTimeout(() => {
      this.reservationsService.saveReservation(this.reservation.uuid, this.reservation).subscribe(
        (data: any) => {
          this.toastr.success('Reservation updated', 'Success!');
          this.inputEdited = false;
          this.getReservationData(() => {
            this.initOrders();
            this.getComments(this.reservation.uuid);
            this.disabledBtnWhileCallingAPI = false;
          });
          // Close modal Ongoing reservation
          this.modalRef.hide();
        },
        (err: any) => {
          if (err.status === 500 && err.error.exception === "nelson.reservation.core.model.allocation.ProductNotAvailableException") {
            // Check error message: No room available with the request and Cannot automatically move roomRequest to other room (check-in <24h) 
            this.openModal('alert', this.overlapOnGoingRoomUsageTemplate);
          } else {
            this.displayAPIerror(err);
          }
          this.disabledBtnWhileCallingAPI = false;
        }
      )
    }, 300);
  }

  overlapOnGoingReservation() {
    this.reservation.isForcedSave = true;
    this.saveReservation();
  }

  revertReservation() {
    this.reservation = null;
    window.location.reload();
    // this.getReservationData(() => {
    //   this.initOrders();
    //   this.getComments(this.reservation.uuid);
    // });
    // this.inputEdited = false;
  }

  calculateRemain(type: string, event: any) {
    let typedPrice = Number(event.target.value);
    if (this.checkValidPricePattern(typedPrice)) {
      let calculatedRemain = 0;
      let splitOldRemain = this.reservation.totalPaid.split(" ");
      let remainCurrency = splitOldRemain[0];
      let remainPrice = Number(splitOldRemain[1]);
      switch (type) {
        case 'money':
        case 'giftcard':
          calculatedRemain = remainPrice - typedPrice;
          break;
        case 'percentage':
          calculatedRemain = remainPrice - (remainPrice * (typedPrice / 100));
          break;
      }
      this.refundRemain[type] = `${remainCurrency} ${calculatedRemain?.toFixed(2)}`
    }
  }

  startRefund() {
    this.disabledBtnWhileCallingAPI = true;
    let request: any = {
      reason: this.reason.value,
      reservationCode: this.reservation.reservationCode,
      value: this.refundForm.controls[this.refundFormSelection].value.trim()
    }
    switch (this.refundFormSelection) {
      case 'moneyAmount':
        request['option'] = "AMOUNT";
        break;
      case 'percentageAmount':
        request['option'] = "PERCENTAGE";
        break;
      case 'giftcardAmount':
        request['expiryDate'] = formatDate(this.giftcardExpireDate.value, "yyyy-MM-dd", "en-US");
        break;
    }
    this.reservationsService.startRefund(request, this.refundFormSelection).subscribe(
      (data: any) => {
        this.modalRef.hide();
        setTimeout(() => {
          this.getReservationData(() => {
            this.disabledBtnWhileCallingAPI = false;
          });
          this.openResultModal('success', 'refund');
        }, 300);
      },
      (err: any) => {
        this.modalRef.hide();
        setTimeout(() => {
          let errorMessage;
          if (err.error.description) errorMessage = err.error.description;
          if (err.error.message) errorMessage = err.error.message;
          this.openResultModal('error', 'refund', errorMessage);
          this.disabledBtnWhileCallingAPI = false;
        }, 300);
      }
    )
  }

  isOrderCancelled(orderIndex: number) {
    let cancelled = true;
    if (this.orders.roomOrders.length) {
      this.orders.roomOrders[orderIndex].forEach((item: any) => {
        if (item.price) {
          cancelled = false;
        }
      });
    }
    return cancelled;
  }

  toggleItemCancel(orderIndex: number, itemIndex: number, event: any) {
    this.markEdited();
    let getOrder = this.reservation.orders[orderIndex];
    let getItem = getOrder.lineItems[itemIndex];
    if (event.target.checked) {
      this.pendingCancelItems.push({
        orderIndex: orderIndex,
        itemIndex: itemIndex
      })
      getItem.toBeCancelled = new Date().toISOString();
    } else {
      const existingPendingCancel = this.pendingCancelItems.findIndex((item: any) => item.orderIndex === orderIndex && item.itemIndex === itemIndex);
      if (existingPendingCancel > -1) {
        this.pendingCancelItems.splice(existingPendingCancel, 1);
      }
      getItem.toBeCancelled = null;
    }

    this.initOrders(false);
  }

  setupProductOfferList() {
    this.initHotelOffer((hotelOffer: any) => {
      this.orders.orderProductOffer = [];
      if (hotelOffer.length > 0) {
        this.orders.roomOrders.forEach((order: any) => {
          order.every((product: any) => {
            if (this.orders.accommodationProducts.includes(product.productId)) {
              let accommodationId = product.productId;
              let productOfferList = [];
              let productOfferIndex = hotelOffer[0].products.findIndex((productOffer: any) => {
                return productOffer.productId == accommodationId;
              })
              let productOffer = hotelOffer[0].products[productOfferIndex];
              productOfferList.push({
                productId: productOffer.productId,
                title: productOffer.title,
                type: 'ACCOMMODATION',
                notAvailable: productOffer.notAvailableOn,
              });
              productOffer.additionalProducts.forEach((product: any) => {
                if (this.hotelConfig.feature.excludeProductOffer.includes(product.type) || (product.type === "SAMEDATE_ECI_FLEX" && this.hotelConfig.feature.excludeProductOffer.includes(product.title))) return;
                productOfferList.push({
                  productId: product.productId,
                  title: product.title,
                  type: product.type,
                  notAvailable: product.notAvailableOn,
                });
              });
              this.orders.orderProductOffer.push(productOfferList)
              return false;
            } else {
              return true;
            }
          })
        });
      }
    })
  }
  offerPriceChange(event: any, orderIndex: number) {
    let offerSelection = this.orderOfferSelection[orderIndex];
    let currency = offerSelection.listPrice.split(" ")[0];
    let value = event.target.value;
    offerSelection.price = `${currency} ${value}`;
    if (this.checkValidPricePattern(value)) {
      offerSelection.enableAdd = true;
    } else {
      offerSelection.enableAdd = false;
    }
  }
  setupExtrasSummary() {
    let extrasSummary: any[] = [];
    this.reservation.orders.forEach((order: any) => {
      order.lineItems.forEach((lineItem: any) => {
        if (this.orders.extraProducts.includes(lineItem.productId) && !lineItem.cancelled && !lineItem.toBeCancelled) {
          let existingExtraIndex = extrasSummary.findIndex((extra: any) => extra.productId === lineItem.productId);
          if (existingExtraIndex > -1) {
            const extraProduct = extrasSummary[existingExtraIndex];
            extraProduct.amount++;
            extraProduct.price = this.calculatePrice('plus', extraProduct.price, lineItem.price.amount);
            extraProduct.listPrice = this.calculatePrice('plus', extraProduct.listPrice, lineItem.listPrice.amount);

            const existingDateIndex = extraProduct.dates.findIndex((date: any) => date.date === lineItem.serviceDate);
            if (existingDateIndex > -1) {
              const extraDate = extraProduct.dates[existingDateIndex];
              extraDate.price = this.calculatePrice('plus', extraDate.price, lineItem.price.amount);
              extraDate.listPrice = this.calculatePrice('plus', extraDate.listPrice, lineItem.listPrice.amount);
              extraDate.amount++;
            } else {
              extraProduct.dates.push({
                date: lineItem.serviceDate,
                amount: 1,
                unitPrice: 0,
                price: lineItem.price.amount,
                listPrice: lineItem.listPrice.amount
              })
            }
          } else {
            extrasSummary.push({
              productId: lineItem.productId,
              amount: 1,
              unitPrice: 0,
              price: lineItem.price.amount,
              listPrice: lineItem.listPrice.amount,
              dates: [
                {
                  date: lineItem.serviceDate,
                  amount: 1,
                  unitPrice: 0,
                  price: lineItem.price.amount,
                  listPrice: lineItem.listPrice.amount
                }
              ]
            })
          }
        }
      })
    })
    extrasSummary.forEach((extra: any) => {
      const extraUnit = Number(extra.price.split(" ")[1]) / extra.amount;
      extra.unitPrice = `${this.currency} ${extraUnit.toFixed(2)}`;
      extra.dates.forEach((date: any) => {
        const unit = Number(date.price.split(" ")[1]) / date.amount;
        date.unitPrice = `${this.currency} ${unit.toFixed(2)}`
      })
      extra.dates = _.orderBy(extra.dates, 'date', 'asc');
    })
    this.orders.extrasSummary = extrasSummary;
  }

  setupDateRangeSummary() {
    let dateRangeSummary: any[] = [];
    this.reservation.orders.forEach((order: any) => {
      if (!order.cancelled) {
        const existingRangeIndex = dateRangeSummary.findIndex((range: any) => range.startDate === order.startDate);
        let tmpRange: any;
        if (existingRangeIndex > -1) {
          tmpRange = dateRangeSummary[existingRangeIndex];
        } else {
          tmpRange = {
            startDate: order.startDate,
            endDate: order.endDate,
            amount: 0,
            unitPrice: `${this.currency} 0`,
            price: `${this.currency} 0`,
            listPrice: `${this.currency} 0`,
            products: []
          };
        }
        order.lineItems.forEach((lineItem: any) => {
          if (!lineItem.cancelled && !lineItem.toBeCancelled) {
            tmpRange.amount++;
            tmpRange.price = this.calculatePrice('plus', tmpRange.price, lineItem.price.amount);
            tmpRange.listPrice = this.calculatePrice('plus', tmpRange.listPrice, lineItem.listPrice.amount);
            let existingItemIndex = tmpRange.products.findIndex((item: any) => item.productId === lineItem.productId);
            if (existingItemIndex > -1) {
              const rangeProduct = tmpRange.products[existingItemIndex];
              rangeProduct.amount++;
              rangeProduct.price = this.calculatePrice('plus', rangeProduct.price, lineItem.price.amount);
              rangeProduct.listPrice = this.calculatePrice('plus', rangeProduct.listPrice, lineItem.listPrice.amount);
            } else {
              tmpRange.products.push({
                productId: lineItem.productId,
                type: this.reservation.productsById[lineItem.productId].type,
                amount: 1,
                unitPrice: `${this.currency} 0`,
                price: lineItem.price.amount,
                listPrice: lineItem.listPrice.amount
              })
            }
          }
        })
        if (existingRangeIndex === -1) {
          dateRangeSummary.push(tmpRange)
        }
      }
    })
    dateRangeSummary.forEach((range: any) => {
      const rangeUnit = Number(range.price.split(" ")[1]) / range.amount;
      range.unitPrice = `${this.currency} ${rangeUnit.toFixed(2)}`;
      range.products.forEach((item: any) => {
        const unit = Number(item.price.split(" ")[1]) / item.amount;
        item.unitPrice = `${this.currency} ${unit.toFixed(2)}`
      });
      range.products = _.orderBy(range.products, 'type', 'asc')
    })
    dateRangeSummary = _.orderBy(dateRangeSummary, ['startDate', 'endDate'], ['asc', 'asc']);
    this.orders.dateRangeSummary = dateRangeSummary;
  }

  changeNameForProduct(product: any, index: number, orderProductOfferLabel: any) {
    let result: any;
    // Update for orderOfferSelection
    if (product.title === orderProductOfferLabel) {
      this.orderOfferSelection[index].productId = product.productId;
      this.orderOfferSelection[index].title = product.title;
    }
    if (product.type == "WINDOWLESS" || product.type == "SINGLE" || product.type == "ACCOMMODATION") { return }
    switch (product.type) {
      case "BREAKFAST":
      case "EXTRA_BED":
      case "LCO":
      case "ECI":
        result = product.title;
        break;
      case "SAMEDATE_ECI_FLEX":
        result = _.find(this.timeSecif, function (time) {
          return time.name === product.title;
        });
        result = result.friendlyName;
        break;
      default:
        result = '';
    }
    return result;
  }
  changeLabelForExtraDropdown(orderIndex: number, product: any) {
    if (!product[orderIndex]) return;
    let label: any;
    if (product[orderIndex].includes('SameDayECIFlex')) {
      label = _.find(this.timeSecif, function (time) {
        return time.name === product[orderIndex];
      });
      label = label.friendlyName;
    } else {
      label = product[orderIndex];
    }
    return label;
  }
  selectProductOffer(orderIndex: number, ...prod: any) {
    let offerSelection = this.orderOfferSelection[orderIndex];
    if (prod.length) {
      this.orderProductOfferLabel[orderIndex] = prod[0].title;
    }
    setTimeout(() => {
      if (offerSelection.productId && offerSelection.selectedDate) {
        let hotelId = this.reservation.hotel.hotelId;
        let productId = offerSelection.productId;
        let selectedDate = formatDate(offerSelection.selectedDate, "yyyy-MM-dd", "en-US");

        let productById = _.assign({}, this.reservation.productsById);
        let itemIdsInRoom = this.reservation.orders[orderIndex].lineItems.map((item: any) => item.productId);
        let breakfastIDInAdditionalProduct = itemIdsInRoom.find((breakfastId: any) => {
          return productById[breakfastId]?.type === "BREAKFAST";
        });
        let additionalProductExist = false;
        let isAllowAddMultiBreakfast = false;
        if (this.orders.accommodationProducts.includes(productId)) {
          this.reservation.orders[orderIndex].lineItems.every((lineItem: any) => {
            if (lineItem.productId === breakfastIDInAdditionalProduct) {
              isAllowAddMultiBreakfast = true;
            }
            if (lineItem.productId == productId && lineItem.serviceDate == selectedDate) {
              additionalProductExist = true;
              return false;
            } else {
              return true;
            }
          });
        }

        let productOffer = this.orders.orderProductOffer[orderIndex];
        let productOfferIndex = productOffer.findIndex((productOffer: any) => {
          return productOffer.productId == productId;
        })
        let notAvailableDate = productOffer[productOfferIndex].notAvailable.includes(selectedDate);
        if (notAvailableDate) {
          offerSelection.notAvailable = true;
        } else {
          offerSelection.notAvailable = false;
        }
        // Allow add mutilple for breakfast on additional product for room in reservaion detail
        if (notAvailableDate || (additionalProductExist && !isAllowAddMultiBreakfast && offerSelection.title === "Breakfast")) {
          offerSelection.enableAdd = false;
        } else {
          offerSelection.enableAdd = true;
        }
        this.reservationsService.getPrice(hotelId, productId, selectedDate).subscribe((data: any) => {
          offerSelection.price = data.price.amount;
          offerSelection.listPrice = data.price.amount;
          offerSelection.vat = data.price.vatPercentage;
        })
      }
    }, 300);
  }

  checkTimeSecif(orderIndex: number, product: any) {
    let isDisable = true;
    if (product.type !== "SAMEDATE_ECI_FLEX") return false;
    _.each(this.availabilityExtra, (item) => {
      if (item === product.title) {
        isDisable = false;
        return;
      }
    })
    return isDisable;
  }

  getInvoiceDate(productId: number, serviceDate: string, orderIndex: number) {
    let productOffer = this.orders.orderProductOffer[orderIndex];
    let productOfferIndex = productOffer.findIndex((productOffer: any) => {
      return productOffer.productId == productId;
    })
    let invoiceDate: any = serviceDate;
    if (productOffer[productOfferIndex].type == "BREAKFAST") {
      let newInvoiceDate = new Date(new Date(serviceDate).getTime() - 24 * 3600 * 1000);
      invoiceDate = formatDate(newInvoiceDate, "yyyy-MM-dd", "en-US");
    } else {
      invoiceDate = formatDate(serviceDate, "yyyy-MM-dd", "en-US")
    }
    return invoiceDate;
  }
  addOfferToOrder(orderIndex: number) {
    this.markEdited();
    let offerSelection = this.orderOfferSelection[orderIndex];
    let orderLineItem = this.reservation.orders[orderIndex].lineItems;
    let newProduct = {
      productId: offerSelection.productId,
      invoiceDate: this.getInvoiceDate(offerSelection.productId, offerSelection.selectedDate, orderIndex),
      serviceDate: formatDate(offerSelection.selectedDate, "yyyy-MM-dd", "en-US"),
      price: {
        amount: offerSelection.price,
        vatPercentage: offerSelection.vat
      },
      listPrice: {
        amount: offerSelection.price,
        vatPercentage: offerSelection.vat
      }
    }
    console.log(newProduct)
    orderLineItem.push(newProduct);
    this.initOrders(false);
  }

  confirmReservation() {
    if (this.isPassedBlockList) {
      this.startConfirmReservation();
    } else {
      this.openConfirmUnblockedModal();
    }
  }
  startConfirmReservation(forced: boolean = false) {
    let request = {};
    if (forced) {
      request = { isForced: true };
    }
    this.isLoadingConfirm = true;
    this.reservationsService.confirmReservation(this.reservation.uuid, request).subscribe(
      (data: any) => {
        this.toastr.success("Confirmed this reservation.", "Success!");
        this.isLoadingConfirm = false;
        this.getReservationData(() => {
          this.initOrders(false);
          this.getComments(this.reservation.uuid);
        });
        if (forced) {
          this.modalRef.hide();
        }
      },
      (err: any) => {
        this.displayAPIerror(err);
        this.isLoadingConfirm = false;
      }
    )
  }

  unblockReservation() {
    this.reservationsService.unblockReservation(this.reservation.uuid).subscribe(
      (data: any) => {
        this.toastr.success("Unblocked this reservation.", "Success!");
        this.getReservationData(() => {
          this.initOrders(false);
          this.getComments(this.reservation.uuid);
        });
        this.modalRef.hide()
      },
      (err: any) => {
        this.displayAPIerror(err);
      }
    )
  }

  cancelChange() {
    this.reservationsService.cancelChange(this.reservation.uuid).subscribe(
      (data: any) => {
        this.toastr.success("Recent changes have been cancelled!", 'Success!');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      (err: any) => {
        this.displayAPIerror(err);
      }
    )
  }

  changeReservation() {
    const getRefreshToken = this.tokenStorageService.getRefreshToken();
    if (this.utils.isLocalEnvironment()) {
      this.tokenStorageService.redirectWithToken((token: any) => {
        window.open(CONSTANT.MANAGE_BOOKING_LOCALHOST + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}`);
      })
      return;
    }
    this.tokenStorageService.redirectWithToken((token: any) => {
      window.open(CONSTANT.SITEHOST + CONSTANT.MANAGE_BOOKING_URL + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}`);
    })
    return;
  }

  viewGuests() {
    const getRefreshToken = this.tokenStorageService.getRefreshToken();
    if (this.utils.isLocalEnvironment()) {
      this.tokenStorageService.redirectWithToken((token: any) => {
        window.open(CONSTANT.CONFIRMATION_LOCALHOST + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}`);
      })
      return;
    }
    this.tokenStorageService.redirectWithToken((token: any) => {
      window.open(CONSTANT.SITEHOST + CONSTANT.CONFIRMATION_URL + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}`);
    })
  }

  editGuestsInBUI(guestId: number) {
    const getRefreshToken = this.tokenStorageService.getRefreshToken();
    if (this.utils.isLocalEnvironment()) {
      this.tokenStorageService.redirectWithToken((token: any) => {
        window.open(CONSTANT.CONFIRMATION_LOCALHOST + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}&guestId=${guestId}`);
      })
      return;
    }
    this.tokenStorageService.redirectWithToken((token: any) => {
      window.open(CONSTANT.SITEHOST + CONSTANT.CONFIRMATION_URL + `/${this.reservation.uuid}?token=${token}&refreshtoken=${getRefreshToken}&${CONSTANT.NEW_SESSION}&guestId=${guestId}`);
    })
  }

  confirmCancel() {
    if (moment().tz(this.reservation.hotel.zone).isSameOrAfter(moment(this.reservation.startDate).tz(this.reservation.hotel.zone)) && moment().tz(this.reservation.hotel.zone).isBefore(moment(this.reservation.endDate).tz(this.reservation.hotel.zone))) {
      this.confirmedCancel = true;
    } else {
      this.cancelReservation(false, true);
    }
  }
  confirmCancelLineItem(isForceRefund: boolean) {
    this.pendingCancelItems.forEach((item: any) => {
      let getOrder = this.reservation.orders[item.orderIndex];
      let getItem = getOrder.lineItems[item.itemIndex];

      getItem.toBeCancelled = new Date().toISOString();
      getItem.isForcedRefundable = isForceRefund;
      getOrder.lineItems.every((lineItem: any) => {
        if (!lineItem.toBeCancelled) {
          getOrder.startDate = formatDate(lineItem.serviceDate, 'dd.MM.yyyy', 'en-US');
          return false;
        } else {
          return true;
        }
      });
      for (let index = getOrder.lineItems.length - 1; index > 0; index = index - 1) {
        if (!getOrder.lineItems[index].toBeCancelled) {
          getOrder.endDate = formatDate(getOrder.lineItems[index].serviceDate, 'dd.MM.yyyy', 'en-US');
          break;
        }
      }
    })
    this.saveReservation();
    this.modalRef.hide();
  }
  cancelReservation(cleanRoom: boolean, notStarted: boolean = false) {
    let roomIds: any = [];
    this.reservation.orders.forEach((order: any) => {
      if (!order.cancelled) {
        roomIds.push(order.roomId);
      }
    });
    this.isLoadingCancel = true;
    this.reservationsService.cancelReservation(this.reservation.uuid).subscribe(
      (data: any) => {
        this.reservation = data;
        this.reservation.payments.forEach((payment: any) => {
          if (this.getPriceNumber(payment.amount) < 0) {
            payment.isRefund = true;
            this.hasRefund = true;
          }
          if (payment.paymentProvider == 'MOBILE_NETS') {
            payment.paymentMethod = `${payment.type} - ${payment.mobileNetsPaymentMethod} - ${payment.mobileNetsTxId}`;
          } else if (payment.paymentProvider == 'PAYTRAIL') {
            let paytrail = _.find(this.paytrails.default, (paytrail) => {
              return paytrail.code == payment.paymentMethod;
            })
            payment.paymentMethod = paytrail.paymentType + ' - ' + paytrail.name;
          } else if (payment.paymentProvider == 'PAYTRAIL_2') {
            payment.paymentMethod = payment.paymentMethod;
          }
        });
        if (notStarted) {
          this.isLoadingCancel = false;
          this.toastr.success("Reservation has been cancelled!", "Success!");
        } else {
          if (cleanRoom) {
            this.reservationsService.markCleanRoom(roomIds).subscribe(
              (data: any) => {
                this.isLoadingCancel = false;
                this.toastr.success("Cancelled reservation and marked as clean for rooms", "Success!");
              },
              (err: any) => {
                this.isLoadingCancel = false;
                this.displayAPIerror(err);
              }
            )
          } else {
            this.reservationsService.markDirtyRoom(roomIds).subscribe(
              (data: any) => {
                this.isLoadingCancel = false;
                this.toastr.success("Cancelled reservation and marked as dirty for rooms", "Success!");
              },
              (err: any) => {
                this.isLoadingCancel = false;
                this.displayAPIerror(err);
              }
            )
          }
        }
        this.modalRef.hide();
      },
      (err: any) => {
        this.displayAPIerror(err);
        this.modalRef.hide();
      }
    )
  }

  proceedToRefund() {
    let request = {
      reservationCode: this.reservation.reservationCode
    }
    this.reservationsService.proceedToRefund(request).subscribe(
      (data: any) => {
        this.toastr.success("Refund completed.", "Success");
        this.getReservationData();
      },
      (err: any) => {
        this.displayAPIerror(err);
      }
    )
  }

  openUsage(roomRequest: number) {
    let usageParams: any = {
      hotelId: this.reservation.hotel.hotelId,
      startDate: formatDate(this.reservation.startDate, 'yyyy-MM-dd', 'en-US'),
      endDate: formatDate(this.reservation.endDate, 'yyyy-MM-dd', 'en-US'),
      reservationUuid: this.reservation.uuid,
      roomRequestId: roomRequest
    };
    this.router.navigate(['/usage'], { queryParams: usageParams })
  }

  openVoucher(giftcard: number) {
    let voucherParams: any = {
      isRedirectFromHA: false,
      giftcard: giftcard
    };
    this.router.navigate(['/voucher'], { queryParams: voucherParams })
  }

  //Credit invoice option
  searchCreditInvoice() {
    this.creditInvoiceVar.selectedInvoice = null;
    let keyword = this.creditInvoiceVar.searchKeyword.toLowerCase();
    this.creditInvoiceVar.filterResult = this.creditInvoiceList.filter((obj: any) => {
      return obj.invoiceNo.toString().includes(keyword);
    });
  }

  hideResultCreditInvoice() {
    setTimeout(() => {
      this.creditInvoiceVar.showSearch = false;
    }, 300);
  }

  showResultCreditInvoice() {
    this.reservationsService.getFennoaCreditInvoiceList(this.reservation.uuid).subscribe(data => {
      //   const data = [
      //     {
      //         "amount": 100.00,
      //         "deliveryMethod": "email",
      //         "invoiceId": 1842,
      //         "invoiceNo": "2227",
      //         "paymentType": "FULL"
      //     },
      //     {
      //         "amount": 10.00,
      //         "deliveryMethod": "email",
      //         "invoiceId": 1843,
      //         "invoiceNo": "2228",
      //         "paymentType": "ADVANCE"
      //     },
      //     {
      //         "amount": 10.00,
      //         "deliveryMethod": "email",
      //         "invoiceId": 1844,
      //         "invoiceNo": "2229",
      //         "paymentType": "DAMAGES"
      //     }
      // ];
      this.creditInvoiceList = data;
      this.creditInvoiceVar.filterResult = data;
      this.creditInvoiceVar.showSearch = true;
    },
      err => {
        this.displayAPIerror(err);
      })
  }

  selectCreditInvoice(index: any) {
    const selectedInvoice = this.creditInvoiceVar.filterResult[index];
    this.creditInvoiceVar.selectedInvoice = selectedInvoice;
    this.creditInvoiceVar.searchKeyword = `${selectedInvoice.invoiceNo} - ${selectedInvoice.amount} ${UTILS.getCurrencySymbol(this.currency)}`;
    this.creditInvoiceVar.selectedCountry = selectedInvoice;
    this.creditInvoiceVar.showSearch = false;
    const invoiceId = selectedInvoice.invoiceId;
    this.reservationsService.getFennoaInvoice(invoiceId, this.reservation.hotel.hotelId).subscribe(
      data => {
        this.invoiceData = data;
        if (data.SalesInvoice.invoice_no) {
          this.currentInvoiceNumber = data.SalesInvoice.invoice_no;
        }
        this.invoiceVar.isSelectedFennoaCustomer = true;
        let invoiceInfo = this.invoiceData.SalesInvoice;
        if (invoiceInfo.approved) {
          this.invoiceApproved = true;
        }
        this.invoiceVar.searchKeyword = invoiceInfo.name;
        setTimeout(() => {
          this.fillInvoiceForm(invoiceInfo.name, invoiceInfo.address, invoiceInfo.postalcode, invoiceInfo.city, invoiceInfo.einvoice_address, invoiceInfo.vat_number, invoiceInfo.your_reference);
          let salesInvoiceDeliveryData = {
            salesInvoiceDelivery: this.invoiceData.SalesInvoiceDelivery,
            index: index
          }
          this.showFilterOptional(invoiceInfo, salesInvoiceDeliveryData, invoiceId);
          let fennoaPaymentStatus = this.reservation.payments.find((payment: any) => {
            return payment.fennoaInvoiceId == invoiceId;
          });
          if (fennoaPaymentStatus) {
            if (fennoaPaymentStatus.fennoaPaymentStatus) {
              fennoaPaymentStatus = fennoaPaymentStatus.fennoaPaymentStatus.replace("_", " ").toLowerCase();
              this.invoiceStatus = fennoaPaymentStatus.charAt(0).toUpperCase() + fennoaPaymentStatus.slice(1);
            } else {
              // Set invoice status for credit invoice is empty
              this.invoiceStatus = "";
            }
          }
          let tmpAmount: any = 0;
          this.invoiceData.SalesInvoiceRow.forEach((product: any) => {
            tmpAmount += Math.abs(Number(product.price_with_vat) * product.quantity);
          });
          this.invoicePaymentAmount = tmpAmount;
          this.invoicePaymentChange('amount');
          this.invoiceVar.selectedCustomer = this.findCustomerFromCustomerID();
          this.disableInvoice(false);
        }, 300);
      },
      err => {
        this.displayAPIerror(err)
      }
    )
  }

  // Invoice improved
  selectInvoiceCountry(item: MenuType) {
    this.selectedInvoiceCountry = item
  }
  selectInvoiceLang(item: MenuType) {
    this.selectedInvoiceLang = item
  }
  selectInvoiceDeliveryMethod(item: MenuType, reset: boolean = true) {
    this.selectedInvoiceDeliveryMethod = item
    if (reset) {
      this.invoiceForm.patchValue({
        einvoice_address: ""
      });
    }
    this.validateEInvoiceAddress();
  }
  setDefaultLanguage() {
    // TO DO: Default lang based on config
    const findDefaultLang = this.langList.find((language) => {
      return language.value == "fi";
    });
    if (findDefaultLang) {
      this.selectInvoiceLang(findDefaultLang);
    }
  }
  //-----------------
  // Menu improved
  selectOtaChannel(item: MenuType) {
    this.selectedOtaChannel = item;
    if (item.value === "none") {
      this.customerEditForm.patchValue({
        emailVirtual: "",
        bookingChannelReservationId: ""
      });
      this.customerEditForm.controls['bookingChannelReservationId'].disable();
      this.customerEditForm.controls['emailVirtual'].disable();
    } else {
      this.customerEditForm.controls['bookingChannelReservationId'].enable();
      this.customerEditForm.controls['emailVirtual'].enable();
    }
  }
  // --------------

  validateEInvoiceAddress() {
    if (this.selectedInvoiceDeliveryMethod) {
      switch (this.selectedInvoiceDeliveryMethod.value) {
        case "email":
          let emailPattern = new RegExp(CONSTANT.REGEX_EMAIL);
          if (!emailPattern.test(this.einvoice_address.value)) {
            this.einvoice_address.setErrors({ 'invalid': true });
          } else {
            this.einvoice_address.setErrors(null);
          }
          break;
        case "finvoice":
          if (!this.einvoice_address.value) {
            this.einvoice_address.setErrors({ 'invalid': true });
          } else {
            this.einvoice_address.setErrors(null);
          }
          break;
        default:
          this.einvoice_address.setErrors(null);
          break;
      }
    }
  }

  searchEInvoice() {
    let keyword = this.eInvoiceOperaterVar.searchKeyword.toLowerCase();
    this.eInvoiceOperaterVar.filterResult = this.eInvoiceOperaters.filter((obj: any) => {
      let mergeKeyword = obj.name.toLowerCase() + " (" + obj.swiftCode.toLowerCase() + ")";
      return mergeKeyword.includes(keyword);
    });
  }

  hideResultEInvoice() {
    setTimeout(() => {
      this.eInvoiceOperaterVar.showSearch = false;
    }, 300);
  }

  showResultEInvoice() {
    this.eInvoiceOperaterVar.showSearch = true;
    this.removeInvoiceInvalid();
  }

  selectEInvoice(index: any) {
    this.eInvoiceOperaterVar.searchKeyword = this.eInvoiceOperaterVar.filterResult[index].name + " (" + this.eInvoiceOperaterVar.filterResult[index].swiftCode + ")";
    this.eInvoiceOperaterVar.showSearch = false;
    this.eInvoiceOperaterVar.swiftCode = this.eInvoiceOperaterVar.filterResult[index].swiftCode;
  }

  selectInvoiceTermsOfPayment(item: MenuType) {
    this.removeInvoiceInvalid();
    this.selectedInvoiceTermsOfPayment = item;
    let paymentDays = Number(item.value);
    if (this.invoiceDate) {
      let tmpDue = this.invoiceDate.getTime() + paymentDays * 24 * 3600 * 1000;
      (this.isAddingInvoiceSalo ? this.invoiceSaloForm : this.invoiceForm).patchValue({
        dueDate: new Date(tmpDue)
      });
    }
  }

  getTermsOfPayment() {
    let invoiceDate = this.invoiceDate.setHours(0, 0, 0, 0);
    let dueDate = this.dueDate.setHours(0, 0, 0, 0);
    let paymentDays = (dueDate - invoiceDate) / 24 / 3600 / 1000;
    let termObj = this.invoiceTermsOfPaymentList.find((obj: any) => {
      return Number(obj.value) == paymentDays;
    });
    if (termObj) {
      this.selectInvoiceTermsOfPayment(termObj);
    } else {
      this.selectedInvoiceTermsOfPayment = undefined;
    }
  }

  searchCustomer() {
    let keyword = this.invoiceVar.searchKeyword.toLowerCase();
    this.invoiceVar.filterResult = this.fennoaCustomer.filter((obj: any) => {
      return obj.Customer.name.toLowerCase().includes(keyword);
    });
  }

  hideResult() {
    setTimeout(() => {
      this.invoiceVar.showSearch = false;
      if (this.invoiceVar.createNew) {
        this.invoiceVar.searchKeyword = '(Create new)';
      }
    }, 300);
  }

  showResult() {
    if (this.invoiceVar.createNew) {
      this.invoiceVar.searchKeyword = '';
    }
    this.invoiceVar.showSearch = true;
  }

  selectFennoaCustomer(selected: any) {
    this.invoiceVar = {
      showSearch: true,
      searchKeyword: '',
      isSelectedFennoaCustomer: true,
      selectedCustomer: selected,
      filterResult: this.invoiceVar.filterResult,
      copyReservation: false,
      createNew: false,
      existCustomerAlert: false
    }
    let customer = selected.Customer;
    this.invoiceVar.searchKeyword = customer.customer_no + "-" + customer.name;
    this.showCountryInfo(customer, true);
    this.fillInvoiceForm(customer.name, customer.address, customer.postalcode, customer.city, customer.einvoice_address, customer.vat_number, this.yourReferenceCustomer());
    this.showFilterOptional(customer, null);
    this.validateEInvoiceAddress();
  }

  showCountryInfo(customer: any, isISOAlpha2: boolean) {
    // Because customer is selected, then customer will be using country_id, 'ISO3166-1-Alpha-2' 
    // And Copy info from reservation is checked, then customer will be using isoCountryCode, 'ISO3166-1-Alpha-3' 
    let countryID = isISOAlpha2 ? customer.country_id : customer.isoCountryCode;
    const findCountry = countryData.find((country) => {
      if (countryID) {
        if (isISOAlpha2) {
          return country.code === countryID
        } else {
          return country.iso3 === countryID
        }
      } else {
        // Default guest country base on config
        return country.iso3 === this.hotelConfig.feature.defaultGuestNationality
      }
    });
    if (findCountry) {
      const findCountryList = this.countryList.find(country => country.value === findCountry.iso3);
      if (findCountryList) {
        this.selectInvoiceCountry(findCountryList)
      }
    }
  }

  showFilterOptional(customer: any, data: any, invoiceId?: number) {
    let eInvoiceOperater;
    const findBalanceItem = this.invoiceTypeList.find(item => item.value === "FULL");

    // Update amount for full type when create new invoice
    if (this.selectedInvoiceType && findBalanceItem && this.selectedInvoiceType.value === findBalanceItem.value) {
      this.invoicePaymentAmount = this.getPriceNumber(this.integratedInvoiceData.remainingTotalPrice);
    }

    // Update invoice type name
    if (!this.isAddedInvoice) {
      if (data && data.index !== null && this.reservation.payments.length && this.reservation.payments[data.index].fennoaInvoiceId !== null) {
        this.showInvoiceType(this.reservation.payments);
      }
    }

    // Update country field
    if (this.invoiceData && this.invoiceData.Country) {
      const findCountry = this.countryList.find(country => country.value === this.invoiceData.Country.code)
      if (findCountry) {
        this.selectInvoiceCountry(findCountry)
      }
    }

    // Show your reference for customer
    this.invoiceForm.patchValue({
      your_reference: this.yourReferenceCustomer()
    });

    // Update delivery method
    if (customer.delivery_method) {
      const selectedDelivery = this.invoiceDeliveryMethodList.find(item => item.value === customer.delivery_method)
      if (selectedDelivery) {
        this.selectInvoiceDeliveryMethod(selectedDelivery, false)
      }
    } else {
      let selectedDelivery: MenuType = {} as MenuType;
      if (invoiceId) {
        const findInvoicePayment = this.reservation.payments.find((payment: any) => payment.fennoaInvoiceId === invoiceId);
        if (findInvoicePayment) {
          const tmpDelivery = this.invoiceDeliveryMethodList.find(item => item.value === findInvoicePayment.fennoaDeliveryMethod);
          if (tmpDelivery) {
            selectedDelivery = tmpDelivery
          }
        }
      } else {
        if (this.reservation.payments.length && this.reservation.payments[data.index].fennoaDeliveryMethod !== null) {
          const tmpDelivery = this.invoiceDeliveryMethodList.find(item => item.value === this.reservation.payments[data.index].fennoaDeliveryMethod);
          if (tmpDelivery) {
            selectedDelivery = tmpDelivery
          }
        }
      }
      if (Object.keys(selectedDelivery).length) {
        this.selectInvoiceDeliveryMethod(selectedDelivery, false)
      }
      if (data.salesInvoiceDelivery.length) {
        this.invoiceForm.patchValue({
          einvoice_address: data.salesInvoiceDelivery[0].address
        });
      } else {
        // Update eivoice address for customer when the invoice don't approve and send
        if (this.invoiceData && this.invoiceData.SalesInvoice) {
          this.getCustomerInfoAfterSavedInvoice(this.reservation.hotel.hotelId, this.invoiceData.SalesInvoice.customer_id);
        }
      }
    }

    if (data && data.index !== null && this.reservation.payments.length && this.reservation.payments[data.index].invoiceLanguage !== null) {
      const findLang = this.langList.find((language) => {
        return language.value == this.reservation.payments[data.index].invoiceLanguage.toLowerCase();
      });
      if (findLang) {
        this.selectInvoiceLang(findLang)
      } else {
        this.setDefaultLanguage()
      }
    } else {
      this.setDefaultLanguage()
    }

    // Update e-invoice operator
    if (customer.einvoice_operator_id) {
      eInvoiceOperater = CONSTANT.E_INVOICE_OPERATER.find((eInvoiceOper) => {
        return eInvoiceOper.swiftCode.toLowerCase() == customer.einvoice_operator_id.toLowerCase();
      });
    } else {
      if (data.salesInvoiceDelivery.length) {
        eInvoiceOperater = CONSTANT.E_INVOICE_OPERATER.find((eInvoiceOper) => {
          return eInvoiceOper.swiftCode.toLowerCase() == data.salesInvoiceDelivery[0].bic.toLowerCase();
        });
      } else {
        // Update E-Invoice Operator when the einvoice haven't approved and sent with 
        return;
      }
    }
    this.eInvoiceOperaterVar.searchKeyword = eInvoiceOperater ? (eInvoiceOperater.name + " (" + eInvoiceOperater.swiftCode + ")") : "";
    this.eInvoiceOperaterVar.swiftCode = eInvoiceOperater ? eInvoiceOperater.swiftCode : "";
  }

  showInvoiceType(payments: any) {
    payments.find((payment: any) => {
      this.invoiceTypeList.find((fennoaInvoiceType: any) => {
        if (fennoaInvoiceType.value === payment.fennoaPaymentType && this.currentInvoiceId === payment.fennoaInvoiceId) {
          this.selectInvoiceType(fennoaInvoiceType)
        }
      });
    });
  }

  copyReservation() {
    this.invoiceVar.copyReservation = !this.invoiceVar.copyReservation;
    if (this.invoiceVar.copyReservation) {
      this.invoiceVar = {
        showSearch: false,
        searchKeyword: '(Create new)',
        isSelectedFennoaCustomer: false,
        selectedCustomer: null,
        copyReservation: true,
        createNew: true,
        filterResult: this.fennoaCustomer,
        existCustomerAlert: false
      }
      let reservationName = this.reservation.customer.firstName + ' ' + this.reservation.customer.lastName;
      if (this.checkExistingCustomer(reservationName)) {
        this.invoiceVar.existCustomerAlert = true;
      }
      this.showCountryInfo(this.reservation.customer, false);
      this.fillInvoiceForm(reservationName, this.reservation.customer.address, this.reservation.customer.postalCode, this.reservation.customer.city, this.reservation.customer.emailReal, null, this.yourReferenceCustomer());

      const findDefaultDelivery = this.invoiceDeliveryMethodList.find(item => item.value === "email");
      if (findDefaultDelivery) {
        this.selectInvoiceDeliveryMethod(findDefaultDelivery)
      }
    } else {
      this.invoiceVar = {
        showSearch: false,
        searchKeyword: '',
        isSelectedFennoaCustomer: false,
        selectedCustomer: null,
        copyReservation: false,
        createNew: false,
        filterResult: this.fennoaCustomer,
        existCustomerAlert: false
      }
    }
  }

  yourReferenceCustomer() {
    return this.reservation.customer.firstName + " " + this.reservation.customer.lastName;
  }

  inputCheckExisting() {
    if (!this.invoiceVar.isSelectedFennoaCustomer && !this.invoiceVar.createNew) {
      this.invoiceVar.createNew = true;
      this.invoiceVar.searchKeyword = '(Create new)';
    }
    if (this.invoiceVar.createNew) {
      if (this.checkExistingCustomer(this.invoiceName.value)) {
        this.invoiceVar.existCustomerAlert = true;
      } else {
        this.invoiceVar.existCustomerAlert = false;
      }
    }
  }

  checkExistingCustomer(name: string) {
    let checkExistingCustomer = []
    if (this.fennoaCustomer) {
      checkExistingCustomer = this.fennoaCustomer.filter((obj: any) => {
        return obj.Customer.name.toLowerCase() === name.toLowerCase();
      });
    }
    if (checkExistingCustomer.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  fillInvoiceForm(name: any, address: any, postalcode: any, city: any, einvoice_address: any, vat_number: any, your_reference: any, description?: any) {
    this.invoiceForm.patchValue({
      name: name,
      address: address,
      postalcode: postalcode,
      city: city,
      einvoice_address: einvoice_address,
      vat_number: vat_number,
      your_reference: your_reference,
      description: description ? description : ""
    });
  }

  clearInvoiceForm() {
    if (this.isAddingInvoiceSalo) {
      this.invoiceSaloForm.patchValue({
        name: null,
        address: null,
        postalcode: null,
        companyName: null,
        city: null,
        einvoice_address: null,
        vat_number: null,
        invoiceDate: new Date(),
        dueDate: null,
        your_reference: ""
      });
      this.reservationCodes = [];
      this.allTotalInvoice = [];
      this.isCheckAllReservations = false;
      this.checkAllReservationsForInvoiceManual(true);
      this.filters = cloneDeep(CONSTANT.FILTER_INVOICE_MANUAL);
      this.searchObjects = cloneDeep(CONSTANT.SEARCH_OBJECT);
      this.reservationSearch.patchValue({
        searchQuery: ""
      });
      this.startDate = new Date(new Date().setHours(0, 0, 0, 0));
      this.endDate = new Date(this.startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
      this.isLoadingInvoiceManual = false;
      this.selectedFilterLabel = "[Default]";
      this.removeInvoiceManualInvalid();
    } else {
      this.invoiceForm.patchValue({
        name: null,
        address: null,
        postalcode: null,
        city: null,
        einvoice_address: null,
        vat_number: null,
        invoiceDate: new Date(),
        dueDate: null,
        your_reference: ""
      });
      let tmpTotal = _.cloneDeep(this.getTotalSummary('totalPrice'));
      if (tmpTotal) {
        this.originalAmount = tmpTotal.split(" ")[1];
        this.invoicePaymentAmount = Number(tmpTotal.split(" ")[1]).toFixed(2);
      }
      this.invoicePaymentPercentage = 100;
      this.removeInvoiceInvalid();
    }
    this.setDefaultLanguage();
    this.invoiceItemList = [];
    this.showInvoiceItem = false;
    setTimeout(() => {
      this.setDefaultInvoiceVar();
      setTimeout(() => {
        this.invoicePaymentChange("amount");
      }, 200);
    }, 200);
  }

  invoicePaymentChange(type: string, invoiceId?: number) {
    const baseBalance = this.getPriceNumber(this.reservation.balance) < 0 ? 0 : this.getPriceNumber(this.reservation.balance);
    let baseTotalPrice = this.getPriceNumber(this.reservation.totalPrice);
    this.reservation.fennoaProductLineItems.forEach((item: any) => {
      baseTotalPrice -= this.getPriceNumber(item.amount);
    })
    const balancePercentage = Number(this.utils.roundDown((baseBalance / baseTotalPrice * 100), 2));
    switch (type) {
      case 'percent':
        let tmpAmount = 0;
        if (this.selectedInvoiceType && this.selectedInvoiceType.value === "ADVANCE") {
          tmpAmount = (((this.invoicePaymentPercentage / 100) * this.getPriceNumber(this.integratedInvoiceData.accommodationTotalPrice)));
        } else {
          tmpAmount = ((this.invoicePaymentPercentage / 100) * baseTotalPrice);
        }
        if (tmpAmount > baseBalance && this.selectedInvoiceType && this.selectedInvoiceType.value !== "CREDIT_INVOICE" && !invoiceId) {
          this.invoicePaymentAmount = this.utils.roundDown(baseBalance, 2);
          setTimeout(() => {
            this.invoicePaymentPercentage = this.utils.roundDown(balancePercentage, 2);
          }, 0);
        } else {
          this.invoicePaymentAmount = this.utils.roundDown(tmpAmount, 2);
        }
        break;
      case 'amount':
        let tmpPercent = 0;
        if (this.selectedInvoiceType && this.selectedInvoiceType.value === "ADVANCE") {
          tmpPercent = (this.invoicePaymentAmount / this.getPriceNumber(this.integratedInvoiceData.accommodationTotalPrice) * 100);
        } else {
          tmpPercent = (this.invoicePaymentAmount / baseTotalPrice) * 100;
        }
        if (tmpPercent > balancePercentage && this.selectedInvoiceType && this.selectedInvoiceType.value !== "CREDIT_INVOICE" && !invoiceId) {
          this.invoicePaymentPercentage = balancePercentage.toFixed(2);
          setTimeout(() => {
            this.invoicePaymentAmount = baseBalance.toFixed(2);
          }, 0);
        } else {
          this.invoicePaymentPercentage = tmpPercent.toFixed(2);
        }
        break;
    }
  }

  public truncateNameOfInvoiceType(name: any): void {
    if (!name)
      return;

    let length = name.length;
    let results;
    if (length > 15) {
      results = name.slice(0, 16) + '...';
    } else {
      results = name;
    }
    return results;
  }

  selectInvoiceType(item: MenuType) {
    this.selectedInvoiceType = item
    if (item.value === "ADVANCE") {
      // Default value when selecting Advance payment should be 15%
      this.invoicePaymentPercentage = 15;
      this.invoicePaymentChange('percent');
    } else if (item.value === "NONE") {
      this.invoicePaymentPercentage = 0;
      this.invoicePaymentChange('percent');
    } else if (item.value !== "CREDIT_INVOICE") {
      // When a booking has an advance payment, the default when adding another invoice should be 'Final payment' and the amount should be the remaining amount AND the percentage field should also be included.
      this.invoicePaymentAmount = this.getPriceNumber(this.integratedInvoiceData.remainingTotalPrice);
      this.invoicePaymentChange('amount');
      return true;
    } else {
      // Force percentage to be 100% when creating full, or remaining invoice type
      this.invoicePaymentPercentage = 100;
      this.invoicePaymentChange('percent');
    }
  }

  sumAmountForAdvanceType() {
    let totalAmount = 0;
    const findAdvanceItem = this.invoiceTypeList.find(item => item.value === "ADVANCE");
    this.reservation.payments.forEach((payment: any) => {
      if (findAdvanceItem && payment.fennoaPaymentType === findAdvanceItem.value) {
        totalAmount = totalAmount + this.getPriceNumber(payment.amount);
      }
    });
    return totalAmount;
  }

  validateDate() {
    if (this.invoiceDate == "Invalid Date") {
      this.invoiceDate = null;
    }
    if (this.dueDate == "Invalid Date") {
      this.dueDate = null;
    }
  }

  dateChange(type: string) {
    if (this.invoiceDate && this.dueDate) {
      this.getTermsOfPayment();
    }
    this.updateDatepicker();
  }

  updateDatepicker() {
    let custom: any;
    if (this.invoiceDate) {
      custom = [
        { date: this.invoiceDate, classes: ['selectedDate', 'start'] },
      ]
    }
    if (this.invoiceDate && this.dueDate) {
      let tmpTime = this.invoiceDate.getTime() + 24 * 3600 * 1000;
      while (tmpTime < this.dueDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24 * 3600 * 1000;
      }
      custom.push({ date: this.dueDate, classes: ['selectedDate', 'end'] });
    }
    this.datepickerCustom = custom
  }

  removeInvoiceInvalid() {
    this.invoiceValidate = {
      name: true,
      address: true,
      postal: true,
      city: true,
      description: true,
      method: true,
      language: true,
      eAddress: true,
      eOperator: true,
      invoiceDate: true,
      dueDate: true,
      country: true,
      creditInvoice: true
    }
  }

  removeInvoiceManualInvalid() {
    this.invoiceManualValidate = {
      name: true,
      address: true,
      postal: true,
      city: true,
      method: true,
      language: true,
      eAddress: true,
      eOperator: true,
      invoiceDate: true,
      dueDate: true,
      country: true
    }
  }

  validateInvoice() {
    let formValid: boolean = true;
    if (this.invoiceName.invalid) {
      this.invoiceValidate.name = false;
      formValid = false;
    } else {
      this.invoiceValidate.name = true;
    }
    if (this.invoiceAddress.invalid) {
      this.invoiceValidate.address = false;
      formValid = false;
    } else {
      this.invoiceValidate.address = true;
    }
    if (this.invoicePostalcode.invalid) {
      this.invoiceValidate.postal = false;
      formValid = false;
    } else {
      this.invoiceValidate.postal = true;
    }
    if (this.invoiceCity.invalid) {
      this.invoiceValidate.city = false;
      formValid = false;
    } else {
      this.invoiceValidate.city = true;
    }
    if (!this.selectedInvoiceDeliveryMethod) {
      this.invoiceValidate.method = false;
      formValid = false;
    } else {
      this.invoiceValidate.method = true;
    }
    if (!this.selectedInvoiceLang) {
      this.invoiceValidate.language = false;
      formValid = false;
    } else {
      this.invoiceValidate.language = true;
    }
    if (this.invoiceDateForm.invalid) {
      this.invoiceValidate.invoiceDate = false;
      formValid = false;
    } else {
      this.invoiceValidate.invoiceDate = true;
    }
    if (this.dueDateForm.invalid) {
      this.invoiceValidate.dueDate = false;
      formValid = false;
    } else {
      this.invoiceValidate.dueDate = true;
    }
    if (this.selectedInvoiceDeliveryMethod && this.selectedInvoiceDeliveryMethod.value === "email") {
      if (this.einvoice_address.invalid) {
        this.invoiceValidate.eAddress = false;
        formValid = false;
      } else {
        this.invoiceValidate.eAddress = true;
      }
    } else {
      if (this.einvoice_address.invalid) {
        this.invoiceValidate.eAddress = false;
        formValid = false;
      } else {
        this.invoiceValidate.eAddress = true;
      }
      if (!this.eInvoiceOperaterVar.searchKeyword) {
        this.invoiceValidate.eOperator = false;
        formValid = false;
      } else {
        this.invoiceValidate.eOperator = true;
      }
    }
    if (this.selectedInvoiceType && this.selectedInvoiceType.value === "CREDIT_INVOICE") {
      if (!this.creditInvoiceVar.selectedInvoice) {
        this.invoiceValidate.creditInvoice = false;
        formValid = false;
      } else {
        this.invoiceValidate.creditInvoice = true;
      }
    } else {
      this.invoiceValidate.creditInvoice = true;
      let percentPattern = new RegExp(/^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/);
      let pricePattern = new RegExp(/^\s*(?=.*[0-9])\d*(?:\.\d{1,2})?\s*$/);
      if (!percentPattern.test(this.invoicePaymentPercentage) || !pricePattern.test(this.invoicePaymentAmount)) {
        formValid = false
      }
      this.invoiceItemList.forEach((item: any) => {
        if (!pricePattern.test(item.value)) {
          formValid = false
        }
      })
    }
    return formValid;
  }

  validateInvoiceManual() {
    let formValid: boolean = true;
    if (this.invoiceManualName.invalid) {
      this.invoiceManualValidate.name = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.name = true;
    }
    if (this.invoiceManualAddress.invalid) {
      this.invoiceManualValidate.address = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.address = true;
    }
    if (this.invoiceManualPostalcode.invalid) {
      this.invoiceManualValidate.postal = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.postal = true;
    }
    if (this.invoiceManualCity.invalid) {
      this.invoiceManualValidate.city = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.city = true;
    }
    if (this.invoiceManualDateForm.invalid) {
      this.invoiceManualValidate.invoiceDate = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.invoiceDate = true;
    }
    if (this.invoiceManualDueDateForm.invalid) {
      this.invoiceManualValidate.dueDate = false;
      formValid = false;
    } else {
      this.invoiceManualValidate.dueDate = true;
    }
    return formValid;
  }

  buildProductPrices() {
    let comparePricesInit: any = [];
    this.integratedInvoiceData.lineItemsUnpaidByFennoa.forEach((lineItem: any) => {
      if (!lineItem.cancelled && !lineItem.toBeCancelled) {
        if (comparePricesInit && comparePricesInit.length) {
          this.isProductExisted(comparePricesInit, lineItem);
        } else {
          comparePricesInit.push({
            "amountPaid": lineItem.amountPaid,
            "productId": lineItem.productId,
            "price": lineItem.price,
            "fennoaPrice": Number(lineItem.price.amount.split(" ")[1]),
            "quantity": 1,
            "lineItemIds": "" + lineItem.id
          });
        }
      }
    });
    return comparePricesInit;
  }
  inputSurchargePrice(event: any) {
    if (!_.isNumber(Number(event.target.value)) || !Number(event.target.value) ||
      (_.isNumber(Number(event.target.value)) && Number(event.target.value) < 0)) {
      this.surchargePrice = this.surchargePriceTemp;
      event.target.value = this.surchargePriceTemp.toFixed(2);
      return;
    }
    this.surchargePrice = Number(event.target.value);
    let sumTotalRemainingPriceTemp = this.sumTotalRemainingPrice - this.surchargePriceTemp;
    this.sumTotalRemainingPrice = Number(sumTotalRemainingPriceTemp) + this.surchargePrice;
    this.surchargePriceTemp = this.surchargePrice;
  }
  checkCreatePdfForInvoiceManual() {
    if (!this.validateInvoiceManual()) {
      return;
    } else if (typeof this.reservationCodes === 'undefined' || JSON.stringify(this.reservationCodes) === "[]") {
      // Check reservation codes to show warning message
      this.toastr.error("Reservation(s) has not been selected yet.", 'Error!');
      return;
    } else {
      this.isLoadingCreatePdf = true;
      let request = {
        name: this.invoiceSaloForm.controls.name.value,
        streetAddress: this.invoiceSaloForm.controls.address.value,
        postalCode: this.invoiceSaloForm.controls.postalcode.value,
        companyName: this.invoiceSaloForm.controls.companyName.value,
        postOffice: this.invoiceSaloForm.controls.city.value,
        invoiceDate: formatDate(this.invoiceDate, 'yyyy-MM-dd', 'en-US'),
        dueDate: formatDate(this.dueDate, 'yyyy-MM-dd', 'en-US'),
        country: this.selectedInvoiceCountry ? this.selectedInvoiceCountry.label : "",
        // yourReference: this.yourReferenceCustomer(),
        yourReference: this.invoiceSaloForm.controls.your_reference.value,
        reservationCodes: this.checkDuplicateReservationCodes(this.reservationCodes),
        includeSurcharge: this.includeSurcharge,
        surchargePrice: this.surchargePrice,
        lang: this.selectedInvoiceLang ? this.selectedInvoiceLang.value : "",
      }
      this.reservationsService.checkCreatePdfForInvoiceManual(request).subscribe(
        (response: any) => {
          this.isLoadingCreatePdf = false;
          if (response) {
            this.createPdfForInvoiceManual();
          }
          else {
            this.openModal('alert', this.checkCreatePdfModalTemplate);
          }
        },
        err => {
          this.isLoadingCreatePdf = false;
          this.displayAPIerror(err);
        }
      );
    }
  }
  createPdfForInvoiceManual() {
    if (!this.validateInvoiceManual()) {
      return;
    } else if (typeof this.reservationCodes === 'undefined' || JSON.stringify(this.reservationCodes) === "[]") {
      // Check reservation codes to show warning message
      this.toastr.error("Reservation(s) has not been selected yet.", 'Error!');
      return;
    } else {
      this.isLoadingCreatePdf = true;
      let request = {
        name: this.invoiceSaloForm.controls.name.value,
        streetAddress: this.invoiceSaloForm.controls.address.value,
        postalCode: this.invoiceSaloForm.controls.postalcode.value,
        companyName: this.invoiceSaloForm.controls.companyName.value,
        postOffice: this.invoiceSaloForm.controls.city.value,
        invoiceDate: formatDate(this.invoiceDate, 'yyyy-MM-dd', 'en-US'),
        dueDate: formatDate(this.dueDate, 'yyyy-MM-dd', 'en-US'),
        country: this.selectedInvoiceCountry ? this.selectedInvoiceCountry.label : "",
        // yourReference: this.yourReferenceCustomer(),
        yourReference: this.invoiceSaloForm.controls.your_reference.value,
        reservationCodes: this.checkDuplicateReservationCodes(this.reservationCodes),
        includeSurcharge: this.includeSurcharge,
        surchargePrice: this.surchargePrice,
        lang: this.selectedInvoiceLang ? this.selectedInvoiceLang.value : "",
      }
      this.reservationsService.createPdfForInvoiceManual(request).subscribe(
        (responsePDF: any) => {
          this.isLoadingCreatePdf = false;
          this.toastr.success('Created pdf for invoice manual', 'Success!', { timeOut: 2000 });
          this.showFilePdf(responsePDF);
          this.modalRef.hide();
          this.getReservationData(() => {
            this.initOrders(false);
          });
          this.clearInvoiceForm();
        },
        err => {
          this.isLoadingCreatePdf = false;
          this.displayAPIerror(err);
        }
      );
    }
  }

  checkDuplicateReservationCodes(reservationCodes: string[]) {
    return Array.from(new Set(reservationCodes)) //
  }

  saveInvoice() {
    if (this.validateInvoice()) {
      // this.invoiceSaving = true;
      let request: any = {
        customer_no: null,
        name: this.invoiceName.value,
        name2: null,
        address: this.invoiceAddress.value,
        postalcode: this.invoicePostalcode.value,
        city: this.invoiceCity.value,
        country: this.selectedInvoiceCountry ? this.selectedInvoiceCountry.value : "",
        invoice_date: formatDate(this.invoiceDate, 'yyyy-MM-dd', 'en-US'),
        due_date: formatDate(this.dueDate, 'yyyy-MM-dd', 'en-US'),
        einvoice_address: this.einvoice_address.value,
        order_identifier: this.reservation.reservationCode + " (varausnumero)",
        SalesInvoiceRow: [],
        delivery_method: this.selectedInvoiceDeliveryMethod ? this.selectedInvoiceDeliveryMethod.value : "",
        einvoice_operator: this.eInvoiceOperaterVar.swiftCode,
        locale: this.selectedInvoiceLang ? this.selectedInvoiceLang.value : "",
        notes_fixed_width: this.invoiceDescription.value,
        sales_invoice_taxclass_id: 1,
        include_vat: 1,
        notes_before: `${this.reservation.hotel.name} ${formatDate(this.reservation.startDate, "dd.MM.yyyy", "en-US")} - ${formatDate(this.reservation.endDate, "dd.MM.yyyy", "en-US")}`,
        your_reference: this.invoiceForm.controls.your_reference.value,
        fennoaPaymentType: this.selectedInvoiceType ? (this.selectedInvoiceType.value === "NONE" ? "ADVANCE" : this.selectedInvoiceType.value) : "",
        fennoaInvoiceDetails: null
      }
      if (this.invoiceVar.isSelectedFennoaCustomer && this.invoiceVar.searchKeyword.trim()) {
        request.customer_no = this.invoiceVar.selectedCustomer.Customer.customer_no;
        request.name2 = this.invoiceVar.selectedCustomer.Customer.name2;
      }

      if (this.selectedInvoiceType && ["FULL"].includes(this.selectedInvoiceType.value)) {
        let salesRow: any = [];
        let initialComparePricesInit: any = this.buildProductPrices();
        console.log(initialComparePricesInit)
        initialComparePricesInit.forEach((product: any) => {
          let fennoaProduct: any = this.fennoaProducts.find((obj: any) => {
            return obj.productId === product.productId;
          });
          let percentage = product.amountPaid / (product.fennoaPrice + product.amountPaid) * 100;
          if (fennoaProduct) {
            salesRow.push({
              product_no: fennoaProduct.code,
              description: percentage > 0 ? percentage.toFixed(2) + "% " + this.translateForFinalPaymentDescription() : "",
              price: product.fennoaPrice,
              include_vat: 1,
              quantity: product.quantity,
              unit: this.unitForLanguage(fennoaProduct),
              vatpercent: fennoaProduct.vatcode_id,
              discount_percent: "0.00",
              dim: null
            })
          }
        });
        request.fennoaInvoiceDetails = "";
        request.SalesInvoiceRow = salesRow;
      }

      if (this.selectedInvoiceType && ["ADVANCE", "CUSTOM"].includes(this.selectedInvoiceType.value)) {
        let salesRow: any = [];
        let initialComparePricesInit: any = this.buildProductPrices();
        initialComparePricesInit.forEach((product: any) => {
          const productType = this.reservation.productsById[product.productId].type;
          if (productType === "ACCOMMODATION" || (this.selectedInvoiceType && !["ADVANCE"].includes(this.selectedInvoiceType.value) && productType !== "ACCOMMODATION")) {
            let fennoaProduct: any = this.fennoaProducts.find((obj: any) => {
              return obj.productId === product.productId;
            });
            let percentage = Number(this.invoicePaymentPercentage) / 100
            let salesRowPrice = this.utils.roundDown(product.fennoaPrice * percentage, 2);
            if (salesRowPrice !== 0 && fennoaProduct && this.selectedInvoiceType) {
              salesRow.push({
                product_no: fennoaProduct.code,
                description: this.selectedInvoiceType.value === "ADVANCE" ? this.invoicePaymentPercentage + "% " + this.translateForDescription() : "",
                price: salesRowPrice,
                include_vat: 1,
                quantity: product.quantity,
                unit: this.unitForLanguage(fennoaProduct),
                vatpercent: fennoaProduct.vatcode_id,
                discount_percent: "0.00",
                dim: null
              })
            }
          }
        });
        request.fennoaInvoiceDetails = "";
        request.SalesInvoiceRow = salesRow;
      }

      if (this.selectedInvoiceType && this.selectedInvoiceType.value === "CREDIT_INVOICE") {
        request["credited_invoice_no"] = this.creditInvoiceVar.selectedInvoice.invoiceNo;
      }
      if (this.selectedInvoiceType && this.invoiceData && ["CREDIT_INVOICE"].includes(this.selectedInvoiceType.value)) {
        let tmpSalesRow = JSON.parse(JSON.stringify(this.invoiceData.SalesInvoiceRow));
        let newSalesRow: any[] = [];
        tmpSalesRow.forEach((row: any) => {
          newSalesRow.push({
            product_no: row.code,
            description: "",
            price: row.price_with_vat * -1,
            include_vat: 1,
            quantity: row.quantity,
            unit: row.unit,
            vatpercent: row.vatpercent,
            discount_percent: row.discount_percent,
            dim: null
          })
        });
        request.SalesInvoiceRow = newSalesRow;
        const invoiceStart = this.invoiceData.SalesInvoice.invoice_date.split("-");
        const invoiceEnd = this.invoiceData.SalesInvoice.due_date.split("-");
        request.notes_before = `${this.reservation.hotel.name} ${invoiceStart[2]}.${invoiceStart[1]}.${invoiceStart[0]} - ${invoiceEnd[2]}.${invoiceEnd[1]}.${invoiceEnd[0]}`
      }

      // Add additional Item
      this.invoiceItemList.forEach(item => {
        const getFennoaProduct: any = this.fennoaProducts.find(product => product.code === item.selectedMenu.value);
        if (getFennoaProduct) {
          request.SalesInvoiceRow.push({
            product_no: getFennoaProduct.code,
            description: "",
            price: Number(item.value),
            include_vat: getFennoaProduct.vat_included,
            quantity: 1,
            unit: this.unitForLanguage(getFennoaProduct),
            vatpercent: getFennoaProduct.vatcode_id,
            discount_percent: "0.00",
            total_net: this.invoicePaymentAmount,
            dim: null
          })
        }
      })
      this.reservationsService.saveFennoaInvoice(this.reservation.uuid, request).subscribe(
        data => {
          this.toastr.success('Invoice saved', 'Success!', { timeOut: 2000 });
          this.currentInvoiceId = data;
          this.getReservationData();
          this.disableInvoice(true);
          this.invoiceSaving = false;
          this.invoiceApproved = false;
          // Update Customer field and unchecked Copy Reservation after saved invoice
          if (this.invoiceVar && this.invoiceVar.createNew && this.invoiceVar.copyReservation) {
            this.invoiceVar.searchKeyword = this.invoiceForm.controls.name.value;
            this.invoiceVar.copyReservation = false;
          }
        },
        err => {
          this.displayAPIerror(err);
          this.invoiceSaving = false;
        }
      );

    }
  }

  checkToShowDescription(productId: number) {
    if (this.integratedInvoiceData.accommodationProductIds.indexOf(Number(productId)) !== -1) {
      return true;
    }
    return false;
  }

  translateForFinalPaymentDescription() {
    if (this.selectedInvoiceLang) {
      switch (this.selectedInvoiceLang.value) {
        case "fi":
          return 'ennakkolasku vähennetty';
        case "sv":
          return 'förskottsbetalning avdragen';
        case "en":
          return 'advance payment deducted';
        default:
          return 'advance payment deducted';
      }
    } else {
      return 'advance payment deducted';
    }
  }

  translateForDescription() {
    if (this.selectedInvoiceLang) {
      switch (this.selectedInvoiceLang.value) {
        case "fi":
          return 'huoneen hinnasta';
        case "sv":
          return 'av rumspriset';
        case "en":
          return 'of room total';
        default:
          return 'of room total';
      }
    } else {
      return 'of room total';
    }
  }

  unitForLanguage(productFennoaForInvoiceType: any) {
    if (this.selectedInvoiceLang) {
      switch (this.selectedInvoiceLang.value) {
        case "fi":
          return productFennoaForInvoiceType.unit_fi;
        case "sv":
          return productFennoaForInvoiceType.unit_sv;
        case "en":
          return productFennoaForInvoiceType.unit_en;
        default:
          return productFennoaForInvoiceType.unit_en;
      }
    } else {
      return productFennoaForInvoiceType.unit_en;
    }
  }

  getProductRange(productId: any) {
    let startDate: any, endDate: any;
    this.reservation.orders.forEach((order: any) => {
      order.lineItems.forEach((lineItem: any) => {
        if (lineItem.productId == productId) {
          if (!startDate) {
            startDate = formatDate(lineItem.serviceDate, "dd.MM.yyyy", "en-US");
          }
          endDate = formatDate(lineItem.serviceDate, "dd.MM.yyyy", "en-US")
        }
      });
    });
    return `${startDate} - ${endDate}`;
  }

  disableInvoice(disable: boolean) {
    if (disable) {
      this.invoiceForm.disable();
      this.disableInvoiceForm = true;
    } else {
      this.invoiceForm.enable();
      this.disableInvoiceForm = false;
    }
  }

  approveSendInvoice() {
    this.invoiceApproving = true;
    this.reservationsService.approveSendFennoaInvoice(this.currentInvoiceId, this.reservation.hotel.hotelId).subscribe(
      data => {
        this.toastr.success('Invoice approved and sent', 'Success!', { timeOut: 2000 });
        this.getInvoice();
        this.invoiceApproved = true;
        this.invoiceApproving = false;
        this.modalRef.hide();
        this.initData();
      },
      err => {
        this.displayAPIerror(err);
        this.invoiceApproving = false;
      }
    )
  }

  isProductExisted(comparePricesInit: any, lineItem: any) {
    let isExisted = false;
    comparePricesInit.forEach((comparePriceInit: any) => {
      if (comparePriceInit.productId === lineItem.productId && comparePriceInit.price.amount.split(" ")[1] === lineItem.price.amount.split(" ")[1]) {
        comparePriceInit.quantity++;
        isExisted = true;
        comparePriceInit.lineItemIds = comparePriceInit.lineItemIds + ", " + lineItem.id
      }
    });
    if (!isExisted) {
      comparePricesInit.push({
        "amountPaid": lineItem.amountPaid,
        "productId": lineItem.productId, //3
        "price": lineItem.price, // 60
        "fennoaPrice": Number(lineItem.price.amount.split(" ")[1]),
        "quantity": 1,
        "lineItemIds": "" + lineItem.id
      });
    }
  }

  // calculateTotalPrice(product: any) {
  //   let finalPrice:any = 0;
  //   let vatPercentage:any = 0;
  //   let finalVat:any = 0;
  //   let price: any = 0;
  //   this.reservation.orders.forEach((order:any) => {
  //     if(!order.cancelled) {
  //       order.lineItems.forEach((lineItem:any) => {
  //         if(lineItem.productId == product.productId && !lineItem.cancelled) {
  //           if (this.reservation.accommodationProductIds.indexOf(lineItem.productId) !== -1) {
  //             price = Number(lineItem.price.amount.split(" ")[1]) * Number(this.invoicePaymentPercentage) / 100;
  //           } else {
  //             price = Number(lineItem.price.amount.split(" ")[1]);
  //           }
  //           finalPrice += price;
  //           vatPercentage = lineItem.price.vatPercentage;
  //           finalVat += price*lineItem.price.vatPercentage/100;
  //         }
  //       })
  //     }
  //   });
  //   let singlePriceWithVat:any;
  //   if(this.getTotalSummary('count', product.productId)) {
  //     singlePriceWithVat = finalPrice / this.getTotalSummary('count', product.productId);
  //   } else {
  //     singlePriceWithVat = 0;
  //   }

  //   return {
  //     singlePriceWithVat: singlePriceWithVat,
  //     priceWithVat: finalPrice.toFixed(2),
  //     vatPercentage: vatPercentage,
  //     finalVat: finalVat.toFixed(2)
  //   };
  // }

  dateChangeForInvoice(type: string) {
    switch (type) {
      case 'startDate':
        if (this.startDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.endDate = new Date(this.startDate.getTime() + 24 * 3600 * 1000);
        }
        break;
      case 'endDate':
        if (this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 24 * 3600 * 1000);
        }
        break;
    }
    this.updateDatepickerForInvoice();
  }

  updateDatepickerForInvoice() {
    if (this.startDate && this.endDate) {
      let custom = [
        { date: this.startDate, classes: ['selectedDate', 'start'] },
      ]
      let tmpTime = this.startDate.getTime() + 24 * 3600 * 1000;
      while (tmpTime < this.endDate.getTime()) {
        custom.push({
          date: new Date(tmpTime), classes: ['dateRange']
        });
        tmpTime += 24 * 3600 * 1000;
      }
      custom.push({ date: this.endDate, classes: ['selectedDate', 'end'] });
      this.datepickerCustom = custom
    }
  }
  changeFilter(index: number, event: any) {
    this.filters[index].checked = event.target.checked;
    this.getCheckedFilterLabel();
  }

  getCheckedFilterLabel() {
    let defaultFilters: any = [];
    for (let i = 0; i < 5; i++) {
      defaultFilters.push(this.filters[i]);
    }
    let selectedFilters: any = [];
    this.filters.forEach((item: any) => {
      if (item.checked) {
        selectedFilters.push(item);
      }
    });
    if (selectedFilters.length == 0 || _.isEqual(defaultFilters, selectedFilters)) {
      this.selectedFilterLabel = "[Default]";
    } else {
      this.selectedFilterLabel = _.map(selectedFilters, 'label').join(", ");
    }
  }

  clearAllFilter() {
    this.filters.forEach((item: any) => {
      item.checked = false;
    });
    this.selectedFilterLabel = "[Default]";
  }

  changeObject(index: number, event: any) {
    this.searchObjects[index].checked = event.target.checked;
    // Check has only guest then uncheck Organization filter
    if (this.searchObjects.length) {
      // Update Organization filter when choose customer, guest
      if (this.searchObjects[0].value === "CUSTOMER" && !this.searchObjects[0].checked
        && this.searchObjects[1].value === "GUEST" && this.searchObjects[1].checked) {
        this.filters.forEach((item: any) => {
          if (item.value === "ORGANIZATION") {
            item.checked = false;
          }
        });
        return true;
      }
      // If we checked/unchecked both customer and guest then checked the Organization filter
      this.checkOrganizationFilter();
    }
  }

  checkOrganizationFilter() {
    this.searchObjects.forEach((object: any) => {
      this.filters.forEach((item: any) => {
        if (item.value === "ORGANIZATION") {
          item.checked = true;
        }
      });
    });
  }

  search(type: string) {
    // this.reservationCodes = [];
    // this.allTotalInvoice = [];
    setTimeout(() => {
      this.isLoadingInvoiceManual = true;
      let request: any = this.requestForInvoiceManual();
      switch (type) {
        case 'search':
        case 'sorting':
          if (this.startDate) {
            request.startDate = this.utils.convertDate(this.startDate, 'YYYY-MM-dd');
          }
          if (this.endDate) {
            request.endDate = this.utils.convertDate(this.endDate, 'YYYY-MM-dd');
          }
          break;
        case 'departingToday':
          request.endDate = this.utils.convertDate(new Date(), 'YYYY-MM-dd');
          request.dateMode = "EXACT";
          break;
        case 'arrivingToday':
          request.startDate = this.utils.convertDate(new Date(), 'YYYY-MM-dd');
          request.dateMode = "EXACT";
          break;
        case 'stayingTonight':
          request.startDate = this.utils.convertDate(new Date(), 'YYYY-MM-dd');
          request.endDate = this.utils.convertDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'YYYY-MM-dd');
          request.dateMode = "OVERLAP";
          break;
      }
      this.reservationsService.searchReservationsForInvoice(request).subscribe(data => {
        this.searchResult = data;
        // Update isChecked attribute for each reservation
        if (data.length) {
          this.searchResult.forEach((reservation: any) => {
            // reservation.isChecked = false;
            let isExist = this.reservationCodes.filter((x: any) => reservation.reservationCode == x);
            if (isExist.length) {
              reservation.isChecked = true;
            }
            else {
              reservation.isChecked = false;
            }
          });
          this.sumTotalInvoiceManual();
        } else {
          this.toastr.success("No search result", 'Success!');
        }

        // Get total number of reservations
        this.reservationsService.countReservationsForInvoice(request).subscribe(countNumber => {
          this.initPagination(countNumber);
        });
        this.isLoadingInvoiceManual = false;
        this.isCheckAllReservations = false;
      });
    }, 100);
  }
  removeInvoice(invoice: any) {
    this.reservationCodes.filter((x: any, i: any) => {
      if (x == invoice) {
        this.allTotalInvoice.splice(i, 1);
      }
    });
    this.reservationCodes = this.reservationCodes.filter((x: any) => x !== invoice);
    this.checkReservationCodesToChecked();
    this.sumTotalInvoiceManual()
  }
  initPagination(total: number) {
    this.cleanEvent.totalItem = total;
    this.cleanEvent.current = 1;
    this.cleanEvent.finalPage = Math.ceil(this.cleanEvent.totalItem / this.cleanEvent.items);
    this.cleanEvent.numberOfPage = Array.from(Array(Math.min(this.cleanEvent.finalPage, this.cleanEvent.showNav)).keys()).map(x => ++x);
  }
  changePage(page: any) {
    this.cleanEvent.current = page;
    this.fetchDataOnPagination();
  }
  singlePageChange(t: any) {
    switch (t) {
      case 'next':
        this.cleanEvent.current = Math.min(this.cleanEvent.current + 1, this.cleanEvent.finalPage);
        break;
      case 'prev':
        this.cleanEvent.current = Math.max(this.cleanEvent.current - 1, 0);
        break;
      case 'first':
        this.cleanEvent.current = 1;
        break;
      case 'last':
        this.cleanEvent.current = this.cleanEvent.finalPage;
        this.cleanEvent.numberOfPage = Array.from(Array(Math.min(this.cleanEvent.finalPage, 4)).keys()).map(x => {
          x = x + (this.cleanEvent.current - (this.cleanEvent.showNav - 1));
          return x;
        });
        break;
      default:
        break;
    }
    if (this.cleanEvent.current + (this.cleanEvent.showNav - 2) < this.cleanEvent.finalPage) {
      this.cleanEvent.numberOfPage = Array.from(Array(Math.min(this.cleanEvent.finalPage, this.cleanEvent.showNav)).keys()).map(x => {
        x = x + this.cleanEvent.current;
        return x;
      });
    }
    this.fetchDataOnPagination();
  }
  requestForInvoiceManual() {
    return {
      query: this.reservationSearch.controls.searchQuery.value,
      orderBy: this.orderBy,
      orderDir: this.orderDir,
      offset: (this.cleanEvent.current - 1) * this.cleanEvent.items,
      limit: this.cleanEvent.items,
      searchObject: _.map(this.searchObjects, (item: any) => { if (item.checked) return item.value; else return 0; }).filter((item: any) => { return item != 0; }),
      optionalFilters: _.map(this.filters, (item: any) => { if (item.checked) return item.value; else return 0; }).filter((item: any) => { return item != 0; }),
    };
  }
  fetchDataOnPagination() {
    let request: any = this.requestForInvoiceManual();
    if (this.startDate) {
      request.startDate = this.utils.convertDate(this.startDate, 'YYYY-MM-dd');
    }
    if (this.endDate) {
      request.endDate = this.utils.convertDate(this.endDate, 'YYYY-MM-dd');
    }
    this.reservationsService.searchReservationsForInvoice(request).subscribe(data => {
      this.searchResult = data;
      this.isLoadingInvoiceManual = false;
      // Update isChecked is false for all reservations after search reservation
      this.isCheckAllReservations = false;
      this.checkReservationCodesToChecked();
    });
  }
  checkReservationCodesToChecked() {
    let numberReservationsChecked = 0;
    this.searchResult.forEach((reservation: any) => {
      reservation.isChecked = false;
      this.reservationCodes.forEach((reservationCode: any) => {
        if (reservation.reservationCode === reservationCode) {
          reservation.isChecked = true;
          numberReservationsChecked = numberReservationsChecked + 1;
        }
      });
    });
    // Update check all reservations
    if (numberReservationsChecked === this.searchResult.length) {
      this.isCheckAllReservations = true;
    } else {
      this.isCheckAllReservations = false;
    }
  }
  getChannelLabel(id: string) {
    let selectedIndex = this.bookingChannel.findIndex((item: any) => {
      return item.value === id;
    });
    return this.bookingChannel[selectedIndex].label;
  }

  openGuestInfo(uuid: string, event: any) {
    event.stopPropagation();
    //Change the URL when using in another domain
    if (this.utils.isLocalEnvironment()) {
      window.location.href = CONSTANT.LOCALHOST_URL + `/confirmation/${uuid}`;
    } else {
      window.location.href = CONSTANT.SITEHOST + `/en/booking/#/confirmation/${uuid}`;
    }
  }

  toggleSorting(columnName: string) {
    this.isLoadingInvoiceManual = true;
    if (this.orderBy == columnName) {
      if (this.orderDir == 'ASC') {
        this.orderDir = 'DESC';
      } else {
        this.orderDir = 'ASC';
      }
    } else {
      this.orderBy = columnName;
      this.orderDir = 'ASC';
    }
    this.search('sorting');
  }

  convertToArray(event: any) {
    let tmpArray: any[] = [];
    event.forEach((item: any) => {
      tmpArray.push(item);
    });
    return tmpArray
  }

  showSearchText() {
    this.isHiddenSearch = !this.isHiddenSearch;
  }

  sumTotalInvoiceManual() {
    var total = 0;
    // this.searchResult.forEach((reservation: any) => {
    //   if (reservation.isChecked) {
    //     total = total + reservation.remainingAmount;
    //   }
    // });
    this.allTotalInvoice.forEach((reservation: any) => {
      total = total + reservation;
    });

    if (this.surchargePrice) {
      total = total + this.surchargePrice;
    }
    this.sumTotalRemainingPrice = total;
  }

  checkInvoicingSurcharge() {
    this.includeSurcharge = !this.includeSurcharge;
    if (!this.includeSurcharge) {
      this.sumTotalRemainingPrice = this.sumTotalRemainingPrice - this.surchargePrice;
    } else {
      this.sumTotalRemainingPrice = this.sumTotalRemainingPrice + this.surchargePrice;
    }
  }

  checkReservationForInvoiceManual(i: number) {
    let reservationChecked;
    reservationChecked = this.searchResult.find((reservation: any, index: number) => {
      if (index == i) {
        // Update status for checkbox for reservation
        reservation.isChecked = !reservation.isChecked;
        return reservation;
      }
    });
    if (reservationChecked) {
      if (!reservationChecked.isChecked) {
        this.sumTotalRemainingPrice = this.sumTotalRemainingPrice - reservationChecked.remainingAmount;
        // Remove reservation codes
        for (let i = 0; i < this.reservationCodes.length; i++) {
          if (this.reservationCodes[i] === reservationChecked.reservationCode) {
            this.reservationCodes.splice(i, 1);
            this.allTotalInvoice.splice(i, 1);
          }
        }
      } else {
        this.sumTotalRemainingPrice = this.sumTotalRemainingPrice + reservationChecked.remainingAmount;
        // Push reservation codes
        if (!this.reservationCodes.includes(reservationChecked.reservationCode)) {
          this.reservationCodes.push(reservationChecked.reservationCode);
          this.allTotalInvoice.push(reservationChecked.remainingAmount);
        }
      }
      // Update isChecked is false for all reservations after search reservation
      this.checkReservationCodesToChecked();
    }
  }

  checkAllReservationsForInvoiceManual(isClear: boolean) {
    if (!isClear) {
      this.isCheckAllReservations = !this.isCheckAllReservations;
    }
    this.searchResult.forEach((reservation: any) => {
      // Update all reservations are checked
      if (this.isCheckAllReservations) {
        reservation.isChecked = true;
        // Push reservation codes
        if (!this.reservationCodes.includes(reservation.reservationCode)) {
          this.reservationCodes.push(reservation.reservationCode);
          this.allTotalInvoice.push(reservation.remainingAmount);
        }
      } else {
        reservation.isChecked = false;
        for (var i = 0; i < this.reservationCodes.length; i++) {
          if (this.reservationCodes[i] === reservation.reservationCode) {
            this.reservationCodes.splice(i, 1);
            this.allTotalInvoice.splice(i, 1);
          }
        }
      }
    });
    this.sumTotalInvoiceManual();
  }

  closeModalForInvoiceManual() {
    // this.reservationCodes = [];
    // this.allTotalInvoice = [];
    this.isAddingManualPayment = true;
    this.isAddingInvoiceOmena = false;
    this.isAddingInvoiceSalo = false;
    this.clearInvoiceForm();
    this.modalRef.hide();
  }
  isAfter6AM(product?: any) {
    let timezone = this.reservation.hotel.zone ? this.reservation.hotel.zone : this.reservation.hotel.timezone;
    var CurrentHour = moment().tz(timezone).hours()
    var CurrentMinutes = moment().tz(timezone).minutes()
    if (product.type == 'ECI') {
      if (CurrentHour >= 6 && CurrentMinutes > 0) {
        return false;
      }
      return true;
    }
    return true;
  }
  cancellingSavingData() {
    this.getReservationData((data: any) => {
      this.assignDataCustomerEditForm();
      this.modalRef.hide()
    });
  }

  handleRadioCheckboxPaymentProvider(title: string) {
    this.addPaymentForm.patchValue({ paymentProvider: title });
  }

  handleRadioCheckboxManualPaymentType(title: string) {
    this.selectedPaymentTypeRadio = title;
    this.addPaymentForm.patchValue({ paymentType: title });
  }

  handleDeletePaymentType(title: string) {
    if (this.manualPaymentTypeList.length === 1) return;
    let newPaymentTypeList = this.manualPaymentTypeList.filter(item => item !== title);
    this.saveManualPaymentTypeList((data: any) => { this.setSelectedPaymentTypeRadio(newPaymentTypeList, title) }, newPaymentTypeList);
  }

  handleAddPaymentType() {
    let findedItemIndex = this.manualPaymentTypeList.findIndex(item => item === this.inputAddPaymentType);
    if (this.inputAddPaymentType === "" || findedItemIndex !== -1) return;
    let newPaymentTypeList = JSON.parse(JSON.stringify(this.manualPaymentTypeList));
    newPaymentTypeList.push(this.inputAddPaymentType);
    this.saveManualPaymentTypeList((data: any) => { this.setSelectedPaymentTypeRadio(newPaymentTypeList, this.selectedPaymentTypeRadio) }, newPaymentTypeList);
  }

  handleAddPaymentTypeMode() {
    setTimeout(() => {
      document.getElementById("paymentTypeInput")?.focus();
    }, 100)
    this.addPaymentTypeMode = true;
  }

  handleCancelPaymentTypeMode() {
    this.addPaymentTypeMode = false;
    this.inputAddPaymentType = "";
  }

  handleClickActivityBtn() {
    window.localStorage.setItem("resLogOrder", JSON.stringify(this.reservation.orders));
    window.localStorage.setItem("resLogProdById", JSON.stringify(this.reservation.productsById));
    window.localStorage.setItem("resLogCreated", JSON.stringify({
      hotel: this.reservation.hotel,
      guests: this.reservation.guests,
      bookingChanel: this.reservation.bookingChannel,
      bookingType: this.reservation.type,
      createdByAdmin: this.reservation.createdByAdmin
    }));
  }

  convertDate(date: any) {
    return moment(date).tz(this.reservation.hotel.zone).format('DD.MM.YYYY HH:mm');
  }

  getDate(date: string, type: string) {
    if (type === "date") {
      return date.split(" ")[0];
    }
    return date.split(" ")[1];
  }

  isRoomEmpty(roomAlias: number) {
    const guestWithRoomAlias = this.reservation.guests.filter((guest: any) => guest.roomAlias === roomAlias);
    let hasGuest = false;
    guestWithRoomAlias.forEach((guest: any) => {
      if (guest.firstName && guest.lastName) {
        hasGuest = true;
      }
    })
    return !hasGuest
  }

  getGuestListBasedOnAlias(roomAlias: number) {
    return this.reservation.guests.filter((guest: any) => guest.roomAlias === roomAlias);
  }

  getFennoaCreditProductName(code: string) {
    const findProduct = this.fennoaProducts.find((obj: any) => {
      return obj.code == code;
    });
    return findProduct ? findProduct.name_en : "";
  }

  getFennoaCreditPrice(price: number) {
    return `${price.toFixed(2)} ${UTILS.getCurrencySymbol(this.currency)}`;
  }

  isGuestUnderaged(age: number | null) {
    if (age && age < this.hotelConfig.feature.underagedThreshold) {
      return true
    }
    return false;
  }
  toggleOrderProductAccordion = (item: string) => {
    const existingItemIndex = this.orderProductAccordion.findIndex(product => product === item);
    if (existingItemIndex > -1) {
      this.orderProductAccordion.splice(existingItemIndex, 1);
    } else {
      this.orderProductAccordion.push(item);
    }
  }

  openAdditionalServicesModal() {
    this.openModal('modal-large', this.additionalServicesModalTemplate);
  }

  enableSecif(productType: string) {
    if (["ECI", "SAMEDATE_ECI_FLEX"].includes(productType)) {
      const nowAtHotel = moment().tz(this.reservation.hotel.zone);
      const reservationStartDate = moment(this.reservation.startDate).tz(this.reservation.hotel.zone);
      const oneDayBeforeStartDate = moment(this.reservation.startDate).subtract(1, "days").tz(this.reservation.hotel.zone);
      if (nowAtHotel.isSameOrAfter(oneDayBeforeStartDate) && nowAtHotel.isBefore(reservationStartDate)) {
        return productType === "SAMEDATE_ECI_FLEX";
      }
      return productType === "ECI";
    }
    return true;
  }

  getPaymentAmountRangeNote() {
    const currency = UTILS.getCurrencySymbol(this.currency);
    const totalPaidNumber = this.getPriceNumber(this.reservation.totalPaid);
    const balanceNumber = this.getPriceNumber(this.reservation.balance);
    return `Amount must be between ${totalPaidNumber === 0 ? 0 : "-" + totalPaidNumber.toFixed(2)} ${currency} and ${balanceNumber.toFixed(2)} ${currency}`
  }
}
