import { Component, OnInit } from '@angular/core';
import { ReservationsService } from 'src/app/services/reservations.service';

@Component({
  selector: 'app-internal-tools-mui',
  templateUrl: './internal-tools-mui.component.html',
  styleUrls: ['./internal-tools-mui.component.sass']
})
export class InternalToolsMuiComponent implements OnInit {

  constructor(
    private reservationsService: ReservationsService,
  ) { }

  hotels: any[] = [];

  ngOnInit(): void {
    this.reservationsService.getHotels().subscribe(data => {
      this.hotels = data;
    });
  }

}
