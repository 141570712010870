import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.sass']
})
export class ForgotComponent implements OnInit {

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private userService: UserService,
    private toastr: ToastrService,
  ) { }

  requestedUsername: string | null = null;
  loading: boolean = false;

  ngOnInit(): void {
    if (this.tokenStorageService.getToken()) {
      this.router.navigateByUrl("/dashboard");
    }
  }

  forgotForm: any = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  validateForgotForm(): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.forgotForm.controls.email.status === "INVALID") {
      if (this.forgotForm.controls.email.errors.required) {
        this.toastr.error("Email is required.", 'Error!');
        return isErrorForm;
      }
      if (this.forgotForm.controls.email.errors.email) {
        this.toastr.error("Please enter valid email address.", 'Error!');
        return isErrorForm;
      }
    }
    return !isErrorForm;
  }
  validateResetPasswordForm(): Boolean {
    let isErrorForm = false;
    // Check email 
    if (this.resetPasswordForm.controls.code.status === "INVALID") {
      if (this.resetPasswordForm.controls.code.errors.required) {
        this.toastr.error("Verification code is required.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.code.errors) {
        this.toastr.error("Please, enter valid verification code.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.controls.newPassword.status === "INVALID") {
      if (this.resetPasswordForm.controls.newPassword.errors.required) {
        this.toastr.error("Please enter a new password.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.newPassword.errors) {
        this.toastr.error("Please enter a valid new password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.confirmNewPassword) {
      this.toastr.error("New password and confirm password do not match.", 'Error!');
      return false;
    }
    return !isErrorForm;
  }

  resetPasswordForm: any = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]*$/)]),
    password: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });

  submitForgotForm() {
    if (this.validateForgotForm()) {
      this.loading = true;
      const username = this.forgotForm.value.email;
      this.sendVerificationCode(username).subscribe(
        () => {
          this.forgotForm.controls.email.disable();
          this.requestedUsername = username;
          this.loading = false;
        },
        (error: any) => {
          const message = error.error.message;
          this.toastr.error(message, 'Error!');
          this.loading = false;
        }
      );
    }
  }

  resendVerificationCode() {
    if (this.loading) return;
    this.loading = true;
    this.sendVerificationCode(this.forgotForm.value.email).subscribe(
      () => {
        this.loading = false;
      },
      (error: any) => {
        const message = error.error.message;
        this.toastr.error(message, 'Error!');
        this.loading = false;
      }
    );
  }

  private sendVerificationCode(username: string) {
    return this.userService.requestVerificationCode(username);
  }

  submitResetPasswordForm() {
    if (this.validateResetPasswordForm() && this.requestedUsername) {
      this.loading = true;
      this.userService.forgotResetPassword(this.resetPasswordForm.value.code, this.requestedUsername, this.resetPasswordForm.value.newPassword).subscribe(
        (data) => {
          this.toastr.success("Password has been changed!", 'Success!');
          this.router.navigate(['/login'])
        },
        (error) => {
          const message = error.error.message;
          this.toastr.error(message, 'Error!');
          this.loading = false;
        }
      );
    }
  }

}
