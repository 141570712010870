<app-header></app-header>
<div class="wrapper">
  <div class="wrapper-header-container">
    <div class="inner-header-container">
      <div class="container-fluid">
        <h1 class="view-title"><b>Room Usages</b></h1>
        <div class="row" *ngIf="hotels">
          <div class="col-xs-12 col-md-4 col-lg-6" *ngIf="hotels.length > 1">
            <label for="select-report">Search by hotel</label>
            <dropdown-menu
              [menuList]="hotelList"
              [selectedItem]="selectedHotelItem"
              [fullWidth]="true"
              (selectItem)="selectHotel($event)"
            ></dropdown-menu>
          </div>
          <div class="col-xs-6 col-md-3 col-lg-2">
            <label>Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                [(bsValue)]="startDatePicker" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', adaptivePosition: true }">
              <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-6 col-md-3 col-lg-2">
            <label>End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDatePicker"
                bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-12 col-md-2">
            <button type="submit" class="btn btn-green input-height" style="margin-top: 35px"
              (click)="getUsageData(true)" [disabled]="!isFormValid || disabledBtnWhileCallingAPI">Apply</button>
          </div>
        </div>
        <div class="row" style="margin-top: 10px">
          <div class="col-md-5">
            <div class="button-row" *ngIf="!tableLoading && (hasChannelRole || hasEciLcoRole)">
              <button type="button" class="btn btn-outline-green height-40" (click)="openEditChannel()" *ngIf="hasChannelRole">
                <img class="mb-1" src="assets/images/edit-icon.svg">
                Edit Channels</button>
                <button type="button" class="btn btn-outline-green height-40" [ngClass]="{hidden: !hotelConfig.feature.eciLco}" (click)="openEditEciLco()" *ngIf="hasEciLcoRole">
                <img class="mb-1" src="assets/images/eci-lco-icon.svg">
                ECI/LCO</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper-table-container">
    <div class="inner-table-container">
      <div class="container-fluid">
        <div class="row" style="padding-top: 15px;">
          <div class="col-xs-12">
            <div class="legend-container">
              <div class="legend-list">
                <ng-container>
                  <div class="list-item">
                    <div class="item-color reservation"></div>
                    <div class="item-text">Reservation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color long-term"></div>
                    <div class="item-text">Long-term Reservation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color pending"></div>
                    <div class="item-text">Pending</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color manual-allocation"></div>
                    <div class="item-text">Manual allocation</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color mismatch"></div>
                    <div class="item-text">Overloaded</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color maintenance"></div>
                    <div class="item-text">Maintenance</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color maintenance cleaning"></div>
                    <div class="item-text">To clean</div>
                  </div>
                  <div class="list-item">
                    <div class="item-color allotment"></div>
                    <div class="item-text">Hard allotment</div>
                  </div>
                  <div class="list-item" *ngIf="hotelConfig.feature.eciLco">
                    <div class="item-color eci-lco"></div>
                    <div class="item-text">ECI/LCO</div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6">
            <ng-template #filterTemplate>
              <!-- Reservation type -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Reservation type</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('reservationType', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('reservationType')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterReservationType" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('reservationType', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Room type -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Room type</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio">
                      <input type="checkbox" (click)="changeFilterAll('room', $event)" [checked]="checkFilterAll('room')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterRoomType" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('room', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{utils.showRoomType(filter.label)}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Capacity -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Capacity</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('capacity', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('capacity')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterCapacity" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('capacity', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Availability -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Availability</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('avail', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('avail')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterAvail" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('avail', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Payment -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Payment</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio" (click)="changeFilterAll('payment', $event)">
                      <input type="checkbox" [checked]="checkFilterAll('payment')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterPayment" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('payment', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- Booking channel -->
              <div class="filter-container">
                <div class="filter-title-container">
                  <div class="filter-title">Channel</div>
                </div>
                <div class="filter-checkbox-container">
                  <div class="filter-checkbox">
                    <div class="custom-radio">
                      <input type="checkbox" (click)="changeFilterAll('channel', $event)" [checked]="checkFilterAll('channel')">
                      <div class="radio-box">
                        <span class="glyphicon glyphicon-ok"></span>
                        All
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let filter of filterBookingChannel" >
                    <div class="filter-checkbox">
                      <div class="custom-radio" (click)="changeFilter('channel', filter.value)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="radio-box">
                          <span class="glyphicon glyphicon-ok"></span>
                          {{filter.label}}
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-template>
            <button type="button" class="btn btn-outline-green height-40 high-zindex" [outsideClick]="true" [popover]="filterTemplate" placement="bottom left" container="body" containerClass="filterPopover" (onHidden)="getUsageWithFilter()">
              <span class="glyphicon glyphicon-filter"></span> Filter rooms
            </button>
          </div>
          <div class="col-xs-12 col-sm-6" style="text-align: right;padding-top: 10px;">
            <div class="normal-checkbox high-zindex" style="display: inline-block">
              <input type="checkbox" (click)="toggleAllotment()" >
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">Show allotment</div>
            </div>
          </div>
        </div>
        <!-- 
          Horizontal table scroll for future use
          <div class="usage-table" [ngClass]="{'fit-table': fitTable}" *ngIf="availabilityData"> -->
        <div class="usage-table" *ngIf="!tableLoading">
          <div class="table-head-container" (scroll)="tableScroll('head')">
            <div class="table-head" [style]="{width: tableWidth}">
              <div class="head-row date-row">
                <div class="row-title trigger-hide-availability" style="height: 55px">
                  <button type="button" class="btn btn-outline-green usage-expand-btn" (click)="switchTriggerHideAvailability()">
                    <span class="glyphicon glyphicon-menu-down" *ngIf="!triggerHideAvailability"></span>
                    <span class="glyphicon glyphicon-menu-up" *ngIf="triggerHideAvailability"></span>
                    {{triggerHideAvailability ? 'Collapse' : 'Expand'}}</button>
                  <!-- 
                    Horizontal table scroll/fit toggle for future use
                    <button (click)="toggleTableSetting()"><span
                      class="glyphicon glyphicon-resize-{{fitTable ? 'full' : 'small'}}"></span>
                  </button> -->
                </div>
                <div class="row-item" *ngFor="let date of allDates">
                  <div class="item-date">{{date}}</div>
                </div>
                <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
              </div>
              <ng-container *ngIf="availabilityData">
                <div class="head-row" id="normal-row" *ngIf="triggerHideAvailability && hotelConfig.MUIfeature.OTAServices.includes('BOOKINGCOM')">
                  <div class="row-title">Booking.com</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.BOOKINGCOM"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability && hotelConfig.MUIfeature.OTAServices.includes('EXPEDIA')">
                  <div class="row-title">Expedia</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.EXPEDIA"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability">
                  <div class="row-title">Nelson</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.channels.NELSON"
                    [ngClass]="{'empty': channel.available == 0}">{{channel.available}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" *ngIf="triggerHideAvailability">
                  <div class="row-title">Maintenance</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.maintenance.dates">{{channel.used}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row" [ngClass]="{'allotment-border': allotmentRows}" *ngIf="triggerHideAvailability">
                  <div class="row-title">Reserved</div>
                  <div class="row-item" *ngFor="let channel of availabilityData.reserved">{{channel.totalReservedRoom}}</div>
                  <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                </div>
                <div class="head-row border-bottom" *ngIf="!triggerHideAvailability">
                </div>
                <ng-container *ngIf="allotmentRows && allotmentData">
                  <ng-container *ngFor="let allotment of allotmentData">
                    <div class="head-row" *ngIf="allotment.allotmentType == 'SOFT'">
                      <div class="row-title" tooltip="Edit {{allotment.allotmentName}}" placement="top" container="body" routerLink="/allotment/{{allotment.allotmentId}}">{{allotment.allotmentName}}</div>
                      <div class="row-item" *ngFor="let num of allotment.hotelAllotmentDates">
                        <span *ngIf="num.availability">{{num.used}}/{{num.availability}}</span>
                      </div>
                      <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                    </div>
                  </ng-container>
                  <div class="head-row">
                    <div class="row-title">Hard Allotment</div>
                    <div class="row-item" *ngFor="let channel of availabilityData.channels.GROUPS">
                      {{channel.available}}/{{channel.available + channel.used}}</div>
                    <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div class="custom-usage-container" [style]="{pointerEvents: showUsageHover ? 'auto' : 'none'}" (scroll)="tableScroll('hover')">
            <div [style]="{width: tableWidth}">
              <div class="flex-container">
                <div class="container-offset"></div>
                <div class="usage-hover-container">
                  <div class="custom-usage-hover" [style]="{left: usageHoverLeft, top: usageHoverTop, opacity: showUsageHover ? 1 : 0, pointerEvents: showUsageHover ? 'auto' : 'none'}" (mouseenter)="hoverUsageDirect()" (mouseleave)="hoverUsageLeave()">
                    <div class="hover-content" *ngIf="selectedHoverUsage">
                      <div class="usage-title">
                        <span *ngIf="selectedHoverUsage.reservationCode">{{selectedHoverUsage.customerFirstName}} {{selectedHoverUsage.customerLastName}}</span>
                        <span *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">Hard allotment</span>
                        <span *ngIf="selectedHoverUsage.maintenanceCategory">{{getMaintenanceName(selectedHoverUsage.maintenanceCategory)}}</span>
                      </div>
                      <div class="usage-info">
                        <div class="info-content">
                          <span *ngIf="selectedHoverUsage.reservationCode">{{selectedHoverUsage.reservationCode}}</span>
                          <span *ngIf="selectedHoverUsage.maintenanceCategory">{{ selectedHoverUsage.maintenanceReason }}</span>
                          <span *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">{{ selectedHoverUsage.allotmentName }}</span>
                        </div>
                        <div class="info-payment unpaid" *ngIf="selectedHoverUsage.reservationCode && selectedHoverUsage.remainingAmount > 0 && hasReservationRole">
                          <span class="glyphicon glyphicon-list-alt"></span>
                          <button (click)="openReservationWithPayment(selectedHoverUsage.reservationUuid)">Unpaid</button>
                        </div>
                        <div class="info-payment paid" *ngIf="selectedHoverUsage.reservationCode && selectedHoverUsage.remainingAmount <= 0">
                          <span class="glyphicon glyphicon-ok-circle"></span>
                          <button>Paid</button>
                        </div>
                        <div class="info-wheelchair" *ngIf="selectedHoverUsage.requestedForDisabled">
                          <img src="assets/images/wheelchair.svg">
                          <span>Wheelchair accessible</span>
                        </div>
                      </div>
                      <div class="usage-time">
                        <div class="time-container">
                          <div class="time-item">
                            <div class="item-date">{{getDate(convertDate(selectedHoverUsage.range.start), 'date')}}</div>
                            {{getDate(convertDate(selectedHoverUsage.range.start), 'time')}}
                          </div>
                          <div class="time-arrow">
                            <span class="glyphicon glyphicon-arrow-right"></span>
                          </div>
                          <div class="time-item">
                            <div class="item-date">{{getDate(convertDate(selectedHoverUsage.range.end), 'date')}}</div>
                            {{getDate(convertDate(selectedHoverUsage.range.end), 'time')}}
                          </div>
                        </div>
                      </div>
                      <div class="usage-guest" *ngIf="selectedHoverUsage.reservationCode">
                        <div class="guest-title">Guests: {{selectedHoverUsage.numberOfGuests}}</div>
                        <ng-container *ngFor="let guest of selectedHoverUsage.guestNames">
                          <div class="guest-item" *ngIf="guest !== ' '">{{guest}}</div>
                        </ng-container>
                      </div>
                      <div class="usage-comment" *ngIf="selectedHoverUsage.comments !== null && selectedHoverUsage.comments.length">
                        <div class="comment-title">Comments<a *ngIf="selectedHoverUsage.reservationUuid && hasReservationRole" class="glyphicon glyphicon-new-window" routerLink="/reservations/{{selectedHoverUsage.reservationUuid}}"></a></div>
                        <ng-container *ngFor="let comment of selectedHoverUsage.comments">
                          <div class="comment-item"><b>{{comment.author.name}}:</b> {{comment.data.comment}}</div>
                        </ng-container>
                      </div>
                      <div class="usage-guest" *ngIf="!selectedHoverUsage.reservationCode && selectedHoverUsage.allotmentId">
                        <div class="guest-title">EXP: {{convertDate(selectedHoverUsage.allotmentExpiryDateTime)}}</div>
                      </div>
                    </div>
                    <div class="hover-arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="table-body-container" *ngIf="usageData" (scroll)="tableScroll('body')">
            <div class="table-body" [style]="{width: tableWidth}">
              <div class="body-container">
                <div class="column-room">
                  <div class="room-item" *ngFor="let room of usageData; let roomIndex = index" [attr.roomleftindex]="roomIndex">
                    <ng-template #roomTemplate>
                      <div class="pop-header">
                        <div class="inline-responsive">
                          <div class="header-title">Room Info Management</div>
                        </div>
                        <div class="inline-responsive" *ngIf="!roomInfoStatus.loading">
                          <div class="header-label" *ngIf="hotels.length > 1">{{utils.hotelDisplayByLabel(hotelConfig, hotels, roomInfo.hotelLabel)}}</div>
                          <div class="header-label">{{roomInfo.label}}</div>
                          <div class="header-label">{{ utils.showRoomType(roomInfo.roomType) }}</div>
                          <div class="header-label" *ngIf="roomInfo.building">{{ roomInfo.building }}</div>
                        </div>
                      </div>
                      <div class="center-item" *ngIf="roomInfoStatus.loading">
                        <ng-lottie
                          [options]="options"
                          width="200px"
                          height="200px"
                        ></ng-lottie>
                      </div>
                      <div class="pop-body" *ngIf="!roomInfoStatus.loading">
                        <div class="body-desc-container">
                          <div class="row row-no-gutters">
                            <div class="col-xs-7 col-sm-4">
                              <div class="desc-title">Room description:</div>
                              <div class="desc-text">{{roomInfo.specificInfo}}</div>
                            </div>
                            <div class="col-xs-5 col-sm-5" style="text-align: right;">
                              <button class="btn btn-none-outline-green edit-btn" [ngClass]="{'hide':roomInfoStatus.roomDescription}"
                                (click)="editDescription(roomInfo.specificInfo)"><span
                                  class="glyphicon glyphicon-edit mr-5"></span>Edit</button>
                              <div [ngClass]="{'hide':!roomInfoStatus.roomDescription}">
                                <button class="btn btn-green" [disabled]="!roomInfoStatus.descriptionInput"
                                  (click)="saveDescription(room.roomId)"><span class="glyphicon glyphicon-ok"></span></button>
                                <button class="btn btn-green" (click)="cancelDescription()"><span
                                    class="glyphicon glyphicon-ban-circle"></span></button>
                              </div>
                            </div>
                            <div class="col-xs-12" *ngIf="roomInfoStatus.roomDescription">
                              <input type="text" class="form-control custom-input"
                                [(ngModel)]="roomInfoStatus.descriptionInput"
                                (input)="checkDescription(roomInfo.specificInfo)">
                            </div>
                          </div>
                        </div>
                        <div class="body-capacity-container">
                          <div class="row row-no-gutters">
                            <div class="col-xs-6 col-sm-4">
                              <div class="desc-title"><b>Capacity:</b> {{roomInfo.capacity}}</div>
                            </div>
                            <div class="col-xs-6 col-sm-5" *ngIf="roomInfo.productName != 'Omena Single'" style="text-align: right;">
                              <button class="btn btn-none-outline-green edit-btn" [ngClass]="{'hide':roomInfoStatus.roomCapacity}"
                                (click)="editCapacity(roomInfo.capacity)"><span
                                  class="glyphicon glyphicon-edit mr-5"></span>Edit</button>
                              <div [ngClass]="{'hide':!roomInfoStatus.roomCapacity}">
                                <div class="capacity-dropdown">
                                  <dropdown-menu
                                    [menuList]="capacityList"
                                    [selectedItem]="selectedCapacity"
                                    [fullWidth]="true"
                                    (selectItem)="selectCapacity($event)"
                                  ></dropdown-menu>
                                </div>
                                <button type="button" class="btn btn-green" (click)="saveCapacity(room.roomId, roomIndex)"><span
                                    class="glyphicon glyphicon-ok"></span></button>
                                <button class="btn btn-green" (click)="cancelCapacity()"><span
                                    class="glyphicon glyphicon-ban-circle"></span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="body-allocation-container">
                          <div class="allocation-item">
                            <div class="alloc-title"><b>Room allocation:</b></div>
                          </div>
                          <div class="allocation-item">
                            <div class="normal-checkbox">
                              <input type="checkbox" [checked]="roomInfo.isDePrioritized" (change)="toggleDePrioritizeRoom(roomInfo.roomId, $event)" >
                              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                              <div class="checkbox-label alloc-title"><b>De-prioritize this room</b></div>
                            </div>
                          </div>
                        </div>
                        <ng-container *ngIf="roomInfo.historicalLogs.length > 0">
                          <div class="text-button">History</div>
                          <div class="custom-table history-table">
                            <table class="custom-table">
                              <colgroup>
                                <col style="width: 140px">
                                <col>
                                <col style="width: 300px">
                              </colgroup>
                              <thead>
                                <th>Time</th>
                                <th>Author</th>
                                <th>Description</th>
                              </thead>
                              <tbody>
                                <tr *ngFor="let log of roomInfo.historicalLogs">
                                  <td>{{convertDate(log.created)}}</td>
                                  <td>{{log.author}}</td>
                                  <td>{{getLogDescription(log.operation, log.oldValues, log.newValues)}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </ng-container>
                      </div>
                    </ng-template>
                    <ng-template #cleanTemplate>
                      <div class="btn-status-container">
                        <div class="row no-gutters">
                          <div class="col-xs-6" (click)="cleanPop.hide()">
                            <button class="btn-status btn-dirty" [ngClass]="{'active': !room.clean}"
                              (click)="markCleanStatus(false, room.roomId, roomIndex, false)">
                              <span class="glyphicon glyphicon-exclamation-sign"></span> Dirty
                            </button>
                          </div>
                          <div class="col-xs-6" (click)="cleanPop.hide()">
                            <button class="btn-status btn-clean" [ngClass]="{'active': room.clean}"
                              (click)="markCleanStatus(true, room.roomId, roomIndex, false)">
                              <span class="glyphicon glyphicon-ok-sign"></span> Clean
                            </button>
                          </div>
                          <div class="col-xs-12">
                            <button class="btn btn-outline-green full-width wrap-text" (click)="createMaintenance(roomIndex)"><span class="glyphicon glyphicon-plus"></span> Create Maintenance</button>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngIf="cleanEventData.length > 0">
                        <button class="text-button" (click)="cleanEvent.show = !cleanEvent.show">Cleaning history</button>
                        <div class="custom-table clean-table" *ngIf="cleanEvent.show">
                          <table>
                            <thead>
                              <th class="col-sm-3 col-md-3">Time</th>
                              <th class="col-sm-3 col-md-3">User</th>
                              <th class="col-sm-3 col-md-3">Status</th>
                              <th class="col-sm-3 col-md-3">Source</th>
                            </thead>
                            <tbody>
                              <tr *ngFor="let event of cleanEventData">
                                <td class="col-sm-3 col-md-3">{{convertDate(event.created)}}</td>
                                <td class="col-sm-3 col-md-3" tooltip="{{event.sourceReference}}" placement="right">{{ truncateEmail(event.sourceReference) }}</td>
                                <td class="col-sm-3 col-md-3">{{event.status | titlecase}}</td>
                                <td class="col-sm-3 col-md-3">{{event.source | cleanSource}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="table-pagination" *ngIf="cleanEvent.totalItem > 2 && cleanEvent.show">
                          <span [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('first', room.roomId)">First</span>
                          <span [ngClass]="{'hide':cleanEvent.current == 1}" (click)="singlePageChange('prev', room.roomId)">Prev</span>
                          <span *ngIf="cleanEvent.hasDotLeft">...</span>
                          <span *ngFor="let page of cleanEvent.pageArray" (click)="changePage(page, room.roomId)"
                            [ngClass]="{'active':cleanEvent.current == page}">{{page}}</span>
                          <span *ngIf="cleanEvent.hasDot">...</span>
                          <span [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('next', room.roomId)">Next</span>
                          <span [ngClass]="{'hide':cleanEvent.current == cleanEvent.finalPage}" (click)="singlePageChange('last', room.roomId)">Last</span>
                        </div>
                      </ng-container>
                    </ng-template>
                    <div class="item-room-number" [outsideClick]="true"
                      *ngIf="hasRoomInfoRole"
                      [popover]="roomTemplate" [adaptivePosition]="false" placement="right" containerClass="room-popover"
                      container="body" (click)="getRoomInfo(room.roomId)">
                      <span>{{room.label}}</span>
                    </div>
                    <div class="item-room-number" *ngIf="!hasRoomInfoRole">
                      <span>{{room.label}}</span>
                    </div>
                    <div class="item-capacity" tooltip="{{utils.showRoomTitle(room.productName)}}" [adaptivePosition]="false" placement="right">
                      <img src="assets/images/bed-icon.svg">
                      {{room.capacity}}
                    </div>
                    <button class="item-mark-room" [outsideClick]="true" [popover]="cleanTemplate" [adaptivePosition]="true" placement="right" containerClass="clean-popover" #cleanPop="bs-popover" container="body" (click)="getCleanEvent(room.roomId)"><span
                        class="glyphicon"
                        [ngClass]="[room.clean ? 'ok-status glyphicon-ok-sign' : 'dirty-status glyphicon-exclamation-sign']"></span></button>
                    <div class="item-wheelchair">
                      <img src="assets/images/wheelchair.svg" *ngIf="room.forDisabled">
                    </div>
                  </div>
                </div>
                <div class="usage-area-border" *ngIf="usageData">
                  <div class="usage-past" [style]="{width: '1px', left: pastWidth + '%'}"></div>
                  <div class="border-item" *ngFor="let date of allDates"></div>
                  <div class="usage-item">
                    <div class="item-row" *ngFor="let room of usageData; let roomIndex = index" [attr.roomindex]="roomIndex" (dragenter)="usageDragenter($event, roomIndex)">
                      <div class="wrapper-square-item">
                        <div class="square-container" (mouseup)="mouseUpResizeShortcut(room, roomIndex, $event)" (mousedown)="mouseDownResizeShortcut(i, $event)" (mousemove)="mouseMoveResizeShortcut($event)" *ngFor="let date of allDates; let i = index;">
                          <div class="square-item" *ngIf="i + 1 < allDates.length" [style]="{left: percentLeftShortcut + '%'}">
                            <div class="shortcut" (click)="selectedShortcut(roomIndex, $event)" [style]="{minWidth: percentShortcutLength + '%'}">
                              <div class="shortcut-action" [ngClass]="[shortcutTopPosition ? 'top' : 'bottom']">
                                <button class="btn btn-red" (click)="removeShortcut($event)"><span class="glyphicon glyphicon-ban-circle"></span></button>
                                <button class="btn btn-green" (click)="openShortcutModal()"><span class="glyphicon glyphicon-check"></span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-container *ngFor="let usage of room.usages; let usageIndex = index">
                        <ng-template #usageHover>
                          <div class="usage-title">
                            <span *ngIf="usage.reservationCode">{{usage.customerFirstName}} {{usage.customerLastName}}</span>
                            <span *ngIf="!usage.reservationCode && usage.allotmentId">Hard allotment</span>
                            <span *ngIf="usage.maintenanceCategory">{{getMaintenanceName(usage.maintenanceCategory)}}</span>
                          </div>
                          <div class="usage-info">
                            <div class="info-content">
                              <span *ngIf="usage.reservationCode">{{usage.reservationCode}}</span>
                              <span *ngIf="usage.maintenanceCategory">{{ usage.maintenanceReason }}</span>
                              <span *ngIf="!usage.reservationCode && usage.allotmentId">{{ usage.allotmentName }}</span>
                            </div>
                            <div class="info-payment unpaid" *ngIf="usage.reservationCode && usage.remainingAmount > 0 && hasReservationRole">
                              <span class="glyphicon glyphicon-list-alt"></span>
                              <button (click)="openReservationWithPayment(usage.reservationUuid)">Unpaid</button>
                            </div>
                            <div class="info-payment paid" *ngIf="usage.reservationCode && usage.remainingAmount <= 0">
                              <span class="glyphicon glyphicon-ok-circle"></span>
                              <button>Paid</button>
                            </div>
                          </div>
                          <div class="usage-time">
                            <div class="time-container">
                              <div class="time-item">
                                <div class="item-date">{{getDate(convertDate(usage.range.start), 'date')}}</div>
                                {{getDate(convertDate(usage.range.start), 'time')}}
                              </div>
                              <div class="time-arrow">
                                <span class="glyphicon glyphicon-arrow-right"></span>
                              </div>
                              <div class="time-item">
                                <div class="item-date">{{getDate(convertDate(usage.range.end), 'date')}}</div>
                                {{getDate(convertDate(usage.range.end), 'time')}}
                              </div>
                            </div>
                          </div>
                          <div class="usage-guest" *ngIf="usage.reservationCode">
                            <div class="guest-title">Guests: {{usage.numberOfGuests}}</div>
                            <ng-container *ngFor="let guest of usage.guestNames">
                              <div class="guest-item" *ngIf="guest !== ' '">{{guest}}</div>
                            </ng-container>
                          </div>
                          <div class="usage-guest" *ngIf="!usage.reservationCode && usage.allotmentId">
                            <div class="guest-title">EXP: {{convertDate(usage.allotmentExpiryDateTime)}}</div>
                          </div>
                        </ng-template>
                        <div class="usage-row" [class]="usage.usageDisplay.usageColorClass"
                        [style]="usage.usageDisplay.usageLength" [ngClass]="{
                          'selected': selectedUsage == (usage.reservationUuid ? usage.reservationUuid : (usage.maintenanceReservationId ? usage.usageDisplay.usageColorClass + usage.maintenanceReservationId : usage.usageDisplay.usageColorClass + usage.allotmentId)),
                          'cleaning': usage.maintenanceCategory === 'TO_CLEAN'
                        }"
                        (click)="highlightUsage(roomIndex, usageIndex)"
                        (mouseenter)="hoverUsage(roomIndex, usageIndex)"
                        (mouseleave)="hoverUsageLeave()"
                        [draggable]="usage.usageDisplay.draggable"
                        (mousedown)="usage.usageDisplay.draggable"
                        (dragstart)="usageDragstart(roomIndex, usageIndex, $event)"
                        (dragend)="usageDrop($event)" [attr.usageid]="usage.usageId">
                          <div class="usage-eci" [style]="usage.usageDisplay.eciLength"></div>
                          <div class="usage-lco" [style]="usage.usageDisplay.lcoLength"></div>
        
                          <a *ngIf="usage.reservationUuid && hasReservationRole" class="glyphicon glyphicon-new-window" routerLink="/reservations/{{usage.reservationUuid}}"></a>
                          <span *ngIf="usage.reservationUuid && !hasReservationRole" class="glyphicon glyphicon-new-window"></span>
        
                          <a *ngIf="usage.maintenanceReservationId && hasMaintenanceRole" class="glyphicon glyphicon-new-window" routerLink="/maintenance/{{usage.maintenanceReservationId}}"></a>
                          <span *ngIf="usage.maintenanceReservationId && !hasMaintenanceRole" class="glyphicon glyphicon-new-window" (click)="openWarningModal()"></span>
        
                          <a *ngIf="usage.allotmentId && usage.maintenanceReservationId === null && usage.roomRequestId === null && hasAllotRole" class="glyphicon glyphicon-new-window" routerLink="/allotment/{{usage.allotmentId}}"></a>
                          <span *ngIf="usage.allotmentId && usage.maintenanceReservationId === null && usage.roomRequestId === null && !hasAllotRole" class="glyphicon glyphicon-new-window"></span>
        
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="last-item" [style]="{flexBasis: lastItemWidth + 'px'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="center-item" *ngIf="tableLoading">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
</div>
<app-footer></app-footer>

<!--Edit channel modal-->
<ng-template #editChannelModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Edit Channels</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeChannelModal()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-6 col-sm-3" *ngIf="hotels.length > 1">
        <label for="select-report">Search by hotel</label>
        <dropdown-menu
          [menuList]="hotelList"
          [selectedItem]="selectedHotelItem"
          [fullWidth]="true"
          (selectItem)="selectHotel($event, true)"
        ></dropdown-menu>
      </div>
      <div class="col-xs-6 col-sm-3">
        <label for="select-report">Room type</label>
        <dropdown-menu
          [menuList]="roomTypeList"
          [selectedItem]="selectedRoomType"
          [fullWidth]="true"
          (selectItem)="selectRoomType($event)"
        ></dropdown-menu>
      </div>
      <div class="col-xs-6 col-sm-3">
        <label>Start date</label>
        <div class="datepicker-input">
          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
            [(bsValue)]="modalStartDate" #startDateModal="bsDatepicker"
            (bsValueChange)="modalDateChange('startDate')"
            [dateCustomClasses]="datepickerCustomModal"
            [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
          <button type="button" class="datepicker-btn" (click)="startDateModal.toggle()">
            <span class="glyphicon glyphicon-calendar"></span>
          </button>
        </div>
      </div>
      <div class="col-xs-6 col-sm-3">
        <label>End date</label>
        <div class="datepicker-input">
          <input type="text" placeholder="Select end date" class="form-control" bsDatepicker appEditDate
            [(bsValue)]="modalEndDate" #endDateModal="bsDatepicker"
            (bsValueChange)="modalDateChange('endDate')"
            [dateCustomClasses]="datepickerCustomModal"
            [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
          <button type="button" class="datepicker-btn" (click)="endDateModal.toggle()">
            <span class="glyphicon glyphicon-calendar"></span>
          </button>
        </div>
      </div>
    </div>

    <div class="center-item" *ngIf="modalLoading">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>

    <ng-container *ngIf="!modalLoading">
      <div class="scrolling-table" id="table1" (scroll)="modalTableScroll('table1')" style="margin-top: 20px;">
        <table>
          <thead>
            <th class="title">Summary</th>
            <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
              <div style="width: 70px">{{day.fullDate}}</div>
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="title"><span tooltip="= total (this hotel) - maintenance - reserved" placement="top">Available</span></td>
              <td *ngFor="let reserved of modalData.reserved" [ngClass]="{'weekend': checkWeekend(reserved.date)}">
                {{reserved.totalAvailableRoom}}</td>
            </tr>
            <tr>
              <td class="title"><span tooltip="= used (nelson) + used (booking.com) + used (expedia) + used (legacy)" placement="top">Reserved</span></td>
              <td *ngFor="let reserved of modalData.reserved" [ngClass]="{'weekend': checkWeekend(reserved.date)}">
                {{reserved.totalReservedRoom}}</td>
            </tr>
            <tr>
              <td class="title"><span tooltip="The number of rooms that under maintenance" placement="top">Maintenance</span></td>
              <td *ngFor="let maintenance of modalData.maintenance.dates"
                [ngClass]="{'weekend': checkWeekend(maintenance.date)}">{{maintenance.used}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="scrolling-table" id="table2" (scroll)="modalTableScroll('table2')">
        <table>
          <thead>
            <th class="title">
              <div style="width: 120px">External</div>
            </th>
            <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
              <div style="width: 70px">{{day.fullDate}}</div>
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="title"><span tooltip="The number of rooms that can be sold by Booking.com and Expedia" placement="top">Total</span></td>
              <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                [ngClass]="{'weekend': checkWeekend(buffer.date), 'sold-out': calculateTable('externalSoldOut', buffer.buffer, bufferIndex)}">
                <div class="custom-input-number">
                  <input type="number" class="form-control" [ngClass]="{'modified-by-system': buffer.modified}"
                  [(ngModel)]="buffer.buffer" (change)="channelEdit($event, bufferIndex, buffer.date)">
                  <div class="arrow-input">
                    <div class="arr-incr"><span (click)="customChannelEdit('increment',bufferIndex, buffer.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                    <div class="arr-desc"><span (click)="customChannelEdit('decrement',bufferIndex, buffer.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  </div>
                </div>
              </td>
            </tr>
            <tr *ngIf="hotelConfig.MUIfeature.OTAServices.includes('BOOKINGCOM')">
              <td class="title"><span tooltip="The number of rooms that have been sold on Booking.com" placement="top">Booking.com</span></td>
              <td *ngFor="let item of modalData.channels.BOOKINGCOM" [ngClass]="{'weekend': checkWeekend(item.date)}">
                {{item.used}}</td>
            </tr>
            <tr *ngIf="hotelConfig.MUIfeature.OTAServices.includes('EXPEDIA')">
              <td class="title"><span tooltip="The number of rooms that have been sold on Expedia" placement="top">Expedia</span></td>
              <td *ngFor="let item of modalData.channels.EXPEDIA" [ngClass]="{'weekend': checkWeekend(item.date)}">
                {{item.used}}</td>
            </tr>
            <tr>
              <td class="title"><span tooltip="= total - used (Booking.com) - used (expedia)" placement="top">Available</span></td>
              <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('externalAvailable', buffer.buffer, bufferIndex) < 0}"
                [attr.id]="'ex-'+buffer.date">{{calculateTable('externalAvailable', buffer.buffer, bufferIndex)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="hotel-desc" style="margin: 20px 0">External channel threshold: {{modalData.thresholdMap[modalSelectedType]}}</div>

      <div class="scrolling-table" id="table3" (scroll)="modalTableScroll('table3')">
        <table>
          <thead>
            <th class="title">
              <div style="width: 120px">Direct</div>
            </th>
            <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
              <div style="width: 70px">{{day.fullDate}}</div>
            </th>
          </thead>
          <tbody>
            <tr>
              <td class="title"><span tooltip="The number of rooms that have been sold by this channel" placement="top">Reserved</span></td>
              <td *ngFor="let item of modalData.channels.NELSON" [ngClass]="{'weekend': checkWeekend(item.date)}">
                {{item.used}}</td>
            </tr>
            <tr>
              <td class="title"><span tooltip="= total - used" placement="top">Available</span></td>
              <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('nelsonAvailable', buffer.buffer, bufferIndex) < 0}"
                [attr.id]="'ex-'+buffer.date">{{calculateTable('nelsonAvailable', buffer.buffer, bufferIndex)}}</td>
            </tr>
            <tr>
              <td class="title"><span tooltip="= available (this hotel) - total (external) - maintenance" placement="top">Total</span></td>
              <td *ngFor="let buffer of modalData.channelAvailabilityBuffers; let bufferIndex = index"
                [ngClass]="{'weekend': checkWeekend(buffer.date), 'invalid-amount': calculateTable('nelsonTotal', buffer.buffer, bufferIndex) < 0}"
                [attr.id]="'ex-'+buffer.date">{{calculateTable('nelsonTotal', buffer.buffer, bufferIndex)}}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-xs-7 col-sm-2" style="margin-bottom: 15px">
          <label style="margin:0">Set availability for multiple days</label>
        </div>
        <div class="col-xs-5 col-sm-2" style="margin-bottom: 15px">
          <div class="custom-input-number">
            <input type="number" class="form-input custom-input" [(ngModel)]="modalSetBuffer">
            <div class="arrow-input">
              <div class="arr-incr"><span (click)="customSetBuffer('increment')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
              <div class="arr-desc"><span (click)="customSetBuffer('decrement')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
            </div>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 15px">
          <div class="datepicker-input">
            <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
              [(bsValue)]="modalSetStartDate" #startDateSetModal="bsDatepicker" (blur)="validateModalDate()"
              (bsValueChange)="modalDateChange('setStartDate')"
              [dateCustomClasses]="datepickerCustomModalSet"
              [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true, customTodayClass:'datepicker-today' }">
            <button type="button" class="datepicker-btn" (click)="startDateSetModal.toggle()">
              <span class="glyphicon glyphicon-calendar"></span>
            </button>
          </div>
        </div>
        <div class="col-xs-6 col-sm-3" style="margin-bottom: 15px">
          <div class="datepicker-input">
            <input type="text" placeholder="Select end date" class="form-control" bsDatepicker appEditDate
              [(bsValue)]="modalSetEndDate" #endDateSetModal="bsDatepicker" (blur)="validateModalDate()"
              (bsValueChange)="modalDateChange('setEndDate')"
              [dateCustomClasses]="datepickerCustomModalSet"
              [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true, customTodayClass:'datepicker-today' }">
            <button type="button" class="datepicker-btn" (click)="endDateSetModal.toggle()">
              <span class="glyphicon glyphicon-calendar"></span>
            </button>
          </div>
        </div>
        <div class="col-xs-12 col-sm-2">
          <button class="btn btn-green btn-big full-width" (click)="setMultipleBuffer()">Apply</button>
        </div>
      </div>
    </ng-container>

  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="closeChannelModal()">Close</button>
    <ng-container *ngIf="!modalLoading">
      <button type="submit" class="btn btn-outline-green" style="margin-right: 10px;" [disabled]="disabledBtnWhileCallingAPI" (click)="saveBufferChange(true)">Save and close</button>
      <button type="submit" class="btn btn-green" [disabled]="disabledBtnWhileCallingAPI" (click)="saveBufferChange()">Save</button>
    </ng-container>
  </div>
</ng-template>

<!--Edit ECI/LCO modal-->
<ng-template #editEciLcoModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Early check-in / Late check-out</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-xs-12 col-sm-4" *ngIf="hotels.length > 1">
        <label for="select-report">Search by hotel</label>
        <dropdown-menu
          [menuList]="hotelList"
          [selectedItem]="selectedHotelItem"
          [fullWidth]="true"
          (selectItem)="selectHotel($event, true)"
        ></dropdown-menu>
      </div>
      <div class="col-xs-6 col-sm-4">
        <label>Start date</label>
        <div class="datepicker-input">
          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
            [(bsValue)]="modalStartDate" #startDateModal="bsDatepicker"
            (bsValueChange)="modalDateChange('startDate')"
            [dateCustomClasses]="datepickerCustomModal"
            [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
          <button type="button" class="datepicker-btn" (click)="startDateModal.toggle()">
            <span class="glyphicon glyphicon-calendar"></span>
          </button>
        </div>
      </div>
      <div class="col-xs-6 col-sm-4">
        <label>End date</label>
        <div class="datepicker-input">
          <input type="text" placeholder="Select end date" class="form-control" bsDatepicker appEditDate
            [(bsValue)]="modalEndDate" #endDateModal="bsDatepicker"
            (bsValueChange)="modalDateChange('endDate')"
            [dateCustomClasses]="datepickerCustomModal"
            [bsConfig]="{isAnimated: true, containerClass:'theme-red end-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
          <button type="button" class="datepicker-btn" (click)="endDateModal.toggle()">
            <span class="glyphicon glyphicon-calendar"></span>
          </button>
        </div>
      </div>
    </div>
    <h2>{{selectedHotelInfo.name}}</h2>

    <div class="center-item" *ngIf="modalLoading">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>

    <ng-container *ngIf="!modalLoading">
      <div class="hotel-desc" style="margin-bottom: 20px">Additional room for early check-in:
        {{modalData.additionalEci.amount}} ({{modalData.additionalEci.date | date:'dd.MM'}})</div>

      <div class="table-container">
        <div class="table-left">
          <div class="table-item date-title">ECI/LCO</div>
          <div class="table-item">Guarantee</div>
        </div>
        <div class="scrolling-table eci-table" id="table1" (scroll)="modalTableScroll('table1')">
          <table>
            <thead>
              <th *ngFor="let day of modalDate" [ngClass]="{'weekend': day.day == 'Sat' || day.day == 'Sun'}">
                <div>{{day.fullDate}}</div>
              </th>
            </thead>
            <tbody>
              <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                [attr.id]="'ecilco-'+info.date">
                <div>
                  {{info.eciInfos.pm200.guaranteedBuffer +
                  info.lcoInfos.pm200.guaranteedBuffer}}/{{modalData.commonBuffer}}
                </div>
              </td>
            </tbody>
          </table>
        </div>
      </div>

      <div class="table-container">
        <div class="table-left">
          <div class="eci-flex">
            <div class="eci">
              14:00 ECI
            </div>
            <div class="right-half">
              <div class="table-item">Guarantee</div>
              <div class="table-item">Used</div>
              <div class="table-item">Available</div>
              <div class="table-item">Open</div>
            </div>
          </div>
        </div>
        <div class="scrolling-table eci-table" id="table2" (scroll)="modalTableScroll('table2')">
          <table>
            <tbody>
              <tr>
                <td *ngFor="let info of modalData.infos; let infoIndex = index"
                  [ngClass]="{'weekend': checkWeekend(info.date)}" [attr.id]="'eci-'+info.date">
                  <div *ngIf="!hasEciLcoEditRole">{{info.eciInfos.pm200.guaranteedBuffer}}</div>
                  <div class="custom-input-number" *ngIf="hasEciLcoEditRole">
                    <input type="number" class="form-control" [(ngModel)]="info.eciInfos.pm200.guaranteedBuffer"
                      (change)="eciEdit($event, infoIndex, info.date)">
                      <div class="arrow-input">
                        <div class="arr-incr"><span (click)="customEciEdit('increment', infoIndex, info.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                        <div class="arr-desc"><span (click)="customEciEdit('decrement', infoIndex, info.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                      </div>
                  </div>
                  
                </td>
              </tr>
              <tr>
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}">
                  <div>{{info.eciInfos.pm200.used}}</div>
                </td>
              </tr>
              <tr>
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  [attr.id]="'eci-left-'+info.date">
                  <div>{{getMaxNumber(info.eciInfos)}}</div>
                </td>
              </tr>
              <tr *ngIf="!hasEciLcoEditRole">
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  [attr.id]="'eci-enable-'+info.date">
                  <div *ngIf="info.eciInfos.pm200.enabled"><span class="glyphicon glyphicon-ok"></span></div>
                  <div *ngIf="!info.eciInfos.pm200.enabled"><span class="glyphicon glyphicon-remove"></span></div>
                </td>
              </tr>
              <tr class="clickable" *ngIf="hasEciLcoEditRole">
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  (click)="info.eciInfos.pm200.enabled = !info.eciInfos.pm200.enabled"
                  [attr.id]="'eci-enable-'+info.date">
                  <div *ngIf="info.eciInfos.pm200.enabled"><span class="glyphicon glyphicon-ok"></span></div>
                  <div *ngIf="!info.eciInfos.pm200.enabled"><span class="glyphicon glyphicon-remove"></span></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="table-container">
        <div class="table-left">
          <div class="eci-flex">
            <div class="eci lco">
              14:00 LCO
            </div>
            <div class="right-half">
              <div class="table-item">Guarantee</div>
              <div class="table-item">Used</div>
              <div class="table-item">Available</div>
              <div class="table-item">Open</div>
            </div>
          </div>
        </div>
        <div class="scrolling-table eci-table" id="table3" (scroll)="modalTableScroll('table3')">
          <table>
            <tbody>
              <tr>
                <td *ngFor="let info of modalData.infos; let infoIndex = index"
                  [ngClass]="{'weekend': checkWeekend(info.date)}" [attr.id]="'lco-'+info.date">
                  <div *ngIf="!hasEciLcoEditRole">{{info.lcoInfos.pm200.guaranteedBuffer}}</div>
                  <div class="custom-input-number" *ngIf="hasEciLcoEditRole">
                    <input type="number" class="form-control" [(ngModel)]="info.lcoInfos.pm200.guaranteedBuffer"
                      (change)="lcoEdit($event, infoIndex, info.date)">
                      <div class="arrow-input">
                        <div class="arr-incr"><span (click)="customLcoEdit('increment', infoIndex, info.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                        <div class="arr-desc"><span (click)="customLcoEdit('decrement', infoIndex, info.date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                      </div>
                  </div>
                  
                </td>
              </tr>
              <tr>
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}">
                  <div>{{info.lcoInfos.pm200.used}}</div>
                </td>
              </tr>
              <tr>
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  [attr.id]="'lco-left-'+info.date">
                  <div>{{getMaxNumber(info.lcoInfos)}}</div>
                </td>
              </tr>
              <tr *ngIf="!hasEciLcoEditRole">
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  [attr.id]="'lco-enable-'+info.date">
                  <div *ngIf="info.lcoInfos.pm200.enabled"><span class="glyphicon glyphicon-ok"></span></div>
                  <div *ngIf="!info.lcoInfos.pm200.enabled"><span class="glyphicon glyphicon-remove"></span></div>
                </td>
              </tr>
              <tr class="clickable" *ngIf="hasEciLcoEditRole">
                <td *ngFor="let info of modalData.infos" [ngClass]="{'weekend': checkWeekend(info.date)}"
                  (click)="info.lcoInfos.pm200.enabled = !info.lcoInfos.pm200.enabled"
                  [attr.id]="'lco-enable-'+info.date">
                  <div *ngIf="info.lcoInfos.pm200.enabled"><span class="glyphicon glyphicon-ok"></span></div>
                  <div *ngIf="!info.lcoInfos.pm200.enabled"><span class="glyphicon glyphicon-remove"></span></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ng-container *ngIf="hasEciLcoEditRole">
        <label>Action on multiple days</label>
        <div class="row">
          <div class="col-xs-6 col-sm-2" style="margin-bottom: 15px;">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="type" [checked]="eciAction.type == 'eci'" (click)="eciAction.type = 'eci'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">ECI</div>
            </div>
            <div class="normal-checkbox radio-style">
              <input type="radio" name="type" [checked]="eciAction.type == 'lco'" (click)="eciAction.type = 'lco'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">LCO</div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-3 col-md-2" style="margin-bottom: 15px;">
            <div class="normal-checkbox radio-style">
              <input type="radio" name="set" [checked]="eciAction.set == 'value'" (click)="eciAction.set = 'value'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Set to value</div>
            </div>
            <div class="custom-input-number"  style="margin-bottom: 10px">
              <input type="number" class="form-input custom-input"
              [(ngModel)]="eciAction.setValue">
              <div class="arrow-input">
                <div class="arr-incr"><span (click)="customSetBufferEciLco('increment')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                <div class="arr-desc"><span (click)="customSetBufferEciLco('decrement')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
              </div>
            </div>
        
            <div class="normal-checkbox radio-style">
              <input type="radio" name="set" [checked]="eciAction.set == 'close'" (click)="eciAction.set = 'close'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Set to close</div>
            </div>
            <div class="normal-checkbox radio-style">
              <input type="radio" name="set" [checked]="eciAction.set == 'open'" (click)="eciAction.set = 'open'">
              <div class="checkbox-box">
                <div class="radio-icon"></div>
              </div>
              <div class="checkbox-label">Set to open</div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-3" style="margin-bottom: 15px;">
            <label>Start date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                [(bsValue)]="modalSetStartDate" #startDateSetModal="bsDatepicker" (blur)="validateModalDate()"
                (bsValueChange)="modalDateChange('setStartDate')"
                [dateCustomClasses]="datepickerCustomModalSet"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true, customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="startDateSetModal.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
            <label style="margin-top: 20px">End date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Select end date" class="form-control" bsDatepicker appEditDate
                [(bsValue)]="modalSetEndDate" #endDateSetModal="bsDatepicker" (blur)="validateModalDate()"
                (bsValueChange)="modalDateChange('setEndDate')"
                [dateCustomClasses]="datepickerCustomModalSet"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY', adaptivePosition: true, customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" (click)="endDateSetModal.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-6 col-sm-2" style="margin-bottom: 15px;">
            <label>Weekdays</label>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Mon" (click)="toggleEciWeekday('Mon')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">MON</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Tue" (click)="toggleEciWeekday('Tue')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">TUE</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Wed" (click)="toggleEciWeekday('Wed')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">WED</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Thu" (click)="toggleEciWeekday('Thu')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">THU</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Fri" (click)="toggleEciWeekday('Fri')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">FRI</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Sat" (click)="toggleEciWeekday('Sat')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">SAT</div>
            </div>
            <div class="normal-checkbox">
              <input type="checkbox" [checked]="eciAction.weekdays.Sun" (click)="toggleEciWeekday('Sun')">
              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
              <div class="checkbox-label">SUN</div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-2" style="margin-bottom: 15px;">
            <button class="btn btn-green btn-big full-width" (click)="applyChange()">Apply</button>
          </div>
        </div>
      </ng-container>

    </ng-container>

  </div>
  <div class="modal-footer">
    <ng-container *ngIf="!modalLoading && hasEciLcoEditRole">
      <button type="submit" class="btn btn-green" (click)="saveEciChange()" [disabled]="disabledBtnWhileCallingAPI">SAVE</button>
      <button type="submit" class="btn btn-green" (click)="saveEciChange(true)" [disabled]="disabledBtnWhileCallingAPI">SAVE & CLOSE</button>
    </ng-container>
    <button type="submit" class="btn btn-green" (click)="closeChannelModal()">CLOSE</button>
  </div>
</ng-template>

<!--Dirty change room-->
<ng-template #dirtyChangeRoomModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Move to dirty room</div>
    <div class="error-desc">Room <b>{{dirtyRoomList}}</b> is dirty, are you sure you want to move to this room?</div>

    <button type="button" class="custom-button gray" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button" (click)="startMoveRoom('change')">Yes</button>
  </div>
</ng-template>

<!--Dirty swap room-->
<ng-template #dirtySwapRoomModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Swap to dirty room</div>
    <div class="error-desc">Room <b>{{dirtyRoomList}}</b> are dirty, are you sure you want to swap the rooms?</div>

    <button type="button" class="custom-button gray" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button" (click)="startMoveRoom('swap')">Yes</button>
  </div>
</ng-template>

<!--Swap room confirm-->
<ng-template #swapRoomModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Swap room</div>
    <div *ngIf="!isNotifySwapRoomAcrossRoomTypes" class="error-desc">Are you sure you want to change room <b>{{swapRoomAlert.roomFrom}}</b> to room
      <b>{{swapRoomAlert.roomTo}}</b> (and room <b>{{swapRoomAlert.roomTo}}</b> to room
      <b>{{swapRoomAlert.roomFrom}}</b>)?</div>
    <div *ngIf="isNotifySwapRoomAcrossRoomTypes" class="error-desc">The room price will be changed after swapping <br/>
      <b>{{swapRoomAlert.roomFrom}}</b> to room
      <b>{{swapRoomAlert.roomTo}}</b> (and room <b>{{swapRoomAlert.roomTo}}</b> to room
      <b>{{swapRoomAlert.roomFrom}}</b>):<br/>
      <b *ngIf="swapRoomAlert.balanceRoomFrom != 0.00">- Room {{swapRoomAlert.roomFrom}} will be {{ swapRoomAlert.balanceRoomFrom > 0 ? 'increased' : 'decreased'}} {{swapRoomAlert.balanceRoomFrom}} {{utils.getCurrency(currency)}}</b> <br/>
      <b *ngIf="swapRoomAlert.balanceRoomTo != 0.00">- Room {{swapRoomAlert.roomTo}} will be {{ swapRoomAlert.balanceRoomTo > 0 ? 'increased' : 'decreased'}} {{swapRoomAlert.balanceRoomTo}} {{utils.getCurrency(currency)}}</b>
    </div>

    <button type="button" class="custom-button gray" (click)="modalRef.hide()">Cancel</button>
    <button *ngIf="isNotifySwapRoomAcrossRoomTypes" type="button" class="custom-button" (click)="swapCheckDirty(true)">Override</button>
    <button *ngIf="isNotifySwapRoomAcrossRoomTypes" type="button" class="custom-button" (click)="swapCheckDirty(false)">Accept</button>
    <button *ngIf="!isNotifySwapRoomAcrossRoomTypes"type="button" class="custom-button" (click)="swapCheckDirty()">Save</button>
  </div>
</ng-template>

<!-- Marking room as clean that has already been marked clean (or simultaneous 'clean' marking) -->
<ng-template #setStatusRoomUsageModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Change room usage status</div>
    <div class="error-desc">Room already marked {{ capitalizeFirstLetter(currentRoomUsageStatus) }}. Do you want to mark it {{ capitalizeFirstLetter(currentRoomUsageStatus) }} again?</div>

    <button type="button" class="custom-button gray" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button" (click)="changeRoomUsageStatus()">{{ capitalizeFirstLetter(currentRoomUsageStatus) }}</button>
  </div>
</ng-template>

<!--Change room confirm-->
<ng-template #changeRoomModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Change room</div>
    <div class="error-desc">New room rate total <b>{{ changeRoomAlert.balanceRoom }} {{utils.getCurrency(currency)}}</b>.<br/>Are you sure you want to change room?</div>

    <button type="button" class="custom-button gray" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="custom-button" (click)="actionChangeRoomAcrossRoomTypes(true)">Override</button>
    <button type="button" class="custom-button" (click)="actionChangeRoomAcrossRoomTypes(false)">Accept</button>
  </div>
</ng-template>

<!--Failed re-allocation-->
<ng-template #failedReAllocationModal>
  <div class="modal-body text-center">
    <div class="error-icon red"><span class="glyphicon glyphicon-exclamation-sign"></span></div>
    <div class="error-title">Room re-allocation failed</div>
    <div class="error-desc">
      <p *ngFor="let failedData of failedReAllocationData">Re-allocation failed for booking {{failedData.reservationCode}} (this is reservation code) on {{getDateRange(failedData)}}. Please re-allocate this booking manually.</p>
    </div>

    <button type="button" class="custom-button" (click)="modalRef.hide()">Close</button>
  </div>
</ng-template>

<!--Customer edit modal-->
<ng-template #shortcutModal >
  <div class="modal-header">
    <div class="modal-title pull-left">Room {{roomSelected.join(',')}}</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <p class="new-date">New dates selected <span class="date-selected">{{labelRangeDateSelected()}}</span></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="button" class="btn btn-yellow btn-big" *ngIf="hasMaintenanceRole" (click)="modalRef.hide();shortcutCreateMaintenance()"
      >Create maintenance</button>
    <button type="button" (click)="bookNow()" *ngIf="hasReservationEditRole" [disabled]="disableBooknow" class="btn btn-green" 
      >Book now</button>
  </div>
</ng-template>

<!--Customer edit modal-->
<ng-template #shortcutErrorModal >
  <div class="modal-header">
    <div class="modal-title">Error</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <p class="new-date">{{shortcutErrorMess}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Ok</button>
  </div>
</ng-template>

<!--warning modal-->
<ng-template #WarningErrorModal >
  <div class="modal-header">
    <div class="modal-title">Error</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body">
    <p class="new-date">You dont have maintenance right</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Ok</button>
  </div>
</ng-template>

<!--Moving modal-->
<ng-template #MovingModal >
  <div class="modal-body">
    <div class="center-item">
      <div class="error-title">Moving room(s)</div>
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  </div>
</ng-template>