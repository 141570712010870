import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANT } from 'src/app/helpers/constants';
import { TenantService } from 'src/app/services/tenant.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { ToastrService } from 'ngx-toastr';
import { UTILS } from 'src/app/helpers/utils';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
const TOKEN_KEY = 'jwt';
const USER_KEY = 'auth-user';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  isLoggedIn: boolean = false;
  email?: string;
  menuItems: any = [];
  userManagementAccess?: boolean = false;
  hotelName: string = '';
  isAdmin: boolean = false;
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(
    private router: Router,
    private tokenStorageService: TokenStorageService,
    private tenantService: TenantService,
    private toastr: ToastrService,
    private utils: UTILS,
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    CONSTANT.ROLE = this.tokenStorageService.getRole();
    this.isLoggedIn = window.localStorage.getItem(TOKEN_KEY) ? true : false;
    if (this.isLoggedIn) {
      const email = this.tokenStorageService.getEmailCurrentUser();
      this.email = email;
      // Show menu items on tabs
      let token = window.localStorage.getItem(TOKEN_KEY);
      let userRole: string | null = null;
      if (token) {
        localStorage.setItem("isUserManagement", "false");
        userRole = this.tokenStorageService.getRole();
        this.userManagementAccess = this.tokenStorageService.getEnvironmentIds().indexOf(',') > -1 || userRole === "NELSON_MANAGER" || userRole === "CLIENT_ADMIN";
        this.isAdmin = userRole == 'NELSON_MANAGER';
        let tmpMenuItems = this.tokenStorageService.getPageNamesFromUserRoles(userRole);
        this.menuItems = tmpMenuItems.filter((menu:any) => this.hotelConfig.MUIfeature.page.includes(menu.pageName));
      }
      this.tenantService.loadTenants({ tenantids: this.tokenStorageService.getTenantIds(), environmentids: this.isAdmin ? '' : this.tokenStorageService.getEnvironmentIds() }).subscribe(
        (data: any) => {
          this.hotelName = userRole && (userRole === "NELSON_MANAGER" || userRole === "CLIENT_ADMIN") ? this.utils.getEnvironments(data.tenants, this.tokenStorageService.decodeJwtToken(this.tokenStorageService.getToken()!)).filter((env: any) => env.managementsitehost.indexOf(window.location.origin) > -1)[0].name : "";
        },
        (errResp: any) => {
          this.toastr.error("Error environment details.", 'Error!');
        }
      );
    } else {
      const getLastUrl = this.utils.encodeLastUrl();
      // this.router.navigateByUrl(`/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`);
      this.router.navigateByUrl(`/login`);
    }
  }
  logout(): void {
    this.isLoggedIn = false;
    this.tokenStorageService.logOutBackToAdmin();
  }
  renderAvatar() {
    const getEmailName = (this.email || '');
    const avatar = getEmailName.split(' ').reduce((a: any, c) => {
      a.push(c.substring(0, 1).toLocaleUpperCase());
      return a;
    }, []);
    return avatar.join('');
  }
  backToUserManagement() {
    // Update Client name when redirect from MUI to User management
    localStorage.setItem("tenant_name", "");
    localStorage.setItem("isUserManagement", "true");
    if (this.utils.isLocalEnvironment()) {
      window.open(`${window.location.origin}/#/dashboard?token=${this.tokenStorageService.getToken()}&refreshtoken=${this.tokenStorageService.getRefreshToken()}`, '_self');
    } else {
      const adminEnv = "admin.nelson.management";
      window.open(`https://${adminEnv}/#/dashboard?token=${this.tokenStorageService.getToken()}&refreshtoken=${this.tokenStorageService.getRefreshToken()}`, '_self');
    }
  }
}
