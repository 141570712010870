import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export type MenuType = {
  label: string,
  value: string,
  disabled: boolean
}

@Component({
  selector: 'dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.sass', '../../../../styles.sass']
})

export class DropdownMenuComponent implements OnInit {

  @Input() menuId:string = "";
  @Input() menuList:MenuType[] = [];
  @Input() disableMenu:boolean = false;
  @Input() fullWidth:boolean = false;
  @Input() selectedItem?:MenuType;
  @Input() searchable:boolean = false;
  @Input() dropup:boolean = false;
  @Input() buttonFontSize:string = "18px";
  @Input() minWidth:string = "0";
  @Input() rightAligned:boolean = false;
  @Input() highlightError:boolean = false;

  @Output() selectItem:EventEmitter<MenuType> = new EventEmitter<MenuType>();

  searchKeyword:string = ""
  menuListFiltered:MenuType[] = [];

  constructor() { }

  ngOnInit(): void {
    this.menuListFiltered = this.menuList;
  }

  selectMenuItem(item:MenuType) {
    this.selectItem.emit(item)
  }

  searchListChange() {
    if(this.searchKeyword === "") {
      this.menuListFiltered = this.menuList;
    } else {
      const tmpFilter = this.menuList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()))
      this.menuListFiltered = tmpFilter
    }
  }

  openSearch() {
    this.searchKeyword = "";
    this.menuListFiltered = this.menuList;
    setTimeout(() => {
      if(this.selectedItem) {
        const dropdownContainer = document.getElementById(`select-menu-${this.menuId}`);
        const selectedItem = document.getElementById(this.menuId + this.selectedItem.value);
        if(dropdownContainer && selectedItem) {
          dropdownContainer.scrollTop = selectedItem.offsetTop - (this.searchable ? 140 : 100);
        }
      }
    }, 0);
  }

}
