import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const SAVE_PRICING_RULE = '/api/management/secure/pricing_rule/';
const PRICING_URL = '/api/management/secure/rates';
const PRICING_RULE_URL = '/api/management/secure/pricing_rules/';
const SECIF_PRICING = '/api/management/secure/secif_pricings';
@Injectable({
  providedIn: 'root'
})
export class PricingService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  public savePricingRule(request: object): Observable<any> {
    return this.http.post(SAVE_PRICING_RULE, request);
  }

  public getPriceRates(request: any): Observable<any> {
    return this.http.get<any>(PRICING_URL, {params: request});
  }

  public getSecifPricing(hotelId:any, startDate:any, endDate:any): Observable<any> {
    return this.http.get(`${SECIF_PRICING}/${hotelId}/${startDate}/${endDate}`);
  }

  public getCurrentRule(hotelID: any): Observable<any> {
    return this.http.get(PRICING_RULE_URL + hotelID);
  }

  public saveRate(request: any) {
    return this.http.post<any>(PRICING_URL, request, {});
  }

  public saveSecifPricing(hotelId:any, request: any) {
    return this.http.post<any>(`${SECIF_PRICING}/${hotelId}`, request, {});
  }

}
