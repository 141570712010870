<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ title }}</h1>
          <form [formGroup]="lockSearch">
            <div class="row">
              <div class="col-md-3">
                <label>Search for</label>
                <input class="form-control custom-input searchQuery" formControlName="searchQuery">
              </div>
              <div class="col-md-2" *ngIf="hotels.length > 1">
                <label>Hotel</label>
                <dropdown-menu
                  [menuList]="hotelList"
                  [selectedItem]="selectedHotel"
                  [fullWidth]="true"
                  (selectItem)="selectHotel($event)"
                ></dropdown-menu>
              </div>
              <div class="col-md-7">
                <div class="row">
                  <div class="col-sm-6">
                    <label>Start date & time</label>
                    <div class="row no-gutters">
                      <div class="col-xs-7">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                            [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                            (bsValueChange)="dateChange('startDate')"
                            [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-5">
                        <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Start time" class="form-control" value="{{startDate | date:'HH:mm'}}"
                              disabled>
                            <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                              <span class="glyphicon glyphicon-time"></span>
                            </button>
                          </div>
                          <div class="dropdown-menu menu-content start-time-cal" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                            <timepicker [(ngModel)]="startDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <label>End date & time</label>
                    <div class="row no-gutters">
                      <div class="col-xs-7">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                            bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                            (bsValueChange)="dateChange('endDate')"
                            [dateCustomClasses]="datepickerCustom"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-xs-5">
                        <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                          <div class="datepicker-input">
                            <input type="text" placeholder="End time" class="form-control" value="{{endDate | date:'HH:mm'}}"
                              disabled>
                            <button type="button" class="datepicker-btn" dropdownToggle aria-controls="endTimePicker">
                              <span class="glyphicon glyphicon-time"></span>
                            </button>
                          </div>
                          <div class="dropdown-menu menu-content start-time-cal" id="endTimePicker" *dropdownMenu (click)="preventClose($event)">
                            <timepicker [(ngModel)]="endDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <label>Filters</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="optionalFilter">
                    <div class="menu-selected">
                      <ng-container *ngFor="let filter of getThreeFilter(); let i = index">
                        <span *ngIf="filter.checked" class="filter-item">
                          <span class="filter-label">{{filter.label}}</span>
                          <span (click)="removeFilter(filter, $event)" class="glyphicon glyphicon-remove"></span>
                        </span>
                      </ng-container>
                      <span *ngIf="getAllFilter().length > 3" class="filter-item">
                        <span class="filter-label"> + {{getAllFilter().length - 3}}</span>
                      </span>
                    </div>
                    <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  </div>
                  <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu
                    (click)="utils.preventClose($event)">
                    <div class="content-item" (click)="clearAllFilter()">Clear all</div>
                    <ng-container *ngFor="let filter of filters; let i = index">
                      <div class="normal-checkbox" (click)="changeFilter(i,$event)">
                        <input type="checkbox" [checked]="filter.checked">
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <div class="checkbox-label">{{filter.label}}</div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div class="col-md-5"></div>
              <div class="col-md-2">
                <button type="submit" class="btn btn-green full-width input-height" style="margin-top: 30px" [disabled]="isSearching" (click)="onSearch('new')"><span class="glyphicon glyphicon-search" style="margin-right: 5px"></span> Search</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="right-btns">
          <button type="submit" class="btn btn-green input-height" routerLink="/locks/lock" [queryParams]="{hotelId: selectedHotel !== undefined ? selectedHotel.value : ''}"><span class="glyphicon glyphicon-asterisk" style="margin-right: 5px"></span> Create new code</button>
          <button type="submit" class="btn btn-green input-height door-icon" routerLink="/locks/code-usage" [queryParams]="{hotelId: selectedHotel !== undefined ? selectedHotel.value : ''}"><img src="assets/images/door-events-icon.svg"> Door events</button>
        </div>
      </div>
      <div class="container-fluid">
        <ng-container *ngIf="searchResult.length > 0">
          <div class="custom-table-wrapper">
            <table class="custom-table">
              <thead>
                <tr>
                  <th style="width:400px">Door</th>
                  <th>Name(s)</th>
                  <th>Hotel</th>
                  <th>Reservation(s)</th>
                  <th>Door code</th>
                  <th>Start date</th>
                  <th>End date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let result of searchResult" routerLink="/locks/lock" [queryParams]="{hotelId: getHotelId(result.hotel), userToken: result.token}">
                  <td>{{result.doorList}}</td>
                  <td>{{result.name}}</td>
                  <td>{{result.hotel}}</td>
                  <td>{{result.reservationCode}}</td>
                  <td>{{result.doorCode}}</td>
                  <td>{{result.startDate}}</td>
                  <td>{{result.endDate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="show-more">
            <label>Showing {{currentCount}}/{{totalCount}}</label>
            <button class="btn btn-green large-btn show-more-btn" *ngIf="remainingCount > 0" [disabled]="isSearching"
              (click)="onSearch('more')"><span class="glyphicon glyphicon-refresh"></span> Show {{remainingCount}}
              more</button>
            <button type="button" class="btn btn-green large-btn show-more-btn" (click)="backToTopPage()" style="margin-left: 15px"><span class="glyphicon glyphicon-menu-up"></span> Back to top page</button>
          </div>
        </ng-container>
      </div>
    </div>
  </div> 
  <div class="center-item" *ngIf="isSearching">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
</div>