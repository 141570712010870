import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UTILS } from 'src/app/helpers/utils';
import { CustomersService } from 'src/app/services/customers.service';
import COUNTRIES from 'src/assets/resources/country-codes.json';
import { AnimationOptions } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.sass','../../../styles.sass']
})
export class CustomerCardComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  email: string | undefined;
  mobile: string | undefined;
  countries = COUNTRIES;
  loading: boolean = false;
  isStatusStatistics: boolean = false;
  commentOffset: number = 0;
  bookingOffset: number = 0;
  bookingLimit: number = 3;
  commentLimit: number = 3;
  customerNotFound:boolean = false;
  customerCard: any = {
    commentSearchResult: {},
    customer: {},
    bookingSearchResult: {},
    statusStatistics: {}
  };
  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private customersService: CustomersService,
    private storeService: StoreService,
    public utils:UTILS) {
  }

  ngOnInit(): void {
    this.email = this.receivedEmail();
    this.mobile = this.receivedMobile();
    // this.customersService.getCountries().subscribe(data => {
    //   this.countries = data;
    // });
    this.getCustomerCard(this.email,this.mobile);
  }
  receivedEmail(): any {
    let routeParams = this.route.snapshot.paramMap;
    return String(routeParams.get('email'));
  }
  receivedMobile(): any {
    let routeParams = this.route.snapshot.paramMap;
    return String(routeParams.get('mobile'));
  }
  getCustomerCard(email: any,mobile: any): void {
    this.isStatusStatistics = false;
    this.loading = true;
    this.customersService.customerCard(email,mobile).subscribe(
      data => {
        this.loading = false;
        if(data.customer) {
          this.customerCard.customer = data.customer;
          this.customerCard.commentSearchResult = data.commentSearchResult;
          this.customerCard.bookingSearchResult = data.bookingSearchResult;
        } else {
          this.customerNotFound = true;
        }
      },
      err => {
        this.customerCard = {};
      });
    this.getStatusStatistics(this.email);
  }
  getStatusStatistics(email: any): any {
    this.isStatusStatistics = false;
    this.customersService.showStatusStatistics(email).subscribe(
      data => {
        this.isStatusStatistics = true;
        this.customerCard.statusStatistics = data;
      },
      err => {
        return null;
      });
  }
  goToPastBooking(): void {
    this.bookingOffset = this.bookingOffset + this.bookingLimit;
    let request = {
      email: this.email,
      bookingOffset: this.bookingOffset,
      bookingLimit: this.bookingLimit
    }
    this.customersService.showMoreBookings(request).subscribe(
      data => {
        data.results.forEach((booking: any) => {
          this.customerCard.bookingSearchResult.results.push(booking);
        });
      }
    )
  }
  showMoreComments(): void {
    this.commentOffset = this.commentOffset + this.commentLimit;
    let request = {
      email: this.email,
      commentOffset: this.commentOffset,
      commentLimit: this.commentLimit
    }
    this.customersService.showMoreComments(request).subscribe(
      data => {
        data.results.forEach((comment: any) => {
          this.customerCard.commentSearchResult.results.push(comment);
        });
      }
    )
  }
  formatCurrency(currencyText: any): any {
    if (!currencyText) {
      return;
    }    
    let currency = currencyText.slice(0,3);
    let number = Number(currencyText.slice(4,currencyText.length));
    let formatCurrency = this.utils.getCurrency(currency);
    return formatCurrency + ' ' + number;
  }
  truncateDiscountCodesForStatus(discountCodes: any): void {
    if (!discountCodes) {
      return;
    }
    let length = discountCodes.join(", ").length;
    let discountCode;
    if (length > 20) {
      discountCode = discountCodes.join(", ").slice(0, 21) + '...';
    } else {
      discountCode = discountCodes.join(", ");
    }
    return discountCode;
  }
  truncateDiscountCodeForBookings(discountCode: any): void {
    if (!discountCode)
      return;

    let length = discountCode.length;
    let discountCodeResults;
    if (length > 20) {
      discountCodeResults = discountCode.slice(0, 21) + '...';
    } else {
      discountCodeResults = discountCode;
    }
    return discountCodeResults;
  }
  getCustomerNationality(nationality: any): any {
    return this.countries.find((country: any) => {
      return country['ISO3166-1-Alpha-3'] == nationality;    
    })?.name;
  }
  editBlocklist(): void {
    let blocklistId = this.customerCard.customer.blocklistId;
    let email = this.customerCard.customer.email;
    let mobile = this.customerCard.customer.mobile;
    if (blocklistId && blocklistId > 0) {
      this.router.navigateByUrl("customer/"+blocklistId);
    } else {
      // this.router.navigateByUrl("customer/" + email);
      this.router.navigate(['customer',email, mobile.substring(1,mobile.length)]);
    }
  }
  backToCustomers(): void {
    this.router.navigateByUrl("/customers");
  }
}