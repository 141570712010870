<app-header-user-management></app-header-user-management>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="heading">
            <h1 class="view-title">Users</h1>
            <button type="button" class="btn btn-green btn-big" (click)="openUserAddModal(addUserModal)"><span
                class="plus-icon"><img src="assets/images/plus-icon.svg"></span> Add New User </button>
          </div>
        </div>
        <div class="row">
          <form name="form" [formGroup]="userFrom" (ngSubmit)="searchUser()">
            <div class="search-row">
              <!-- Choose client -->
              <div *ngIf="isAdminRole || allTenants.length>1" class="col-xs-12 col-md-4">
                <label>Choose client</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="dropdown-basic">
                    <div class="menu-selected">{{selectedTenantLabel}}</div>
                    <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span>
                    </div>
                  </div>
                  <div class="dropdown-menu menu-content dropdown-content" id="dropdown-basic" *dropdownMenu>
                    <ul class="menu-item-list">
                      <ng-container *ngFor="let tenant of allTenants">
                        <li [class.active]="selectedTenantLabel===tenant.tenantname" (click)="changeTenant(tenant)">{{
                          tenant.tenantname }}</li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>

              <!-- Search user -->
              <div class="col-xs-12 col-md-4">
                <div class="btn-group dropdown">
                  <label for="textSearch">Search user by email</label>
                  <input name="query" class="form-control custom-input"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
                    formControlName="email" autofocus>
                </div>
              </div>

              <!-- Optional filters for user type -->
              <div class="col-xs-12 col-md-4">
                <label>User type</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="dropdown-basic">
                    <div class="menu-selected">{{selectedRoleLabel}}</div>
                    <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span>
                    </div>
                  </div>
                  <div class="dropdown-menu menu-content dropdown-content" id="dropdown-basic" *dropdownMenu
                    (click)="$event.stopImmediatePropagation()">
                    <ng-container *ngFor="let role of allRoles; let idx = index;">
                      <div formArrayName="allroles" class="normal-checkbox">
                        <input type="checkbox" (click)="selectUserRole(role)" [formControlName]="idx">
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <div class="checkbox-label">
                          <ng-container>
                            {{role.roleName}}
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>

            <!-- Some buttons for customers -->
            <div class="format-btn">
              <div class="col-xs-12 col-md-12 text-right" style="margin-top: 40px">
                <button type="submit" class="btn btn-green btn-big"><span class="glyphicon glyphicon-search"></span>
                  Search </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper-outer-table">
    <div class="outer-table">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6">
            <label class="title-search">All users</label>

          </div>
        </div>
        <div class="table-responsive">
          <table class="custom-table">
            <thead>
              <th>
                <div class="padding">User email</div>
              </th>
              <th>
                <div class="padding">User rights</div>
              </th>
              <th class="last sticky">
                <div class="padding"></div>
              </th>
            </thead>
            <tbody>
              <ng-container *ngFor="let result of searchResult">
                <tr>
                  <td>{{result.email}}</td>
                  <td>
                    <ng-container>
                      {{getUserRoleName(result.roles)}}
                    </ng-container>
                  </td>
                  <td class="text-center sticky">
                    <a class="edit-btn" (click)="openEditModal(result, editModal)">
                      <span class="glyphicon glyphicon glyphicon-edit"></span> Edit
                    </a>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
          <div style="text-align: center;">
            <button class="btn btn-green large-btn show-more-btn" (click)="this.handleClickShowMore()"
              *ngIf="this.lastSearchEvaluatedId !== ''"><span class="glyphicon glyphicon-refresh"
                [ngClass]="this.loadingContainer ? 'rotating' : ''"></span> Show more</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Loading icon -->
  <div class="center-item" *ngIf="loadingContainer">
    <ng-lottie [options]="options" width="200px" height="200px"></ng-lottie>
  </div>
</div>

<!-- Edit Modal -->
<ng-template #editModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Edit user</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <ng-container *ngIf="!selectedUser.name">
    <div class="center-item">
      <ng-lottie [options]="options" width="150px" height="150px"></ng-lottie>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedUser.name">
    <div class="modal-body edit-body-modal">
      <div class="inner" *ngIf="selectedUser.name">
        <form name="form" [formGroup]="editFrom">
          <div class="row">
            <div class="col-md-12">
              <span class="avatar">{{createAvatar(selectedUser.name)}}</span>
            </div>
            <div class="col-md-12 mt-20">
              <span class="prefix">Full name: </span>
              <strong>{{selectedUser.name}}</strong>
            </div>
            <div class="col-md-12 mt-16">
              <span class="prefix">E-mail: </span>
              <strong>{{selectedUser.email}}</strong>
            </div>
            <div class="col-md-6 mt-16">
              <span class="prefix">Created on: </span>
              <strong>{{selectedUser.createddate | date: 'dd.MM.yyyy'}}</strong>
            </div>
            <div class="col-md-6 mt-20">
              <span class="prefix">Last login: </span>
              <strong>{{selectedUser.lastlogintime | date: 'dd.MM.yyyy'}}</strong>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <strong>Select enivorment</strong>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span class="glyphicon glyphicon-info-sign"></span>
            </div>
            <ng-container *ngFor="let enviroment of allEnviroments; let idx = index">
              <div class="col-md-12 mt-16">
                <div formArrayName="envir" class="normal-checkbox">
                  <input type="checkbox" [value]="enviroment.id" [formControlName]="idx">
                  <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                  <div class="checkbox-label">{{enviroment.name}}</div>
                </div>
              </div>
            </ng-container>
          </div>
          <hr />
          <div class="row">
            <div class="col-md-12">
              <strong>User rights</strong>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span class="glyphicon glyphicon-info-sign"></span>
            </div>
            <ng-container *ngFor="let role of allRoles">
              <div class="col-md-12 mt-16">
                <div class="normal-checkbox radio-style">
                  <input type="radio" [value]="role.id" formControlName="roles" [(ngModel)]="selectedUser.roles">
                  <div class="checkbox-box">
                    <div class="radio-icon"></div>
                  </div>
                  <div class="checkbox-label">
                    <ng-container>
                      {{role.roleName}}
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <br />
          <div *ngIf="getUserRoleIsCleaner(true)">
            <div class="row" *ngFor="let environment of getSelectedEnvironments(true)">
              <br />
              <div class="col-md-12">
                <strong>Hotel access - {{environment.name}}</strong>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <span class="glyphicon glyphicon-info-sign"></span>
                <ng-container *ngFor="let hotel of environment.hotels">
                  <div class="col-md-12 mt-16">
                    <div class="normal-checkbox">
                      <input type="checkbox" [value]="isHotelSelected(hotel.id)" [(ngModel)]="selectedHotels[hotel.id]" [ngModelOptions]="{standalone: true}">
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      <div class="checkbox-label">{{hotel.name}}</div>
                    </div>
                  </div>
                </ng-container>
                <br />
              </div>
            </div>
          </div>
          <hr />
          <div class="row" *ngIf="!checkEditUserIsCurrent()">
            <div class="col-md-12">
              <div class="custom-radio">
                <input type="checkbox" formControlName="status" [(ngModel)]="selectedUser.enabled">
                <div class="radio-box">
                  <span class="glyphicon glyphicon-user"></span>
                  <ng-container *ngIf="selectedUser.enabled">
                    <span class="cross">x&nbsp;&nbsp;</span>
                    Disable user account
                  </ng-container>
                  <ng-container *ngIf="!selectedUser.enabled">
                    <span class="cross">&nbsp;&nbsp;</span>
                    Enable user account
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="checkEditUserIsCurrent()">
            <form [formGroup]="resetPasswordForm" (ngSubmit)="submitResetPassword()">
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="password" id="password" autofocus>
                <label>Current password</label>
              </div>
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="newPassword" id="newPassword" autofocus>
                <label>New password</label>
              </div>
              <div class="custom-float-input">
                <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword" autofocus>
                <label>Confirm new password</label>
              </div>
              <div class="password-criteria">
                <p>New password must contain at least:</p>
                <ul>
                  <li>At least 8 characters—the more characters, the better</li>
                  <li>A mixture of both uppercase and lowercase letters</li>
                  <li>Contains at least one number and one special character</li>
                </ul>
              </div>
              <button type="submit" [disabled]="updatingPassword" class="btn btn-lg btn-block btn-green btn-big">Update password</button>
            </form>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-none-outline-green" (click)="resetPassword()">Reset Password</button>
      <button type="submit" class="btn btn-none-outline-green" (click)="cancelEdit()">Cancel</button>
      <button type="submit" class="btn btn-green" (click)="editUser()">Save</button>
    </div>
  </ng-container>
</ng-template>

<!--User add modal-->
<ng-template #addUserModal>
  <div class="modal-header">
    <div class="modal-title pull-left">Add new user</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()"><span
        aria-hidden="true">×</span></button>
  </div>
  <div class="modal-body add-body-modal">
    <div class="inner">
      <form name="form" [formGroup]="addForm">
        <div class="row no-gutters">
          <div class="col-md-12 field-title">
            <b>E-mail</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input form-control" formControlName="email" type="text">
          </div>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 field-title">
            <b>Full Name</b>
          </div>
          <div class="col-md-12">
            <input class="custom-input form-control" formControlName="fullname" type="text">
          </div>
        </div>
        <hr />
        <div class="row no-gutters">
          <div class="col-md-12 field-title">
            <b>Select Enviroment</b> <img class="brand-logo" src="assets/images/more-info.svg">
          </div>
          <ng-container *ngFor="let enviroment of allEnviroments; let idx = index">
            <div class="col-md-12 mt-16">
              <div class="normal-checkbox" formArrayName="envir">
                <input type="checkbox" [id]="idx" [value]="enviroment.label" [formControlName]="idx"
                  (ngModelChange)="checkInvalidAddForm()">
                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                <div class="checkbox-label">{{enviroment.name}}</div>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="row no-gutters">
          <div class="col-md-12 field-title">
            <b>User rights</b> <img class="brand-logo" src="assets/images/more-info.svg">
          </div>
          <ng-container *ngFor="let role of allRoles">
            <div class="col-md-12 mt-16">
              <div class="normal-checkbox radio-style">
                <input type="radio" [value]="role.id" formControlName="roles">
                <div class="checkbox-box">
                  <div class="radio-icon"></div>
                </div>
                <div class="checkbox-label">
                  <ng-container>
                    {{role.roleName}}
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <br />
        <div *ngIf="getUserRoleIsCleaner(false)">
          <div class="row" *ngFor="let environment of getSelectedEnvironments(false)">
            <br />
            <div class="col-md-12">
              <strong>Hotel access - {{environment.name}}</strong>
              <span>&nbsp;&nbsp;&nbsp;</span>
              <span class="glyphicon glyphicon-info-sign"></span>
              <ng-container *ngFor="let hotel of environment.hotels">
                <div class="col-md-12 mt-16">
                  <div class="normal-checkbox">
                    <input type="checkbox" [value]="isHotelSelected(hotel.id)" [(ngModel)]="selectedHotels[hotel.id]" [ngModelOptions]="{standalone: true}">
                    <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                    <div class="checkbox-label">{{hotel.name}}</div>
                  </div>
                </div>
              </ng-container>
              <br />
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <button type="submit" class="btn btn-green" (click)="addUser()" [disabled]="!addForm.valid || !isValidAddForm">Add
      new user</button>
  </div>
</ng-template>