<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="row">
            <div class="col-md-12"><h1 class="view-title">Customer Dashboard</h1></div>
          </div>
        </div>
        <div style="overflow: hidden;" class="row">
          <!-- Show message after change customer/ reason for customer -->
            <div class="col-md-12 message-alert-dashboard">
              <div *ngFor="let alert of alerts" class="alert alert-{{alert.type}}" role="alert">
                {{ alert.message }}
              </div>
            </div>
            <div class="col-md-12">
              <div class="row" >
                <div class="col-xs-12 col-md-6">
                  <div class="row">
                    <div class="col-md-6 ">
                      <!-- Start datepicker -->
                      <label for="start-date">From*</label>
                      <div class="datepicker-input">
                        <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                          [(bsValue)]="fromDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('startDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
                        <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                    <!-- End date picker -->
                    <div class="col-md-6 ">
                      <label for="end-date">To*</label>
                      <div class="datepicker-input">
                        <input type="text" placeholder="End date" class="form-control" bsDatepicker appEditDate
                          [(bsValue)]="toDate" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                          (bsValueChange)="dateChange('endDate')"
                          [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
                        <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-md-12">
                      <small class="">* Dates refer to creation date (time booked, time joined member program, time blocked)</small>
                    </div>
                  </div>

                </div>
                <!-- Begin Compare to box -->
                <div class="col-xs-12 col-md-3 ">
                  <label for="optional-filter">Compare to</label>
                  <dropdown-menu
                    [menuList]="compareList"
                    [selectedItem]="selectedCompare"
                    [fullWidth]="true"
                    (selectItem)="selectCompare($event)"
                  ></dropdown-menu>
                </div>
                <div class="col-xs-12 col-md-3 ">
                  <div class="btn-customer-group-submit nls-less-col-padding-compare-to apply-btn">
                    <button type="button" class="col-md-4 col-md-offset-1 btn btn-green btn-big" [disabled]="!isFormValid" (click)="applyFilter()">Apply</button>
                    <button type="button" class="col-md-6 col-md-offset-1 btn btn-outline-green btn-big"
                      (click)="redirectToCustomerDataExport()">Export data</button>
                  </div> 
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-9 box" *ngIf="isDashboardData && !loading">
                <!-- Data for customer -->
                <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.customers }}"placement="bottom">
                  <div>
                    {{ numberWithCommas(customerDashboard.customer.total)}}
                    <span class="upward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.customer.growthRate) && numberBiggerZero(customerDashboard.customer.growthRate)">
                      <span class="upward-arrow-icon"></span><small>+ {{ customerDashboard.customer.growthRate }}%</small>
                    </span>
                    <span class="downward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.customer.growthRate) && !numberBiggerZero(customerDashboard.customer.growthRate)">
                      <span class="downward-arrow-icon"></span><small>- {{
                        absForGrowthRate(customerDashboard.customer.growthRate)
                        }}%</small>
                    </span>
                    <span class="upward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.customer.growthRate) && (customerDashboard.customer.total > customerDashboard.customer.previousTotal)">
                    </span>
                    <span class="downward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.customer.growthRate) && (customerDashboard.customer.total < customerDashboard.customer.previousTotal)">
                      <span class="downward-arrow-icon"></span>
                      <small>na%</small>
                    </span>
                  </div>
                  <h5 class="item-title">Customer emails</h5>
                </div>
                
                <!-- Data for member -->
                <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.members }}"
                  placement="bottom">
                  <div>
                    {{numberWithCommas(customerDashboard.member.total)}}
                    <span class="upward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.member.growthRate) && numberBiggerZero(customerDashboard.member.growthRate)">
                      <span class="upward-arrow-icon"></span><small>+ {{ customerDashboard.member.growthRate }}%</small>
                    </span>
                    <span class="downward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.member.growthRate) && !numberBiggerZero(customerDashboard.member.growthRate)">
                      <span class="downward-arrow-icon"></span><small>- {{
                        absForGrowthRate(customerDashboard.member.growthRate)
                        }}%</small>
                    </span>
                    <span class="upward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.member.growthRate) && (customerDashboard.member.total > customerDashboard.member.previousTotal)"><span
                        class="upward-arrow-icon"></span><small>na%</small></span>
                    <span class="downward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.member.growthRate) && (customerDashboard.member.total < customerDashboard.member.previousTotal)"><span
                        class="downward-arrow-icon"></span><small>na%</small></span>
                  </div>
                  <h5 class="item-title">Members</h5>
                </div>
                <!-- Data for subscriber -->
                <div class="item-box" containerClass="customer-dashboard-tooltip" tooltip="{{ tooltipContents.subscribers }}"
                  placement="bottom">
                  <div>
                    {{numberWithCommas(customerDashboard.subscriber.total)}}
                    <span class="upward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.subscriber.growthRate) && numberBiggerZero(customerDashboard.subscriber.growthRate)">
                      <span class="upward-arrow-icon"></span><small>+ {{ customerDashboard.subscriber.growthRate }}%</small>
                    </span>
                    <span class="downward-arrow"
                      *ngIf="numberNotIsNaN(customerDashboard.subscriber.growthRate) && !numberBiggerZero(customerDashboard.subscriber.growthRate)">
                      <span class="downward-arrow-icon"></span><small>- {{
                        absForGrowthRate(customerDashboard.subscriber.growthRate)
                        }}%</small>
                    </span>
                    <span class="upward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.subscriber.growthRate) && (customerDashboard.subscriber.total > customerDashboard.subscriber.previousTotal)"><span
                        class="upward-arrow-icon"></span><small>na%</small></span>
                    <span class="downward-arrow"
                      *ngIf="!numberNotIsNaN(customerDashboard.subscriber.growthRate) && (customerDashboard.subscriber.total < customerDashboard.subscriber.previousTotal)"><span
                        class="downward-arrow-icon"></span><small>na%</small></span>
                  </div>
                  <h5 class="item-title">Subscribers</h5>
                </div>
              </div>
            </div>
            
            <!-- Loading icon -->
            <div class="center-item" *ngIf="loading">
              <ng-lottie
                [options]="options"
                width="200px"
                height="200px"
              ></ng-lottie>
            </div>
        </div>
      </div>
    </div>
  </div>