<app-header></app-header>
<div class=" main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="row">
            <div class="col-md-12">
              <h1 class="view-title">Customer Data Export</h1>
            </div>
          </div>
        </div>
  
        <div class="row">
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
              <div class="col-md-3 ">
                <!-- Start datepicker -->
                <label for="start-date">From</label>
                <!-- <div class="input-group datepicker">
                  <input type="text" class="form-control text-input datepicker-input nls-input" placement="bottom"
                    formControlName="fromDate" (ngModelChange)="mySelectFromDate($event)">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [maxDate]="maxDate"
                      [(bsValue)]="bsFromDateValue" (bsValueChange)="onValueChangeFromDate($event)" [dateCustomClasses]="datepickerCustom" 
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </span>
                </div> -->
                <div class="datepicker-input">
                  <input type="text" placeholder="Start date" class="form-control" bsDatepicker appEditDate
                    [(bsValue)]="fromDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                    (bsValueChange)="dateChange('startDate')"
                    [dateCustomClasses]="datepickerCustom"
                    [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
                  <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
              </div>
              <!-- End date picker -->
              <div class="col-md-3">
                <label for="end-date">To</label>
                <!-- <div class="input-group datepicker">
                  <input type="text" class="form-control text-input datepicker-input nls-input" formControlName="toDate"
                    (ngModelChange)="mySelectToDate($event)">
                  <span class="input-group-btn">
                    <button type="button" class="btn btn-red btn-big datepicker-btn" bsDatepicker [maxDate]="maxDate"
                      [(bsValue)]="bsToDateValue" (bsValueChange)="onValueChangeToDate($event)" [dateCustomClasses]="datepickerCustom" 
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </span>
                </div> -->
                <div class="datepicker-input">
                  <input type="text" placeholder="End date" class="form-control" bsDatepicker appEditDate
                    [(bsValue)]="toDate" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                    (bsValueChange)="dateChange('endDate')"
                    [dateCustomClasses]="datepickerCustom"
                    [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today', maxDate: maxDate }">
                  <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                    <span class="glyphicon glyphicon-calendar"></span>
                  </button>
                </div>
              </div>  
                
       
              <!-- Begin Compare to box -->
              <div class="col-md-6 ">
                <div class="btn-group dropdown " dropdown [insideClick]="true">
                  <label for="fields">Fields</label>
                  <button type="button" class="form-control custom-input dropbtn no-overflow-button" dropdownToggle
                    aria-controls="dropdown-basic">{{selectedFieldLabels}}<span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></button>                  
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li class="optional-filters normal-checkbox" role="menuitem" *ngIf="!isAllFilters" (click)="selectAll()">
                      <!-- <input type="checkbox" [checked]="isAllFilters" no-dirty> -->
                      <!-- <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div> -->
                      <div for="auto-notify" no-dirty>Select All</div>
                    </li>
                    <li class="optional-filters normal-checkbox" role="menuitem" *ngIf="isAllFilters" (click)="clearAll()">
                      <!-- <input type="checkbox" [checked]="!isAllFilters" no-dirty> -->
                      <!-- <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div> -->
                      <div for="auto-notify" no-dirty><b>Clear All</b></div>
                    </li>
                    <li class="optional-filters normal-checkbox" role="menuitem" *ngFor="let field of fields" (click)="fieldChange(field)">
                      <input type="checkbox" [checked]="field.checked" no-dirty>
                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                      <div for="auto-notify">{{ field.label }}</div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-12 text-right download-btn">
                <button type="submit" class="btn btn-green btn-big"
                  [disabled]="!(hasFields && isValidFromDate && isValidToDate)">
                  <span class="glyphicon glyphicon-download-alt"></span> Download
                </button>
              </div>
            </form>
        </div>
      </div>
    </div>
  </div>
</div>