import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { HotelWebConfig } from 'src/app/@types/app';
import { CONSTANT } from 'src/app/helpers/constants';
import { UTILS } from 'src/app/helpers/utils';
import { InternalToolsService } from 'src/app/services/internal-tools.service';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../../common/dropdown-menu/dropdown-menu.component';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-beonx-hotel-notif-export',
  templateUrl: './beonx-hotel-notif-export.component.html',
  styleUrls: ['../internal-tools-mui.component.sass']
})
export class BeonxHotelNotifExportComponent implements OnInit {

  @Input() hotels:any;

  constructor(
    private storeService: StoreService,
    public utils: UTILS,
    private internalToolsService: InternalToolsService,
    private toastr: ToastrService,
  ) { }

  hotelConfig: HotelWebConfig = this.storeService.getConfig();

  hotelList: MenuType[] = [];
  selectedHotel?:MenuType;

  fileTypeList:MenuType[] = [
    {
      value: "OTA_HotelResNotifRQ",
      label: "OTA_HotelResNotifRQ",
      disabled: false
    },
    {
      value: "OTA_HotelInvBlockNotifRQ",
      label: "OTA_HotelInvBlockNotifRQ",
      disabled: false
    },
    {
      value: "OTA_HotelInvCountNotifRQ",
      label: "OTA_HotelInvCountNotifRQ",
      disabled: false
    }
  ];
  selectedFileType:MenuType = this.fileTypeList[0];

  retrieveForm = new FormGroup({
    reservationCode: new FormControl('', [this.validateReservationCode.bind(this)]),
  });
  get reservationCode() {
    return this.retrieveForm.get("reservationCode")!
  }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if(this.hotels.length) {
      let tmpHotelList:MenuType[] = [];
      this.hotels.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectHotel(tmpHotelList[0])
    }
  }

  displayAPIerror(err:any) {
    let errorMessage;
    if(err.error.description) errorMessage = err.error.description;
    if(err.error.message) errorMessage = err.error.message;
    if(!errorMessage) errorMessage = "Unknown error occurred!";
    this.toastr.error(errorMessage, 'Error!');
  }

  selectHotel(item:MenuType) {
    this.selectedHotel = item;
  }

  selectFileType(item:MenuType) {
    this.selectedFileType = item;
    if(this.retrieveForm) {
      this.retrieveForm.controls["reservationCode"].markAsTouched();
      this.retrieveForm.controls["reservationCode"].updateValueAndValidity();
    }
  }

  validateReservationCode(control:AbstractControl): {[key:string]:boolean} | null {
    let invalidObject = {'invalidMobile':true};
    if (this.selectedFileType.value !== "OTA_HotelResNotifRQ" || (this.selectedFileType.value === "OTA_HotelResNotifRQ" && this.retrieveForm && this.retrieveForm.controls.reservationCode.value !== "")) {
      return null;
    }
    return invalidObject;
  }

  exportHotelNotif() {
    this.retrieveForm.controls["reservationCode"].markAsTouched();
    this.retrieveForm.controls["reservationCode"].updateValueAndValidity();
    if(this.retrieveForm.valid && this.selectedFileType) {
      let request = "";
      if(this.selectedFileType && this.selectedFileType.value === "OTA_HotelResNotifRQ") {
        request = `reservationCode=${this.reservationCode.value}`;
      }
      if(this.selectedFileType && this.selectedHotel && ['OTA_HotelInvBlockNotifRQ', 'OTA_HotelInvCountNotifRQ'].includes(this.selectedFileType.value)) {
        const findHotel = this.hotels.find((hotel:any) => hotel.hotelId === Number(this.selectedHotel?.value))
        if(findHotel) {
          request = `hotelLabel=${findHotel.label}`;
        }
      }
      let url = `/api/management/secure/beonx/xml-data?${request}&type=${this.selectedFileType.value}`;
      const fileName = `${this.selectedFileType.value}-${this.reservationCode.value}`;
      this.internalToolsService.downloadXmlPost(url, fileName).subscribe(blob => {
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = `${fileName}.xml`;
        a.click();
        URL.revokeObjectURL(objectUrl);
      }, error => {
        this.displayAPIerror(error)
      });
    }
  }

}
