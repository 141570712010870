<div class="container main-container login-page">
  <div id=main>
    <div class="row">
      <div class="col-md-4 col-md-offset-4 login-box">
        <img class="brand-login-logo" src="assets/images/nelson-logo.svg">
        <!-- <h1 class="text-center">{{ content.clientTitle }} </h1>      -->
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="custom-float-input">
            <input type="text" min="1" formControlName="email" id="email" autofocus>
            <label>{{ content.usernameLabel }}</label>
          </div>
          <div class="custom-float-input" *ngIf="!requirePasswordUpdate">
            <input type="password" min="1" formControlName="password" id="password" autofocus>
            <label>{{ content.passwordLabel }}</label>
          </div>
          <div class="custom-float-input" *ngIf="requirePasswordUpdate">
            <input type="password" min="1" formControlName="newPassword" id="newPassword" autofocus>
            <label>{{ content.newPasswordLabel }}</label>
          </div>
          <div class="custom-float-input" *ngIf="requirePasswordUpdate">
            <input type="password" min="1" formControlName="confirmNewPassword" id="confirmNewPassword" autofocus>
            <label>{{ content.confirmNewPasswordLabel }}</label>
          </div>
          <div class="password-criteria" *ngIf="requirePasswordUpdate">
            <p>Password must contain at least:</p>
            <ul>
              <li>At least 8 characters—the more characters, the better</li>
              <li>A mixture of both uppercase and lowercase letters</li>
              <li>Contains at least one number and one special character</li>
            </ul>
          </div>
          <div class="forgot-btn">
            <a routerLink="/forgot">Forgot password?</a>
          </div>
          <button type="submit" [disabled]="disabledBtnWhileCallingAPI" class="btn btn-lg btn-block btn-green btn-big">{{ content.signInBtn }}</button>
        </form>
      </div>
    </div>
  </div>
</div>