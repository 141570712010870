<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">{{ title }}</h1>
          <h3 class="view-code" style="margin-top: -10px; margin-bottom: 20px;" *ngIf="!hotelOnly">Code usage: {{currentDoorCode}}</h3>
          <div class="row">
            <div class="col-xs-12 col-md-3" *ngIf="hotelOnly">
              <div class="row">
                <div class="col-xs-12 col-md-5" style="margin-bottom: 10px" *ngIf="hotels.length > 1">
                  <label>Hotel</label>
                  <dropdown-menu
                    [menuList]="hotelList"
                    [selectedItem]="selectedHotel"
                    [fullWidth]="true"
                    (selectItem)="selectHotel($event)"
                  ></dropdown-menu>
                </div>
                <div class="col-xs-12 col-md-7" style="margin-bottom: 10px">
                  <label>Select doors</label>
                  <div class="select-menu door-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true" [ngClass]="{disabled: !doorsList.length || doorListLoading}" (onHidden)="loadEventType()">
                    <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedDoor() && doorsList.length}" dropdownToggle aria-controls="optionalFilter">
                      <div class="menu-selected" *ngIf="!doorListLoading">
                        <span *ngIf="selectAllDoors">
                          <span class="filter-label">All doors</span>
                        </span>
                        <ng-container *ngIf="!selectAllDoors && getAllFilter().length > 0">
                          <span class="filter-label">{{getAllFilter().length}} {{getAllFilter().length <= 1 ? "door" : "doors"}}</span>
                        </ng-container>
                      </div>
                      <div class="menu-selected" *ngIf="doorListLoading">
                        <div class="menu-loading">
                          <ng-lottie
                            [options]="options"
                            width="35px"
                            height="35px"
                          ></ng-lottie>
                        </div>
                      </div>
                      <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                    </div>
                    <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                      <div class="menu-tooltip-list" *ngIf="getAllFilter().length">
                        <div class="menu-title">Selected doors</div>
                        <ng-container *ngFor="let selectedDoor of getAllFilter()">
                          <div class="menu-item" [style]="{paddingLeft: selectedDoor.type === 'room' ? '20px' : '0px'}">
                            {{selectedDoor.label}}
                          </div>
                        </ng-container>
                      </div>
                      <div class="scroll-menu">
                        <div class="normal-checkbox" (click)="setAllDoors($event)">
                          <input type="checkbox" [checked]="selectAllDoors">
                          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                          <div class="checkbox-label">All</div>
                        </div>
                        <ng-container *ngFor="let door of doorsList; let i = index">
                          <ng-container *ngIf="!['floor', 'room', 'elevator'].includes(door.type)">
                            <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                              <input type="checkbox" [checked]="door.checked">
                              <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                              <div class="checkbox-label">{{door.label}}</div>
                            </div>
                          </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let floor of allFloors">
                          <ng-container *ngFor="let door of doorsList; let i = index">
                            <!-- Elevator -->
                            <ng-container *ngIf="door.type === 'elevator' && door.floor === floor">
                              <div class="normal-checkbox" (click)="changeDoor(i,$event)">
                                <input type="checkbox" [checked]="door.checked">
                                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                <div class="checkbox-label">{{door.label}}</div>
                              </div>
                            </ng-container>
                            <!-- Rooms with entrances -->
                            <ng-container *ngIf="door.type === 'floor' && door.floor === floor && !missingEntranceFloors.includes(floor)">
                              <div class="accordion">
                                <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{door.floor}}" [checked]="true">
                                <div class="normal-checkbox">
                                  <span (click)="changeDoor(i,$event)">
                                    <input type="checkbox" [checked]="door.checked">
                                    <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                    <div class="checkbox-label">{{door.label}}</div>
                                  </span>
                                  <label class="accordion-arrow" for="floor{{door.floor}}">
                                    <span class="glyphicon glyphicon-chevron-right"></span>
                                  </label>
                                </div>
                                <div class="accordion-content">
                                  <ng-container *ngFor="let roomDoor of doorsList; let roomIndex = index">
                                    <ng-container *ngIf="roomDoor.type === 'room' && roomDoor.floor === floor">
                                      <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                        <input type="checkbox" [checked]="roomDoor.checked">
                                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                        <div class="checkbox-label">{{roomDoor.label}}</div>
                                      </div>
                                    </ng-container>
                                  </ng-container>
                                </div>
                              </div>
                            </ng-container>
                          </ng-container>
                          <!-- Rooms without entrances -->
                          <ng-container *ngIf="missingEntranceFloors.includes(floor)">
                            <div class="accordion">
                              <input class="accordion-arrow-checkbox" type="checkbox" id="floor{{floor}}" [checked]="true">
                              <div class="normal-checkbox">
                                <span>
                                  <input type="checkbox" disabled>
                                  <div class="checkbox-box disabled"><span class="glyphicon glyphicon-ok"></span></div>
                                  <div class="checkbox-label">
                                    Floor {{floor}}
                                    <div class="checkbox-desc">(No entrance)</div>
                                  </div>
                                </span>
                                <label class="accordion-arrow" style="top: -1px" for="floor{{floor}}">
                                  <span class="glyphicon glyphicon-chevron-right"></span>
                                </label>
                              </div>
                              <div class="accordion-content">
                                <ng-container *ngFor="let roomDoor of doorsList; let roomIndex = index">
                                  <ng-container *ngIf="roomDoor.type === 'room' && roomDoor.floor === floor">
                                    <div class="normal-checkbox" (click)="changeDoor(roomIndex,$event)">
                                      <input type="checkbox" [checked]="roomDoor.checked">
                                      <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                                      <div class="checkbox-label">{{roomDoor.label}}</div>
                                    </div>
                                  </ng-container>
                                </ng-container>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12" [ngClass]="{'col-md-9': hotelOnly, 'col-md-12': !hotelOnly}">
              <div class="row">
                <div class="col-xs-12 col-md-9">
                  <div class="row">
                    <div class="col-sm-6" style="margin-bottom: 10px">
                      <label>Start date & time</label>
                      <div class="row no-gutters">
                        <div class="col-xs-7">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                              [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                              (bsValueChange)="dateChange('startDate')"
                              [dateCustomClasses]="datepickerCustom"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                              <span class="glyphicon glyphicon-calendar"></span>
                            </button>
                          </div>
                        </div>
                        <div class="col-xs-5">
                          <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                            <div class="datepicker-input">
                              <input type="text" placeholder="Start time" class="form-control" value="{{startDate | date:'HH:mm'}}"
                                disabled>
                              <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                                <span class="glyphicon glyphicon-time"></span>
                              </button>
                            </div>
                            <div class="dropdown-menu menu-content start-time-cal" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                              <timepicker [(ngModel)]="startDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6" style="margin-bottom: 10px">
                      <label>End date & time</label>
                      <div class="row no-gutters">
                        <div class="col-xs-7">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                              bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                              (bsValueChange)="dateChange('endDate')"
                              [dateCustomClasses]="datepickerCustom"
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-end-cal', dateInputFormat: 'DD.MM.YYYY', minDate: startDate, customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                              <span class="glyphicon glyphicon-calendar"></span>
                            </button>
                          </div>
                        </div>
                        <div class="col-xs-5">
                          <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                            <div class="datepicker-input">
                              <input type="text" placeholder="End time" class="form-control" value="{{endDate | date:'HH:mm'}}"
                                disabled>
                              <button type="button" class="datepicker-btn" dropdownToggle aria-controls="endTimePicker">
                                <span class="glyphicon glyphicon-time"></span>
                              </button>
                            </div>
                            <div class="dropdown-menu menu-content start-time-cal" id="endTimePicker" *dropdownMenu (click)="preventClose($event)">
                              <timepicker [(ngModel)]="endDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [hourStep]="1" [minuteStep]="1"></timepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-md-3" style="margin-bottom: 10px">
                  <label>Event list</label>
                  <div class="select-menu event-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true" [ngClass]="{disabled: !eventList.length || eventListLoading}">
                    <div class="menu-list dropdown-toggle" [ngClass]="{error: !checkSelectedEvent() && eventList.length}" dropdownToggle aria-controls="optionalFilter">
                      <div class="menu-selected" *ngIf="!eventListLoading">
                        <span *ngIf="selectAllEvents">
                          <span class="filter-label">All events</span>
                        </span>
                        <ng-container *ngIf="!selectAllEvents && getAllEvent().length > 0">
                          <span class="filter-label">{{getAllEvent().length}} {{getAllEvent().length <= 1 ? "event" : "events"}}</span>
                        </ng-container>
                      </div>
                      <div class="menu-selected" *ngIf="eventListLoading">
                        <div class="menu-loading">
                          <ng-lottie
                            [options]="options"
                            width="35px"
                            height="35px"
                          ></ng-lottie>
                        </div>
                      </div>
                      <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                    </div>
                    <div class="dropdown-menu menu-content" id="optionalFilter" *dropdownMenu (click)="utils.preventClose($event)">
                      <div class="normal-checkbox" (click)="setAllEvent($event)">
                        <input type="checkbox" [checked]="selectAllEvents">
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <div class="checkbox-label">All</div>
                      </div>
                      <ng-container *ngFor="let ev of eventList; let i = index">
                        <div class="normal-checkbox" (click)="changeEvent(i,$event)">
                          <input type="checkbox" [checked]="ev.checked">
                          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                          <div class="checkbox-label">{{ev.value}}</div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12">
              <div class="right-btns">
                <button class="btn btn-green input-height" [disabled]="(hotelOnly && !checkSelectedDoor()) || !checkSelectedEvent()" (click)="showEvent('new')">Show events</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <ng-container *ngIf="searchResult.length > 0">
      <div class="custom-table-wrapper">
        <table class="custom-table">
          <thead>
            <tr>
              <th style="width:200px">Door</th>
              <th>Code</th>
              <th>Time</th>
              <th>Event</th>
              <th style="width:200px">Name</th>
              <th>Reservation</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let result of searchResult">
              <td>{{result.door}}</td>
              <td>{{result.doorCode}}</td>
              <td>{{result.time}}</td>
              <td>{{result.doorEvent}}</td>
              <td>{{result.doorName}}</td>
              <td>{{result.doorReservation}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="show-more">
        <button type="button" class="btn btn-green large-btn show-more-btn" [ngClass]="{disabled: currentCount <= 20}" (click)="showEvent('prev')" style="margin-right: 15px"><span class="glyphicon glyphicon-menu-left"></span></button>
        <label style="display: inline-block">Showing {{currentCount}}/{{totalCount}}</label>
        <button type="button" class="btn btn-green large-btn show-more-btn"[ngClass]="{disabled: currentCount >= totalCount}" (click)="showEvent('next')" style="margin-left: 15px"><span class="glyphicon glyphicon-menu-right"></span></button>
      </div>
      <div class="right-btns">
        <button type="button" class="btn btn-green large-btn show-more-btn" (click)="exportCsv()"><span class="glyphicon glyphicon-download-alt"></span> Export as CSV</button>
      </div>
    </ng-container>
  </div>
  <div class="center-item" *ngIf="isSearching">
    <ng-lottie
      [options]="options"
      width="200px"
      height="200px"
    ></ng-lottie>
  </div>
</div>