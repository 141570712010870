<div class="btn-group mui-menu" [ngClass]="{'full-width': fullWidth}" dropdown #dropdown="bs-dropdown" [dropup]="dropup" [insideClick]="searchable">
  <button dropdownToggle type="button" [ngClass]="{'disabled': disableMenu}" class="btn dropdown-toggle" (click)="openSearch()" [style]="{fontSize: buttonFontSize}">
    <div class="dial-code-item" *ngIf="selectedItem">
      <div class="item-dial">+{{selectedItem.dialCode}}</div>
      <div class="item-label">{{ selectedItem.label }}</div>
    </div><div class="caret"></div>
  </button>
  <div *dropdownMenu class="dropdown-menu" role="menu">
    <div class="menu-search" *ngIf="searchable">
      <input type="text" class="custom-input form-control" placeholder="Search item" [(ngModel)]="searchKeyword" (ngModelChange)="searchListChange()">
    </div>
    <div class="menu-item-scroll" (click)="dropdown.hide()" [attr.id]="'select-menu-' + menuId">
      <ng-container *ngFor="let item of menuListFiltered">
        <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedItem && selectedItem.value === item.value}" [attr.id]="menuId + item.value">
          <div class="dial-code-item">
            <div class="item-dial">+{{item.dialCode}}</div>
            <div class="item-label">{{ item.label }}</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>