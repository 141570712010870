export class LOCKDUMMY {
  public static DUMMY_LOCK_HOTEL = [{
    "available": true,
    "city": "New York",
    "country": "United States",
    "currency": "EUR",
    "defaultCheckInTime": "16:00:00",
    "defaultCheckOutTime": "12:00:00",
    "dropdownHotelName": "MH1",
    "forceSsnEndDate": "2023-01-31",
    "forceSsnStartDate": "2023-01-25",
    "hotelBreakfastImageUrls": [
        "https://bui-2-pictures.s3.eu-west-1.amazonaws.com/ushotels/test/hotels/MH1/BREAKFAST/mh1_breakfast_espresso_house.jpg"
    ],
    "hotelChainId": 1,
    "hotelId": 1,
    "hotelImageUrls": [
        "https://bui-2-pictures.s3.eu-west-1.amazonaws.com/ushotels/test/hotels/MH1/mh1_facade.jpg",
        "https://bui-2-pictures.s3.eu-west-1.amazonaws.com/ushotels/test/hotels/MH1/mh1_room_selection_banner.jpg"
    ],
    "label": "MH1",
    "latitude": "40.76534127036792",
    "longitude": "-73.98287929809365",
    "magicWords": [],
    "maxCheckOutDate": 548,
    "merchantId": 1,
    "name": "Moderne hotel",
    "postalCode": "10019",
    "streetAddress": "243 W 55th St, New York",
    "zone": "Europe/Helsinki"
}]
  public static DUMMY_LOCK_SEARCH = {
    "items": [
      {
          "contactName": "Lila Starling",
          "credentialToken": "123456_123456",
          "doorCode": "589421",
          "doorNumberList": [
            "Room 101", "Room 102"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "874215963",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Xavier Frost",
          "credentialToken": "123456_123457",
          "doorCode": "736805",
          "doorNumberList": [
            "Room 101", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "596328741",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Seraphina Blaze",
          "credentialToken": "123456_123458",
          "doorCode": "124976",
          "doorNumberList": [
            "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "102547896",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Jasper Storm",
          "credentialToken": "123456_123459",
          "doorCode": "890213",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "789654321",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Penelope Wren",
          "credentialToken": "123456_123460",
          "doorCode": "367542",
          "doorNumberList": [
            "Room 101", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "234567890",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Zane Midnight",
          "credentialToken": "123456_123461",
          "doorCode": "451890",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "456789123",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Aurora Quinn",
          "credentialToken": "123456_123462",
          "doorCode": "208734",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "987654321",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Felix Shadow",
          "credentialToken": "123456_123463",
          "doorCode": "615297",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "321456789",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Lyra Moon",
          "credentialToken": "123456_123464",
          "doorCode": "980361",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "678901234",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Atlas Steele",
          "credentialToken": "123456_123465",
          "doorCode": "573816",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "135790246",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Scarlett Ryder",
          "credentialToken": "123456_123466",
          "doorCode": "302745",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "890123456",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Orion Phoenix",
          "credentialToken": "123456_123467",
          "doorCode": "864109",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "543210987",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Luna Valor",
          "credentialToken": "123456_123468",
          "doorCode": "197483",
          "doorNumberList": [
            "Room 101"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "369874521",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Leo Thunder",
          "credentialToken": "123456_123469",
          "doorCode": "648507",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "752843619",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Evangeline Ember",
          "credentialToken": "123456_123470",
          "doorCode": "531964",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "214365870",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Sebastian Silver",
          "credentialToken": "123456_123471",
          "doorCode": "426789",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "456789012",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Celeste Nova",
          "credentialToken": "123456_123472",
          "doorCode": "759324",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "987012345",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Asher Raven",
          "credentialToken": "123456_123473",
          "doorCode": "183250",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "630215874",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Elara Wilder",
          "credentialToken": "123456_123474",
          "doorCode": "894672",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "159487632",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Magnus Stone",
          "credentialToken": "123456_123475",
          "doorCode": "315478",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "824763195",
          "startTime": "2023-03-20T12:00:00Z"
      }
  ],
    "limit": 20,
    "offset": 0,
    "total": 20
  };
  public static DUMMY_LOCK_SEARCH_SHOW_MORE = {
    "items": [
      {
          "contactName": "Lila Starling",
          "credentialToken": "123456_123456",
          "doorCode": "589421",
          "doorNumberList": [
            "Room 101", "Room 102"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "874215963",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Xavier Frost",
          "credentialToken": "123456_123457",
          "doorCode": "736805",
          "doorNumberList": [
            "Room 101", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "596328741",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Seraphina Blaze",
          "credentialToken": "123456_123458",
          "doorCode": "124976",
          "doorNumberList": [
            "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "102547896",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Jasper Storm",
          "credentialToken": "123456_123459",
          "doorCode": "890213",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "789654321",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Penelope Wren",
          "credentialToken": "123456_123460",
          "doorCode": "367542",
          "doorNumberList": [
            "Room 101", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "234567890",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Zane Midnight",
          "credentialToken": "123456_123461",
          "doorCode": "451890",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "456789123",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Aurora Quinn",
          "credentialToken": "123456_123462",
          "doorCode": "208734",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "987654321",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Felix Shadow",
          "credentialToken": "123456_123463",
          "doorCode": "615297",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "321456789",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Lyra Moon",
          "credentialToken": "123456_123464",
          "doorCode": "980361",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "678901234",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Atlas Steele",
          "credentialToken": "123456_123465",
          "doorCode": "573816",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "135790246",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Scarlett Ryder",
          "credentialToken": "123456_123466",
          "doorCode": "302745",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "890123456",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Orion Phoenix",
          "credentialToken": "123456_123467",
          "doorCode": "864109",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "543210987",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Luna Valor",
          "credentialToken": "123456_123468",
          "doorCode": "197483",
          "doorNumberList": [
            "Room 101"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "369874521",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Leo Thunder",
          "credentialToken": "123456_123469",
          "doorCode": "648507",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "752843619",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Evangeline Ember",
          "credentialToken": "123456_123470",
          "doorCode": "531964",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "214365870",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Sebastian Silver",
          "credentialToken": "123456_123471",
          "doorCode": "426789",
          "doorNumberList": [
            "Room 101", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "456789012",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Celeste Nova",
          "credentialToken": "123456_123472",
          "doorCode": "759324",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "987012345",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Asher Raven",
          "credentialToken": "123456_123473",
          "doorCode": "183250",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "630215874",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Elara Wilder",
          "credentialToken": "123456_123474",
          "doorCode": "894672",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "159487632",
          "startTime": "2023-03-20T12:00:00Z"
      },
      {
          "contactName": "Magnus Stone",
          "credentialToken": "123456_123475",
          "doorCode": "315478",
          "doorNumberList": [
            "Room 101", "Room 102", "Room 103", "Room 104", "Room 105", "Room 106"
          ],
          "endTime": "2023-03-24T10:00:00Z",
          "hotelLabel": "MH1",
          "reservationCode": "824763195",
          "startTime": "2023-03-20T12:00:00Z"
      }
  ],
    "limit": 20,
    "offset": 0,
    "total": 20
  }
  public static DUMMY_LOCK_DOOR_LIST = [
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Main entrance",
        "additionalNumber": 1,
        "floor": -1,
        "hotelLabel": "MH1",
        "order": 1,
        "roomName": "entrance",
        "token": "MH1_main_entrance",
        "type": "main"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Floor 1 entrance",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 100,
        "roomName": "entrance",
        "token": "MH1_floor_1_1_entrance",
        "type": "floor"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 101",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 101,
        "roomName": 101,
        "token": "MH1_room_1_101",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 102",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 102,
        "roomName": 102,
        "token": "MH1_room_1_102",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 103",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 103,
        "roomName": 103,
        "token": "MH1_room_1_103",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 104",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 104,
        "roomName": 104,
        "token": "MH1_room_1_104",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 105",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 105,
        "roomName": 105,
        "token": "MH1_room_1_105",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 106",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 106,
        "roomName": 106,
        "token": "MH1_room_1_106",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 107",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 107,
        "roomName": 107,
        "token": "MH1_room_1_107",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 108",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 108,
        "roomName": 108,
        "token": "MH1_room_1_108",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 109",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 109,
        "roomName": 109,
        "token": "MH1_room_1_109",
        "type": "room"
    },
    {
        "accessPointList": "doorlist",
        "accessProfileName": "Room 110",
        "additionalNumber": 1,
        "floor": 1,
        "hotelLabel": "MH1",
        "order": 110,
        "roomName": 110,
        "token": "MH1_room_1_110",
        "type": "room"
    }
  ]
  public static DUMMY_LOCK_USAGE = {
    "items": [
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:30:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:30:30Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:32:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:50:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:55:26Z",
            "reservationCode": 239458573
        },
    ],
    "limit": 20,
    "offset": 0,
    "total": 5
  }
  public static DUMMY_LOCK_USAGE_SHOW_MORE = {
    "items": [
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:30:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:30:30Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:32:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:50:26Z",
            "reservationCode": 239458573
        },
        {
            "accessProfileName": "Room 101",
            "credentialType": "StandardCredential",
            "description": null,
            "door": "Room 101",
            "doorCode": 238495,
            "eventType": "AccessGranted",
            "instant": "2023-03-14T14:55:26Z",
            "reservationCode": 239458573
        },
    ],
    "limit": 20,
    "offset": 0,
    "total": 5
  }

  public static DUMMY_LOCK_USER_DATA = {
    "doorAccessProfileTokenList": [
        "MH1_main_entrance",
        "MH1_floor_1_1_entrance",
        "MH1_room_1_101",
        "MH1_room_1_103",
        "MH1_room_1_105",
        "MH1_room_1_106"
    ],
    "doorCode": "210434",
    "endDate": "2024-02-05",
    "endTime": "12:00:00",
    "hotelLabel": "MH1",
    "reservationCode": "938593483",
    "startDate": "2024-02-05",
    "startTime": "15:45:00",
    "token": "123456_123456",
    "username": "Xavior Frost"
  }
  public static DUMMY_LOCK_EVENT_TYPE = [
    "AccessGranted",
    "AccessPoint",
    "Denied",
    "State"
  ]
}