import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment, UrlSegmentGroup, UrlTree } from '@angular/router';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { ToastrService } from 'ngx-toastr';
import { CustomersService } from 'src/app/services/customers.service';
import { AnimationOptions } from 'ngx-lottie';
import { DialCodeMenuType } from '../common/dial-code-dropdown-menu/dial-code-dropdown-menu.component';
import { HotelWebConfig } from 'src/app/@types/app';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';
import { FinnishSSN } from 'finnish-ssn-validator';
import { UTILS } from 'src/app/helpers/utils';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
const PRIMARY_OUTLET = 'primary';
@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.sass','../../../styles.sass']
})
export class CustomerDetailComponent implements OnInit {
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  private id!: number;
  email!: string;
  mobile!: string;
  isFirstName: boolean = true;
  isLastName: boolean = true;
  isMobile: boolean = true;
  isEmail: boolean = true;
  isSSN: boolean = true;
  isPassport: boolean = true;
  isReason: boolean = true;
  isLoading: boolean = false;
  blocklistHistories: any = [];
  currentCustomerData:any;
  
  selectedState?:MenuType;
  stateList:MenuType[] = [
    { label: 'Blocked', value: 'BLOCKED', disabled: false },
    { label: 'Unblocked', value: 'UNBLOCKED', disabled: false }
  ];

  dialCodeList:DialCodeMenuType[] = [];
  selectedDialCode?:DialCodeMenuType;

  contentAlerts = {
    saveSuccess: { type: 'success', message: 'Changes saved.' },
    saveError: { type: 'danger', message: 'Customer already exists!' },
    missingFields: { type: 'danger', message: 'Please fill in missing fields!' },
    wrongPhoneNumber: { type: 'danger', message: 'Incorrect phone number!' },
    wrongEmail: { type: 'danger', message: 'Incorrect email!' },
  }
  editCustomerForm = new FormGroup({
    firstName: new FormControl('', [Validators.required, Validators.pattern(/^[^@!#$%&*^()_+=/?|\\]+$/i)]),
    lastName: new FormControl('', [Validators.required, Validators.pattern(/^[^@!#$%&*^()_+=/?|\\]+$/i)]),
    mobile: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    ssn: new FormControl('', [this.validateSSN.bind(this)]),
    passport: new FormControl(''),
    reason: new FormControl(''),
  });
  disabledBtnWhileCallingAPI: boolean = false;
  constructor(private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private customersService: CustomersService,
    private storeService: StoreService,
    public utils: UTILS
  ) { }

  ngOnInit(): void {
    const tree: UrlTree = this.router.parseUrl(this.router.url);
    const g: UrlSegmentGroup = tree.root.children[PRIMARY_OUTLET];
    const s: UrlSegment[] = g.segments;
    this.id = Number(s[1].path);
    this.isLoading = true;
    this.dialCodeList = this.utils.getDialCodeListForMenu();
    this.customersService.getCustomer(this.id).subscribe(
      data => {
        this.currentCustomerData = data;
        this.setValuesByBlockedIdForForm(data);
        this.setBlocklistHistories(data.blocklistHistories);
        this.setAllIs(true);
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
        this.toastr.error(err.error.description, 'Error!');
        this.router.navigateByUrl("/app/customers");
      }
    );
  }
  onSubmit(): void {
    if(this.selectedDialCode && this.selectedState) {
      this.disabledBtnWhileCallingAPI = true;
      const firstNameValid = this.f.firstName.valid;
      const lastNameValid = this.f.lastName.valid;

      const mobileValid = this.validatePhoneNumber(`+${this.selectedDialCode.dialCode}${this.editCustomerForm.value.mobile}`);
      
      const emailValid = this.f.email.valid;
      const ssnValid = !this.editCustomerForm.value.ssn || this.f.ssn.valid;
      const passportValid = this.f.passport.valid;

      if(!firstNameValid) {
        this.isFirstName = false;
      }
      if(!lastNameValid) {
        this.isLastName = false;
      }
      if(!firstNameValid || !lastNameValid) {
        this.toastr.error("Name is required", 'Error!');
      }
      if(!mobileValid) {
        this.isMobile = false;
        this.toastr.error("Mobile number is invalid", 'Error!');
      }
      if(!emailValid) {
        this.isEmail = false;
        this.toastr.error("Email is invalid", 'Error!');
      }
      if(!ssnValid) {
        this.isSSN = false;
        this.toastr.error("SSN is invalid", 'Error!');
      }

      if(firstNameValid && lastNameValid && mobileValid && emailValid && ssnValid && passportValid) {
        const request = {
          email: this.editCustomerForm.value.email,
          firstName: this.editCustomerForm.value.firstName,
          id: this.id,
          lastName: this.editCustomerForm.value.lastName,
          mobile: '+' + this.selectedDialCode.dialCode + this.editCustomerForm.value.mobile,
          mobileCountry: '+' + this.selectedDialCode.dialCode,
          phoneNumber: this.editCustomerForm.value.mobile,
          ssn: this.editCustomerForm.value.ssn,
          passportNumber: this.editCustomerForm.value.passport,
          state: this.selectedState.value,
          blocklistHistories: this.blocklistHistories
        }
        this.customersService.saveCustomer(request).subscribe(response => {
          this.toastr.success('Customer details have been saved successfully', 'Success!');
          this.setValuesByBlockedIdForForm(response);
          this.setAllIs(true);
          this.disabledBtnWhileCallingAPI = false;
        });
      }
    }
  }

  selectDialCode(item:DialCodeMenuType) {
    this.selectedDialCode = item;
    this.mobileOnChanges(this.editCustomerForm.value.mobile);
  }
  selectDialCodeBasedOnValue(value?:string) {
    const findDialCode = this.dialCodeList.find(dialCode => {
      if(value !== undefined) {
        return dialCode.value === value.toString();
      } else {
        return dialCode.value === this.hotelConfig.feature.defaultGuestNationality;
      }
    });
    if(findDialCode) {
      this.selectDialCode(findDialCode);
    }
  }

  selectState(item:MenuType) {
    this.selectedState = item;
  }
  selectStateBasedOnValue(value:string) {
    const findState = this.stateList.find(state => state.value === value);
    if(findState) {
      this.selectState(findState)
    }
  }

  addHistory(): void {
    if(this.selectedState) {
      this.disabledBtnWhileCallingAPI = true;
      let request = {
        id: this.id,
        state: this.selectedState.value,
        reason: this.editCustomerForm.value.reason
      }
      this.customersService.addHistory(request).subscribe(response => {
        const _blocklistHistories = [];
        _blocklistHistories.push(response);
        for (let item of this.blocklistHistories) {
          _blocklistHistories.push(item);
        }
        this.blocklistHistories = _blocklistHistories;
        this.toastr.success('Block state has been updated and added to history!', 'Success!');
        this.clearSate();
        this.disabledBtnWhileCallingAPI = false;
        window.location.reload();
      });
    }
  }
  setValuesByBlockedIdForForm(value: any) {
    this.editCustomerForm.patchValue({
      firstName: value.firstName,
      lastName: value.lastName,
      mobile: this.separatePhoneNumber(value.mobile),
      email: value.email,
      ssn: value.ssn,
      passport: value.passportNumber,
      reason: value.blocklistHistories[0].reason,
    });
    this.isReason = true;
    this.email = value.email;
    this.mobile = value.mobile;
    this.selectDialCodeBasedOnValue(this.findPhoneNumberCountry(value.mobile));
    this.selectStateBasedOnValue(value.state);
  }
  setBlocklistHistories(historyList: any) {
    this.blocklistHistories = [];
    historyList.forEach((element: any) => {
      let item = {
        author: element.author,
        created: element.created,
        reason: element.reason,
        state: element.state,
        id: element.id ? element.id : ''
      }
      this.blocklistHistories.push(item);
    });
  }
  get f() {
    return this.editCustomerForm.controls;
  }
  validateSSN(control:AbstractControl): {[key:string]:boolean} | null {
    let ssn = control.value;
    if(!ssn){
      return null;
    }
    if(!FinnishSSN.validate(ssn)) {
      this.isSSN = false;
      return {'invalidSSN':true};
    }
    this.isSSN = true;
    return null;
  }
  firstNameOnChanges(): void {
    this.isFirstName = this.f.firstName.valid;
  }
  lastNameOnChanges(): void {
    this.isLastName = this.f.lastName.valid;
  }
  mobileOnChanges(number: any) {
    if(this.selectedDialCode) {
      this.isMobile = this.validatePhoneNumber(`+${this.selectedDialCode.dialCode}${number}`);
    }
  }
  emailOnChanges() {
    this.isEmail = !this.editCustomerForm.controls.email.invalid;
  }
  ssnOnChanges() {
    this.isSSN = !this.editCustomerForm.controls.ssn.invalid;
  }
  passportOnChanges() {
    this.isPassport = !this.editCustomerForm.controls.passport.invalid;
  }
  reasonOnChanges(): void {
    this.isReason = this.f.reason.valid;
  }
  separatePhoneNumber(phoneNumber: any): any {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const phoneCountryCode = parsedPhoneNumber.country;
    if(phoneCountryCode) {
      const findCountry = this.dialCodeList.find(dialCode => dialCode.code === phoneCountryCode);
      if(findCountry) {
        const separatedNumber = parsedPhoneNumber.number.split(`+${findCountry.dialCode}`)[1];
        return separatedNumber
      }
    }
  }
  findPhoneNumberCountry(phoneNumber:string) {
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
    const phoneCountryCode = parsedPhoneNumber.country;
    if(phoneCountryCode) {
      const findCountry = this.dialCodeList.find(dialCode => dialCode.code === phoneCountryCode);
      if(findCountry) {
        return findCountry.value
      }
    }
  }
  validatePhoneNumber(phoneNumber:string) {
    if(this.selectedDialCode) {
      const getDialCode:any = this.selectedDialCode.code;
      return isValidPhoneNumber(phoneNumber, getDialCode);
    } else {
      return false;
    }
  }
  backToCustomers(): void {
    this.router.navigateByUrl("/app/customers");
  }
  setAllIs(isBoolean: boolean): void {
    this.isFirstName = isBoolean;
    this.isLastName = isBoolean;
    this.isMobile = isBoolean;
    this.isEmail = isBoolean;
    this.isSSN = isBoolean;
    this.isPassport = isBoolean;
  }
  resetInfo(): void {
    this.editCustomerForm.patchValue({
      firstName: this.currentCustomerData.firstName,
      lastName: this.currentCustomerData.lastName,
      mobile: this.separatePhoneNumber(this.currentCustomerData.mobile),
      email: this.currentCustomerData.email,
      ssn: this.currentCustomerData.ssn,
      passport: this.currentCustomerData.passportNumber,
    });
    this.selectDialCodeBasedOnValue(this.findPhoneNumberCountry(this.currentCustomerData.mobile));
    this.setAllIs(true);
  }
  clearSate(): void {
    this.editCustomerForm.patchValue({
      reason: this.blocklistHistories[0].reason
    });
    if(this.currentCustomerData.state) {
      this.selectStateBasedOnValue(this.currentCustomerData.state);
    } else {
      this.selectState(this.stateList[0]);
    }
  }
  backToCustomerCard(): void {
    if (!!this.email&& !!this.mobile) {
      this.router.navigate(["customer-card/",this.email,this.mobile.substring(1,this.mobile.length)]);
      return;
    }
    this.router.navigateByUrl("customers");
  }
}