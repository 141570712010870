import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const URL = "/api/management/secure/hotel-management";
const URL_HOTEL_ROOM_TYPES = "/api/hotel_room_types";
const URL_HOTELS = '/api/hotel-chains/1/hotels';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class HotelService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }
  public getForcingSSN(): Observable<any> {
    return this.http.get(URL);
  }
  public updateForcingHotel(request: any) {
    return this.http.put<any>(URL + "/" + request.hotelLabel, request, {});
  }
  public getHotelRoomTypes(): Observable<any> {
    return this.http.get(URL_HOTEL_ROOM_TYPES);
  }
  public getHotels(): Observable<any> {
    return this.http.get(URL_HOTELS);
  }
}
