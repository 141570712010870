import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { TenantService } from 'src/app/services/tenant.service';
import { ToastrService } from 'ngx-toastr';
import {
  FormControl,
  FormGroup,
  FormArray,
  Validators,
} from '@angular/forms';
import { CONSTANT } from 'src/app/helpers/constants';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { UTILS } from 'src/app/helpers/utils';
import { AnimationOptions } from 'ngx-lottie';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.sass', '../../../styles.sass']
})
export class UserComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  isAdminRole = true;
  allRoles: any = [];
  allTenants: any = [];
  searchResult: any = [];
  allEnviroments: any = [];
  lastSearchEvaluatedId: string = '';
  selectedRoleLabel = 'All';
  selectedRole: any[] = [];
  selectedTenantLabel = '';
  selectedTenant: any = {};
  selectedUser: any = {};
  selectedHotels: any = {};
  loadingContainer: boolean = false;
  submitted = false;
  isValidAddForm = false;
  loadUserLimit = CONSTANT.LIMITOFSEARCH;
  numberOfClickShowMore = 1;
  updatingPassword = false;
  userFrom = new FormGroup({
    email: new FormControl(""),
    allroles: new FormArray([]),
  });
  editFrom = new FormGroup({
    status: new FormControl('', []),
    roles: new FormControl('', []),
    envir: new FormArray([]),
  });
  addForm = new FormGroup({
    email: new FormControl('aaa', [
      Validators.required,
      Validators.pattern(CONSTANT.REGEX_EMAIL),
    ]),
    fullname: new FormControl('', [
      Validators.required,
    ]),
    roles: new FormControl('CLIENT_ADMIN', [
      Validators.required,
    ]),
    envir: new FormArray([], [
      Validators.required,
      Validators.minLength(1)
    ])
  });
  resetPasswordForm: any = new FormGroup({
    password: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)]),
    confirmNewPassword: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=\D*\d).{8,}$/)])
  });
  loadingTenant: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingRole: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  loadingUsers: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild('editModal') editModalTemplate!: TemplateRef<any>;

  // Modal
  modalRef!: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private tenantService: TenantService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private token: TokenStorageService,
    private utils: UTILS
  ) { }
  get f() { return this.userFrom.controls; };
  get getEditForm() { return this.editFrom.controls; };
  get getAddForm() { return this.addForm.controls; };
  get getEnvAddForm() {
    return this.getAddForm.envir as FormArray;
  }
  get envirFormArray() {
    return this.getEditForm.envir as FormArray;
  };
  get rolesFormArray() {
    return this.userFrom.controls.allroles as FormArray;
  };
  ngOnInit(): void {
    this.isAdminRole = this.token.getRole()?.includes('NELSON_MANAGER') || false;
    this.loadingContainer = true;
    let thread = [this.loadingRole.asObservable(), this.loadingTenant.asObservable(), this.loadingUsers.asObservable()];
    const loadTenantsService = this.tenantService.loadTenants({ tenantids: this.isAdminRole ? '' : this.token.getTenantIds() });
    loadTenantsService.subscribe(
      (data: any) => {
        this.allTenants = data.tenants;
        this.allEnviroments = this.utils.getEnvironments(this.allTenants, this.token.decodeJwtToken(this.token.getToken()!));
        this.selectedTenantLabel = this.allTenants[0].tenantname;
        this.selectedTenant = this.allTenants[0];
        this.loadingTenant.next(true);
      },
      (errorResp: any) => {
        this.toastr.error("Error loading clients.", 'Error!');
        this.loadingTenant.next(true);
      });

    this.userService.loadAllRoles().subscribe(
      (data: any) => {
        this.allRoles = data.roles.filter((role: any) => this.isAdminRole || role.id != "NELSON_MANAGER");
        this.addRolesCheckboxes();
        this.loadingRole.next(true);
      },
      (errorResp: any) => {
        this.addRolesCheckboxes();
        this.loadingRole.next(true);
      }
    );
    loadTenantsService.subscribe((data: any) => {
      this.loadUsers();
    })
    combineLatest(thread).subscribe((data) => {
      this.loadingContainer = false;
    });
    setTimeout(() => {
      this.checkParam();
    }, 500);
  }
  checkParam() {
    this.route.queryParamMap.subscribe(params => {
      let paramKeys = params.keys;
      let checkBasicParams = ["editCurrentUser"].every((key: any) => paramKeys.includes(key));
      if (checkBasicParams) {
        const getToken = this.token.getToken();
        if (params.get('editCurrentUser') === "true" && getToken) {
          this.modalRef = this.modalService.show(this.editModalTemplate, { id: 1, class: 'edit-modal' });
          try {
            const decodedToken: any = this.token.decodeJwtToken(getToken);
            this.userService.loadUserDetails({
              userid: decodedToken["cognito:username"]
            }).subscribe(
              (data: any) => {
                this.selectedUser = data.user;
                this.getEditForm.status.setValue(this.selectedUser.enabled);
                this.envirFormArray.clear();
                this.addSelectedEnvironmentCheckboxes(this.selectedUser);
              }
            ),
              (errorResp: any) => {
                this.toastr.error("Error loading user details.", 'Error!');
                this.loadingContainer = false;
              }
          } catch (error) {
            console.log("User account access is malfunctioned!")
          }
        }
      }
    })
  }
  checkEditUserIsCurrent() {
    const getToken = this.token.getToken();
    if(getToken) {
      try {
        const decodedToken: any = this.token.decodeJwtToken(getToken);
        return decodedToken.email === this.selectedUser.email;
      } catch (error) {
        return false;
      }
    }
  }
  changeTenant(tenant: any) {
    this.selectedTenantLabel = tenant.tenantname;
    this.selectedTenant = tenant;
  }
  searchUser() {
    this.submitted = true;
    if (this.userFrom.invalid) {
      return;
    }
    this.loadingContainer = true;
    this.numberOfClickShowMore = 1;
    this.lastSearchEvaluatedId = "";
    this.loadUsers();
  }
  convertRoleTermToString(roles: any[]) {
    return roles.reduce((a, c) => {
      a.push(this.allRoles.filter((role: any) => role.id == c)[0].roleName);
      return a;
    }, []).join();
  }
  selectUserRole(role: any) {
    let hasRole = this.selectedRole.includes(role.id);
    if (hasRole) {
      this.selectedRole = this.selectedRole.filter(r => r != role.id);
    }
    else {
      this.selectedRole.push(role.id);
    }
    if (this.selectedRole.length && this.selectedRole.length < this.allRoles.length) {
      this.selectedRoleLabel = this.convertRoleTermToString(this.selectedRole);
    }
    else {
      this.selectedRoleLabel = 'All';
    }
  }
  loadUsers() {
    let request: any = {
      tenantid: this.selectedTenant.id,
      userroleids: this.selectedRole.join(','),
      email: this.f.email.value,
      limit: this.loadUserLimit,
    };
    if (this.lastSearchEvaluatedId != '') {
      request.lastevaluatedid = this.lastSearchEvaluatedId;
    }
    this.userService.loadUsers(request).subscribe(
      (data: any) => {
        if (this.lastSearchEvaluatedId === "") this.searchResult = [];
        this.searchResult.push(...data.users.filter((user: any) => this.isAdminRole || user.roles != "NELSON_MANAGER"));
        this.loadingContainer = false;
        this.loadingUsers.next(true);
        this.lastSearchEvaluatedId = data.lastEvaluatedId || '';
        this.loadUserLimit = CONSTANT.LIMITOFSEARCH;
      },
      (errorResp: any) => {
        this.toastr.error("Unable to fetch users.", 'Error!');
        this.searchResult = [];
        this.loadingContainer = false;
        this.loadingUsers.next(true);
        this.loadUserLimit = CONSTANT.LIMITOFSEARCH;
      }
    );
  }
  editUser(result?: any) {
    const selectedEnvironmentsIds = this.editFrom.value.envir
      .map((checked: boolean, i: number) => checked ? this.allEnviroments[i].id : null)
      .filter((v: any) => v !== null);
    if (!selectedEnvironmentsIds.length) {
      this.toastr.error("Please select at least one environment.", 'Error!');
      this.loadingContainer = false;
      return;
    }
    this.selectedUser.environmentids = selectedEnvironmentsIds.join(',');
    this.selectedUser.tenantids = this.utils.getTenantsForEnvironments(this.allTenants, selectedEnvironmentsIds);
    this.loadingContainer = true;
    this.selectedUser.username = this.selectedUser.email;
    this.selectedUser.fullname = this.selectedUser.name;
    this.selectedUser.disabled = !this.selectedUser.enabled;
    this.selectedUser.resendcredentials = !this.selectedUser.confirmed;
    this.selectedUser.hotelids = this.getHotelsIdsToSubmit(selectedEnvironmentsIds).join(',');
    this.userService.updateUser(this.selectedUser).subscribe(
      (data: any) => {
        this.lastSearchEvaluatedId = "";
        this.loadUserLimit = this.numberOfClickShowMore * CONSTANT.LIMITOFSEARCH;
        this.loadUsers();
        this.cancelEdit();
      },
      (errorResp: any) => {
        this.toastr.error("Error updating user.", 'Error!');
        this.loadingContainer = false;
      });
  }
  addUser(result?: any) {
    const selectedEnvironmentsIds = this.addForm.value.envir
      .map((checked: boolean, i: number) => checked ? this.allEnviroments[i].id : null)
      .filter((v: any) => v !== null);
    this.loadingContainer = true;
    this.userService.updateUser({
      "email": this.addForm.value.email,
      "username": this.addForm.value.email,
      "fullname": this.addForm.value.fullname,
      "environmentids": selectedEnvironmentsIds.join(','),
      "tenantids": this.utils.getTenantsForEnvironments(this.allTenants, selectedEnvironmentsIds),
      "roles": this.addForm.value.roles,
      "hotelids": this.getHotelsIdsToSubmit(selectedEnvironmentsIds).join(',')
    }).subscribe(
      (data: any) => {
        this.lastSearchEvaluatedId = "";
        this.loadUsers();
        this.cancelEdit();
      },
      (errorResp: any) => {
        this.toastr.error("Error adding user.", 'Error!');
        this.loadingContainer = false;
      }
    );
  }
  resetPassword(result?: any) {
    this.userService.resetUserPassword({
      username: this.selectedUser.email
    }).subscribe(
      (data: any) => {
        this.toastr.success("User password reset successfully", 'Success!', {
          timeOut: 2000
        });
      },
      (errResp: any) => {
        this.toastr.error("Unable to reset user password", 'Error!');
      }
    );
  }
  openUserAddModal(template: TemplateRef<any>) {
    this.getEnvAddForm.clear();
    this.addForm.patchValue({
      email: '',
      fullname: '',
      roles: '',
    });
    let arr: any = [];
    this.allEnviroments.forEach((e: any) => {
      return this.getEnvAddForm.push(new FormControl(arr.includes(e.label)));
    });
    this.selectedHotels = {};
    this.modalRef = this.modalService.show(template, { class: 'add-modal' });
  }
  openEditModal(result: any, template: TemplateRef<any>) {
    this.selectedUser = JSON.parse(JSON.stringify(result));
    this.modalRef = this.modalService.show(template, { id: 1, class: 'edit-modal' });
    this.userService.loadUserDetails({
      userid: this.selectedUser.id
    }).subscribe(
      (data: any) => {
        this.selectedUser = data.user;
        this.getEditForm.status.setValue(this.selectedUser.enabled);
        this.envirFormArray.clear();
        this.addSelectedEnvironmentCheckboxes(this.selectedUser);
        this.selectedHotels = {};
        this.selectedUser.hotelids ? this.selectedUser.hotelids.split(',').forEach((hid: string) => {
          this.selectedHotels[hid] = true;
        }) : {};
      }
    ),
      (errorResp: any) => {
        this.toastr.error("Error loading user details.", 'Error!');
        this.loadingContainer = false;
      }
  }
  addSelectedEnvironmentCheckboxes(result: any) {
    this.allEnviroments.forEach((e: any) => {
      return this.envirFormArray.push(new FormControl(result.environmentids?.split(',').includes(e.id)));
    });
  }
  addRolesCheckboxes() {
    this.allRoles.forEach((e: any) => {
      return this.rolesFormArray.push(new FormControl(false));
    });
  }
  checkInvalidAddForm() {
    const selectEnvironments = this.addForm.value.envir
      .map((checked: boolean, i: number) => checked ? this.allEnviroments[i].label : null)
      .filter((v: any) => v !== null);
    if (selectEnvironments.length > 0)
      return this.isValidAddForm = true
    return this.isValidAddForm = false;
  }
  createAvatar(fullName: string) {
    let allName = fullName.split(" ").reduce((a: string[], c: string) => {
      a.push(c.substring(0, 1).toLocaleUpperCase());
      return a
    }, [])
    return allName.join('');
  }
  cancelEdit() {
    this.modalRef.hide();
  }
  getUserRoleName(roleId: string) {
    return this.allRoles.filter((role: any) => role.id == roleId)[0]?.roleName
  }
  handleClickShowMore() {
    this.numberOfClickShowMore++;
    this.loadingContainer = true;
    this.loadUsers();
  }
  getUserRoleIsCleaner(isEditForm: boolean) {
    return (isEditForm ?
      this.selectedUser.roles : this.addForm.value.roles) == 'CLEANER';
  }
  getSelectedEnvironments(isEditForm: boolean) {
    var formValue = isEditForm ? this.editFrom.value : this.addForm.value;
    return formValue.envir
      .map((checked: boolean, i: number) => checked ? this.allEnviroments[i] : null)
      .filter((v: any) => v !== null);
  }
  isHotelSelected(hotelId: string) {
    return this.selectedHotels[hotelId] == true;
  }
  // Function returns list of hotel Ids to submit for the user create or update actions
  getHotelsIdsToSubmit(selectedEnvironmentIds: string[]) {
    // Loop through all environments which are selected for the user
    // Loop through each hotel and take the id which is in the selected hotel list
    var hotelIds: string[] = [];
    this.allEnviroments.filter((e: any) => selectedEnvironmentIds.indexOf(e.id) >= 0).forEach((e: any) => {
      hotelIds.push(...(e.hotels.filter((h: any) => this.selectedHotels[h.id] == true).map((h: any) => h.id)));
    });
    return hotelIds;
  }

  validateResetPasswordForm(): Boolean {
    let isErrorForm = false;
    if (this.resetPasswordForm.controls.password.status === "INVALID") {
      if (this.resetPasswordForm.controls.password.errors.required) {
        this.toastr.error("Current password is required.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.password.errors) {
        this.toastr.error("Please enter a valid current password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.controls.newPassword.status === "INVALID") {
      if (this.resetPasswordForm.controls.newPassword.errors.required) {
        this.toastr.error("Please enter a new password.", 'Error!');
        return isErrorForm;
      }
      if (this.resetPasswordForm.controls.newPassword.errors) {
        this.toastr.error("Please enter a valid new password.", 'Error!');
        return isErrorForm;
      }
    }
    if (this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.confirmNewPassword) {
      this.toastr.error("New password and confirm password do not match.", 'Error!');
      return false;
    }
    return !isErrorForm;
  }

  submitResetPassword() {
    if (!this.validateResetPasswordForm()) {
      return;
    }
    this.updatingPassword = true;
    const getToken = this.token.getToken();
    if(getToken) {
      try {
        const decodedToken: any = this.token.decodeJwtToken(getToken);
        let refreshToken = this.token.getRefreshToken();
        if(refreshToken && decodedToken) {
          this.authService.loginWithAccess(decodedToken.sub, refreshToken).subscribe(
            (data) => {
              this.userService.updatePassword(data.accesstoken, this.resetPasswordForm.value.password, this.resetPasswordForm.value.newPassword).subscribe(
                (data) => {
                  this.toastr.success("Password has been changed!", 'Success!');
                  this.resetPasswordForm.patchValue({
                    password: "",
                    newPassword: "",
                    confirmNewPassword: ""
                  });
                  this.updatingPassword = false;
                },
                (error) => {
                  this.updatingPassword = false;
                  this.toastr.error(error.error.message, 'Error!');
                }
              )
            },
            (error) => {
              this.updatingPassword = false;
              this.toastr.error("An error occurred when changing password, please try again later!", 'Error!');
            }
          );
        }
      } catch (error) {
        this.updatingPassword = false;
        this.toastr.error("Invalid login session, please log out and log in again, then try changing password again", 'Error!');
      }
    }
  }
}
