import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UTILS } from 'src/app/helpers/utils';

export type DialCodeMenuType = {
  code: string,
  label: string,
  value: string,
  dialCode: string
}

@Component({
  selector: 'dial-code-dropdown-menu',
  templateUrl: './dial-code-dropdown-menu.component.html',
  styleUrls: ['./dial-code-dropdown-menu.component.sass', '../../../../styles.sass']
})
export class DialCodeDropdownMenuComponent implements OnInit {

  @Input() menuId:string = "";
  @Input() disableMenu:boolean = false;
  @Input() fullWidth:boolean = false;
  @Input() menuList:DialCodeMenuType[] = [];
  @Input() selectedItem?:DialCodeMenuType;
  @Input() searchable:boolean = false;
  @Input() dropup:boolean = false;
  @Input() buttonFontSize:string = "18px";

  @Output() selectItem:EventEmitter<DialCodeMenuType> = new EventEmitter<DialCodeMenuType>();

  searchKeyword:string = ""
  menuListFiltered:DialCodeMenuType[] = [];

  constructor(
    public utils: UTILS,
  ) { }

  ngOnInit(): void {
    this.menuListFiltered = this.menuList;
  }

  selectMenuItem(item:DialCodeMenuType) {
    this.selectItem.emit(item)
  }

  searchListChange() {
    if(this.searchKeyword === "") {
      this.menuListFiltered = this.menuList;
    } else {
      const tmpFilter = this.menuList.filter(item => item.label.toLowerCase().includes(this.searchKeyword.toLowerCase()) || item.dialCode.toString().includes(this.searchKeyword))
      this.menuListFiltered = tmpFilter
    }
  }

  openSearch() {
    this.searchKeyword = "";
    this.menuListFiltered = this.menuList;
    setTimeout(() => {
      if(this.selectedItem) {
        const dropdownContainer = document.getElementById(`select-menu-${this.menuId}`);
        const selectedItem = document.getElementById(this.menuId + this.selectedItem.value);
        if(dropdownContainer && selectedItem) {
          dropdownContainer.scrollTop = selectedItem.offsetTop - (this.searchable ? 140 : 100);
        }
      }
    }, 0);
  }

}
