import { formatDate } from '@angular/common';
import { Component, OnInit, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { CONSTANT } from 'src/app/helpers/constants';
import { BreakfastService } from 'src/app/services/breakfast.service';
import { HotelService } from 'src/app/services/hotel.service';
import { PricingService } from 'src/app/services/pricing.service';
import { DatepickerDateCustomClasses } from 'ngx-bootstrap/datepicker';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { AnimationOptions } from 'ngx-lottie';
import { HotelWebConfig } from 'src/app/@types/app';
import { UTILS } from 'src/app/helpers/utils';
import { StoreService } from 'src/app/services/store.service';
import { MenuType } from '../common/dropdown-menu/dropdown-menu.component';

type RuleType = {
  operator: MenuType,
  valueType: MenuType,
  value: string
}
type RuleList = {
  ruleValue: string,
  ruleName: string,
  ruleBaseValueName: string,
  editMode: boolean,
  rule: RuleType,
  originalRule: RuleType,
  supported: boolean
}
type RuleValueConvertType = {
  [key: string]: {
    ruleName: string,
    ruleBaseValueName: string
  }
}

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.sass','../../../styles.sass']
})
export class PricingComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  content: any = {
    pricing: {
      title: 'Pricing',
      resetBtn: 'Reset',
      saveBtn: 'Save',
      alerts: {
        loadResponseError: { type:'danger', message:'No rates found!' },
        loadError: { type:'danger', message:'Failed to load rates!' },
        saveSuccess: { type:'success', message: 'Changes saved.' },
        saveError: { type:'danger', message: 'Failed to save changes!' },
        savePricingRuleError: { type:'danger', message: 'Failed to save pricing rule!' }
      }
    }
  }
  hotels: any[] = [];
  hotelList:MenuType[] = [];
  selectedHotel?:MenuType;

  hotelRoomTypes: any[] = [];
  roomTypeList:MenuType[] = [];
  selectedRoomType?:MenuType;

  datepickerOptions: any = CONSTANT.datepickerConfig;
  isValidStartDate: boolean = true;
  isValidEndDate: boolean = true;
  minEnd:any;
  startDate:any = new Date(new Date().setHours(0,0,0,0));
  endDate:any;
  startPickFlag:number = 0;
  endPickFlag:number = 0;
  startInputFlag:number = 0;
  endInputFlag:number = 0;
  startRepeat:number = 0;
  endRepeat:number = 0;
  secif: string = "SAMEDATE_ECI_FLEX";
  isSaveValid:boolean = false;
  isLoading:boolean = true;
  hasPricingRule:boolean = false;

  priceRates: any[] = [];
  secifPrice: any[] = [];

  // Weekdays
  selectedWeekdays: any[] = [
    { label: 'Sun', weekday: 0, checked: false },
    { label: 'Mon', weekday: 1, checked: false },
    { label: 'Tue', weekday: 2, checked: false },
    { label: 'Wed', weekday: 3, checked: false },
    { label: 'Thu', weekday: 4, checked: false },
    { label: 'Fri', weekday: 5, checked: false },
    { label: 'Sat', weekday: 6, checked: false }
  ];

  // Time SECIF
  timeSECIF: any[] = [
    { name: "SameDayECIFlex_6", time: "06:00" },
    { name: "SameDayECIFlex_7", time: "07:00" },
    { name: "SameDayECIFlex_8", time: "08:00" },
    { name: "SameDayECIFlex_9", time: "09:00" },
    { name: "SameDayECIFlex_10", time: "10:00" },
    { name: "SameDayECIFlex_11", time: "11:00" },
    { name: "SameDayECIFlex_12", time: "12:00" },
    { name: "SameDayECIFlex_13", time: "13:00" },
    { name: "SameDayECIFlex_14", time: "14:00" },
    { name: "SameDayECIFlex_15", time: "15:00" },
  ];

  // Rule pricing
  currency:string = "";
  ruleValueConvert:RuleValueConvertType = {
    SINGLE: {
      ruleName: "Single",
      ruleBaseValueName: "Standard"
    },
    MEMBER: {
      ruleName: "Member",
      ruleBaseValueName: "STG&SGL"
    }
  }
  ruleList?:RuleList[];

  ruleOperatorList:MenuType[] = [
    {
      label: "+",
      value: "PLUS",
      disabled: false
    },
    {
      label: "-",
      value: "MINUS",
      disabled: false
    }
  ]

  ruleValueTypeList:MenuType[] = [];

  pricingForm = new FormGroup({
    selectedWeekday: new FormControl(Validators.required),
  });
  disabledBtnWhileCallingAPI: boolean = false;
  modalRef!: BsModalRef;
  @ViewChild('alertModal') alertTemplate!:TemplateRef<any>;

  datepickerCustom: DatepickerDateCustomClasses[];
  hotelConfig: HotelWebConfig = this.storeService.getConfig();
  constructor(
    private breakfastService: BreakfastService,
    private hotelService: HotelService,
    private pricingService: PricingService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private storeService: StoreService,
    public utils: UTILS
  ) { 
    this.datepickerCustom = []
  }

  ngOnInit(): void {
    this.getHotelRoomTypes();
  }

  updateDatepicker() {
    let custom = [
      {date: this.startDate, classes: ['selectedDate', 'start']},
    ]
    let tmpTime = this.startDate.getTime() + 24*3600*1000;
    while(tmpTime < this.endDate.getTime()) {
      custom.push({
        date: new Date(tmpTime), classes: ['dateRange']
      });
      tmpTime += 24*3600*1000;
    }
    custom.push({date: this.endDate, classes: ['selectedDate', 'end']});
    this.datepickerCustom = custom
  }

  get f () {
    return this.pricingForm.controls;
  }

  // Handle menus
  getPricingTableData() {
    if(this.endDate) {
      this.isLoading = true;
      this.getPriceRates();
      this.getCurrentRule();
      this.clearTableInput();
    }
  }
  selectHotel(item:MenuType) {
    this.selectedHotel = item;
    const findHotel = this.getHotelFromId(item.value);
    if(findHotel) {
      const tmpRuleValueTypeList:MenuType[] = [
        {
          label: "%",
          value: "PERCENT",
          disabled: false,
        },
        {
          label: findHotel.currency,
          value: "AMOUNT",
          disabled: false
        }
      ]
      this.currency = findHotel.currency
      this.ruleValueTypeList = tmpRuleValueTypeList;

      let tmpRoomTypeList:MenuType[] = [];
      this.hotelRoomTypes[findHotel.hotelId].forEach((roomType:any) => {
        tmpRoomTypeList.push({
          label: this.utils.showRoomType(roomType),
          value: roomType,
          disabled: false
        })
      })
      tmpRoomTypeList.push({
        label: this.utils.showRoomType("SAMEDATE_ECI_FLEX"),
        value: "SAMEDATE_ECI_FLEX",
        disabled: false
      })
      this.roomTypeList = tmpRoomTypeList;
      this.selectRoomType(tmpRoomTypeList[0], false)
    }
    this.getPricingTableData();
  }
  getHotelFromId(hotelId:string) {
    const findHotel = this.hotels.find((hotel:any) => hotel.hotelId === Number(hotelId));
    if(findHotel) {
      return findHotel
    } else {
      return undefined
    }
  }

  selectRoomType(item:MenuType, reload:boolean = true) {
    this.selectedRoomType = item;
    if(reload) {
      this.getPricingTableData();
    }
  }

  // -------------------

  getHotels(): void {
    this.breakfastService.getHotels().subscribe(data => {
      this.hotels = data;
      let tmpHotelList:MenuType[] = [];
      data.forEach((hotel:any) => {
        tmpHotelList.push({
          label: this.hotelConfig.MUIfeature.useHotelCode ? hotel.label : hotel.name,
          value: hotel.hotelId.toString(),
          disabled: false
        })
      })
      this.hotelList = tmpHotelList;
      this.selectHotel(tmpHotelList[0])

      this.pricingForm.patchValue({
        selectedWeekday: false,
      });
    });
  }
  getHotelRoomTypes(): void {
    this.hotelService.getHotelRoomTypes().subscribe(data => {
      this.hotelRoomTypes = data;
      this.getHotels();
    });
  }
  clearTableInput():void {
    let getTableInput = document.querySelectorAll<HTMLInputElement>('th input');
    for (let i = 0; i < getTableInput.length; i++) {
      getTableInput[i].value = '';
    }
  }
  changeUseNelson(e:any,index:any) {
    this.priceRates[index].useNelsonPrice = e.target.checked;
    this.isSaveValid = true;
  }
  checkCurrentHotel(checkHotelEnv:string) {
    return checkHotelEnv === environment.hotel;
  }
  //Convert date
  convertDate(date:any, type:string) {
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth()+1)).slice(-2);
    let day = ('0' + date.getDate()).slice(-2);
    switch (type) {
      case 'YYYY-MM-dd':
        return `${year}-${month}-${day}`;
      case 'dd.MM.YYYY':
        return `${day}.${month}.${year}`;
      case 'MM.YYYY':
        return `${month}.${year}`;
      case 'dd.MM.YYYY hh:mm:ss':
        let hour = ('0' + date.getHours()).slice(-2);
        let minute = ('0' + date.getMinutes()).slice(-2);
        let second = ('0' + date.getSeconds()).slice(-2);
        return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    }
    return;
  }
  //Get weekday
  getWeekday(date:any) {
    let inputDate = date.split('-');
    let newDay = parseInt(inputDate[2]);
    let newMonth = parseInt(inputDate[1]);
    let newYear = parseInt(inputDate[0]);
    let fullDate = new Date(newYear,newMonth-1,newDay);
    return fullDate.getDay();
  }
  //Convert string date
  convertStringDate(date:any) {
    let inputDate = date.split('-');
    let newDay = ('0' + parseInt(inputDate[2])).slice(-2);
    let newMonth = ('0' + parseInt(inputDate[1])).slice(-2);
    let newYear = parseInt(inputDate[0]);
    return `${newDay}.${newMonth}.${newYear}`;
  }
  //Datepicker
  validateDate() {
    if(this.startDate && this.endDate && this.startDate != "Invalid Date" && this.endDate != "Invalid Date") {
      document.querySelector<HTMLInputElement>('input')?.classList.remove('invalid');
      this.getPriceRates();
    }
  }

  dateChange(type:string) {
    switch(type) {
      case 'startDate':
        if(this.startDate && ((this.endDate && this.startDate.getTime() > this.endDate.getTime()) || (!this.endDate))) {
          this.endDate = new Date(this.startDate.getTime() + 3*24*3600*1000);
        }
        break;
      case 'endDate':
        if(this.startDate && this.endDate && this.startDate.getTime() > this.endDate.getTime()) {
          this.startDate = new Date(this.endDate.getTime() - 3*24*3600*1000);
        }
        break;
    }
    this.updateDatepicker();
    this.validateDate();
  }
  // Weekday change
  weekdayChange(e:any,day:any) {
    let selectedIndex = this.selectedWeekdays.findIndex((weekday:any) => {
      return weekday.label === day;
    });
    this.selectedWeekdays[selectedIndex].checked = e;
  }

  // Rule pricing
  selectRuleOperator(item: MenuType, ruleValue:string) {
    if(this.ruleList) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        let newRule = JSON.parse(JSON.stringify(selectedRule.rule));
        newRule.operator = item;
        selectedRule.rule = newRule
      }
    }
  }
  selectRuleValueType(item: MenuType, ruleValue:string) {
    if(this.ruleList) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        let newRule = JSON.parse(JSON.stringify(selectedRule.rule));
        newRule.valueType = item;
        selectedRule.rule = newRule
      }
    }
  }
  changeRuleValue(value:any, ruleValue:string) {
    if(this.ruleList) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        let newRule = JSON.parse(JSON.stringify(selectedRule.rule));
        newRule.value = value;
        selectedRule.rule = newRule
      }
    }
  }
  showRuleEdit(ruleValue:string) {
    if(this.ruleList) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        selectedRule.editMode = true;
        selectedRule.rule = selectedRule.originalRule;
      }
    }
  }
  saveRuleEdit(ruleValue:string) {
    if(this.ruleList && this.selectedHotel) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        selectedRule.editMode = true;
        let request = {
          hotelId: this.selectedHotel.value,
          rule: {
            operation: selectedRule.rule.operator.value,
            value: selectedRule.rule.value,
            valueType: selectedRule.rule.valueType.value,
          },
          type: selectedRule.ruleValue
        }
        this.pricingService.savePricingRule(request).subscribe((data: any) => {
          this.getPriceRates();
          this.getCurrentRule();
        });
      }
    }
  }

  getPriceRates() {
    if(this.selectedHotel && this.selectedRoomType) {
      let hotelId = this.selectedHotel.value;
      let productType = this.selectedRoomType.value;
      let startDate = this.convertDate(this.startDate,'YYYY-MM-dd');
      let endDate = this.convertDate(this.endDate,'YYYY-MM-dd');
      if(productType != 'SAMEDATE_ECI_FLEX') {
        let request = {
          'hotelId': hotelId,
          'productType': productType,
          'startDate': startDate,
          'endDate': endDate,
        }
        this.pricingService.getPriceRates(request).subscribe((data: any) => {
          let tmpPriceRate = JSON.parse(JSON.stringify(data));
          tmpPriceRate.forEach((price:any) => {
            price.maxOccupancy = price.maxOccupancy ? (Number(price.maxOccupancy) * 100).toFixed(0) : null;
            price.minOccupancy = price.minOccupancy ? (Number(price.minOccupancy) * 100).toFixed(0) : null;
          })
          this.priceRates = tmpPriceRate;
          this.isLoading = false;
        });
      } else {
        this.pricingService.getSecifPricing(hotelId, startDate, endDate).subscribe((data: any) => {
          this.secifPrice = data;
          this.isLoading = false;
        })
      }
    }
  }
  getSecifTimePrice(index:number, name:string) {
    let priceIndex = this.secifPrice[index].prices.findIndex((item:any) => {
      return item.name === name;
    });
    return this.secifPrice[index].prices[priceIndex].price;
  }
  getCurrentRule() : void {
    if(this.selectedHotel) {
      this.pricingService.getCurrentRule(this.selectedHotel.value).subscribe((data: any) => {
        const ruleListValue = Object.keys(data);
        let tmpRuleList:RuleList[] = [];
        let tmpHasPricingRule:boolean = false;
        ruleListValue.forEach(rule => {
          const ruleData = data[rule];
          const findRuleOperator = this.ruleOperatorList.find(operator => operator.value === ruleData.rule.operation);
          const findRuleValueType = this.ruleValueTypeList.find(value => value.value === ruleData.rule.valueType);
          if(findRuleOperator && findRuleValueType) {
            const ruleValue:RuleType = {
              operator: findRuleOperator,
              valueType: findRuleValueType,
              value: ruleData.rule.value.toString()
            }
            const ruleListItem:RuleList = {
              ruleValue: rule,
              ruleName: this.ruleValueConvert[rule].ruleName,
              ruleBaseValueName: this.ruleValueConvert[rule].ruleBaseValueName,
              editMode: false,
              rule: ruleValue,
              originalRule: ruleValue,
              supported: this.checkSupportedRule(rule)
            }
            if(ruleListItem.supported) {
              tmpHasPricingRule = true;
            }
            this.hasPricingRule = tmpHasPricingRule;
            tmpRuleList.push(ruleListItem);
          }
        })
        this.ruleList = tmpRuleList;
      });
    }
  }
  checkSupportedRule(ruleValue:string) {
    switch(ruleValue) {
      case "SINGLE":
        return this.hotelConfig.MUIfeature.fullPricingPage
      case "MEMBER":
        return this.hotelConfig.feature.membership
      default:
        return false;
    }
  }

  discardRuleEdit(ruleValue:string) {
    if(this.ruleList) {
      const findRuleIndex = this.ruleList.findIndex(rule => rule.ruleValue === ruleValue);
      if(findRuleIndex > -1) {
        const selectedRule = this.ruleList[findRuleIndex]
        selectedRule.editMode = false;
      }
    }
  }

  checkPattern(name:string, value:any) {
    this.isSaveValid = true;
    const ratePattern = new RegExp(/^\d{1,4}(\,|\.)?(\d{1,2})?$/);
    const occupancyPattern = new RegExp(/^[1-9][0-9]?$|^100$/);
    const secifPattern = new RegExp(/^\d{1,2}(\,|\.)?(\d{1,2})?$/);
    switch(name) {
      case 'minRate':
      case 'maxRate':
      case 'nelsonPrice':
      case 'nelsonPriceSass':
        return ratePattern.test(value);
      case 'minOccupancy':
      case 'maxOccupancy':
        return occupancyPattern.test(value);
      default:
        return secifPattern.test(value);
    }
  }

  validateTableInput(table:string, index:number, name:string, value:any) {
    document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${index}'] input[name=${name}]`)?.classList.add("override")
    let patternCheck = this.checkPattern(name,value.target.value);
    if(!patternCheck) {
      let getInput = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${index}'] input[name=${name}]`);
      if(getInput) getInput.value = "";
    }
  }

  fetch() :void {
    this.getPriceRates();
    this.getCurrentRule();
    this.clearTableInput();
  }
  getTableInput(table:string, index:number, name:string) {
    return document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${index}'] input[name=${name}]`)?.value;
  }
  multipleInput(table:string, name:string, value:any) {
    let patternCheck = this.checkPattern(name,value.target.value);
    let getCheckedWeekdays = _.filter(this.selectedWeekdays, (o) => {return o.checked;});
    if(!patternCheck) {
      let getInput = document.querySelector<HTMLInputElement>(`#${table} th input[name=${name}]`);
      if(getInput) getInput.value = "";
    } else if(getCheckedWeekdays.length == 0) {
      if(this.selectedRoomType && this.selectedRoomType.value != 'SAMEDATE_ECI_FLEX') {
        for (let i = 0; i < this.priceRates.length; i++) {
          let getInput = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}'] input[name=${name}]`);
          if(getInput) getInput.value = value.target.value;
        }
      } else {
        for (let i = 0; i < this.secifPrice.length; i++) {
          let getInput = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}'] input[name=${name}]`);
          if(getInput) getInput.value = value.target.value;
        }
      }
    } else {
      getCheckedWeekdays.forEach((item:any) => {
        if(this.selectedRoomType && this.selectedRoomType.value != 'SAMEDATE_ECI_FLEX') {
          for (let i = 0; i < this.priceRates.length; i++) {
            let getRowWeekday = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}']`)?.getAttribute('data-weekday');
            if(getRowWeekday == item.weekday) {
              let getInput = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}'] input[name=${name}]`);
              if(getInput) getInput.value = value.target.value;
            }
          }
        } else {
          for (let i = 0; i < this.secifPrice.length; i++) {
            let getRowWeekday = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}']`)?.getAttribute('data-weekday');
            if(getRowWeekday == item.weekday) {
              let getInput = document.querySelector<HTMLInputElement>(`#${table} tr[data-row='${i}'] input[name=${name}]`);
              if(getInput) getInput.value = value.target.value;
            }
          }
        }
      })
    }
  }
  allInput(type:string,index:number,value:any) {
    switch(type) {
      case 'allRow':
        let patternCheck = this.checkPattern('secif',value.target.value);
        if(!patternCheck) {
          let getInput = document.querySelector<HTMLInputElement>(`#secifProduct tr[data-row='${index}'] input[name=allRow]`);
          if(getInput) getInput.value = "";
        } else {
          this.timeSECIF.forEach((time:any) => {
            let getRowInput = document.querySelector<HTMLInputElement>(`#secifProduct tr[data-row='${index}'] input[name=${time.name}]`);
            if(getRowInput) getRowInput.value = value.target.value;
          })
        }
        break;
      case 'allTable':
        let allPatternCheck = this.checkPattern('secif',value.target.value);
        let getCheckedWeekdays = _.filter(this.selectedWeekdays, (o) => {return o.checked;});
        if(!allPatternCheck) {
          let getInput = document.querySelector<HTMLInputElement>(`#secifProduct th input[name=allTable]`);
          if(getInput) getInput.value = "";
        } else if(getCheckedWeekdays.length == 0) {
          for (let row = 0; row < this.secifPrice.length; row++) {
            this.timeSECIF.forEach((time:any) => {
              let getRowInput = document.querySelector<HTMLInputElement>(`#secifProduct tr[data-row='${row}'] input[name=${time.name}]`);
              if(getRowInput) getRowInput.value = value.target.value;
            })
          }
        } else {
          getCheckedWeekdays.forEach((item:any) => {
            for (let row = 0; row < this.secifPrice.length; row++) {
              let getRowWeekday = document.querySelector<HTMLInputElement>(`#secifProduct tr[data-row='${row}']`)?.getAttribute('data-weekday');
              if(getRowWeekday == item.weekday) {
                this.timeSECIF.forEach((time:any) => {
                  let getRowInput = document.querySelector<HTMLInputElement>(`#secifProduct tr[data-row='${row}'] input[name=${time.name}]`);
                  if(getRowInput) getRowInput.value = value.target.value;
                }) 
              }
            }
          })
          
        }
    }
  }
  save() :void {
    if(this.selectedHotel && this.selectedRoomType) {
      this.disabledBtnWhileCallingAPI = true;
      if(this.selectedRoomType.value != 'SAMEDATE_ECI_FLEX') {
        const tmpPriceRate = JSON.parse(JSON.stringify(this.priceRates))
        for (let i = 0; i < tmpPriceRate.length; i++) {
          let getItem = tmpPriceRate[i];
          const getMinOcp = this.getTableInput('normalProduct',i,'minOccupancy');
          const getMaxOcp = this.getTableInput('normalProduct',i,'maxOccupancy')
          getItem.minRate = this.getTableInput('normalProduct',i,'minRate');
          getItem.maxRate = this.getTableInput('normalProduct',i,'maxRate');
          getItem.minOccupancy = getMinOcp ? (Number(getMinOcp) / 100) : "";
          getItem.maxOccupancy = getMaxOcp ? (Number(getMaxOcp) / 100): "";
          getItem.nelsonPrice = this.getTableInput('normalProduct',i,this.hotelConfig.MUIfeature.fullPricingPage ? 'nelsonPrice' : 'nelsonPriceSass');
        }
        var hasEmpty = tmpPriceRate.filter((p:any) => p.useNelsonPrice && !p.nelsonPrice.trim())
        if(hasEmpty.length){
          this.modalRef = this.modalService.show(this.alertTemplate);
          return;
        }
        this.pricingService.saveRate(tmpPriceRate).subscribe(
          (data: any) => {
            this.toastr.success("Pricing changes saved!", 'Success!');
            this.disabledBtnWhileCallingAPI = false;
            this.fetch();
          },
          (err: any) => {
            this.toastr.error(err.statusText, "Error!");
            this.disabledBtnWhileCallingAPI = false;
          }
        )
      } else {
        for (let i = 0; i < this.secifPrice.length; i++) {
          let getSecifValue = document.querySelectorAll<HTMLInputElement>(`#secifProduct tr[data-row='${i}'] .secifRow input`);
          let priceIndex = 9;
          for (let item = 0; item < getSecifValue.length; item++) {
            this.secifPrice[i].prices[priceIndex].price = getSecifValue[item].value;
            priceIndex--;
          }
        }
        this.pricingService.saveSecifPricing(this.selectedHotel.value, this.secifPrice).subscribe(
          (data: any) => {
            this.toastr.success("Pricing changes saved!", 'Success!');
            this.disabledBtnWhileCallingAPI = false;
          },
          (err: any) => {
            this.toastr.error(err.statusText, "Error!");
            this.disabledBtnWhileCallingAPI = false;
          }
        )
      }
    }
  }
  onKeydown(e: any) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8 || e.keyCode == 190 || e.keyCode == 110)) {
        return false;
    }
  }
}
