import { Injectable } from '@angular/core';
import { HotelWebConfig } from '../@types/app';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  constructor() { }

  hotelConfig: HotelWebConfig = {} as HotelWebConfig;

  updateConfig(newConfig: HotelWebConfig) {
    this.hotelConfig = newConfig;
  }
  getConfig() {
    return this.hotelConfig;
  }
}
