import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CONSTANT } from '../helpers/constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ngModuleJitUrl } from '@angular/compiler';
import { UTILS } from '../helpers/utils';
import { environment } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';

const TOKEN_KEY = 'jwt';
const REFRESH_TOKEN_KEY = 'refresh-jwt';
const USER_KEY = 'auth-user';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
const ROLES_FOR_USER_MANAGEMENT = [
  {
    pageName: 'dashboard',
    friendlyName: 'dashboard',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'users',
    friendlyName: 'users',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
  },
  {
    pageName: 'internal-tools',
    friendlyName: 'internal tools',
    requiredRoles: ['NELSON_MANAGER'],
  }
];
const ROLES_FOR_USER_MANAGEMENT_LOCAL = [
  {
    pageName: 'dashboard',
    friendlyName: 'dashboard',
    requiredRoles: [
      'NELSON_MANAGER',
      'CLIENT_ADMIN',
      'CUSTOMER_SERVICE',
      'CLEANER',
      'REVENUE_MANAGER',
      'ACCOUNTING',
    ],
  },
  {
    pageName: 'users',
    friendlyName: 'users',
    requiredRoles: ['NELSON_MANAGER', 'CLIENT_ADMIN'],
  },
  {
    pageName: 'internal-tools',
    friendlyName: 'internal tools',
    requiredRoles: ['NELSON_MANAGER'],
  }
];
const API_LOGOUT = '/api/user/logout';
const API_LOGIN = '/api/user/login';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  static getToken: any;

  constructor(
    private router: Router,
    private http: HttpClient,
    public utils: UTILS,
    private jwtHelper: JwtHelperService
  ) {}

  logOut(): void {
    this.logoutToken().subscribe((response: any) => {
      window.localStorage.clear();
      setTimeout(() => {
        if(this.utils.isLocalEnvironment()) {
          window.open(`http://${window.location.host}/#/login`, "_self")
        } else {
          let adminEnv = "";
          if(environment.hotel === "admin") {
            adminEnv = window.location.hostname;
          } else {
            adminEnv = "admin.nelson.management";
          }
          window.open(`https://${adminEnv}/#/login`, "_self")
        }
      }, 200);
    });
  }
  logOutBackToAdmin(): void {
    this.logoutToken().subscribe((response: any) => {
      window.localStorage.clear();
      setTimeout(() => {
        if(this.utils.isLocalEnvironment()) {
          window.open(`http://${window.location.host}/#/dashboard?forceSignOut=true`, "_self")
        } else {
          const adminEnv = "admin.nelson.management";
          window.open(`https://${adminEnv}/#/dashboard?forceSignOut=true`, "_self")
        }
      }, 200);
    });
  }
  logoutToken(): Observable<any> {
    return this.http.post(API_LOGOUT, localStorage.getItem('jwt'), {
      responseType: 'text',
    });
  }
  login(username: string, refreshtoken: string): Observable<any> {
    return this.http.post(
      API_LOGIN,
      {
        username,
        refreshtoken,
      },
      httpOptions
    );
  }
  getRefreshToken(): string | null {
    return window.localStorage.getItem(REFRESH_TOKEN_KEY);
  }
  saveRefreshTokenToken(refreshToken: string): void {
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
  reAuthenticate(username: string, refreshToken: string): Observable<any> {
    return this.login(username, refreshToken);
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }
  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }
  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(
      USER_KEY,
      JSON.stringify(user).replace(/['"]+/g, '')
    );
  }
  public getRole(): string | null {
    const jwtToken = window.localStorage.getItem("jwt");
    if(jwtToken) {
      const decodedJwt:any = jwtDecode(jwtToken);
      if(decodedJwt) {
        return decodedJwt.roles;
      }
      return ""
    }
    return ""
  }
  public getEmailCurrentUser(): any {
    const email = window.localStorage.getItem(USER_KEY);
    if (email) {
      return email;
    }
    return {};
  }
  public decodeJwtToken(token: string): any {
    return jwtDecode(token);
  }
  public getPagesFromUserRoles(userRoles: string): any {
    let pages: Array<any> = [];
    let rolesForHotel = this.getPageRequiredRoleConstant();
    for (let page of rolesForHotel) {
      for (let i in page.requiredRoles) {
        if (userRoles.indexOf(page.requiredRoles[i]) !== -1) {
          pages.push(page.pageName);
        }
      }
    }
    return pages;
  }
  public getPageNamesFromUserRoles(userRoles: any): any[] {
    let pages: Array<any> = [];
    let rolesForHotel = this.getPageRequiredRoleConstant();
    for (let page of rolesForHotel) {
      if (page.requiredRoles.includes(userRoles)) {
        pages.push(page);
      }
    }
    return pages || [];
  }
  getPageRequiredRoleConstant(): any[] {
    if (
      (this.utils.isRoleUserManagement() ||
        this.getEnvironmentIds().indexOf(',') > -1) &&
      localStorage.getItem('isUserManagement') === 'true'
    ) {
      return this.utils.isLocalEnvironment() ? ROLES_FOR_USER_MANAGEMENT_LOCAL : ROLES_FOR_USER_MANAGEMENT;
    } else {
      return CONSTANT.PAGE_REQUIRED_ROLES;
    }
  }
  public hasRoles(requiredRoles: string[]): any {
    let valid = false;
    let role = "";
    const jwtToken = window.localStorage.getItem("jwt");
    if(jwtToken) {
      const decodedJwt:any = jwtDecode(jwtToken);
      if(decodedJwt) {
        role = decodedJwt.roles;
      }
    }
    if (role) {
      valid = requiredRoles.includes(role);
    }
    return valid;
  }
  public requiredValuesInArray(rolesArray: any, requiredArray: any) {
    let hasValues = false;
    for (let i = 0, len = requiredArray.length; i < len; i++) {
      if (rolesArray.indexOf(requiredArray[i]) !== -1) {
        hasValues = true;
        break;
      }
    }
    return hasValues;
  }
  public getRoles(pageName: string): any[] {
    let page = this.utils.isLocalEnvironment() ? ROLES_FOR_USER_MANAGEMENT_LOCAL.find((page: any) => {
      return page.pageName == pageName;
    }) : ROLES_FOR_USER_MANAGEMENT.find((page: any) => {
      return page.pageName == pageName;
    });
    if (!page) {
      page = CONSTANT.PAGE_REQUIRED_ROLES.find((page: any) => {
        return page.pageName == pageName;
      });
    }
    return page ? page.requiredRoles : [];
  }
  public getTenantIds(): string {
    return this.decodeJwtToken(this.getToken()!).tenantids;
  }
  public getEnvironmentIds(): string {
    return this.decodeJwtToken(this.getToken()!).environmentids;
  }

  public redirectWithToken = (callback: any) => {
    let token = this.getToken();
    let refreshToken = this.getRefreshToken();
    if (!token || !refreshToken) {
      this.logOut();
      return;
    }
    if (!this.jwtHelper.isTokenExpired(token)) {
      callback(token);
      return;
    }
    this.reAuthenticate(
      this.decodeJwtToken(token)['cognito:username'] as string,
      refreshToken
    ).subscribe(
      (response: any) => {
        if (response.token) {
          this.saveToken(response.token);
          callback(response.token);
        }
      },
      (error: any) => {
        this.logOut();
        return;
      }
    );
  };
}
