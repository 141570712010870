import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const hotelAPI = '/api/hotel-chains/1/hotels';
const hotelRoomType = '/api/hotel_room_types';
const productAPI = '/api/products';
const secureManagement = '/api/management/secure';
const hotelManage = secureManagement + '/hotels';
const hotelChannel = secureManagement + '/channel/hotels'
const hotelEci = secureManagement + '/eci_lco/hotels'
const hotelUsage = secureManagement + '/usage/hotels';
const searchHotelAllotment = secureManagement + '/allotment/search-hotel-allotment';
const roomInfoAPI = secureManagement + '/rooms/info/details/';
const checkChangeRoomAcrossRoomTypes = secureManagement + '/is-change-room-across-room-types';
const roomAPI = secureManagement + '/rooms';
const markClean = roomAPI + '/mark-clean';
const markDirty = roomAPI + '/mark-dirty';
const endOnGoingReservation = secureManagement + '/hotelId/usage-adjustment';
const setDescription = roomAPI + '/info/specific-info';
const setCapacity = roomAPI + '/info/set-capacity';
const saveBuffer = secureManagement + '/channel/save';
const saveEci = secureManagement + '/eci_lco/save';
const hotelOfferAPI = '/api/management/secure/hotel-offers'
const reservationPreviewAPI = '/api/management/secure/reservation-preview';
const dePrioritizeAPI = '/api/management/secure/rooms/info/de-prioritized';

@Injectable({
  providedIn: 'root'
})
export class UsageService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  public getHotels(): Observable<any> {
    return this.http.get(hotelAPI);
  }

  public getHotelRoomTypes(): Observable<any> {
    return this.http.get(hotelRoomType);
  }
  
  public getProducts(): Observable<any> {
    return this.http.post(productAPI, ["ACCOMMODATION"]);
  }

  public getUsage(hotelId:any, request: any): Observable<any> {
    return this.http.get<any>(`${hotelManage}/${hotelId}/usage`, {params: request});
  }

  public getAvailability(hotelId:any, request: any): Observable<any> {
    return this.http.get<any>(`${hotelUsage}/${hotelId}/availability-stat`, {params: request});
  }

  public getAvailabilityChannel(hotelId:any, request: any): Observable<any> {
    return this.http.get<any>(`${hotelChannel}/${hotelId}/availability-stat`, {params: request});
  }

  public getEciLco(hotelId:any, request: any): Observable<any> {
    return this.http.get<any>(`${hotelEci}/${hotelId}/eci-lco-stat`, {params: request});
  }

  public getAllotment(request: any): Observable<any> {
    return this.http.get<any>(searchHotelAllotment, {params: request});
  }

  public getRoomInfo(roomId: number): Observable<any> {
    return this.http.get(roomInfoAPI + roomId);
  }

  public getCleanEvent(roomId: number, request:any): Observable<any> {
    return this.http.get(`${roomAPI}/${roomId}/clean-event-history`, {params: request});
  }

  public markClean(roomId:number) {
    return this.http.post(markClean, roomId);
  }

  public markDirty(roomId:number) {
    return this.http.post(markDirty, roomId);
  }

  public setDescription(request:any) {
    return this.http.post(setDescription, request);
  }

  public setCapacity(request:any) {
    return this.http.post<any>(setCapacity, request);
  }
  
  public saveBufferChange(request:any) {
    return this.http.post<any>(saveBuffer, request);
  }
  
  public saveEciLcoChange(request:any) {
    return this.http.post<any>(saveEci, request);
  }

  public changeRoom(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/change-room`, request);
  }

  public changeRoomState(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/change-room/room-state`, request);
  }

  public swapRoomState(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/swap-room/room-state`, request);
  }

  public swapRoom(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/swap-room`, request);
  }

  public checkChangeRoomAcrossRoomTypes(): Observable<any> {
    return this.http.get(checkChangeRoomAcrossRoomTypes);
  }

  public changeRoomAcrossRoomTypes(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/change-room/room-price-difference`, request);
  }

  public swapRoomAcrossRoomTypes(hotelId:number, request:any) {
    return this.http.post<any>(`${hotelManage}/${hotelId}/swap-room/room-price-difference`, request);
  }

  public getHotelOffer(request:any): Observable<any> {
    return this.http.get(`${hotelOfferAPI}`, {params: request});
  }
  public reservationPreview(request:any): Observable<any> {
    return this.http.post(`${reservationPreviewAPI}`, request);
  }
  public postDePrioritize(request:any): Observable<any> {
    return this.http.post(`${dePrioritizeAPI}?roomId=${request.roomId}&isDePrioritized=${request.isDePrioritized}`, {});
  }
}
