import { Component, Input, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { VoucherService } from 'src/app/services/voucher.service';
import {UTILS} from 'src/app/helpers/utils';
import { ToastrService } from 'ngx-toastr';
import { ModalModule, BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DiscountDetailsComponent } from '../discount-details/discount-details.component';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-discount-table',
  templateUrl: './discount-table.component.html',
  styleUrls: ['../../voucher.component.sass','../../../../../styles.sass']
})
export class DiscountTableComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  @Input() discountResult:any;
  @Input() discountCount:any;
  @ViewChild(DiscountDetailsComponent)
  private discountDetails!: DiscountDetailsComponent;
  @Output() showMore = new EventEmitter<any>();

  hotels: any[] = [];
  selectedHotelLabel: any;
  receivedDiscountDetails:any;
  detailVoucher:any;
  sendVoucher:any = null;

  //Modal
  modalRef!: BsModalRef;
  @ViewChild('voucherModal') voucherModalTemplate!:TemplateRef<any>;

  validityTitle:string = "Error";
  validityDesc:string = "Error description";
  validityType:string = "validity";

  //Date
  resStartDate:any = new Date(new Date().setHours(0,0,0,0));
  resEndDate:any = new Date(new Date().setHours(0,0,0,0));

  bookStartDate:any = new Date(new Date().setHours(0,0,0,0));
  bookEndDate:any = new Date(new Date().setHours(0,0,0,0));
  
  //Toggle
  voucher100show = false;

  //Status
  isLoading:boolean = false;
  newDetailShow:boolean = false;
  newDetail100Show:boolean = false;
  inputClicked = false;
  inputStatus:any = {};
  selectedVoucher:number = -1;
  editDetail:boolean = false;
  deleteVoucher:any;
  voucher100number:number = 1;
  disabledBtnWhileCallingAPI: boolean = false;
  constructor(
    private voucherService: VoucherService,
    public utils: UTILS,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.resEndDate.setDate(this.resEndDate.getDate() + 1);
    this.bookEndDate.setDate(this.bookEndDate.getDate() + 1);
    this.checkAllotmentParam();
  }

  checkAllotmentParam() {
    setTimeout(() => {
      this.route.queryParamMap.subscribe(params => {
        let paramKeys = params.keys;
        let checkAllotParams = ['isRedirectFromHA', 'allotmentId'].every((key:any) => paramKeys.includes(key));
        if(checkAllotParams && params.get('isRedirectFromHA') == 'true') {
          this.toggleNewDetail('show');
          let allotmentId = Number(params.get('allotmentId'));
          this.voucherService.getAllotments(allotmentId).subscribe((data: any) => {
            this.resStartDate = new Date(data.created);
            this.resEndDate = new Date(data.hotelAllotments[0].expiryDateTime);
            this.bookStartDate = new Date(data.hotelAllotments[0].startDate);
            this.bookEndDate = new Date(data.hotelAllotments[0].endDate);
          })
        }
      })
    }, 200);
  }

  getData(): void {
    this.voucherService.getHotels().subscribe(data => {
      this.hotels = data;
      this.selectedHotelLabel = "All";
    });
  }

  changeInputStatus(name:string) {
    this.inputClicked = true;
    this.inputStatus[name] = false;
  }

  callShowMore() {
    this.showMore.emit('showMore');
  }

  getInputValue(name:string, type?:string) {
    let getInput, tmpValue:any;
    getInput = document.querySelector<HTMLInputElement>(`.discount-table input[name=${name}]`);
    if(getInput) tmpValue = getInput.value;
    if(type == 'number') {
      return parseInt(tmpValue);
    } else {
      return tmpValue;
    }
  }

  validateAllDetails() {
    let inputException = ['minNights', 'maxNights', "custom-menu-search"];
    let getInput = document.querySelectorAll<HTMLInputElement>(`.discount-table input`);
    getInput.forEach(input => {
      if(inputException.indexOf(input.name) == -1 && input.value == "") {
        console.log(input.name)
        input.classList.add('input-error');
      }
    });
    let getTextarea = document.querySelector<HTMLInputElement>(`.discount-table textarea`);
    if(!getTextarea!.value) {
      getTextarea?.classList.add('input-error');
    }
    if(!this.discountDetails.selectedCategory){
      this.discountDetails.categoryError = true;
    }

    let getInputError = document.querySelectorAll<HTMLInputElement>(`.discount-table .input-error`);
    console.log(getInputError)
    if(getInputError.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  getVoucher(detail:any) {
    this.detailVoucher = detail;
    if(this.editDetail) {
      this.detailVoucher.validFrom = this.utils.convertDate(this.resStartDate, 'YYYY-MM-dd');
      this.detailVoucher.validUntil = this.utils.convertDate(this.resEndDate, 'YYYY-MM-dd');
      
      let getBookIndex = detail.restrictions.findIndex((item:any) => {return item.type === 'InvoiceDateRestriction'});

      if(getBookIndex == -1) {
        this.detailVoucher.restrictions.push({'type': 'InvoiceDateRestriction', 'dateFrom': this.utils.convertDate(this.bookStartDate, 'YYYY-MM-dd'), 'dateUntil': this.utils.convertDate(this.bookEndDate, 'YYYY-MM-dd')})
      } else {
        this.detailVoucher.restrictions[getBookIndex].dateFrom = this.utils.convertDate(this.bookStartDate, 'YYYY-MM-dd');
        this.detailVoucher.restrictions[getBookIndex].dateUntil = this.utils.convertDate(this.bookEndDate, 'YYYY-MM-dd');
      }
      this.saveVoucher('validity');
    } else {
      this.addVoucher();
    }
  }

  addVoucher() {
    let detail = this.detailVoucher;
    if(this.validateAllDetails()) {
      this.disabledBtnWhileCallingAPI = true;
      let addVoucher:any = {
        'categoryId': detail.category,
        'HotelRestriction': null,
        'WeekDayRestriction': null,
        'allotmentId': detail.allotment,
        'amount': this.getInputValue('voucherAmount'),
        'comment': detail['description'],
        'dateFrom': this.bookStartDate.toISOString(),
        'dateUntil': this.bookEndDate.toISOString(),
        'daysOfWeek': detail['weekdaysArray'],
        'editingMode': 'new',
        'hotelRestriction': 'All',
        'hotels': detail['hotelsArray'],
        'isAddMultiple': false,
        'isAddNew': true,
        'isEditing': true,
        'refundable': detail.refund,
        'key': this.getInputValue('voucherKey'),
        'percentage': detail['percentage'],
        'validFrom': this.utils.convertDate(this.resStartDate, 'YYYY-MM-dd'),
        'validUntil': this.utils.convertDate(this.resEndDate, 'YYYY-MM-dd'),
        'voucherType': detail['detailType'],
        'weekdayRestriction': 'All',
        'status': {},
        'restrictions': [
          {'type': 'ProductTypeRestriction', 'productTypes': ['ACCOMMODATION', 'SINGLE', 'EXTRA_BED']},
          {'type': 'InvoiceDateRestriction', 'dateFrom': this.utils.convertDate(this.bookStartDate, 'YYYY-MM-dd'), 'dateUntil': this.utils.convertDate(this.bookEndDate, 'YYYY-MM-dd')}
        ]
      }
      if(this.newDetail100Show) {
        addVoucher.isAddMultiple = true;
        addVoucher.mulDisVoucherNum = this.voucher100number;
        delete addVoucher.key;
      }
      if(detail.hotels == 'All') {
        addVoucher.HotelRestriction = 'All';
      } else {
        addVoucher.HotelRestriction = detail.hotels.join(", ");
        addVoucher.restrictions.push({
          'type': 'HotelRestriction', 
          'hotelIds': _.map(detail.hotelsArray,(item) => {if(item.checked) return item.hotelId; else return 0;}).filter((item:any) => {return item != 0;})
        })
      }
      if(detail.weekdays == 'All') {
        addVoucher.WeekDayRestriction = 'All';
      } else {
        addVoucher.WeekDayRestriction = detail.weekdays.join(", ");
        addVoucher.restrictions.push({
          'type': 'InvoiceDayOfWeekRestriction', 
          'daysOfWeek': _.map(detail.weekdaysArray,(item) => {if(item.checked) return item['value']; else return 0;}).filter((item:any) => {return item != 0;})
        })
      }
      if(detail.fixedPrice.length > 0) {
        addVoucher.fixedPrice = detail.fixedPrice;
      } else {
        addVoucher.fixedPrice = null;
      }
      if(detail.breakfast) {
        addVoucher.includedBreakfast = detail.breakfast;
      }
      if(detail.eci) {
        addVoucher.includedECI = detail.eci;
        addVoucher.restrictions[0].productTypes.push('ECI');
      }
      if(detail.lco) {
        addVoucher.includedLCO = detail.lco;
        addVoucher.restrictions[0].productTypes.push('LCO');
      }
      if(detail.minNights) {
        addVoucher.minNights = detail.minNights;
      }
      if(detail.maxNights) {
        addVoucher.maxNights = detail.maxNights;
      }
      if(this.inputClicked) {
        addVoucher.status = this.inputStatus;
      }
      if(detail.minNights || detail.maxNights) {
        addVoucher.restrictions.push({'type': 'NightCountRestriction'});
        let nightRestrictionIndex = addVoucher.restrictions.findIndex((item:any) => {return item.type === 'NightCountRestriction'});
        if(detail.minNights) {
          addVoucher.restrictions[nightRestrictionIndex].minNights = detail.minNights;
        }
        if(detail.maxNights) {
          addVoucher.restrictions[nightRestrictionIndex].maxNights = detail.maxNights;
        }
      }
      if(detail.segmentId) {
        addVoucher.segmentId = detail.segmentId;
      }
      this.detailVoucher = addVoucher;
      this.saveVoucher('validity');
    }
  }

  saveVoucher(type:string) {
    switch(type) {
      case 'validity':
        this.disabledBtnWhileCallingAPI = true;
        this.voucherService.validityDiscount(this.detailVoucher).subscribe(
          data => {
            if(Object.keys(data.messages).length === 0) {
              this.saveVoucher('save');
            } else {
              let message = data.messages;
              this.validityType = 'validity';
              this.validityTitle = 'No-overlapped error';
              if(message.INVALID_HOTEL !== undefined) {
                this.validityDesc = 'No overlapped hotel';
              } else if(message.INVALID_WEEK_DAY !== undefined) {
                this.validityDesc = `Weekdays are not overlapped: ${message.INVALID_WEEK_DAY.join(", ")}`;
              } else if(message.INVALID_PERIOD !== undefined) {
                this.validityDesc = `Periods are not overlapped: ${message.INVALID_PERIOD.join(", ")}`;
              }
              this.openAlert(this.voucherModalTemplate);
            }
            this.disabledBtnWhileCallingAPI = false;
          },
          err => {
            this.toastr.error(err.error.message, "Error!");
            this.disabledBtnWhileCallingAPI = false;
          }
        )
        break;
      case 'save':
        this.disabledBtnWhileCallingAPI = true;
        if(this.newDetail100Show) {
          this.voucherService.createDiscount(this.detailVoucher).subscribe(
            data => {
              this.toastr.success("Voucher saved!", 'Success!');
              this.discountDetails.resetNewDetail();
              this.toggleNewDetail('hide');
              data.forEach((newVoucher:any) => {
                this.discountResult.push(newVoucher);
                this.discountCount++;
              });
              this.modalRef && this.modalRef.hide();
              this.disabledBtnWhileCallingAPI = false;
              if(this.voucher100show) {
                this.toggle100voucher();
              }
            },
            err => {
              this.toastr.error(err.error.message, "Error!");
              this.disabledBtnWhileCallingAPI = false;
            }
          )
        } else {
          this.voucherService.saveDiscount(this.detailVoucher).subscribe(
            data => {
              this.toastr.success("Voucher saved!", 'Success!');
              if(this.newDetailShow) {
                this.toggleNewDetail('hide');
                this.discountResult.push(data);
                this.discountCount++;
              }
              if(this.editDetail) {
                this.deselectVoucher();
              }
              this.modalRef && this.modalRef.hide();
              this.disabledBtnWhileCallingAPI = false;
            },
            err => {
              this.toastr.error(err.error.message, "Error!");
              this.disabledBtnWhileCallingAPI = false;
            }
          )
        }
        break;
    }
  }

  openAlert(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
    this.modalRef.setClass('custom-alert');
  }

  checkPattern(name:string, value:any) {
    const datePattern = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\.](0?[1-9]|1[012])[\.]\d{4}$/);
    const amountPattern = new RegExp(/^(?:[1-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?)$/);
    switch (name) {
      case 'voucherKey':
        if(!value) {
          return false;
        } else {
          return true;
        }
      case 'resStartDate':
      case 'resEndDate':
      case 'bookStartDate':
      case 'bookEndDate':
        if(value == "Invalid Date" || value == null) {
          return false;
        } else {
          return true;
        }
      case 'voucherAmount':
        return amountPattern.test(value);
      default:
        return false;
    }
  }

  validateInput(name:string, e:any, type:string) {
    let inputValue;
    if(type == 'object') {
      inputValue = e.target.value;
    } else {
      inputValue = e;
    }
    let patternCheck = this.checkPattern(name, inputValue);
    let getInput = document.querySelector<HTMLInputElement>(`.discount-table input[name=${name}]`);
    if(!patternCheck) {
      if(getInput) {
        getInput.classList.add('input-error');
        return false;
      } else {
        return false;
      }
    } else {
      if(getInput) {
        getInput.classList.remove('input-error');
        return true;
      } else {
        return false;
      }
    }
  }
  customHandleManyVoucher(type:string){
    if(type==='increment'){
      this.voucher100number += 1;
      return
    }
    if(this.voucher100number===1) return;
    this.voucher100number -= 1;
  }

  toggleNewDetail(type:string,voucher100?:boolean) {
    switch(type) {
      case 'show':
        if((this.newDetailShow && this.discountDetails.newDetailShow) || (this.discountDetails.viewDetail)) {
          this.toastr.error("The other voucher is being selected or edited!", "Cannot add new voucher!");
        } else {
          this.newDetailShow = true;
          this.discountDetails.newDetailShow = true;
          if(voucher100) {
            let getVoucher100number:any;
            this.newDetail100Show = true;
            this.discountDetails.newDetail100Show = true;
            let getInput = document.querySelector<HTMLInputElement>(`input[name=voucher100number]`);
            if(getInput) {
              getVoucher100number = getInput.value;
            }
            if(getVoucher100number) {
              this.voucher100number = parseInt(getVoucher100number);
            } else {
              this.voucher100number = 1;
            }
          }
        }
        break;
      case 'hide':
        this.discountDetails.resetNewDetail();
        this.newDetailShow = false;
        this.discountDetails.newDetailShow = false;
        this.newDetail100Show = false;
        this.discountDetails.newDetail100Show = false;
        this.voucher100number = 1;
        
        this.resStartDate = new Date(new Date().setHours(0,0,0,0));
        this.resEndDate = new Date(new Date().setHours(0,0,0,0));
        this.bookStartDate = new Date(new Date().setHours(0,0,0,0));
        this.bookEndDate = new Date(new Date().setHours(0,0,0,0));
        this.inputClicked = false;
        this.inputStatus = {};
        this.detailVoucher = null;
        break;
    }
  }

  viewVoucher(voucher:any) {
    if(this.newDetailShow || this.editDetail) {
      if(voucher.id != this.selectedVoucher) {
        this.toastr.error("The other voucher is being edited!", "Cannot view voucher!");
      }
    } else {
      this.selectedVoucher = voucher.id;
      this.sendVoucher = voucher;
      this.discountDetails.viewDetail = true;

      this.resStartDate = new Date(voucher.validFrom);
      this.resEndDate = new Date(voucher.validUntil);

      let getAllVoucherRow = document.querySelectorAll<HTMLInputElement>(`.table-input.discount-table tbody tr.active`);
      getAllVoucherRow.forEach(item => {
        item.classList.remove('active');
      });

      let getVoucherRow = document.querySelector<HTMLInputElement>(`.table-input.discount-table tbody tr[data-voucherId='${voucher.id}']`);
      if(getVoucherRow) getVoucherRow.classList.add('active');
    }
  }

  deselectVoucher() {
    setTimeout(() => {
      this.selectedVoucher = -1;
      this.editDetail = false;
      this.discountDetails.editDetail = false;
      this.discountDetails.viewDetail = false;
      this.discountDetails.resetNewDetail()
      this.resStartDate = new Date(new Date().setHours(0,0,0,0));
      this.resEndDate = new Date(new Date().setHours(0,0,0,0));
      this.bookStartDate = new Date(new Date().setHours(0,0,0,0));
      this.bookEndDate = new Date(new Date().setHours(0,0,0,0));
      let getAllVoucherRow = document.querySelectorAll<HTMLInputElement>(`.table-input.discount-table tbody tr.active`);
      getAllVoucherRow.forEach(item => {
        item.classList.remove('active');
      });
    }, 100);
  }

  editVoucher(voucher:any) {
    setTimeout(() => {
      if((!this.newDetailShow || !this.editDetail) && voucher.id == this.selectedVoucher) {
        this.editDetail = true;
        this.discountDetails.editDetail = true;
        this.resStartDate = new Date(voucher.validFrom);
        this.resEndDate = new Date(voucher.validUntil);
        
        let getBookDate = _.find(voucher.restrictions,{'type':'InvoiceDateRestriction'});
        if(getBookDate == undefined) {
          this.bookStartDate = new Date(new Date().setHours(0,0,0,0));
          this.bookEndDate = new Date(new Date().setHours(0,0,0,0));
        } else {
          this.bookStartDate = new Date(getBookDate.dateFrom);
          this.bookEndDate = new Date(getBookDate.dateUntil);
        }
      }
    }, 100);
  }

  deleteVoucherModal(voucher:any) {
    this.deleteVoucher = voucher;
    this.validityType = 'delete';
    this.validityTitle = 'Cancel Discount Voucher';
    this.validityDesc = `Do you want to cancel Discount Voucher: "${voucher.key}"?`;
    this.openAlert(this.voucherModalTemplate);
  }

  confirmDeleteVoucher() {
    this.voucherService.cancelDiscount(this.deleteVoucher.id).subscribe(
      data => {
        this.deselectVoucher();
        let deletedIdIndex = this.discountResult.findIndex((item:any) => {return item.id === this.deleteVoucher.id});
        this.discountResult.splice(deletedIdIndex, 1);
        this.modalService.hide();
      },
      err => {
        this.toastr.error(err.error.message, "Error!");
      }
    )
  }

  copyVoucher(text:any) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    this.toastr.success("Voucher copied!", 'Success!');
  }

  updateStartDate(type:string) {
    switch(type) {
      case 'reservation':
        if(this.resEndDate <= this.resStartDate) {
          this.resEndDate.setTime(this.resStartDate.getTime() + 24*60*60*1000);
          let getInput = document.querySelector<HTMLInputElement>('input[name=resEndDate]');
          if(getInput) getInput.value = this.utils.convertDate(this.resEndDate, 'dd.MM.YYYY')!;
        }
        this.validateInput('resStartDate',this.resStartDate, 'value');
        break;
      case 'booking':
        if(this.bookEndDate < this.bookStartDate) {
          this.bookEndDate.setTime(this.bookStartDate.getTime());
          let getInput = document.querySelector<HTMLInputElement>('input[name=bookEndDate]');
          if(getInput) getInput.value = this.utils.convertDate(this.bookEndDate, 'dd.MM.YYYY')!;
        }
        this.validateInput('bookStartDate',this.bookStartDate, 'value');
        break;
    }
  }

  updateEndDate(type:string) {
    switch(type) {
      case 'reservation':
        if(this.resEndDate <= this.resStartDate) {
          this.resStartDate.setTime(this.resEndDate.getTime() - 24*60*60*1000);
          let getInput = document.querySelector<HTMLInputElement>('input[name=resStartDate]');
          if(getInput) getInput.value = this.utils.convertDate(this.resStartDate, 'dd.MM.YYYY')!;
        }
        this.validateInput('resEndDate',this.resEndDate, 'value');
        break;
      case 'booking':
        if(this.bookEndDate < this.bookStartDate) {
          this.bookStartDate.setTime(this.bookEndDate.getTime());
          let getInput = document.querySelector<HTMLInputElement>('input[name=bookStartDate]');
          if(getInput) getInput.value = this.utils.convertDate(this.bookStartDate, 'dd.MM.YYYY')!;
        }
        this.validateInput('bookEndDate',this.bookEndDate, 'value');
        break;
    }
  }

  toggle100voucher() {
    this.voucher100number = 1;
    if(this.voucher100show) {
      this.voucher100show = false;
    } else {
      this.voucher100show = true;
    }
  }

  //Check table
  getRestriction(data:any, type:string) {
    switch(type) {
      case 'bookStart':
      case 'bookEnd':
        let getData = _.find(data,{'type':'InvoiceDateRestriction'});
        if(getData) {
          if(type == 'bookStart') {
            return this.utils.convertStringDate(getData.dateFrom);
          } else {
            return this.utils.convertStringDate(getData.dateUntil);
          }
        } else {
          return null;
        }
      default:
        return null;
    }
  }

}