<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="heading">
            <h1 class="view-title">Edit Allotment</h1>
            <button class="btn btn-lg btn-green" (click)="openCancelAllotmentModal()" 
            [disabled]="currentAllotment.cancelled">
            <i class="glyphicon glyphicon-trash"></i> CANCEL
            </button>
          </div>
        </div>
        <h3 class="cancelled-text" [hidden]="!currentAllotment.cancelled">(This allotment has been cancelled)</h3>
        <div style="overflow: hidden;" class="row">
          <div>
            <form [formGroup]="addAllotmentForm" (ngSubmit)="onSubmit()">
              <div class="row">
                <div class="form-group col-xs-12 col-md-12">
                  <!-- Alloment name -->
                  <label for="alm-name" class="col-sm-2 col-md-3 control-label">Name</label>
                  <div class="col-sm-4 col-md-3">
                    <input type="text" class="form-control custom-input" id="alm-name"
                      [ngClass]="{'error-glowing': !isValidName}" formControlName="name" no-special-character>
                  </div>
                  <!-- Allotment types -->
                  <label for="alm-type" class="col-sm-2 col-md-3 control-label">Type</label>
                  <div class="col-sm-4 col-md-3">
                    <dropdown-menu
                      [menuList]="allotmentTypeList"
                      [selectedItem]="selectedAllotmentType"
                      [disableMenu]="true"
                      [fullWidth]="true"
                      (selectItem)="selectAllotmentType($event)"
                    ></dropdown-menu>
                  </div>
                  <br />
                </div>
              </div>
              
              <!-- Description -->
              <div class="row">
                <div class="form-group col-xs-12 col-sm-12">
                  <label for="alm-description" class="col-sm-2 col-md-3 control-label">Description</label>
                  <div class="col-sm-10 col-md-9">
                    <textarea type="text" class="form-control custom-input textarea-vertical-resize-only nls-input text-input"
                      id="alm-description" formControlName="description" rows="3"></textarea>
                  </div>
                </div>
              </div>
              
              <!-- Group contact email -->
              <div class="row">
                <div class="form-group col-xs-12 col-sm-12" *ngIf="hotelActived.allotmentType === 'HARD'">
                  <label for="alm-contact-person-email" class="col-sm-2 col-md-3 control-label">Group contact email</label>
                  <div class="col-sm-10 col-md-9">
                    <input type="text" class="form-control custom-input nls-input text-input" id="alm-email"
                      [ngClass]="{'error-glowing': !isValidEmail}" formControlName="email" required>
                  </div>
                </div>
              </div>
              
              <!-- Hotels -->
              <div class="row" *ngIf="hotels.length > 1">
                <div class="form-group col-xs-12 col-md-12">
                  <label for="alm-hotels" class="col-sm-2 col-md-3 control-label">Hotels</label>
                  <div class="col-sm-8 col-md-6 allotment-list-hotel">
                    <div class="row">
                      <div class="col-xs-6 col-sm-4 custom-checkbox" [class.cancel-allotment]="currentAllotment.cancelled" *ngFor="let hotelChecked of hotels">
                        <input type="checkbox" [checked]="hotelChecked.selected" (change)="onHotelCheck(hotelChecked)" 
                        [disabled]="isDisabled"/>
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <span class="hotel-label-allotment">{{ utils.hotelDisplayByHotel(hotelConfig, hotelChecked) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper-outer-table">
    <div class="outer-tab-heading" *ngIf="hotels.length > 1">
      <!-- Allotment tab -->
      <div class="row" [hidden]="!hasHotelSelected">
        <div class="col-md-12">
          <div class="allotment-tab">
            <ul class="nav nav-tabs" [hidden]="hotels.length === 0">
              <li *ngFor="let hotel of hotels; let i = index" [ngClass]="{'active allotment-active': hotel.active,'allotment':true}"> 
                <ng-container *ngIf="hotel.selected">
                  <a data-toggle="tab" (click)="activeHotelTab(hotel)">{{ utils.hotelDisplayByHotel(hotelConfig, hotel) }}</a>
                </ng-container>
              </li>
            </ul>
            <span class="divider"></span>
          </div>
        </div>
      </div>
    </div>
  
    <div class="outer-table">
      <div class="container-fluid">
        <div class="row" [hidden]="!hasHotelSelected">
          <div class="col-md-12">
            <div class="tab-content" *ngIf="hotelActived">
              <h2 class="title-search">Configuration for {{ utils.hotelDisplayByLabel(hotelConfig, hotels, hotelActived.label) }}</h2>
              <div class="form-horizontal">
                <div class="row">
                  <div class="form-group col-xs-12 col-md-12">
                    <label class="col-md-3">Start date</label>
                    <div class="col-md-3">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                          [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                          (blur)="validateDate()" (bsValueChange)="dateChange('startDate')" [dateCustomClasses]="datepickerCustom" 
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="hotelActived.allotmentType === 'HARD'">
                      <label class="col-md-2 control-label label-format">Release Date and Time</label>
                      <div class="row no-gutters">
                        <div class="col-md-2">
                          <div class="datepicker-input">
                            <input type="text" placeholder="Select expiry date" class="form-control" bsDatepicker
                              appEditDate [ngClass]="{'error-glowing': isExpiryDate }" [(bsValue)]="expiryDate"
                              #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="validateDate()"
                              (bsValueChange)="updateExpiryDate()" 
                              [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                            <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                              <span class="glyphicon glyphicon-calendar"></span>
                            </button>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                            <div class="datepicker-input">
                              <input type="text" placeholder="Expiry time" class="form-control"
                                [ngClass]="{'error-glowing': isExpiryTime }" value="{{expiryDate | date:'HH:mm'}}"
                                disabled>
                              <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                                <span class="glyphicon glyphicon-time"></span>
                              </button>
                            </div>
                            <div class="dropdown-menu menu-content" id="startTimePicker" *dropdownMenu 
                              (click)="preventClose($event)">
                              <timepicker [(ngModel)]="expiryDate" [showMeridian]="false"
                                [ngModelOptions]="{standalone: true}"></timepicker>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="row">
                  <div class="form-group col-xs-12 col-md-12">
                    <label class="col-md-3">End date</label>
                    <div class="col-md-3">
                      <div class="datepicker-input">
                        <input type="text" placeholder="Select end date" class="form-control" appEditDate
                          [(bsValue)]="endDate" bsDatepicker #endDateInput="bsDatepicker" (input)="disableSearch()"
                          (blur)="validateDate()" (bsValueChange)="dateChange('endDate')" [dateCustomClasses]="datepickerCustom"
                          [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                        <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                          <span class="glyphicon glyphicon-calendar"></span>
                        </button>
                      </div>
                    </div>
                    <div class="form-group" *ngIf="hotelActived.allotmentType === 'HARD'">
                      <label for="alm-room-amount" class="col-md-2 control-label label-format">Set amount of rooms for multiple
                        days</label>
                      <div class="col-md-2 small no-padding">
                        <div class="custom-input-number">
                          <input type="number" class="form-control custom-input text-input nls-input"
                          [ngClass]="{'error-glowing': !isValidateForAmountOfRooms }" [(ngModel)]="applyRangeAmountOfRoom" (keydown)="onKeydown($event)"
                          (change)="validateAmountOfRooms(applyRangeAmountOfRoom)" min="1">
                          <div class="arrow-input">
                            <div class="arr-incr"><span (click)="customValidateAmountOfRooms('increment')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                            <div class="arr-desc"><span (click)="customValidateAmountOfRooms('decrement')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                          </div>
                        </div>
                        
                      </div>
                      <div class="col-md-2 small no-padding">
                        <button class="btn btn-green btn-big apply-hard-allotment-btn"
                          [disabled]="!isValidateForAmountOfRooms"
                          (click)="applyAmountOfRoomForMultipleDay(true)">Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
                
        
                <!-- Day of week -->
                <div class="row">
                  <div class="form-group col-xs-12 col-md-12" *ngIf="hotelActived.allotmentType !== 'HARD'">
                    <label for="alm-weekdays" class="col-md-3">Day</label>
                    <div class="col-md-9" id="alm-weekdays">
                      <div class="custom-checkbox" *ngFor="let hotelChecked of hotelActived.weekDays">
                        <input type="checkbox" [checked]="hotelChecked.selected" [ngClass]="hotelChecked.checked"
                          id="weekday-{{hotelChecked.name}}-{{hotelActived.label}}"
                          (change)="updateDayOfWeek(hotelChecked)" />
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <span id="weekday-{{hotelChecked.name}}-{{hotelActived.label}}"
                          class="hotel-label-allotment ">{{hotelChecked.code }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                
                <!--  Amount of room -->
                <div class="row">
                  <div class="form-group col-xs-12 col-md-12 {{ hotelActived.allotmentType === 'HARD' ? 'amount-of-room-hard-allotment' : 'amount-of-room-soft-allotment' }}">
                    <label for="alm-room-amount" class="col-md-3">{{ hotelActived.allotmentType === 'HARD'
                      ?
                      'Week days' : 'Rooms' }}</label>
                    <div class="col-md-9 amount-room-per-day">
                      <div class="date-zone">
                        <div id="date-container" class="date-container custom-scrollbar">
                          <div *ngFor="let date of hotelActived.hotelAllotmentDates" class="alm-date"
                            [hidden]="!date.checked" [ngClass]="{'weekend': date.day === 'Sat' || date.day === 'Sun'}">
                            <p class="date" ng-bind="date.day">{{ date.day }}</p>
                            <p class="date" ng-bind="date.time"><strong>{{ date.time }}</strong></p>
                            <div class="custom-input-number" [class.cancel-allotment]="currentAllotment.cancelled" style="margin-top: 16px">
                              <input type="number" value="{{ date.availability }}" (change)="updateAvailabilityForWeekdays($event, date)" min="1" (keydown)="onKeydown($event)" 
                              [disabled]="hotelActived.allotmentType === 'HARD'">
                              <div class="arrow-input" [hidden]="hotelActived.allotmentType === 'HARD'">
                                <div class="arr-incr"><span (click)="customChangeAvailabilityForWeekdays('increment',date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                                <div class="arr-desc"><span (click)="customChangeAvailabilityForWeekdays('decrement',date)"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
        
                <!-- Action for soft allotment -->
                <div class="row">
                  <div class="form-horizontal" *ngIf="hotelActived.allotmentType !== 'HARD'">
                    <div class="form-group col-xs-12 col-md-12 set-amount-wrapper">
                      <div class="col-sm-12 col-md-4 col-lg-3">
                        <label for="alm-room-amount">Set amount of rooms for multiple
                          days</label>
                      </div>
                      <div class="col-sm-4 col-md-2 col-lg-2 small no-padding">
                        <div class="custom-input-number">
                          <input type="number" class="form-control custom-input" (keydown)="onKeydown($event)" min="1"
                          [(ngModel)]="applyRangeAmountOfRoom" min="1">
                          <div class="arrow-input">
                            <div class="arr-incr"><span (click)="customChangeRangeAmountOfRoom('increment')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                            <div class="arr-desc"><span (click)="customChangeRangeAmountOfRoom('decrement')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-lg-2">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                            [(bsValue)]="applyRangeStartDate" #startDateInput="bsDatepicker" (input)="disableSearch()"
                            (blur)="validateDate()" (bsValueChange)="rangeDateChange('startDate')" [dateCustomClasses]="datepickerCustomForApplyAmount"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-4 col-md-3 col-lg-2">
                        <div class="datepicker-input">
                          <input type="text" placeholder="Select end date" class="form-control" appEditDate
                            [(bsValue)]="applyRangeEndDate" bsDatepicker #endDateInput="bsDatepicker" [dateCustomClasses]="datepickerCustomForApplyAmount"
                            (input)="disableSearch()" (blur)="validateDate()" (bsValueChange)="rangeDateChange('endDate')"
                            [bsConfig]="{isAnimated: true, containerClass:'theme-red', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                          <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                            <span class="glyphicon glyphicon-calendar"></span>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-3 apply-btn-wrapper">
                        <div>
                          <button class="btn btn-green btn-big"
                            (click)="applyAmountOfRoomForMultipleDay(false)">Apply</button>
                        </div>
                        <div>
                          <button *ngIf="hotelActived.allotmentType !== 'HARD'" class="btn btn-green"
                          (click)="applyToAllHotel()">Apply to all hotels</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
        
                <!--  Action for hard allotment -->
                <div class="row">
                  <div class="form-horizontal" *ngIf="hotelActived.allotmentType !== 'HARD'">
                    <div class="form-group col-xs-12 col-md-12 text-right " [hidden]="hotels.length === 0 || !hasHotelSelected">
                      <div class="">
                        <div class="btn-create-allotment"><button class="btn btn-outline-green" [disabled]="currentAllotment.cancelled || (hotelActived.allotmentType === 'HARD' && selectedRooms.length === 0) || disabledBtnWhileCallingAPI"
                          (click)="editAllotment()"><span
                          class="glyphicon glyphicon-save"></span> SAVE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
        
                <!-- Show rooms -->
                <div class="row">
                  <div class="form-horizontal col-xs-12 col-md-12" *ngIf="isShowSelectRooms">
                    <label for="alm-room-amount text-left" class="col-md-3">Rooms</label>
                    <div class="col-md-4">
                      <label class="top-margin">Available Rooms</label>
                      <div class="room-select-container" id="leftRoom">
                        <ng-container *ngFor="let room of availableRooms; let i = index">
                          <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label" [ngClass]="{active: room.selected}"
                           (click)="selectListRoom(room, $event, true)">Room <b>{{room.label}} </b>({{ room.capacity }})
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <div class="col-md-1">
                      <div class="room-arrow-container">
                        <div class="room-select-arrow">
                          <button type="button" class="btn btn-green" (click)="selectRoom()"><span
                              class="glyphicon glyphicon-chevron-right"></span></button>
                          <button type="button" class="btn btn-green" (click)="unselectRoom()" [disabled]="selectedRooms.length <= 1"><span
                              class="glyphicon glyphicon-chevron-left"></span></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <label class="top-margin">Selected Rooms</label>
                      <div class="room-select-container" id="rightRoom">
                        <ng-container *ngFor="let room of selectedRooms; let i = index">
                          <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label" [ngClass]="{active: room.selected}"
                           (click)="selectListRoom(room, $event, false)">
                            Room <b>{{room.label}} </b>({{ room.capacity }})
                          </div>
                        </ng-container>
                      </div>
                    </div>
                    <br/>
                  </div>
                </div>
                
        
                <!-- Show rooms -->
                <div class="row">
                  <div class="form-horizontal" *ngIf="!isShowSelectRooms">
                    <div class="form-group col-xs-12 col-md-12 row show-available-rooms" *ngIf="currentAllotment.allotmentType === 'HARD'">
                      <label class="col-md-3">Selected Rooms</label>
                      <div class="col-md-9" *ngFor="let item of newRoomInAllotment; let i = index">
                        {{ item.hotelId == hotelActived.hotelId ? item.listRoomSelected.join(', ') : "" }}
                      </div>
                    </div>
                  </div>
                </div>
    
        
                <!-- See usages -->
                <div class="row">
                  <div class="form-horizontal" *ngIf="!isShowSelectRooms">
                    <div class="form-group col-xs-12 col-md-12 row show-available-rooms" *ngIf="hotelActived.allotmentType === 'HARD'">
                      <div class="col-md-3">
                        <a (click)="goToUsage()">See usage ></a>
                      </div>
                    </div>
                  </div>
                </div>
                
        
                <!--  action for hard allotment -->
                <div class="form-horizontal" *ngIf="hotelActived.allotmentType === 'HARD'" >
                  <div class="{{ isShowSelectRooms ? 'text-center' : '' }}" *ngIf="isShowSelectRooms">
                    <div class="col-md-4 col-md-offset-8 { hotelActived.allotmentType === 'HARD' ? 'col-md-offset-3' : 'col-md-offset-2'}}">
                      <div class="row text-right">
                        <div class="col-md-6" style="padding-left: 70px"><button *ngIf="hotelActived.allotmentType === 'HARD' && isShowSelectRooms" class="btn btn-none-outline-green allotment-action-button" (click)="selectRooms()">GO BACK</button></div>
                        <div class="col-md-6"><button class="btn btn-big btn-green format-create-btn allotment-action-button" *ngIf="hotelActived.allotmentType === 'HARD'" (click)="editAllotment()" [disabled]="hotelActived.cancelled || (hotelActived.allotmentType === 'HARD' && selectedRooms.length === 0)"><span
                          class="glyphicon glyphicon-save"></span> SAVE</button></div>
                      </div>
                    </div>
                  </div>
                  <div class="{{ isShowSelectRooms ? 'text-center' : '' }}" *ngIf="!isShowSelectRooms">
                    <div class="col-md-6 col-md-offset-6 text-right">
                      <div class="">
                        <div class="col-md-4 format-btn-edit-HA">
                          <button *ngIf="hotelActived.allotmentType === 'HARD' && !isShowSelectRooms" class="btn btn-green allotment-action-button full-width" 
                          (click)="selectRooms()">SELECT ROOMS</button>
                        </div>
                        <div class="col-md-4 format-btn-edit-HA">
                          <button class="btn btn-green allotment-action-button full-width"  *ngIf="hotelActived.allotmentType === 'HARD' && !isShowSelectRooms" 
                          (click)="goToVoucher()" [disabled]="currentAllotment.cancelled">
                            CREATE VOUCHER
                          </button>
                        </div>
                        <div class="col-md-4 format-btn-edit-HA">
                          <button class="btn btn-green allotment-action-button full-width" *ngIf="hotelActived.allotmentType === 'HARD'" 
                          (click)="editAllotment()" [disabled]="currentAllotment.cancelled || (hotelActived.allotmentType === 'HARD' && selectedRooms.length === 0)">SAVE</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- Allotment tab -->



<!-- Cancel allotment -->
<ng-template #cancelAllotmentModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Cancel allotment</div>
    <ng-container *ngIf="!confirmedCancel">
      <div class="error-desc">Are you sure you want to cancel this allotment?</div>
      <button type="button" class="custom-button gray" (click)="modalRef.hide()">No</button>
      <button type="button" class="custom-button" (click)="cancelAllotment()">Yes</button>
    </ng-container>
  </div>
</ng-template>

<!-- Voucher overlap alert -->
<ng-template #voucherOverlapModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Voucher overlap</div>
      <div class="error-desc">
        There are vouchers that not overlapped: {{voucherOverlap}}.
        Are you sure you still want to update this allotment?
      </div>
      <button type="button" class="custom-button gray" (click)="modalRef.hide()">No</button>
      <button type="button" class="custom-button" (click)="confirmEditAllotment()">Yes</button>
  </div>
</ng-template>

<!-- Voucher overlap alert -->
<ng-template #amountOfRoomModal>
  <div class="modal-body text-center">
    <div class="error-icon"><img class="drop-icon" src="assets/images/question-sign.svg"/></div>
    <div class="error-title">Notification for hard allotment</div>
      <div class="error-desc">The amount of rooms has been changed. Would you like to proceed?</div>
      <button type="button" class="custom-button gray" (click)="cancelUpdateAmount()">No</button>
      <button type="button" class="custom-button" (click)="confirmSetAmountOfRoom()">Yes</button>
  </div>
</ng-template>