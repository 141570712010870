<!--View detail-->
<table class="custom-table detail-table discount-table" *ngIf="viewDetail && sendVoucher">
  <colgroup>
    <col style="width: 48%;">
    <col>
  </colgroup>
  <thead>
    <tr rowspan="2">
      <th colspan="2">Details</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>Type</b></td>
      <td>
        {{getVoucherType(sendVoucher.fixedPrice)}}
      </td>
    </tr>
    <ng-container *ngIf="sendVoucher.fixedPrice.length > 0">
      <tr>
        <td colspan="2" style="text-align: center;">
          <div class="row no-gutters">
            <div class="col-xs-3"><b>1st</b></div>
            <div class="col-xs-3"><b>2nd</b></div>
            <div class="col-xs-3"><b>3rd</b></div>
            <div class="col-xs-3"><b>4th</b></div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="number-input" style="text-align: center;">
          <div class="row no-gap">
            <div class="col-xs-3">
              {{sendVoucher.fixedPrice[0]}}
            </div>
            <div class="col-xs-3">
              {{sendVoucher.fixedPrice[1]}}
            </div>
            <div class="col-xs-3">
              {{sendVoucher.fixedPrice[2]}}
            </div>
            <div class="col-xs-3">
              {{sendVoucher.fixedPrice[3]}}
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="sendVoucher.percentage">
      <td class="percentage"><b>Percentage(%)</b></td>
      <td class="number-input">
        {{sendVoucher.percentage}}%
      </td>
    </ng-container>
    <tr *ngIf="hotelConfig.feature.breakfast">
      <td><b>Incl. Breakfast</b></td>
      <td>
        {{sendVoucher.includedBreakfast ? 'Yes' : 'No'}}
      </td>
    </tr>
    <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
      <td><b>Include ECI</b></td>
      <td>
        {{sendVoucher.includedECI ? 'Yes' : 'No'}}
      </td>
    </tr>
    <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
      <td><b>Include LCO</b></td>
      <td>
        {{sendVoucher.includedLCO ? 'Yes' : 'No'}}
      </td>
    </tr>
    <tr>
      <td><b>Refundable</b></td>
      <td>
        {{sendVoucher.refundable ? 'Yes' : 'No'}}
      </td>
    </tr>
    <tr *ngIf="hotels.length > 1">
      <td><b>Hotel</b></td>
      <td>
        {{getRestriction(sendVoucher.restrictions,'hotel')}}
      </td>
    </tr>
    <tr>
      <td><b>Day</b></td>
      <td>
        {{getRestriction(sendVoucher.restrictions,'weekday')}}
      </td>
    </tr>
    <tr>
      <td><b>Min Nights</b></td>
      <td>
        {{getRestriction(sendVoucher.restrictions,'minNights')}}
      </td>
    </tr>
    <tr>
      <td><b>Max Nights</b></td>
      <td>
        {{getRestriction(sendVoucher.restrictions,'maxNights')}}
      </td>
    </tr>
    <tr>
      <td><b>Category</b></td>
      <td>
        {{getCategory(sendVoucher.categoryId)}}
      </td>
    </tr>
    <tr>
      <td><b>Group Segment</b></td>
      <td>
        <ng-container *ngIf="!editDetail">
          {{getSegment(sendVoucher.segmentId)}}
        </ng-container>
        <ng-container *ngIf="editDetail">
          <dropdown-menu
            [menuList]="segmentList"
            [selectedItem]="selectedSegment"
            [rightAligned]="true"
            [searchable]="true"
            minWidth="200px"
            buttonFontSize="14px"
            [fullWidth]="true"
            (selectItem)="selectSegment($event)"
          ></dropdown-menu>
        </ng-container>
      </td>
    </tr>
    <tr>
      <td><b>Description</b></td>
      <td class="textarea-input">
        <ng-container *ngIf="!editDetail"><span style="word-wrap: break-word;">{{sendVoucher.comment}}</span></ng-container>
        <ng-container *ngIf="editDetail">
          <textarea rows="4" (input)="validateDetailInput('description',$event)">{{sendVoucher.comment}}</textarea>
        </ng-container>
      </td>
    </tr>
    <tr *ngIf="hasAllotRole">
      <td><b>Allotment</b></td>
      <td>
        <ng-container *ngIf="!editDetail">{{getAllotment(sendVoucher.allotmentId)}}</ng-container>
        <ng-container *ngIf="editDetail">
          <dropdown-menu
            [menuList]="allotmentList"
            [selectedItem]="selectedAllotment"
            [rightAligned]="true"
            [minWidth]="'200px'"
            [buttonFontSize]="'14px'"
            [fullWidth]="true"
            [searchable]="true"
            (selectItem)="selectAllotment($event)"
          ></dropdown-menu>
        </ng-container>
      </td>
    </tr>
    <ng-container *ngIf="!editDetail">
      <tr>
        <td><b>Created by</b></td>
        <td style="word-wrap:break-word">
          {{sendVoucher.createdBy}}
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="editDetail">
      <tr>
        <td colspan="2" style="text-align: center;">
          <button class="btn btn-green large-btn" [disabled]="loading" (click)="updateVoucher()"><span
              class="glyphicon glyphicon-ok"></span> Update</button>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>

<!--New detail-->
<table class="custom-table detail-table discount-table" *ngIf="newDetailShow">
  <colgroup>
    <col style="width: 48%;">
    <col>
  </colgroup>
  <thead>
    <tr rowspan="2">
      <th colspan="2">Details</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><b>Type</b></td>
      <td>
        <ng-container>
          <div class="normal-checkbox radio-style">
            <input type="radio" name="type-selection" [checked]="detailType == 'FIXED_PRICE'"
              (click)="toggleDetailType('FIXED_PRICE')">
            <div class="checkbox-box">
              <div class="radio-icon"></div>
            </div>
            <div class="checkbox-label">Fixed price</div>
          </div>
        </ng-container>
        <div class="normal-checkbox radio-style">
          <input type="radio" name="type-selection" [checked]="detailType == 'PERCENTAGE'"
            (click)="toggleDetailType('PERCENTAGE')">
          <div class="checkbox-box">
            <div class="radio-icon"></div>
          </div>
          <div class="checkbox-label">Percentage</div>
        </div>
      </td>
    </tr>
    <ng-container *ngIf="detailType == 'FIXED_PRICE'">
      <tr>
        <td colspan="2" style="text-align: center;">
          <div class="row no-gutters">
            <div class="col-xs-3"><b>1st</b></div>
            <div class="col-xs-3"><b>2nd</b></div>
            <div class="col-xs-3"><b>3rd</b></div>
            <div class="col-xs-3"><b>4th</b></div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="number-input" style="text-align: center;">
          <div class="row no-gap">
            <div class="col-xs-3">
              <div class="custom-input-number">
                <input type="number" min="0" maxlength="7" name="firstPrice" [(ngModel)]="orderFixedPrice.firstPrice"
                (input)="validateDetailInput('firstPrice',$event)">
                <div class="arrow-input">
                  <div class="arr-incr"><span (click)="customHandleFixedPriceInput('increment','firstPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  <div class="arr-desc"><span (click)="customHandleFixedPriceInput('decrement', 'firstPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="custom-input-number">
                <input type="number" min="0" maxlength="7" name="secondPrice" [(ngModel)]="orderFixedPrice.secondPrice"
                (input)="validateDetailInput('secondPrice',$event)">
                <div class="arrow-input">
                  <div class="arr-incr"><span (click)="customHandleFixedPriceInput('increment','secondPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  <div class="arr-desc"><span (click)="customHandleFixedPriceInput('decrement', 'secondPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="custom-input-number">
                <input type="number" min="0" maxlength="7" name="thirdPrice" [(ngModel)]="orderFixedPrice.thirdPrice"
                (input)="validateDetailInput('thirdPrice',$event)">
                <div class="arrow-input">
                  <div class="arr-incr"><span (click)="customHandleFixedPriceInput('increment','thirdPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  <div class="arr-desc"><span (click)="customHandleFixedPriceInput('decrement', 'thirdPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
              </div>
            </div>
            <div class="col-xs-3">
              <div class="custom-input-number">
                <input type="number" min="0" maxlength="7" name="fourthPrice" [(ngModel)]="orderFixedPrice.fourthPrice"
                (input)="validateDetailInput('fourthPrice',$event)">
                <div class="arrow-input">
                  <div class="arr-incr"><span (click)="customHandleFixedPriceInput('increment','fourthPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  <div class="arr-desc"><span (click)="customHandleFixedPriceInput('decrement', 'fourthPrice')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
    <ng-container *ngIf="detailType == 'PERCENTAGE'">
      <td><b>Percentage(%)</b></td>
      <td class="number-input">
        <div class="custom-input-number">
          <input type="number" min="0" max="100" maxlength="3" name="percentage" [(ngModel)]="orderFixedPrice.percentage"
          (input)="validateDetailInput('percentage',$event)">
          <div class="arrow-input">
            <div class="arr-incr"><span (click)="customHandleFixedPriceInput('increment','percentage')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
            <div class="arr-desc"><span (click)="customHandleFixedPriceInput('decrement', 'percentage')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
          </div>
        </div>
      </td>
    </ng-container>
    <tr *ngIf="hotelConfig.feature.breakfast">
      <td><b>Incl. Breakfast</b></td>
      <td>
        <div class="normal-checkbox" [ngClass]="{'disabled': (!hotelConfig.MUIfeature.allowBreakfast100Percent && detailType === 'PERCENTAGE') || (detailType === 'PERCENTAGE' && orderFixedPrice.percentage != 100 && !newDetail100Show)}">
          <input type="checkbox" [checked]="detailGeneral['breakfast']"
            (click)="changeDetailCheckbox('breakfast', $event)">
          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
        </div>
      </td>
    </tr>
    <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
      <td><b>Include ECI</b></td>
      <td>
        <div class="normal-checkbox">
          <input type="checkbox" [checked]="detailGeneral['eci']" (click)="changeDetailCheckbox('eci', $event)">
          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
        </div>
      </td>
    </tr>
    <tr [ngClass]="{hidden: !hotelConfig.feature.eciLco}">
      <td><b>Include LCO</b></td>
      <td>
        <div class="normal-checkbox">
          <input type="checkbox" [checked]="detailGeneral['lco']" (click)="changeDetailCheckbox('lco', $event)">
          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
        </div>
      </td>
    </tr>
    <tr>
      <td><b>Refundable</b></td>
      <td>
        <div class="normal-checkbox">
          <input type="checkbox" [checked]="detailGeneral['refund']" (click)="changeDetailCheckbox('refund', $event)">
          <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
        </div>
      </td>
    </tr>
    <tr *ngIf="hotels.length > 1">
      <td><b>Hotel</b></td>
      <td>
        <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
          <div class="menu-list dropdown-toggle" id="selectHotels" dropdownToggle aria-controls="selectHotels">
            <div class="menu-selected">{{selectedHotels}}</div>
            <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
          </div>
          <div class="dropdown-menu dropdown-menu-right menu-content" id="selectHotels" *dropdownMenu
            (click)="utils.preventClose($event)">
            <div class="content-item" (click)="selectAllHotels()">All</div>
            <ng-container *ngFor="let hotel of hotels; let i = index">
              <div class="normal-checkbox">
                <input type="checkbox" (click)="hotelChange($event,i)" [checked]="hotel.checked">
                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                <div class="checkbox-label">{{utils.hotelDisplayByHotel(hotelConfig, hotel)}}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td><b>Day</b></td>
      <td>
        <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
          <div class="menu-list dropdown-toggle" id="selectWeekdays" dropdownToggle aria-controls="selectWeekdays">
            <div class="menu-selected">{{selectedWeekdays}}</div>
            <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
          </div>
          <div class="dropdown-menu dropdown-menu-right menu-content" id="selectWeekdays" *dropdownMenu
            (click)="utils.preventClose($event)">
            <div class="content-item" (click)="selectAllWeekdays()">All</div>
            <ng-container *ngFor="let day of weekdays; let i = index">
              <div class="normal-checkbox">
                <input type="checkbox" (click)="weekdayChange($event,i)" [checked]="day.checked">
                <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                <div class="checkbox-label">{{day.label}}</div>
              </div>
            </ng-container>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td><b>Min Nights</b></td>
      <td class="number-input">
        <div class="custom-input-number">
          <input type="number" name="minNights" (blur)="nightInput('minNights',$event)" [(ngModel)]="nightsInput.minNights">
          <div class="arrow-input">
            <div class="arr-incr"><span (click)="customHandleNightValue('increment','minNights')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
            <div class="arr-desc"><span (click)="customHandleNightValue('decrement', 'minNights')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td><b>Max Nights</b></td>
      <td class="number-input">
        <div class="custom-input-number">
          <input type="number" name="maxNights" (blur)="nightInput('maxNights',$event)" [(ngModel)]="nightsInput.maxNights">
          <div class="arrow-input">
            <div class="arr-incr"><span (click)="customHandleNightValue('increment','maxNights')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
            <div class="arr-desc"><span (click)="customHandleNightValue('decrement', 'maxNights')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <td><b>Category</b></td>
      <td>
        <dropdown-menu
          [menuList]="categoryList"
          [selectedItem]="selectedCategory"
          [highlightError]="categoryError"
          [rightAligned]="true"
          minWidth="150px"
          buttonFontSize="14px"
          [fullWidth]="true"
          (selectItem)="selectCategory($event)"
        ></dropdown-menu>
      </td>
    </tr>
    <tr>
      <td><b>Group Segment</b></td>
      <td>
        <dropdown-menu
          [menuList]="segmentList"
          [selectedItem]="selectedSegment"
          [rightAligned]="true"
          [searchable]="true"
          minWidth="200px"
          buttonFontSize="14px"
          [fullWidth]="true"
          (selectItem)="selectSegment($event)"
        ></dropdown-menu>
      </td>
    </tr>
    <tr>
      <td><b>Description</b></td>
      <td class="textarea-input">
        <textarea rows="4" (input)="validateDetailInput('description',$event)"></textarea>
      </td>
    </tr>
    <tr *ngIf="hasAllotRole">
      <td><b>Allotment</b></td>
      <td>
        <dropdown-menu
          [menuList]="allotmentList"
          [selectedItem]="selectedAllotment"
          [rightAligned]="true"
          [minWidth]="'200px'"
          [buttonFontSize]="'14px'"
          [fullWidth]="true"
          [searchable]="true"
          (selectItem)="selectAllotment($event)"
        ></dropdown-menu>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="text-align: center;">
        <button class="btn btn-green large-btn" [disabled]="loading" (click)="addVoucher()"><span class="glyphicon glyphicon-ok"></span>
          Create</button>
      </td>
    </tr>
  </tbody>
</table>