import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UTILS } from 'src/app/helpers/utils';
import { TokenStorageService } from 'src/app/services/token-storage.service';
const TOKEN_KEY = 'jwt';
const USER_KEY = 'auth-user';

@Component({
  selector: 'app-header-user-management',
  templateUrl: './header-user-management.component.html',
  styleUrls: ['./header-user-management.component.sass']
})
export class HeaderUserManagementComponent implements OnInit {

  isLoggedIn: boolean = false;
  email?: string;
  menuItems: any = [];

  constructor(private router: Router, private tokenStorageService: TokenStorageService, private utils: UTILS,) { }

  ngOnInit(): void {
    this.isLoggedIn = window.localStorage.getItem(TOKEN_KEY) ? true : false;
    if (this.isLoggedIn) {
      const email = this.tokenStorageService.getEmailCurrentUser();
      this.email = email;
      // Show menu items on tabs
      let token = window.localStorage.getItem(TOKEN_KEY);
      if (token) {
        localStorage.setItem("isUserManagement", "true");
        const roles = this.tokenStorageService.getRole();;
        this.menuItems = this.tokenStorageService.getPageNamesFromUserRoles(roles);
      }
    } else {
      const getLastUrl = this.utils.encodeLastUrl();
      // this.router.navigateByUrl(`/login${!getLastUrl.includes("forceSignOut") ? "?lastUrl=" + getLastUrl : ""}`);
      this.router.navigateByUrl(`/login`);
    }
  }
  logout(): void {
    this.isLoggedIn = false;
    this.tokenStorageService.logOut();
  }
  renderAvatar() {
    const getEmailName = (this.email || '');
    const avatar = getEmailName.split(' ').reduce((a: any, c) => {
      a.push(c.substring(0, 1).toLocaleUpperCase());
      return a;
    }, []);
    return avatar.join('');
  }
  openEditCurrentUser() {
    const userRole = this.tokenStorageService.getRole();
    if(userRole && ["NELSON_MANAGER", "CLIENT_ADMIN"].includes(userRole)) {
      if(this.router.url.includes("users?editCurrentUser")) {
        window.location.reload();
      } else {
        this.router.navigate(['/users'], {queryParams: {editCurrentUser: true}});
      }
    }
  }
}