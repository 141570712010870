<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">Reports</h1>
        </div>
  
        <div class="row">
          <div class="col-md-5 col-lg-4">
            <label for="select-report">Select Report</label>
            <dropdown-menu
              [menuList]="reportList"
              [selectedItem]="selectedReport"
              [fullWidth]="true"
              (selectItem)="selectReport($event)"
            ></dropdown-menu>
          </div>
  
          <div class="col-md-5 col-lg-8 sort-date">
            <label>Shortcuts</label>
            <div class="shortcuts">
              <button class="btn btn-solid-green btn-big distance-btn" download="sales-report.csv" [class.active]="activeClassFilter==='week'" (click)="lastWeek($event)" [disabled]="fillTimeDisable">
                <span class="glyphicon glyphicon-bookmark"></span> Last Week
              </button>
              <button class="btn btn-solid-green btn-big distance-btn" download="sales-report.csv" [class.active]="activeClassFilter==='month'" (click)="lastMonth($event)" [disabled]="fillTimeDisable">
                <span class="glyphicon glyphicon-bookmark"></span> Last Month
              </button>
              <button class="btn btn-solid-green btn-big distance-btn" download="sales-report.csv" [class.active]="activeClassFilter==='start-month'" (click)="startOfThisMonth($event)" [disabled]="fillTimeDisable">
                <span class="glyphicon glyphicon-bookmark"></span> Start of Month
              </button>
              <button class="btn btn-solid-green btn-big distance-btn" download="sales-report.csv" [class.active]="activeClassFilter==='start-year'" (click)="startOfYear($event)" [disabled]="fillTimeDisable">
                <span class="glyphicon glyphicon-bookmark"></span> Start of Year
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-md-3">
            <label for="start-date">From</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Start date" class="form-control" bsDatepicker
                [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableSearch()" (blur)="downloadBtnDisabled()" [minDate]="minDateOfReportDate" [disabled]="!requireStartDate || isCleaningReport"
                (bsValueChange)="dateChange('startDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-red report-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" [disabled]="!requireStartDate || isCleaningReport" (click)="startDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-3">
            <label for="until">Until</label>
            <div class="datepicker-input">
              <input type="text" placeholder="End date" class="form-control" bsDatepicker
                [(bsValue)]="endDate" #endDateInput="bsDatepicker" (input)="disableSearch()" (blur)="downloadBtnDisabled()" [minDate]="minDateOfReportDate" [disabled]="!requireEndDate"
                (bsValueChange)="dateChange('endDate')"
                [dateCustomClasses]="datepickerCustom"
                [bsConfig]="{isAnimated: true, containerClass:'theme-green', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" [disabled]="!requireEndDate" (click)="endDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-12 col-sm-4 col-md-2">
            <label for="reportDate">Date</label>
            <div class="datepicker-input">
              <input type="text" placeholder="Report date" class="form-control" bsDatepicker 
                [(bsValue)]="reportDate" #reportDateInput="bsDatepicker" (input)="disableSearch()" (blur)="downloadBtnDisabled()" [disabled]="!requireReportDate || !requireStartDate"
                (bsValueChange)="dateChange('reportDate')"
                [bsConfig]="{isAnimated: true, containerClass:'theme-green report-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
              <button type="button" class="datepicker-btn" [disabled]="!requireReportDate || !requireStartDate" (click)="reportDateInput.toggle()">
                <span class="glyphicon glyphicon-calendar"></span>
              </button>
            </div>
          </div>
          <div class="col-xs-6 col-sm-4 col-md-2">
            <label for="report-time">Time</label>
            <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <div class="datepicker-input">
                <input type="text" class="form-control" id="reportTimepicker" value="{{reportDate | date:'HH:mm'}}" disabled>
                <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker" [ngClass]="{'disable-el': !requireReportDate || !requireStartDate}">
                  <span class="glyphicon glyphicon-time"></span>
                </button>
              </div>
              <div class="dropdown-menu menu-content" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                <timepicker [(ngModel)]="reportDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}" [disabled]="!requireReportTime">
                </timepicker>
              </div>
            </div>
          </div>
          <!-- Hotels -->
          <div class="col-xs-6 col-sm-4 col-md-2" *ngIf="hotels.length > 1">
            <label for="hotel">Hotel</label>
            <dropdown-menu
              [menuList]="hotelList"
              [selectedItem]="selectedHotelItem"
              [fullWidth]="true"
              (selectItem)="selectHotel($event)"
            ></dropdown-menu>
          </div>
          <!-- -------------- -->
        </div>
        <div class="btn-download text-right">
          <div>
            <button class="btn btn-green btn-big" (click)="downloadGuestsInHouse($event)" [disabled]="downloadGuestsInHouseDisabled || isLoadingDownload">
              <span class="glyphicon glyphicon-download-alt"></span> Download Guests In-House
            </button>
          </div>
          <div>
            <button class="btn btn-green btn-big" (click)="downloadReport($event)" [disabled]="downloadDisabled  || isLoadingDownload">
              <span class="glyphicon glyphicon-download-alt"></span> Download Report
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Loading icon -->
    <div class="center-item" *ngIf="isLoadingDownload">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>
  </div>
</div>

<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<app-footer></app-footer>
