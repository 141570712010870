<div class="view-subtitle">Discount Voucher</div>
<div class="row no-gutters">
  <div class="col-xs-12 col-sm-8 col-md-9" id="discount-table">
    <div class="voucher-table">
      <table class="custom-table table-input discount-table">
        <thead>
          <tr>
            <th rowspan="2" class="name">Voucher code</th>
            <th colspan="2" class="date">Booking Period</th>
            <th colspan="2" class="date">Stay Period</th>
            <th rowspan="2" class="amount">Nights per voucher</th>
            <th rowspan="2">Used</th>
            <th rowspan="2" class="edit">Edit</th>
            <th rowspan="2" class="action">Action</th>
          </tr>
          <tr>
            <th class="date-col">Start Date</th>
            <th class="date-col">End Date</th>
            <th class="date-col">Start Date</th>
            <th class="date-col">End Date</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let discount of discountResult">
            <tr class="new-row" [attr.data-voucherId]="discount.id" (click)="viewVoucher(discount)">
              <td>
                <div class="name" tooltip="{{discount.key}}" placement="right" (click)="copyVoucher(discount.key)">
                  {{discount.key}}</div>
              </td>
              <ng-container *ngIf="selectedVoucher != discount.id || !editDetail">
                <td>{{utils.convertStringDate(discount.validFrom)}}</td>
                <td>{{utils.convertStringDate(discount.validUntil)}}</td>
                <td>{{getRestriction(discount.restrictions,'bookStart')}}</td>
                <td>{{getRestriction(discount.restrictions,'bookEnd')}}</td>
              </ng-container>
              <ng-container *ngIf="selectedVoucher == discount.id && editDetail">
                <td class="date-input">
                  <input name="resStartDate" type="text" (click)="changeInputStatus('startReservationPeriod')"
                    bsDatepicker [(bsValue)]="resStartDate" (bsValueChange)="updateStartDate('reservation')"
                    [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
                </td>
                <td class="date-input">
                  <input name="resEndDate" type="text" (click)="changeInputStatus('endReservationPeriod')" bsDatepicker
                    [(bsValue)]="resEndDate" (bsValueChange)="updateEndDate('reservation')"
                    [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
                </td>
                <td class="date-input">
                  <input name="bookStartDate" type="text" (click)="changeInputStatus('dateFrom')" bsDatepicker
                    [(bsValue)]="bookStartDate" (bsValueChange)="updateStartDate('booking')"
                    [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
                </td>
                <td class="date-input">
                  <input name="bookEndDate" type="text" (click)="changeInputStatus('dateUntil')" bsDatepicker
                    [(bsValue)]="bookEndDate" (bsValueChange)="updateEndDate('booking')"
                    [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
                </td>
              </ng-container>
              <td>{{discount.amount}}</td>
              <td>{{discount.used}}</td>
              <td class="edit">
                <button class="btn btn-warning" [disabled]="selectedVoucher == discount.id && editDetail"
                  (click)="editVoucher(discount)"><span class="glyphicon glyphicon-edit"></span></button>
              </td>
              <td class="action">
                <ng-container *ngIf="selectedVoucher == discount.id">
                  <button class="btn btn-danger" (click)="deselectVoucher()"><span
                      class="glyphicon glyphicon-remove"></span></button>
                </ng-container>
                <ng-container *ngIf="selectedVoucher != discount.id">
                  <button class="btn btn-danger" (click)="deleteVoucherModal(discount)"><span
                      class="glyphicon glyphicon-trash"></span></button>
                </ng-container>
              </td>
            </tr>
          </ng-container>
          <!--New row-->
          <tr class="new-row" *ngIf="newDetailShow">
            <td>
              <ng-container *ngIf="!newDetail100Show">
                <input type="text" name="voucherKey" (input)="validateInput('voucherKey',$event,'object')">
              </ng-container>
            </td>
            <td class="date-input">
              <input name="resStartDate" type="text" (click)="changeInputStatus('startReservationPeriod')" bsDatepicker
                [(bsValue)]="resStartDate" (bsValueChange)="updateStartDate('reservation')"
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
            </td>
            <td class="date-input">
              <input name="resEndDate" type="text" (click)="changeInputStatus('endReservationPeriod')" bsDatepicker
                [(bsValue)]="resEndDate" (bsValueChange)="updateEndDate('reservation')"
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
            </td>
            <td class="date-input">
              <input name="bookStartDate" type="text" (click)="changeInputStatus('dateFrom')" bsDatepicker
                [(bsValue)]="bookStartDate" (bsValueChange)="updateStartDate('booking')"
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
            </td>
            <td class="date-input">
              <input name="bookEndDate" type="text" (click)="changeInputStatus('dateUntil')" bsDatepicker
                [(bsValue)]="bookEndDate" (bsValueChange)="updateEndDate('booking')"
                [bsConfig]="{ isAnimated: true, containerClass:'theme-red start-cal', dateInputFormat: 'DD.MM.YYYY' }">
            </td>
            <td><input type="text" name="voucherAmount" (input)="validateInput('voucherAmount',$event,'object')"></td>
            <td>0</td>
            <td class="edit">
              <ng-container *ngIf="!newDetailShow">
                <button class="btn btn-warning"><span class="glyphicon glyphicon-edit"></span></button>
              </ng-container>
            </td>
            <td class="action">
              <button class="btn btn-danger" (click)="toggleNewDetail('hide')"><span
                  class="glyphicon glyphicon-remove"></span></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="center-item" *ngIf="isLoading">
      <ng-lottie
        [options]="options"
        width="200px"
        height="200px"
      ></ng-lottie>
    </div>

    <div class="new-bar">
      <button class="btn btn-green btn-big" style="margin-right: 79px;" (click)="toggleNewDetail('show')"><span 
        class="plus-icon"><img src="assets/images/plus-icon.svg"></span>New voucher</button>

      <button class="btn btn-green btn-big" (click)="toggle100voucher()">Create batch</button>

      <span *ngIf="voucher100show">
        <div class="custom-float-input">
          <div class="custom-input-number">
            <input name="voucher100number" type="number" placeholder=" " min="1" [(ngModel)]="voucher100number">
            <div class="arrow-input">
              <div class="arr-incr"><span (click)="customHandleManyVoucher('increment')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
              <div class="arr-desc"><span (click)="customHandleManyVoucher('decrement')"><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
            </div>
          </div>
          <label>Number of vouchers</label>
        </div>
        <button class="btn btn-green btn-big" (click)="toggleNewDetail('show', true)"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span>Create vouchers</button>
      </span>
    </div>

    <div class="show-more" *ngIf="discountCount > 0">
      <label>Showing {{discountResult.length}}/{{discountCount}}</label>
      <button class="btn btn-green large-btn show-more-btn" (click)="callShowMore()" *ngIf="discountCount > 10 && discountResult.length != discountCount"><span
          class="glyphicon glyphicon-refresh"></span> Show {{discountCount - discountResult.length >= 10 ? 10 : discountCount - discountResult.length}} more</button>
    </div>
  </div>
  <div class="col-xs-12 col-sm-4 col-md-3">
    <div id="discount-details" [ngClass]="{'init-discount-height': discountResult.length > 0 && (newDetailShow || selectedVoucher !== -1)}">
      <div class="top-sticky">
        <app-discount-details (sendParent)="getVoucher($event)" [loading]="disabledBtnWhileCallingAPI" [sendVoucher]="sendVoucher"></app-discount-details>
      </div>
    </div>
  </div>
</div>

<!-- Validity error -->
<ng-template #voucherModal>
  <div class="modal-body text-center">
    <ng-container *ngIf="validityType == 'validity'">
      <div class="error-icon"><span class="glyphicon glyphicon-exclamation-sign"></span></div>
    </ng-container>
    <ng-container *ngIf="validityType == 'delete'">
      <div class="error-icon red"><span class="glyphicon glyphicon-question-sign"></span></div>
    </ng-container>
    <div class="error-title">{{validityTitle}}</div>
    <div class="error-desc">{{validityDesc}}</div>

    <button type="button" class="btn btn-none-outline-green" (click)="modalRef.hide()">Cancel</button>
    <ng-container *ngIf="validityType == 'validity'">
      <button type="button" class="custom-button" (click)="saveVoucher('save')">OK</button>
    </ng-container>
    <ng-container *ngIf="validityType == 'delete'">
      <button type="button" class="custom-button" (click)="confirmDeleteVoucher()">OK</button>
    </ng-container>
  </div>
</ng-template>