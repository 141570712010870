import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const hotelAPI = '/api/hotel-chains/1/hotels';
const breakfastAvailableAPI = '/api/availability/breakfast';
const breakfastUpdateAPI = '/api/management/secure/availability/breakfast';
const updateBreakfastPriceAPI = '/api/management/secure/product/update-price';
const getRestaurantEmail = '/api/management/secure/reports/get_emails';
const updateRestaurantEmail = '/api/management/secure/reports/update_email';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BreakfastService {

  constructor(private http: HttpClient, private token: TokenStorageService) { }

  public getHotels(): Observable<any> {
    return this.http.get(hotelAPI);
  }

  public getBreakfastAvailable(request: any): Observable<any> {
    return this.http.get<any>(breakfastAvailableAPI, {params: request});
  }

  public updateBreakfastPrice(request: any) {
    return this.http.post<any>(updateBreakfastPriceAPI, request, {});
  }

  public updateBreakfastAvailable(request: any) {
    return this.http.post<any>(breakfastUpdateAPI, request, {});
  }

  public getRestaurantEmail(request: any): Observable<any> {
    return this.http.get<any>(getRestaurantEmail, {params: request});
  }

  public updateRestaurantEmail(request: any) {
    return this.http.put<any>(updateRestaurantEmail, {}, {params: request});
  }
}
