<div class="feature-container">
  <div class="feature-title have-label-under">
    <h3>Manually retrieve external reservation</h3>
    <div class="feature-desc">In case of Nelson failing to create reservation in Nelson from external service request (Expedia, Booking.com, ...) due to problem like rooms not available. User can free up the target rooms and use this tool to manually retrieve and create reservation again. Note that this tool only triggers the retrieval process again, you have to check email if it fails again or search the reservation if it succeeds.</div>
  </div>
  <div class="row">
    <div class="col-xs-6 col-sm-3">
      <div class="dropdown" *ngIf="hotelMenu.length > 1"> 
        <label for="select-report">Hotel</label>
        <br/>
        <button class="dropbtn" (click)="showDropdown('hotel', $event)">{{utils.hotelDisplayByHotel(hotelConfig, selectedHotel)}} <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></button>
        <div *ngIf="manageMenu.hotel" class="dropdown-content">
          <ul>
            <ng-container *ngFor="let hotel of hotelMenu" >
              <li [class.active]="selectedHotel.hotelId === hotel.hotelId" (click)="hotelChange(hotel)">{{ utils.hotelDisplayByHotel(hotelConfig, hotel) }}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-xs-6 col-sm-3">
      <div class="dropdown"> 
        <label for="select-report">Booking channel</label>
        <br/>
        <button class="dropbtn" (click)="showDropdown('channel', $event)">{{selectedBookingChannel.label}} <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></button>
        <div *ngIf="manageMenu.channel" class="dropdown-content">
          <ul>
            <ng-container *ngFor="let channel of bookingChannel" >
              <li [class.active]="selectedBookingChannel.value === channel.value" (click)="channelChange(channel)">{{ channel.label }}</li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <form [formGroup]="retrieveForm" (ngSubmit)="retrieve()">
      <div class="col-xs-12 col-sm-4">
        <label>OTA booking number</label>
        <input class="form-control custom-input" [ngClass]="{'error': externalNumber?.invalid && (externalNumber?.dirty || externalNumber?.touched)}" formControlName="externalNumber">
        <div class="input-error-desc" *ngIf="externalNumber?.invalid && (externalNumber?.dirty || externalNumber?.touched)">This field is required</div>
      </div>
      <div class="col-xs-12 col-sm-2">
        <div class="button-no-label">
          <button type="submit" class="btn btn-green btn-big">Retrieve</button>
        </div>
      </div>
    </form>
  </div>
  <div class="success-info" *ngIf="responseSuccess">Requested OTA reservation retrieval, check your email to know if the retrieval succeed or not, if it succeeds, you can search the reservation.</div>
</div>