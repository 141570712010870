import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';
import { CONSTANT } from 'src/app/helpers/constants';
import { CustomersService } from 'src/app/services/customers.service';
import {UTILS} from 'src/app/helpers/utils';
import { AnimationOptions } from 'ngx-lottie';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.sass','../../../styles.sass']
})
export class CustomersComponent implements OnInit {
  options: AnimationOptions = {
    path: "assets/resources/loading.json"
  }
  optionFiltersForCustomer = [
    {label: "Full name", value: "FULL_NAME", checked: false},
    {label: "First name", value: "FIRST_NAME", checked: false },
    {label: "Last name", value: "LAST_NAME", checked: false},
    {label: "Email", value: "EMAIL", checked: true},
    {label: "Mobile number", value: "MOBILE_NUMBER", checked: false},
    {label: "SSN", value: "SSN", checked: false},
    {label: "Blocked", value: "BLOCKED", checked: false},
  ];
  selectedCustomer: string = "Email";
  optionFilterAll = ['FIRST_NAME', 'LAST_NAME', 'EMAIL', 'MOBILE', 'SSN'];
  customerStateAll = ['BLOCKED', 'UNBLOCKED'];
  contentAlerts: any = {
    loadResponseNoCustomer: { type: 'danger', message: 'No customers found!' },
    loadError: { type: 'danger', message: 'Failed to load customers!' },
    needInputTextSearch: { type: 'danger', message: 'You need input into text search!' }
  };
  alerts: any = {};
  form: any = {
    query: "",
    optionalFilters: [],
    limit: 30,
    offset: 0,
    filter:''
  };
  loading: boolean = false;
  hasShowMore: boolean = false;
  hasChooseOptionFilters: boolean = true;
  isBackToCustomersPage: boolean = false;
  isShowDashboardBtn: boolean = true;
  customers: any[] = [];
  step: number = 0;
  totalCountCustomer: number = 0;
  isError: boolean = false;
  isAlert: boolean = false;
  limit: number = CONSTANT.LIMITOFSEARCH;
  currentFilterStatus: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    public utils: UTILS,
    private customersService: CustomersService) { }

  ngOnInit(): void {
  }
  onSubmit(): void {
    this.form.optionalFilters = this.watchOptionFilters.map(x => x.value);
    this.getCustomerData(this.form, 0);
  }
  get watchOptionFilters() {
    return this.optionFiltersForCustomer.filter(x => x.checked);
  }
  checkValidateFormRequest(request: any): Boolean {
    if (request.query === "" || request.optionalFilters.length === 0) {
      // Allow search customer with BLOCKED optional filters
      let hasNotOnlyBlocked = true;
      request.optionalFilters.forEach((optional: any) => { 
        if (optional === 'BLOCKED' && request.optionalFilters.length === 1) {
          hasNotOnlyBlocked = false;
        }
      });
      return hasNotOnlyBlocked;
    }
    return false;
  }
  textChange(): void {
    this.hasChooseOptionFilters = true;
  }
  getCustomerData(request: any, nextpage: number): void {
    if (this.checkValidateFormRequest(request)) {
      this.alerts = this.contentAlerts.needInputTextSearch;
      this.isError = true;
      this.isAlert = true;
      this.hasChooseOptionFilters = false;
      this.customers = [];
      return;
    }
    if (nextpage === 0) {
      this.customers = [];
    }
    this.isAlert = false;
    this.loading = true;
    this.hasChooseOptionFilters = true;
    this.customersService.searchCustomers(request).subscribe(
      (data: any) => {
        if (data.results.length === 0) {
          this.alerts = this.contentAlerts.loadResponseNoCustomer;
          this.isError = true;
          this.isAlert = true;
          this.loading = false;
          return;
        }
        this.customers = this.customers.concat(data.results);
        this.hasShowMore = (this.customers.length === data.total) ? false : true;
        this.totalCountCustomer = data.total;
        this.isBackToCustomersPage = true;
        this.isError = false;
        this.loading = false;
      });
  }
  loadMore(): void {
    this.step++;
    const stepForm = cloneDeep(this.form);
    stepForm.offset = stepForm.limit * this.step;
    this.getCustomerData(stepForm, this.step);
  }
  backToTopPage(): void {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  addNewCustomer(): void {
    this.router.navigateByUrl("/customer");
  }
  clearSearchCustomers() {
    for (const option of this.optionFiltersForCustomer) {
      option.checked = false;
    }
    this.isAlert = false;
    this.alerts = {};
    this.isError = false;
    this.loading = false;
    this.hasChooseOptionFilters = true;
    this.customers = [];
    this.form = {
      query: "",
      optionalFilters: [],
      limit: 30,
      offset: 0
    };
    this.selectedCustomer = this.selectedCustomerLabel();
  }
  filterChange(optionFilterForCustomer: any) {
    optionFilterForCustomer.checked = !optionFilterForCustomer.checked;
    if (optionFilterForCustomer.value == "SSN") {
      this.watchOptionFilters.forEach((item:any) => {
        if(item.value != "SSN" && item.value != "BLOCKED") {
          item.checked = false;
        }
      });  
    } else {
      if (optionFilterForCustomer.value != "BLOCKED") {
        this.watchOptionFilters.forEach((item:any) => {
          if(item.value == "SSN") {
            item.checked = false;
          }
        });  
      }
    }
    this.selectedCustomer = this.selectedCustomerLabel();
  }
  dashboardOnCustomers(): void {
    this.router.navigateByUrl("/customers/customer-dashboard");
  }
  selectedCustomerLabel(): string {
    const optionFiltersArr = this.watchOptionFilters.filter(x => x.checked);
    if (optionFiltersArr.length === this.optionFiltersForCustomer.length) {
      this.selectedCustomer = "All";
      return "All";
    }
    if (optionFiltersArr.length === 0) {
      this.selectedCustomer = "";
      return "";
    }
    return optionFiltersArr.map(x => x.label).join(', ');
  }
  selectCustomer(email: string,mobile: string) {
    this.router.navigate(["customer-card/", email, mobile.substring(1,mobile.length)]);
  }
  selectStatus(status:string):void{
    if(this.currentFilterStatus===''){
      this.currentFilterStatus = status;
      return
    }if(this.currentFilterStatus === status){
      this.currentFilterStatus = '';

      return
    }
    this.currentFilterStatus = status;

  }
}