<div class="btn-group mui-menu" [ngClass]="{'full-width': fullWidth, 'highlight-error': highlightError}" dropdown #dropdown="bs-dropdown" [dropup]="dropup" [insideClick]="searchable">
  <button dropdownToggle type="button" [ngClass]="{'disabled': disableMenu}" class="btn dropdown-toggle" (click)="openSearch()" [style]="{fontSize: buttonFontSize}">
    {{selectedItem ? selectedItem.label : ""}} <span class="caret"></span>
  </button>
  <div *dropdownMenu class="dropdown-menu" role="menu" [ngClass]="{'right-align': rightAligned}" [style]="{minWidth: minWidth}">
    <div class="menu-search" *ngIf="searchable">
      <input type="text" name="custom-menu-search" class="custom-input form-control" placeholder="Search item" [(ngModel)]="searchKeyword" (ngModelChange)="searchListChange()">
    </div>
    <div class="menu-item-scroll" (click)="dropdown.hide()" [attr.id]="'select-menu-' + menuId">
      <ng-container *ngFor="let item of menuListFiltered">
        <div class="menu-item" (click)="selectMenuItem(item)" [ngClass]="{'selected': selectedItem && selectedItem.value === item.value, 'disabled': item.disabled}" [attr.id]="menuId + item.value">
          {{ item.label }}
        </div>
      </ng-container>
    </div>
  </div>
</div>