<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <h1 class="view-title">Internal tools (Client)</h1>
        </div>
        <div class="notice-container">
          <div class="notice-icon">
            <span class="glyphicon glyphicon-info-sign"></span>
          </div>
          <div class="notice-content">WARNING: Internal tools is only available to Nelson Manager users and is used for various modification to hotel clients data. Please read the descriptions of each tools before using them.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="body-container">
    <app-beonx-hotel-notif-export [hotels]="hotels"></app-beonx-hotel-notif-export>
    <app-manual-external-retrieve [hotels]="hotels"></app-manual-external-retrieve>
    <app-update-reservation-lang></app-update-reservation-lang>
  </div>
</div>