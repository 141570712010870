import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[dayAsCheckbox]'
})
export class DayAsCheckboxDirective implements OnInit {
  @Input() dayRestriction: any[] | undefined;
  dayOfWeek: any = [
    {
      label: 'MON',
      checked: false
    },
    {
      label: 'TUE',
      checked: false
    },
    {
      label: 'WED',
      checked: false
    },
    {
      label: 'THU',
      checked: false
    },
    {
      label: 'FRI',
      checked: false
    },
    {
      label: 'SAT',
      checked: false
    },
    {
      label: 'SUN',
      checked: false
    }];

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.dayOfWeek.forEach((element: any) => {
      let day = this.dayRestriction?.find(x => { return x === element.label });
      if (element.label === day) {
        element.checked = true;
      }
    });
    for (let day of this.dayOfWeek) {
      const child = document.createElement('span');
      let className = "day-restrict-as-text";
      if (day.label == 'SAT' || day.label == 'SUN') {
        className += " weekend";
      }
      if (day.checked) {
        className += " checked";
      }
      child.setAttribute('class', className);
      child.append(day.label);
      this.renderer.appendChild(this.el.nativeElement, child);
    }
  }
}