<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="heading">
            <h1 class="view-title">Customers</h1>
            <button type="button" class="btn btn-green btn-big" (click)="addNewCustomer()"><span class="plus-icon"><img src="assets/images/plus-icon.svg"></span> Add Block </button>
          </div>
        </div>
        
         <!-- Show message after change customers/ reason for customers -->
        <div class="row">
          <div *ngIf="isAlert" class="col-xs-12 col-md-12 ">
            <div class="alert alert-{{alerts.type}}" role="alert">
              {{ alerts.message }}
            </div>
        </div>
        
        <form name="form" (ngSubmit)="onSubmit()">
          <div class="search-row">
            <!-- Search button -->
            <div class="col-xs-12 col-sm-6">
              <div class="btn-group dropdown">
                <label for="textSearch">Search for</label>
                <input name="query"
                  class="form-control custom-input {{!hasChooseOptionFilters ? 'has-not-choose-option-filter': ''}}"
                  [(ngModel)]="form.query" (ngModelChange)="textChange()" autofocus>
              </div>
            </div>
            
            <!-- Optional filters for customers -->
            <div class="col-xs-12 col-sm-6 ">
              <!-- <div class="btn-group dropdown" dropdown [insideClick]="true">
                <label for="optional-filter">Optional filters</label>
                  <input type="button" class="form-control text-input nls-input" dropdownToggle
                  aria-controls="dropdown-basic" [value]="selectedCustomer"> 
                  <span><img class="drop-icon" src="assets/images/triangle-down.svg"></span>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <ng-template ngFor let-option [ngForOf]="optionFiltersForCustomer" let-idx="index">
                      <li class="optional-filters normal-checkbox" role="menuitem" (click)="filterChange(option)" [class.active]="option.checked">
                        <input type="checkbox" (ngModelChange)="filterChange(option)" [checked]="option.checked" no-dirty>
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <div for="auto-notify">{{ option.label }}</div>
                      </li>
                    </ng-template>
                  </ul>
              </div> -->
              <label>Optional filters</label>
                <div class="select-menu" dropdown #dropdown="bs-dropdown" [autoClose]="true">
                  <div class="menu-list dropdown-toggle" dropdownToggle aria-controls="dropdown-basic">
                    <div class="menu-selected">{{selectedCustomer}}</div>
                    <div class="menu-arrow"><span><img class="drop-icon" src="assets/images/triangle-down.svg"></span></div>
                  </div>
                  <div class="dropdown-menu menu-content" id="dropdown-basic" *dropdownMenu (click)="utils.preventClose($event)">
                    <ng-container *ngFor="let option of optionFiltersForCustomer">
                      <div class="normal-checkbox">
                        <input type="checkbox" (click)="filterChange(option)" [checked]="option.checked">
                        <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
                        <div class="checkbox-label">{{option.label}}</div>
                      </div>
                    </ng-container>
                  </div>
                </div>
            </div>
          </div>  
  
          <!-- Some buttons for customers -->
          <div class="format-btn">
            <div class="col-xs-12 col-md-6 dashboard" *ngIf="isShowDashboardBtn" style="margin-top: 25px">
              <button type="button" class="btn btn-green btn-big dashboard-btn" (click)="dashboardOnCustomers()">
                <span class="fa fa-bar-chart"></span> Dashboard 
              </button>
            </div>
            <div class="col-xs-12 col-md-6 text-right"  style="margin-top: 40px">
              
              <button type="button" class="btn btn-none-outline-green left-margin" (click)="clearSearchCustomers()"><span
                class="glyphicon glyphicon-trash"></span>Clear Search</button>
              <button type="submit" class="btn btn-green btn-big"
                [disabled]="(!selectedCustomer && !loading) || loading"><span class="glyphicon glyphicon-search"></span>
                Search </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>  
</div>


<!-- Customers table -->
<div class="wrapper-outer-table" *ngIf="customers.length>0">
  <div class="outer-table">
    <div class="container-fluid">
        <label class="title-search">Customer Status</label>
        <ng-container>
          <div class="status-checkbox-group">
            <label>Status</label>
            <div class="filter-radio" *ngFor="let status of customerStateAll; let idx = index">
              <div class="radio-box" [class.active]="currentFilterStatus===status" (click)="selectStatus(status)">
                <span class="glyphicon glyphicon-ok" *ngIf="currentFilterStatus===status"></span>{{status}}
              </div>
            </div>
          </div>
        </ng-container>
      <div class="custom-table-wrapper">
        <table class="table table-bordered table-striped sticky-header">
          <thead>
            <tr>
              <th class="table-customers">Email</th>
              <th class="table-customers">First name</th>
              <th class="table-customers">Last name</th>
              <th class="table-customers">SSN</th>
              <th class="table-customers">Mobile</th>
              <th class="table-customers">State</th>
            </tr>
          </thead>
          <tbody>
            <tr class="customer-row" *ngFor="let customer of customers">
            <ng-template [ngIf]="currentFilterStatus && customer.state===currentFilterStatus">
              <td><a [routerLink]="['/customer-card/',customer.email,customer.mobile ? customer.mobile.substring(1,customer.mobile) : '']">{{customer.email}}</a></td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.firstName ? customer.firstName : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.lastName ? customer.lastName : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.ssn ? customer.ssn : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.mobile ? customer.mobile : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.state ? customer.state : '-' }}</td>
            </ng-template>
            <ng-template [ngIf]="!currentFilterStatus">
              <td><a [routerLink]="['/customer-card/',customer.email,customer.mobile ? customer.mobile.substring(1,customer.mobile) : '']">{{customer.email}}</a></td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.firstName ? customer.firstName : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.lastName ? customer.lastName : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.ssn ? customer.ssn : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.mobile ? customer.mobile : '-' }}</td>
              <td (click)="selectCustomer(customer.email, customer.mobile)">{{ customer.state ? customer.state : '-' }}</td>
            </ng-template>
           </tr>
          </tbody>
      </table>
      </div>
      
      <!-- Show more customers after searching customers -->
      <div class="row row-bottom show-result">
        <div class="col-xs-12 text-left" *ngIf="customers.length">
          <label class="customer-total-count">Showing {{ customers.length + '/' + totalCountCustomer }}</label>
        </div>
        <div class="col-xs-12 text-center" *ngIf="customers.length >= limit">
          <button type="button" *ngIf="hasShowMore" class="btn btn-green btn-big" (click)="loadMore()"><span
              class="glyphicon glyphicon-refresh"></span> Show {{ limit }} more</button>
          <button type="button" *ngIf="isBackToCustomersPage" class="btn btn-green btn-big"
            (click)="backToTopPage()"><span class="glyphicon glyphicon-menu-up"></span> Back to top page</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Loading icon -->
<div class="center-item" *ngIf="loading">
  <ng-lottie
    [options]="options"
    width="200px"
    height="200px"
  ></ng-lottie>
</div>