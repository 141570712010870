<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="row">
            <div class="col-md-12">
              <h1 class="view-title">Create blocklist entry</h1>
            </div>
          </div>
        </div>
  
        <div class="row">
          <form [formGroup]="addCustomerForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <label for="firstName" class="col-xs-2 col-md-2">First name</label>
                <div class="col-xs-10 col-md-10">
                  <input type="text" formControlName="firstName"
                    class="firstName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isFirstName ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="firstNameOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="lastName" class="col-xs-2 col-md-2">Last name</label>
                <div class="col-xs-10 col-md-10">
                  <input type="text" formControlName="lastName"
                    class="lastName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isLastName ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="lastNameOnChanges()">
                </div>
              </div>
            </div>
  
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="mobile" class="col-xs-2 col-md-2">Mobile number</label>
                <div class="col-xs-5 col-md-3">
                  <dial-code-dropdown-menu
                    [menuList]="dialCodeList"
                    [selectedItem]="selectedDialCode"
                    [searchable]="true"
                    [fullWidth]="true"
                    (selectItem)="selectDialCode($event)"
                  ></dial-code-dropdown-menu>
                </div>
                <div class="col-xs-5 col-md-7">
                  <div class="custom-input-number">
                    <input type="number" formControlName="mobile"
                    class="mobile form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isMobile ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="mobileOnChanges($event)">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="email" class="col-xs-2 col-md-2">Email address</label>
                <div class="col-xs-10 col-md-10">
                  <input type="email" formControlName="email"
                    class="email form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isEmail ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="emailOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="ssn" class="col-xs-2 col-md-2">SSN</label>
                <div class="col-xs-10 col-md-10">
                  <input type="text" formControlName="ssn"
                  class="ssn form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched  {{ !isSSN ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="ssnOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="ssn" class="col-xs-2 col-md-2">Passport number</label>
                <div class="col-xs-10 col-md-10">
                  <input type="text" formControlName="passport"
                  class="passport form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched  {{ !isPassport ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="passportOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="state" class="col-xs-2 col-md-2">State</label>
                <div class="col-xs-10 col-md-10">
                  <dropdown-menu
                    [menuList]="stateList"
                    [selectedItem]="selectedState"
                    [fullWidth]="true"
                    (selectItem)="selectState($event)"
                  ></dropdown-menu>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="reason" class="col-xs-2 col-md-2 input-state-reason-customer">Reason</label>
                <div class="col-xs-10 col-md-10">
                  <input type="text" class="reason form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched"
                    formControlName="reason">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12 format-customer p-t-10">
                <div class="col-xs-12 col-md-12 text-right">
                  <button type="button" class="btn btn-none-outline-green btn-big" (click)="clearAll()"> CLEAR
                  </button>
                  <button type="submit" class="btn btn-green btn-big"><span
                      class="glyphicon glyphicon-save"></span> SAVE </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>