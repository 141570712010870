<div class="feature-container">
  <div class="feature-title have-label-under">
    <div class="title-with-logo">
      <div class="logo with-bg">
        <img src="assets/images/BEONxLogoWhite.png">
      </div>
      <div class="title">
        <h3>Download Hotel***Notif XML file</h3>
      </div>
      <div class="feature-status wip">
        Work in progress
      </div>
    </div>
    <div class="feature-desc">Download OTA Hotel***Notif XML for BEONx service.</div>
  </div>
  <div class="row">
    <form [formGroup]="retrieveForm" (ngSubmit)="exportHotelNotif()">
      <div class="col-xs-12 col-sm-3">
        <ng-container *ngIf="['OTA_HotelResNotifRQ'].includes(selectedFileType.value)">
          <label>Reservation code</label>
          <input class="form-control custom-input" [ngClass]="{'error': reservationCode.invalid && (reservationCode.dirty || reservationCode.touched)}" formControlName="reservationCode">
          <div class="input-error-desc" *ngIf="reservationCode?.invalid && (reservationCode?.dirty || reservationCode?.touched)">This field is required</div>
        </ng-container>
        <ng-container *ngIf="['OTA_HotelInvBlockNotifRQ', 'OTA_HotelInvCountNotifRQ'].includes(selectedFileType.value)">
          <label>Hotel</label>
          <dropdown-menu
            [menuList]="hotelList"
            [selectedItem]="selectedHotel"
            [fullWidth]="true"
            (selectItem)="selectHotel($event)"
          ></dropdown-menu>
        </ng-container>
      </div>
      <div class="col-xs-12 col-sm-4">
        <div class="dropdown"> 
          <label for="select-report">File type</label>
          <dropdown-menu
            menuId="file-type"
            [menuList]="fileTypeList"
            [selectedItem]="selectedFileType"
            [fullWidth]="true"
            (selectItem)="selectFileType($event)"
          ></dropdown-menu>
        </div>
      </div>
      <div class="col-xs-6 col-sm-2">
        <div class="button-no-label">
          <button type="button" class="btn btn-green btn-big">Download</button>
        </div>
      </div>
      <div class="col-xs-6 col-sm-2">
        <div class="button-no-label">
          <button type="button" class="btn btn-green btn-big" (click)="exportHotelNotif()">Export</button>
        </div>
      </div>
    </form>
  </div>
</div>