<app-header></app-header>
<div class="main-container">
  <div class="wrapper-inner-main-container">
    <div class="inner-main-container">
      <div class="container-fluid">
        <div id="main">
          <div class="row">
            <div class="col-md-12">
              <h1 class="view-title">Customer Details</h1>
            </div>
          </div>
        </div>
        <div class="row">
          <form [formGroup]="editCustomerForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading">
            <div class="row">
              <div class="col-xs-12 col-md-12">
                <label for="firstName" class="col-sm-2">First name</label>
                <div class="col-sm-10">
                  <input type="text" formControlName="firstName"
                    class="firstName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isFirstName ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="firstNameOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="lastName" class="col-sm-2">Last name</label>
                <div class="col-sm-10">
                  <input type="text" formControlName="lastName"
                    class="lastName form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isLastName ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="lastNameOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="mobile" class="col-sm-2">Mobile number</label>
                <div class="col-xs-5 col-md-3 mobile-padding">
                  <dial-code-dropdown-menu
                    [menuList]="dialCodeList"
                    [selectedItem]="selectedDialCode"
                    [searchable]="true"
                    [fullWidth]="true"
                    (selectItem)="selectDialCode($event)"
                  ></dial-code-dropdown-menu>
                </div>
                <div class="col-xs-5 col-md-7">
                  <div class="custom-input-number">
                    <input type="number" formControlName="mobile"
                    class="mobile form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isMobile ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="mobileOnChanges($event)">
                  </div>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="email" class="col-sm-2">Email address</label>
                <div class="col-sm-10">
                  <input type="email" formControlName="email"
                    class="email form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isEmail ? 'has-not-choose-option-filter' :''}}"
                    (ngModelChange)="emailOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="ssn" class="col-sm-2">SSN</label>
                <div class="col-sm-10">
                  <input type="text" formControlName="ssn"
                    class="ssn form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isSSN ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="ssnOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="passport" class="col-sm-2">Passport number</label>
                <div class="col-sm-10">
                  <input type="text" formControlName="passport"
                    class="passport form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isPassport ? 'has-not-choose-option-filter' :''}}" (ngModelChange)="passportOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12 format-customer p-t-10">
                <div class="col-xs-12 col-md-12 text-right">
                  <button type="button" class="btn btn-none-outline-green btn-big" (click)="resetInfo()"> Reset customer info
                  </button>
                  <button type="submit" class="btn btn-green btn-big"
                    [disabled]="disabledBtnWhileCallingAPI"><span
                      class="glyphicon glyphicon-save"></span> Save customer details </button>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="state" class="col-sm-2">State</label>
                <div class="col-sm-10">
                  <dropdown-menu
                    [menuList]="stateList"
                    [selectedItem]="selectedState"
                    [fullWidth]="true"
                    (selectItem)="selectState($event)"
                  ></dropdown-menu>
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-xs-12 col-md-12 format-input-customer">
                <label for="reason" class="col-sm-2">Reason</label>
                <div class="col-sm-10">
                  <input type="text"
                    class="reason form-control text-input nls-input ng-pristine ng-valid ng-empty ng-touched {{ !isReason ? 'has-not-choose-option-filter' :''}}"
                    formControlName="reason" (ngModelChange)="reasonOnChanges()">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12 format-customer p-t-10 p-b-10">
                <div class="col-xs-12 col-md-12 text-right">
                  <button type="button" class="btn btn-none-outline-green btn-big" (click)="clearSate()"> Reset block state
                  </button>
                  <button type="button" class="btn btn-green btn-big" [disabled]="!(isReason) || disabledBtnWhileCallingAPI" (click)="addHistory()"><span
                     class="glyphicon glyphicon-save"></span> Save block state </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="wrapper-outer-table" >
  <div class="outer-table" *ngIf="blocklistHistories.length > 0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <label class="title-search">History</label>
        </div>
      </div>
      <div class="row" >
        <div class="col-xs-12 col-md-12">
          <div class="custom-table-container">
            <table class="table table-bordered table-hover table-striped custom-table">
              <thead>
                <tr>
                  <th class="table-customers">Author</th>
                  <th class="table-customers">Date</th>
                  <th class="table-customers">State</th>
                  <th class="table-customers">Reason</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let history of blocklistHistories">
                  <td>{{ history.author }}</td>
                  <td>{{ history.created | date:'dd.MM.yyyy HH:mm:ss' }}</td>
                  <td>{{ history.state }}</td>
                  <td>{{ history.reason }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class=" row-bottom show-back">
          <div class="col-xs-12 text-left" >
            <label class="customer-total-count" (click)="backToCustomerCard()"><span class="glyphicon glyphicon-chevron-left"></span>Back</label>
          </div>
        </div>
        <!-- Loading icon -->
        <div class="center-item" *ngIf="isLoading">
          <ng-lottie
            [options]="options"
            width="200px"
            height="200px"
          ></ng-lottie>
        </div>
      </div>
    </div>
  </div>
</div>