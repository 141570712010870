<div [hidden]="!hotelConfig.feature.giftCard">
  <table class="custom-table detail-table monetary-table" *ngIf="viewDetail && sendVoucher">
    <colgroup>
      <col style="width: 43%;">
      <col>
    </colgroup>
    <thead>
      <tr rowspan="2">
        <th colspan="2">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td><b>Cusomter @</b></td>
        <td class="number-input">
          <ng-container *ngIf="!editDetail">
            {{sendVoucher.customerEmail}}
          </ng-container>
          <ng-container *ngIf="editDetail">
            <input type="text" name="customerEmail" value="{{customerEmail}}"
              (input)="updateInputEmail('customer', $event)">
          </ng-container>
        </td>
      </tr>
      <tr>
        <td><b>Phone</b></td>
        <td class="number-input">
          <ng-container *ngIf="!editDetail">
            {{sendVoucher.customerMobile}}
          </ng-container>
          <ng-container *ngIf="editDetail">
            <input class="phone-number" type="text" name="phone" (input)="validateDetailInput('phone',$event)" [value]="sendVoucher.customerMobile">
          </ng-container>
        </td>
      </tr>
      <tr>
        <td><b>Recipient @</b></td>
        <td class="number-input">
          <ng-container *ngIf="!editDetail">
            {{sendVoucher.recipientEmail ? sendVoucher.recipientEmail : ''}}
          </ng-container>
          <ng-container *ngIf="editDetail">
            <input type="text" name="recipientEmail" value="{{recipientEmail}}"
              (input)="updateInputEmail('recipient', $event)">
          </ng-container>
        </td>
      </tr>
      <tr>
        <td colspan="2" class="textarea-input">
          <b>Message to recipient</b>
          <button class="hide-btn" *ngIf="showMessage" (click)="toggleDetail('message')">Hide</button>
          <button class="hide-btn" *ngIf="!showMessage" (click)="toggleDetail('message')">Show</button>
          <ng-container *ngIf="showMessage">
            <textarea rows="4" style="margin-top:10px" [disabled]="!editDetail" (input)="countMessage($event)" [(ngModel)]="sendVoucher.greetingText"></textarea>
            <div style="margin-bottom:20px">Maximum {{sendVoucher.greetingText && sendVoucher.greetingText.length ? sendVoucher.greetingText.length : 0}}/255 characters</div>
  
            <b>Notes (internal use only)</b>
            <textarea rows="4" style="margin-top:10px" disabled>{{sendVoucher.refundedReason}}</textarea>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <b>Payments</b>
          <button class="hide-btn" *ngIf="showPayments" (click)="toggleDetail('payments')">Hide</button>
          <button class="hide-btn" *ngIf="!showPayments" (click)="toggleDetail('payments')">Show</button>
        </td>
      </tr>
      <ng-container *ngIf="showPayments">
        <tr>
          <td><b>Date</b></td>
          <td>
            {{sendVoucher.created ? utils.convertISODate(sendVoucher.created) : ''}}
          </td>
        </tr>
        <tr>
          <td><b>Amount</b></td>
          <td>
            {{sendVoucher.amount | number: '1.2-2'}} {{utils.getCurrency(sendVoucher.currencyCode)}}
          </td>
        </tr>
        <tr>
          <td><b>Payment provider</b></td>
          <td>
            {{ sendVoucher.payments[0] ? sendVoucher.payments[0].paymentProvider : '' }}
          </td>
        </tr>
        <tr>
          <td><b>Payment method</b></td>
          <td>
            {{ sendVoucher.payments[0] ? sendVoucher.payments[0].paymentTypeAndName : '' }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  
  <div class="row" style="margin-bottom: 20px" *ngIf="editDetail">
    <div class="col-md-6">
      <button class="btn btn-green large-btn full-width" [disabled]="loading" (click)="submitUpdate()"><span
          class="glyphicon glyphicon-ok"></span> Update</button>
    </div>
    <div class="col-md-6">
      <button class="btn btn-green large-btn full-width" (click)="openResendModal()">@ Resend</button>
    </div>
  </div>
  
  <!-- Resend modal -->
  <ng-template #resendModal>
    <div class="modal-header">
      <div class="modal-title pull-left">Notification</div>
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-4">
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="checkedCustomer" (click)="resendCheck('customer', $event)">
            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
            <div class="checkbox-label">Email</div>
          </div>
        </div>
        <div class="col-md-8">
          <input type="text" name="resendCustomerEmail" placeholder="Customer email" value="{{customerEmail}}"
            (input)="validateResend()">
        </div>
      </div>
      <div class="row" style="margin-top: 20px">
        <div class="col-md-4">
          <div class="normal-checkbox">
            <input type="checkbox" [checked]="checkedRecipient" (click)="resendCheck('recipient', $event)">
            <div class="checkbox-box"><span class="glyphicon glyphicon-ok"></span></div>
            <div class="checkbox-label">Recipient</div>
          </div>
        </div>
        <div class="col-md-8">
          <input type="text" name="resendRecipientEmail" placeholder="Recipient email" value="{{recipientEmail}}"
            (input)="validateResend()">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-none-outline-green" (click)="modalRef.hide()">Close</button>
      <button type="submit" class="btn btn-green" [disabled]="!resendValid || disabledBtnWhileCallingAPI" (click)="sendNotification()">Send</button>
    </div>
  </ng-template>
</div>