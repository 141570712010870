import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const DOOR_EVENT = "/api/management/secure/lock-ui/axis/door-event";
const HOTEL_API = "/api/management/secure/lock-ui/axis/availableHotels";
const SEARCH_API = "/api/management/secure/lock-ui/axis/search";
const DOOR_API = "/api/management/secure/lock-ui/axis/accessDoorList/";
const CREATE_DOOR_API = "/api/management/secure/lock-ui/axis/createDoorCode";
const UPDATE_DOOR_API = "/api/management/secure/lock-ui/axis/updateDoorCode";
const GET_USER_DOOR_API = "/api/management/secure/lock-ui/axis/credential";
const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8'});

@Injectable({
  providedIn: 'root'
})
export class LockService {

  constructor(
    private http: HttpClient
  ) { }

  public getHotels(): Observable<any> {
    return this.http.get(HOTEL_API);
  }

  public searchLock(request: any): Observable<any> {
    return this.http.post<any>(SEARCH_API, request, {});
  }

  public getAccessDoor(hotelLabel: string): Observable<any> {
    return this.http.get(DOOR_API + hotelLabel);
  }

  public createDoorCode(request: any): Observable<any> {
    return this.http.post<any>(CREATE_DOOR_API, request, {});
  }

  public updateDoorCode(request: any): Observable<any> {
    return this.http.put<any>(UPDATE_DOOR_API, request, {});
  }

  public getUserDoor(hotelLabel: string, userToken: string): Observable<any> {
    return this.http.get(`${GET_USER_DOOR_API}/${hotelLabel}/${userToken}`);
  }

  public getDoorEventType(hotelLabel:string, request: any): Observable<any> {
    return this.http.post<any>(`${DOOR_EVENT}/${hotelLabel}/eventTypes`, request, {});
  }

  public getUserDoorEventType(hotelLabel: string, userToken: string): Observable<any> {
    return this.http.get(`${GET_USER_DOOR_API}/${hotelLabel}/${userToken}/eventTypes`);
  }

  public getUsage(hotelLabel: string, request: any): Observable<any> {
    return this.http.post(`${DOOR_EVENT}/${hotelLabel}`, request, {});
  }

  public getUserUsage(hotelLabel: string, userToken: string, request: any): Observable<any> {
    return this.http.post(`${GET_USER_DOOR_API}/${hotelLabel}/${userToken}/usages`, request, {});
  }
  
  public exportUsage(hotelLabel: string, request: any): Observable<any> {
    return this.http.post(`${DOOR_EVENT}/${hotelLabel}/report`, request, { headers, responseType: 'blob' });
  }
  
  public exportUserUsage(hotelLabel: string, userToken: string, request: any): Observable<any> {
    return this.http.post(`${GET_USER_DOOR_API}/${hotelLabel}/${userToken}/usages/${request.doorCode}/report`, request, { headers, responseType: 'blob' });
  }
}
