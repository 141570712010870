import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenStorageService } from 'src/app/services/token-storage.service';
import { CONSTANT } from '../helpers/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient, private token: TokenStorageService) { }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token.getToken()!
    })
  };
  loadUsers(request: any): Observable<any> {
    return this.http.get(CONSTANT.LIST_USERS_API, { params: request });
  }
  loadUserDetails(request: any): Observable<any> {
    return this.http.get(CONSTANT.USER_API, { params: request });
  }
  loadAllRoles(): Observable<any> {
    return this.http.get(CONSTANT.USER_ROLES_API);
  }
  updateUser(request: any): Observable<any> {
    return this.http.post(CONSTANT.USER_API, request, this.httpOptions);
  }
  resetUserPassword(request: any): Observable<any> {
    return this.http.post(CONSTANT.RESET_PASSWORD_API, request, this.httpOptions);
  }
  updatePassword(accesstoken: string, currentpassword: string, newpassword: string): Observable<any> {
    return this.http.post(CONSTANT.CHANGE_PASSWORD_API, { currentpassword, newpassword, accesstoken });
  }
  requestVerificationCode(username: string): Observable<any> {
    return this.http.post(CONSTANT.FORGOT_PASSWORD_API, { username });
  }
  forgotResetPassword(confirmationcode: string, username: string, newpassword: string): Observable<any> {
    return this.http.post(CONSTANT.CONFIRM_FORGOT_PASSWORD_API, { confirmationcode, username, newpassword });
  }
}
