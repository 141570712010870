<app-header></app-header>
<div class="main-container">
  <div class="container-fluid">
    <h1 class="view-title">{{ title }}</h1>
  
    <div class="row" *ngIf="!isCreate">
      <div class="col-xs-0 col-sm-6 col-md-8"></div>
      <div class="col-xs-6 col-sm-3 col-md-2">
        <button class="btn btn-green full-width" (click)="openUsage()">View Usage</button>
      </div>
      <div class="col-xs-6 col-sm-3 col-md-2">
        <button class="btn btn-green full-width" (click)="cancelMaintenance()"><span
            class="glyphicon glyphicon-trash"></span> Cancel</button>
      </div>
    </div>
  
    <form [formGroup]="maintenanceDetailForm">
      <div class="row">
        <div class="col-md-8">
          <div class="row">
            <div class="col-xs-6 margin-top" *ngIf="hotels.length > 1">
              <label for="top-margin">Hotel</label>
              <dropdown-menu
                [menuList]="hotelList"
                [selectedItem]="selectedHotel"
                [fullWidth]="true"
                (selectItem)="selectHotel($event)"
              ></dropdown-menu>
            </div>
            <div class="col-xs-6 margin-top">
              <label for="top-margin">Floor</label>
              <dropdown-menu
                [menuList]="floorList"
                [selectedItem]="selectedFloor"
                [fullWidth]="true"
                (selectItem)="selectFloor($event)"
              ></dropdown-menu>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-5">
              <label class="top-margin">Available Rooms</label>
              <div class="room-select-container" id="leftRoom">
                <ng-container *ngFor="let room of rooms; let i = index">
                  <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label"
                    *ngIf="checkRoom(room,'left')" (click)="selectListRoom($event,'leftRoom')">Room <b>{{room.label}}</b>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="col-sm-2">
              <div class="room-arrow-container">
                <div class="room-select-arrow">
                  <button type="button" class="btn btn-green" (click)="selectRoom('add')"><span
                      class="glyphicon glyphicon-chevron-right"></span></button>
                  <button type="button" class="btn btn-green" (click)="selectRoom('remove')"><span
                      class="glyphicon glyphicon-chevron-left"></span></button>
                </div>
              </div>
            </div>
            <div class="col-sm-5">
              <div class="row">
                <div class="col-xs-8">
                  <label class="top-margin">Selected Rooms</label>
                </div>
                <div class="col-xs-4">
                  <label class="top-margin done-col" *ngIf="!isCreate && notAllowedEditRoom">Done</label>
                </div>
              </div>
              <div class="room-select-container" id="rightRoom">
                <ng-container *ngFor="let room of rooms; let i = index">
                  <div class="room-item" [attr.data-index]="i" [attr.data-label]="room.label"
                    *ngIf="checkRoom(room,'right')" (click)="selectListRoom($event,'rightRoom')">
                    Room <b>{{room.label}}</b>
                    <div class="custom-checkbox" *ngIf="!isCreate" [ngClass]="{disabled:false}">
                      <input type="checkbox" [checked]="checkDone(room.status)">
                      <div class="check-box" *ngIf="notAllowedEditRoom"><span class="glyphicon glyphicon-ok"></span></div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-sm-6 col-md-12">
              <label class="top-margin">Start date</label>
              <div class="row no-gutters">
                <div class="col-xs-7">
                  <div class="datepicker-input">
                    <input type="text" placeholder="Select start date" class="form-control" bsDatepicker appEditDate
                      [(bsValue)]="startDate" #startDateInput="bsDatepicker" (input)="disableUpdate()" (blur)="validateForm()"
                      (bsValueChange)="dateChange('startDate')"
                      [dateCustomClasses]="datepickerCustom"
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', customTodayClass:'datepicker-today' }">
                    <button type="button" class="datepicker-btn" (click)="startDateInput.toggle()">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </div>
                </div>
                <div class="col-xs-5">
                  <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                    <div class="datepicker-input">
                      <input type="text" placeholder="Start time" class="form-control" value="{{startDate | date:'HH:mm'}}"
                        disabled>
                      <button type="button" class="datepicker-btn" dropdownToggle aria-controls="startTimePicker">
                        <span class="glyphicon glyphicon-time"></span>
                      </button>
                    </div>
                    <div class="dropdown-menu menu-content start-time-cal" id="startTimePicker" *dropdownMenu (click)="preventClose($event)">
                      <timepicker [(ngModel)]="startDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}"
                        [hourStep]="1" [minuteStep]="1"></timepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-md-12">
              <label class="top-margin">End date</label>
              <div class="row no-gutters">
                <div class="col-xs-7">
                  <div class="datepicker-input">
                    <input type="text" placeholder="Select end date" class="form-control" appEditDate [(bsValue)]="endDate"
                      bsDatepicker #endDateInput="bsDatepicker" (input)="disableUpdate()" (blur)="validateForm()"
                      (bsValueChange)="dateChange('endDate')"
                      [dateCustomClasses]="datepickerCustom"
                      [bsConfig]="{isAnimated: true, containerClass:'theme-red maintenance-start-cal', dateInputFormat: 'DD.MM.YYYY', minDate: minEnd, customTodayClass:'datepicker-today' }">
                    <button type="button" class="datepicker-btn" (click)="endDateInput.toggle()">
                      <span class="glyphicon glyphicon-calendar"></span>
                    </button>
                  </div>
                </div>
                <div class="col-xs-5">
                  <div dropdown #dropdown="bs-dropdown" [autoClose]="true">
                    <div class="datepicker-input">
                      <input type="text" placeholder="End time" class="form-control" value="{{endDate | date:'HH:mm'}}"
                        disabled>
                      <button type="button" class="datepicker-btn" dropdownToggle aria-controls="endTimePicker">
                        <span class="glyphicon glyphicon-time"></span>
                      </button>
                    </div>
                    <div class="dropdown-menu menu-content start-time-cal" id="endTimePicker" *dropdownMenu (click)="preventClose($event)">
                      <timepicker [(ngModel)]="endDate" [showMeridian]="false" [ngModelOptions]="{standalone: true}"
                        [hourStep]="1" [minuteStep]="1"></timepicker>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <label for="top-margin">Maintenance Category</label>
          <dropdown-menu
            [menuList]="categoryList"
            [selectedItem]="selectedCategory"
            [fullWidth]="true"
            (selectItem)="selectCategory($event)"
          ></dropdown-menu>
  
          <label class="top-margin">Reason</label>
          <textarea rows="3" class="custom-textarea" formControlName="reason" (ngModelChange)="validateForm()"></textarea>
  
          <button type="submit" class="btn btn-green center top-margin" style="margin-bottom:30px" *ngIf="isCreate"
            [disabled]="!(isFormValid) || disabledBtnWhileCallingAPI" (click)="onUpdateMaintenance()">Create</button>
          <button type="submit" class="btn btn-green center top-margin" style="margin-bottom:30px" *ngIf="!isCreate"
            [disabled]="!(isFormValid) || disabledBtnWhileCallingAPI" (click)="onUpdateMaintenance()">Update</button>
        </div>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>